import Flipbox from './Flipbox'

function Navbar() {
  return (
    <div>
      <Flipbox />
    </div>
  )
}

export default Navbar
