const CardData10 = [
  
  {
    title: 'Red Hot Chili Peppers',
    description:
      'Red Hot Chili Peppers es una banda de rock estadounidense formada en 1983 en Los Ángeles, California. Sus integrantes son, el vocalista Anthony Kiedis, el guitarrista John Frusciante, el bajista Flea y el baterista Chad Smith. El estilo musical de la banda fusiona el funk tradicional con el rock y el rock alternativo incluyendo elementos de otros géneros como el rap, pop rock, heavy metal, dance, punk, hip hop e indie rock. Aunque en algunos sitios son considerados los inventores del punk funk.',
    videourl: 'http://youtube.com/playlist?list=PL1343579D67ED4740',
    price: 36,
    coverImg: 'rhcp.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://redhotchilipeppers.com/',
    facebook: 'https://facebook.com/chilipeppers',
    instagram: 'http://instagram.com/chilipeppers',
    twitter: 'https://twitter.com/chilipeppers',
    youtube: 'https://www.youtube.com/channel/UCEuOwB9vSL1oPKGNdONB4ig',
    spotify: 'https://open.spotify.com/artist/0L8ExT028jH3ddEcZwqJJ5',
    deezer: 'https://www.deezer.com/mx/artist/182',
    apple: 'https://music.apple.com/mx/artist/red-hot-chili-peppers/889780',
    soundcloud: 'https://soundcloud.com/red-hot-chili-peppers-official',
    tidal: 'https://listen.tidal.com/artist/13957',
    wiki: 'https://es.wikipedia.org/wiki/Red_Hot_Chili_Peppers',
    ticketmaster:
      'https://www.ticketmaster.com/red-hot-chili-peppers-tickets/artist/848229',
    tiktok: 'https://www.tiktok.com/@chilipeppers?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000SX8BBO/red-hot-chili-peppers',
  },
  {
    title: 'Imagine Dragons',
    description:
      "Imagine Dragons es una banda estadounidense de Pop Rock originaria de Las Vegas, Nevada. Está compuesta por Dan Reynolds (vocalista), Daniel Wayne Sermon (guitarrista), Ben McKee (bajista) y Daniel Platzman (baterista). La banda ganó el reconocimiento mundial con el lanzamiento de su álbum de estudio debut Night Visions (2012), y con su canción 'It's Time'. Billboard colocó a Imagine Dragons en la cima de su ranking del 2013 'Year In Rock', además los llamó «la banda revelación del 2013».",
    videourl:
      'https://youtube.com/playlist?list=PLG7Rcw-np_6fJKgE9Lhu2gW-cq4bolc2B',
    price: 36,
    coverImg: 'imaginedragons.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.imaginedragonsmusic.com/',
    facebook: 'https://www.facebook.com/ImagineDragons',
    instagram: 'https://www.instagram.com/imaginedragons/',
    twitter: 'https://twitter.com/Imaginedragons',
    youtube: 'https://www.youtube.com/channel/UCT9zcQNlyht7fRlcjmflRSA',
    spotify: 'https://open.spotify.com/artist/53XhwfbYqKCa1cC15pYq2q',
    deezer: 'https://www.deezer.com/mx/artist/416239',
    apple: 'https://music.apple.com/mx/artist/imagine-dragons/358714030',
    soundcloud: 'https://soundcloud.com/imaginedragons',
    tidal: 'https://listen.tidal.com/artist/4394210',
    wiki: 'https://es.wikipedia.org/wiki/Imagine_Dragons',
    ticketmaster:
      'https://www.ticketmaster.com/imagine-dragons-tickets/artist/1435919',
    tiktok: 'https://www.tiktok.com/@imaginedragons?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B003AM1Q94/imagine-dragons',
  },
  {
    title: 'Fallout Boy',
    description:
      'Fall Out Boy es una banda de rock estadounidense formada en Wilmette, Illinois, un suburbio de Chicago, en 2001. La banda está compuesta por el vocalista principal y guitarrista Patrick Stump, el bajista Pete Wentz, el baterista Andy Hurley y el guitarrista Joe Trohman. La banda se originó en la escena de hardcore punk de Chicago y fue formada por Wentz y Trohman como un proyecto paralelo de pop punk; Stump se unió poco después. El grupo pasó por varios bateristas antes de que Hurley se uniera. Su álbum debut, Take This to Your Grave (2003), se convirtió en un éxito underground y ayudó a la banda a obtener una base de seguidores dedicados a través de intensas giras. Take This to Your Grave es citado como un disco influyente en la música pop punk de los años 2000..',
    videourl: 'https://youtube.com/playlist?list=PLB43607A7266FA847',
    price: 36,
    coverImg: 'falloutboy.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://falloutboy.com/',
    facebook: 'https://www.facebook.com/falloutboy',
    instagram: 'https://www.instagram.com/falloutboy/',
    twitter: 'https://twitter.com/falloutboy',
    youtube: 'https://www.youtube.com/channel/UC2qWxZHgnlwDvcmLqP23jrA',
    spotify: 'https://open.spotify.com/artist/4UXqAaa6dQYAk18Lv7PEgX',
    deezer: 'https://www.deezer.com/mx/artist/404',
    apple: 'https://music.apple.com/us/artist/fall-out-boy/28673423',
    soundcloud: 'https://soundcloud.com/falloutboy',
    tidal: 'https://listen.tidal.com/artist/17231',
    wiki: 'https://es.wikipedia.org/wiki/Fall_Out_Boy',
    ticketmaster:
      'https://www.ticketmaster.com/fall-out-boy-tickets/artist/854398',
    tiktok: 'https://www.tiktok.com/@falloutboy?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNJRG/fall-out-boy',
  },
  {
    title: 'Gorillaz',
    description:
      'Gorillaz es una banda virtual inglesa creada en 1998 por Damon Albarn y Jamie Hewlett. La banda está compuesta por cuatro miembros ficticios: 2-D, Noodle, Murdoc Niccals y Russel Hobbs. La mayoría de sus canciones junto con todo su universo ficticio, se presentan a través de vídeos musicales animados, de animación tradicional y animación por computadora, entrevistas y pequeños cortos animados. En la realidad, Albarn es el único miembro permanente de la banda. El productor y ejecutivo Remi Kabaka Jr. empezaría a colaborar con el grupo en 2016 después de proveer por varios años la voz de Russel Hobbs y sería nombrado miembro oficial junto a Albarn y Hewlett en 2019 en el documental de Gorillaz.',
    videourl:
      'https://youtube.com/playlist?list=PLtKoi37ubAW0tpP93-fO4FQ9memrbcXnO',
    price: 36,
    coverImg: 'gorillaz.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.gorillaz.com/',
    facebook: 'https://www.facebook.com/Gorillaz',
    instagram: 'https://www.instagram.com/gorillaz/',
    twitter: 'https://twitter.com/Gorillaz',
    youtube: 'https://www.youtube.com/@Gorillaz',
    spotify: 'https://open.spotify.com/artist/3AA28KZvwAUcZuOKwyblJQ',
    deezer: 'https://www.deezer.com/en/artist/14',
    apple: 'https://music.apple.com/mx/artist/gorillaz/567072',
    soundcloud: 'https://soundcloud.com/gorillaz',
    tidal: 'https://listen.tidal.com/artist/4394210',
    wiki: 'https://es.wikipedia.org/wiki/Gorillaz',
    ticketmaster: 'https://www.ticketmaster.com/gorillaz-tickets/artist/807314',
    tiktok: 'https://www.tiktok.com/@gorillaz',
    napster: 'https://music.amazon.com.mx/artists/B000S282KK/gorillaz',
  },

  {
    title: 'Foo Fighters',
    description:
      'Foo Fighters es una banda de rock estadounidense formada en la ciudad de Seattle en 1994 por Dave Grohl, exbaterista de Nirvana y Scream. El grupo debe su nombre a los ovnis y los diversos fenómenos aéreos que fueron reportados por los pilotos de los aviones aliados en la Segunda Guerra Mundial, que se conocen colectivamente como Foo Fighters. Antes del lanzamiento de su álbum debut en 1995, Grohl, como único miembro oficial, reclutó al bajista Nate Mendel y el baterista William Goldsmith, ambos anteriormente miembros de Sunny Day Real Estate, así como su compañero en las giras de Nirvana, Pat Smear como guitarrista para completar la alineación.',
    videourl:
      'https://youtube.com/playlist?list=PLyjqnQsvvnpLDEXLf58J39uJTfTXjmVzx',
    price: 36,
    coverImg: 'foo.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.FooFighters.com/',
    youtube: 'https://www.youtube.com/channel/UCi2KNss4Yx73NG0JARSFe0A',
    facebook: 'https://www.facebook.com/foofighters',
    instagram: 'https://www.instagram.com/foofighters/',
    twitter: 'https://twitter.com/foofighters',
    spotify: 'https://open.spotify.com/artist/7jy3rLJdDQY21OgRLCZ9sD',
    deezer: 'https://www.deezer.com/mx/artist/566',
    apple: 'https://music.apple.com/mx/artist/foo-fighters/6906197',
    soundcloud: 'https://soundcloud.com/foofighters',
    tidal: 'https://listen.tidal.com/artist/2753',
    wiki: 'https://es.wikipedia.org/wiki/Foo_Fighters',
    ticketmaster:
      'https://www.ticketmaster.com/foo-fighters-tickets/artist/776005',
    tiktok: 'https://www.tiktok.com/@foofightersofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKAGGM/foo-fighters',
  },
  {
    title: 'Nirvana',
    description:
      'Nirvana fue una banda de grunge estadounidense procedente de Aberdeen, Washington, Estados Unidos fundada por el vocalista y guitarrista Kurt Cobain y el bajista Krist Novoselic en 1987. Desde su formación, Nirvana pasó por una serie de 6 cambios de baterista hasta que en 1990 se unió, de manera definitiva, el baterista Dave Grohl. Nirvana es catalogada como una de las bandas más famosas, importantes e influyentes de la historia de la música, siendo considerada como un símbolo de su generación.',
    videourl:
      'https://youtube.com/playlist?list=PLQXcAyvY0ao6DjukeNbIRv7XKdUR2elCW',
    price: 36,
    coverImg: 'nirvana.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.nirvana.com/',
    youtube: 'https://www.youtube.com/channel/UCFMZHIQMgBXTSxsr86Caazw',
    facebook: 'https://www.facebook.com/Nirvana/',
    instagram: 'https://www.instagram.com/kurtsmemento/',
    twitter: 'https://twitter.com/chilipeppers',
    spotify: 'https://open.spotify.com/artist/6olE6TJLqED3rqDCT0FyPh',
    deezer: 'https://www.deezer.com/mx/artist/415',
    apple: 'https://music.apple.com/mx/artist/nirvana/112018',
    soundcloud: 'https://soundcloud.com/nirvana',
    tidal: 'https://listen.tidal.com/artist/19368',
    wiki: 'https://es.wikipedia.org/wiki/Nirvana_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/nirvana-tickets/artist/997582',
    tiktok: 'https://www.tiktok.com/@nirvana356?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKLV4I/nirvana',
  },
  {
    title: 'Smashing Pumpkins',
    description:
      "The Smashing Pumpkins es una banda estadounidense de rock alternativo formada en Chicago en 1988. Fundada en un inicio por el vocalista Billy Corgan (voz, guitarra) y James Iha (guitarra), poco después incluyeron a Jimmy Chamberlin (batería) y D'arcy Wretzky (bajo), completando su formación original. La banda ha sufrido muchos cambios en su formación a lo largo de su existencia, con Corgan y el guitarrista Jeff Schroeder siendo los únicos miembros oficiales a partir de 2014.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_meh35Hhh_1U50Fcskgu8vhXHoIDRJiDd8',
    price: '$50',
    coverImg: 'smashingpumpkins.gif',
    category: 'Alternativo',
    location: 'United States',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://smashingpumpkins.com/',
    youtube: 'https://www.youtube.com/channel/UCZuPJZ2kGFdlbQu1qotZaHw',
    facebook: 'https://www.facebook.com/smashingpumpkins',
    instagram: 'https://www.instagram.com/smashingpumpkins/',
    twitter: 'https://twitter.com/SmashingPumpkin',
    spotify: 'https://open.spotify.com/artist/790FomKkXshlbRYZFtlgla',
    deezer: 'https://www.deezer.com/mx/artist/193331',
    apple: 'https://music.apple.com/us/artist/the-smashing-pumpkins/1646302',
    soundcloud: 'https://soundcloud.com/smashingpumpkins',
    tidal: 'https://listen.tidal.com/search?q=Smashing%20Pumpkins',
    wiki: 'https://es.wikipedia.org/wiki/The_Smashing_Pumpkins',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Smashing+Pumpkins',
    tiktok: 'https://www.tiktok.com/@thesmashingpumpkins',
    napster:
      'https://music.amazon.com.mx/artists/B00G9QUPUO/the-smashing-pumpkins',
  },
  {
    title: 'New Order',
    description:
      'New Order (en español, literalmente, «Nueva orden» o Nuevo orden es un grupo británico de rock y electrónica formado en 1980.La banda fue formada por los antiguos integrantes de Joy Division al desintegrarse esta tras el suicidio de su cantante, Ian Curtis. Desde entonces, se ha convertido en una de las bandas más aclamadas por la crítica y una de las más influyentes de la década de los 1980.',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'neworder.gif',
    category: 'Retro',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.neworder.com/',
    youtube: 'https://www.youtube.com/channel/UCeROFwqsf3ADf02l5JzVKJg',
    facebook: 'https://www.facebook.com/NewOrderOfficial/',
    instagram: 'https://www.instagram.com/neworderofficial/',
    twitter: 'https://twitter.com/neworder',
    spotify: 'https://open.spotify.com/artist/0yNLKJebCb8Aueb54LYya3',
    deezer: 'https://www.deezer.com/mx/artist/2016',
    apple: 'https://music.apple.com/mx/artist/new-order/176722',
    soundcloud: 'https://soundcloud.com/neworderofficial',
    tidal: 'https://listen.tidal.com/artist/11950',
    wiki: 'https://es.wikipedia.org/wiki/New_Order',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=New+Order',
    tiktok: 'https://www.tiktok.com/@neworder',
    napster: 'https://music.amazon.com.mx/artists/B000QJNM3M/new-order',
  },
  {
    title: 'Kings of Leon',
    description:
      'Kings of Leon es grupo de música estadounidense de rock fundado en Nashville, Tennessee, en 1999. Está formado por tres hermanos, Caleb, Nathan y Jared Followill y su primo Matthew Followill. Cada miembro de la familia es conocido por su segundo nombre en vez del primero.1​ El nombre del grupo deriva del padre y abuelo de Nathan, Caleb y Jared, los dos llamados Leon.',
    videourl:
      'https://youtube.com/playlist?list=PLcaPdH-BvlV-H3MqKzUpvqj4TLL58ZATU',
    price: '$50',
    coverImg: 'leon.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://kingsofleon.com/',
    youtube: 'https://www.youtube.com/channel/UCfJein8E4rcYZSUCc8UqyMA',
    facebook: 'https://www.facebook.com/KingsOfLeon',
    instagram: 'https://www.instagram.com/kingsofleon/',
    twitter: 'https://twitter.com/kingsofleon',
    spotify: 'https://open.spotify.com/artist/2qk9voo8llSGYcZ6xrBzKx',
    deezer: 'https://www.deezer.com/mx/artist/385',
    apple: 'https://music.apple.com/mx/artist/kings-of-leon/1883403',
    soundcloud: 'https://soundcloud.com/kingsofleon',
    tidal: 'https://soundcloud.com/kingsofleon',
    wiki: 'https://es.wikipedia.org/wiki/Kings_of_Leon',
    ticketmaster:
      'https://www.ticketmaster.com/kings-of-leon-tickets/artist/862453',
    tiktok: 'https://www.tiktok.com/@kingsofleon',
    napster: 'https://music.amazon.com.mx/artists/B000UOEVRE/kings-of-leon',
  },

  {
    title: ' The Jonas Brothers',
    description:
      "The Jonas Brothers (/ˈdʒoʊnəs/) are an American pop rock band. Formed in 2005, they gained popularity from their appearances on the Disney Channel television network. They consist of three brothers: Kevin Jonas, Joe Jonas, and Nick Jonas.[1][2][3] Raised in Wyckoff, New Jersey, the Jonas Brothers moved to Little Falls, New Jersey, in 2005, where they wrote their first record that made its Hollywood Records release.[4] They starred in the 2008 Disney Channel Original Movie Camp Rock and its 2010 sequel, Camp Rock 2: The Final Jam. They also starred in their own Disney Channel series Jonas, which was rebranded as Jonas L.A. for its second season. The band have released five albums: It's About Time (2006), Jonas Brothers (2007), A Little Bit Longer (2008), Lines, Vines and Trying Times (2009), and Happiness Begins (2019).",
    videourl:
      'https://youtube.com/playlist?list=PLISjWix87QOmCbVCUY5g-vEMSgUITz575',
    price: '$50',
    coverImg: 'jonas.gif',
    category: 'Pop-Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.jonasbrothers.com/',
    youtube: 'https://www.youtube.com/channel/UCFvXnyAhluG3sa6p3eOs_LA',
    facebook: 'https://www.facebook.com/JonasBrothers/',
    instagram: 'https://www.instagram.com/jonasbrothers/',
    twitter: 'https://twitter.com/jonasbrothers',
    spotify: 'https://open.spotify.com/artist/7gOdHgIoIKoe4i9Tta6qdD',
    deezer: 'https://www.deezer.com/mx/artist/15888',
    apple: 'https://music.apple.com/us/artist/jonas-brothers/114342891',
    soundcloud: 'https://soundcloud.com/jonasbrothers',
    tidal: 'https://listen.tidal.com/artist/14641320',
    wiki: 'https://en.wikipedia.org/wiki/Jonas_Brothers',
    ticketmaster: 'https://boletos.lataquillamx.com/venta/es/jonasbrothersgdl',
    tiktok: 'https://www.tiktok.com/@jonasbrothers?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00137FSEC/jonas-brothers',
  },
  {
    title: 'Evanescence',
    description:
      'Evanescence is an American rock band founded in Little Rock, Arkansas in 1995 by singer and musician Amy Lee and guitarist Ben Moody.After recording independent albums, the band released their first full-length album, Fallen, on Wind-up Records in 2003. On the strength of hit singles Bring Me to Life and My Immortal, Fallen sold more than 17 million copies worldwide, and won the band two Grammy Awards out of six nominations. A year later, Evanescence released their first live album, Anywhere but Home, which sold over one million copies worldwide.',
    videourl: 'https://youtube.com/playlist?list=PL198741246C1CF574',
    price: '$50',
    coverImg: 'evanessence.jpeg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://evanescencestore.com/dept/the-bitter-truth?cp=101960_108529',
    youtube: 'https://www.youtube.com/channel/UCJeH7gl6PbDVV4DTldIOPOA',
    facebook: 'https://www.facebook.com/Evanescence',
    instagram: 'https://www.instagram.com/evanescenceofficial/',
    twitter: 'https://twitter.com/evanescence',
    spotify: 'https://open.spotify.com/artist/5nGIFgo0shDenQYSE0Sn7c',
    deezer: 'https://www.deezer.com/mx/artist/98',
    apple: 'https://music.apple.com/mx/artist/evanescence/42102393',
    soundcloud: 'https://soundcloud.com/evilian/evanessence-my-immortal-dj',
    tidal: 'https://soundcloud.com/user-844058994/sets/evanessence',
    wiki: 'https://en.wikipedia.org/wiki/Evanescence',
    ticketmaster:
      'https://www.ticketmaster.com/evanescence-tickets/artist/857529',
    tiktok: 'https://www.tiktok.com/@evanescence',
    napster: 'https://music.amazon.com.mx/artists/B0013TI0CC/evanescence',
  },

  {
    title: 'Beastie Boys',
    description:
      'Beastie Boys fue un grupo musical de rap neoyorquino fundado en Brooklyn en 1981. Comenzaron tocando hardcore punk en sus primeras grabaciones para la disquera independiente Ratcage Records de David Parsons. Habiendo asimilado la cultura callejera de la gran manzana desde 1983 comenzaron a experimentar con hip hop y hacer rap. También han editado discos basados en el funk y el jazz y es frecuente que en sus discos de hip hop incluyan rock o punk o algún corte acústico. También son conocidas las actividades de turntablism y de producción musical de sus miembros. Según Mike D., Beastie quiere decir: Boys Entering Anarchistic States Towards Internal Excellence',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mv36h4Y-yvaQcMMpsH3vNWKRDMFuhUhkY',
    price: 36,
    coverImg: 'beastieboys.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://beastieboys.com/',
    youtube: 'https://www.youtube.com/channel/UCpRUSBcRWUQZIj3_jWF19Dg',
    facebook: 'https://www.facebook.com/beastieboys',
    instagram: 'https://www.instagram.com/beastieboysstory/',
    twitter: 'https://twitter.com/beastieboys',
    spotify: 'https://open.spotify.com/artist/03r4iKL2g2442PT9n2UKsx',
    deezer: 'https://www.deezer.com/mx/artist/194746',
    apple: 'https://music.apple.com/mx/artist/beastie-boys/1971863',
    soundcloud: 'https://soundcloud.com/beastieboys',
    tidal: 'https://listen.tidal.com/artist/8963',
    wiki: 'https://es.wikipedia.org/wiki/Beastie_Boys',
    ticketmaster:
      'https://www.ticketmaster.com/beastie-boys-tickets/artist/702455',
    tiktok: 'https://www.tiktok.com/@beastieboys_tiktok?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJPXG6/beastie-boys',
  },
  {
    title: 'Green Day',
    description:
      'Green Day es una banda estadounidense de rock integrada por Billie Joe Armstrong, Mike Dirnt y Tré Cool. El grupo originario de Berkeley, California, se gestó prematuramente en 1986 bajo el nombre de Sweet Children, con el baterista John Kiffmeyer. En 1989 se cambiaron al nombre actual ya que el anterior se confundía con el de una banda local de esa época y poco después del lanzamiento de su primer álbum de estudio, Tré Cool reemplazó a Al Sobrante.',
    videourl: 'http://youtube.com/playlist?list=PL5150F38E402FACE8',
    price: 36,
    coverImg: 'greenday.gif',
    category: 'Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://greenday.com/',
    youtube: 'https://www.youtube.com/channel/UCqC_GY2ZiENFz2pwL0cSfAw',
    facebook: 'https://www.facebook.com/GreenDay/',
    instagram: 'https://www.instagram.com/greenday/',
    twitter: 'https://twitter.com/GreenDay',
    spotify: 'https://open.spotify.com/search/Green%20Day',
    deezer: 'https://www.deezer.com/search/green%20day',
    apple: 'https://music.apple.com/mx/artist/green-day/954266',
    soundcloud: 'https://soundcloud.com/greenday',
    tidal: 'https://listen.tidal.com/artist/13866',
    wiki: 'https://es.wikipedia.org/wiki/Green_Day',
    ticketmaster:
      'https://www.ticketmaster.com/green-day-tickets/artist/768353',
    tiktok: 'https://www.tiktok.com/@greenday?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJOSO/green-day',
  },
  {
    title: 'Rage Against The Machine',
    description:
      'Rage Against the Machine es un grupo musical estadounidense de rap metal fundado en el año 1991 por Tom Morello y Zack de la Rocha. Junto con Tim Commerford y Brad Wilk, el grupo tocó durante toda la década de 1990 hasta su disolución en 2000. Luego se tomaron un descanso indefinido hasta que en abril de 2007 y coincidiendo con la disolución de Audioslave anunciaron su regreso en el festival de música de Coachella en California, siendo la primera vez que tocaban todos juntos en siete años. A raíz de esto, la banda continuó dando conciertos en varios festivales alrededor del mundo hasta 2011, y no sería hasta 2019 que volverían a retomar la banda hasta la actualidad.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lEr5UKXA2UoinNIDdZ8Pd3BshVWIvYB3U',
    price: 36,
    coverImg: 'ratm.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://tour.ratm.com/',
    youtube: 'https://www.youtube.com/channel/UCm_W_hbwbzJE8w52jZ2NO0A',
    facebook: 'https://www.facebook.com/RATM',
    instagram: 'https://www.instagram.com/rageagainstthemachine/',
    twitter: 'https://twitter.com/ratmofficial',
    spotify: 'https://open.spotify.com/artist/2d0hyoQ5ynDBnkvAbJKORj',
    deezer: 'https://www.deezer.com/mx/album/1398967',
    apple: 'https://music.apple.com/mx/artist/rage-against-the-machine/899409',
    soundcloud: 'https://soundcloud.com/greenday',
    tidal: 'https://listen.tidal.com/artist/741',
    wiki: 'https://es.wikipedia.org/wiki/Rage_Against_the_Machine',
    ticketmaster:
      'https://www.ticketmaster.com/rage-against-the-machine-tickets/artist/750164',
    tiktok: 'https://www.tiktok.com/@rageagainstthemachine91?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000XEE3CO/rage-against-the-machine',
  },
  {
    title: 'Nine Inch Nails',
    description:
      'Nine Inch Nails (abreviado NIN y estilizado NIИ) es una banda estadounidense de rock industrial fundada en 1988 por Trent Reznor en Cleveland, Ohio, Estados Unidos. Como su principal productor, cantante, compositor e instrumentista, Reznor es el único miembro oficial de Nine Inch Nails y el único responsable de la dirección de la banda.La música de Nine Inch Nails abarca un gran número de géneros musicales, aunque mantiene un sonido característico utilizando instrumentos electrónicos y procesamiento en el estudio de grabación. Después de grabar cada nuevo álbum de estudio, Reznor forma un grupo de músicos para las giras y conciertos. Suelen ser cambiantes y hacer arreglos en las canciones para ajustarlas a las actuaciones en directo. En el escenario, NIN suele utilizar elementos visuales espectaculares para sus actuaciones, entre las que destaca el espectáculo lumínico o enormes paneles de ledes y algunos táctiles que sirven como secuenciadores',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWe5i3Hpws_YZM4-cfnyJQz',
    price: 36,
    coverImg: 'nin.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.nin.com/',
    youtube: 'https://www.youtube.com/channel/UC4a5d57ZAWl999-YXw0C1Vg',
    facebook: 'https://www.facebook.com/ninofficial',
    instagram: 'https://www.instagram.com/nineinchnails/',
    twitter: 'https://twitter.com/nineinchnails',
    spotify: 'https://open.spotify.com/artist/0X380XXQSNBYuleKzav5UO',
    deezer: 'https://www.deezer.com/mx/artist/407',
    apple: 'https://music.apple.com/mx/artist/nine-inch-nails/107917',
    soundcloud: 'https://soundcloud.com/nineinchnails',
    tidal: 'https://listen.tidal.com/artist/15794',
    wiki: 'https://es.wikipedia.org/wiki/Nine_Inch_Nails',
    ticketmaster:
      'https://www.ticketmaster.com/nine-inch-nails-tickets/artist/735762',
    tiktok: 'https://www.tiktok.com/@nineinchnails_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000VZKOCS/nine-inch-nails',
  },
  {
    title: 'The White Stripes',
    description:
      'The White Stripes fue una banda estadounidense de rock alternativo, también algunas veces considerada dentro del movimiento del garage rock. Fue formada en 1997 en Detroit por la pareja compuesta por John Anthony Gillis bajo el nombre de Jack White y Meg White. Después de publicar sus primeras canciones y grabar dos álbumes de estudio, formaron parte del resurgimiento del garage rock de los años 60, caracterizado por su baja fidelidad de sonido y sus composiciones y arreglos simples inspirados principalmente por el punk rock, el blues rock, el folk rock y la música country. Su tercer y cuarto álbum White Blood Cells y Elephant de 2001 y 2003 respectivamente, los consagró en la escena musical internacional y llamó la atención de una gran variedad de medios de comunicación en los Estados Unidos y en Reino Unido con el sencillo «Seven Nation Army» que icónicamente se ha convertido en su mayor éxito.',
    videourl: 'http://youtube.com/playlist?list=PLAC9127A72919F8DC',
    price: 36,
    coverImg: 'whitestripes.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.whitestripes.com/',
    youtube: 'https://www.youtube.com/channel/UC0sQemK7pgX5fYy0k1MFsHg',
    facebook: 'https://www.facebook.com/thewhitestripes/',
    instagram: 'https://www.instagram.com/thewhitestripes/',
    twitter: 'https://twitter.com/whitestripesnet',
    spotify: 'https://open.spotify.com/artist/4F84IBURUo98rz4r61KF70',
    deezer: 'https://www.deezer.com/mx/artist/636',
    apple: 'https://music.apple.com/mx/artist/the-white-stripes/2456318',
    soundcloud: 'https://soundcloud.com/the-white-stripes',
    tidal: 'https://listen.tidal.com/artist/3520510',
    wiki: 'https://es.wikipedia.org/wiki/The_White_Stripes',
    ticketmaster:
      'https://www.ticketmaster.com/the-white-stripes-tickets/artist/714425',
    tiktok: 'https://www.tiktok.com/@thewhitestripes0?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00GK8PAIS/the-white-stripes',
  },
  {
    title: '3 Doors Down',
    description:
      '3 Doors Down es un grupo estadounidense de rock alternativo fundado en Escatawpa, Misisipi, Estados Unidos en el año 1996. La banda originalmente estaba conformada por Brad Arnold (voz / batería), Todd Harrell (bajo) y Matt Roberts (guitarra). Más tarde, el guitarrista Chris Henderson se uniría a la banda en los primeros días de la creación de The Better Life, lanzando el álbum como una banda de cuatro integrantes.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mN84txxkmTCfryqZwmG2iUaDPDXkuMohc',
    price: 36,
    coverImg: 'threedoorsdown.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.3doorsdown.com/#!/',
    youtube: 'https://www.youtube.com/channel/UCxYS_wIljaOTgFFDK2RK15w',
    facebook: 'https://www.facebook.com/3DoorsDown',
    instagram: 'https://www.instagram.com/3doorsdown/',
    twitter: 'https://twitter.com/3doorsdown',
    spotify: 'https://open.spotify.com/artist/2RTUTCvo6onsAnheUk3aL9',
    deezer: 'https://www.deezer.com/mx/artist/340',
    apple: 'https://music.apple.com/mx/artist/3-doors-down/101604',
    soundcloud: 'https://soundcloud.com/3-doors-down-780781253',
    tidal: 'https://listen.tidal.com/artist/30209',
    wiki: 'https://es.wikipedia.org/wiki/3_Doors_Down',
    ticketmaster:
      'https://www.ticketmaster.com/3-doors-down-tickets/artist/710683',
    tiktok: 'https://www.tiktok.com/@3doorsdownofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000TDVVMO/3-doors-down',
  },

  {
    title: 'Twenty One Pilots',
    description:
      'Twenty One Pilots es un dúo musical estadounidense de Columbus, Ohio. La banda se formó en 2009 por el vocalista Tyler Joseph junto con Nick Thomas y Chris Salih, quienes se fueron en 2011. Desde su partida, la formación ha consistido en Tyler Joseph y el baterista Josh Dun. El dúo es principalmente conocido por los sencillos-Stressed Out, -Ride y Heathens. El grupo recibió un Premio Grammy al mejor pop de dúo/grupo en los Premios Grammy de 2017.',
    videourl: 'https://youtube.com/playlist?list=PL0A42C164824B74F9',
    price: 36,
    coverImg: 'twentyonepilots.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.twentyonepilots.com/',
    youtube: 'https://www.youtube.com/channel/UCBQZwaNPFfJ1gZ1fLZpAEGw',
    facebook: 'https://www.facebook.com/twentyonepilots',
    instagram: 'https://www.instagram.com/twentyonepilots/',
    twitter: 'https://twitter.com/twentyonepilots',
    spotify:
      'https://open.spotify.com/playlist/1WCRwQ0f4yT8tuIUUxP5m1?si=MuTYg4tKSU-RTcY15OPLzA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/647650',
    apple: 'https://music.apple.com/mx/artist/twenty-one-pilots/349736311',
    soundcloud: 'https://soundcloud.com/the-white-stripes',
    tidal: 'https://listen.tidal.com/artist/4664877',
    wiki: 'https://es.wikipedia.org/wiki/Twenty_One_Pilots',
    ticketmaster:
      'https://www.ticketmaster.com/twenty-one-pilots-tickets/artist/1495843',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=twenty%20one%20pilots&t=1654149324526',
    napster: 'https://music.amazon.com.mx/artists/B0033LXVEU/twenty-one-pilots',
  },



  {
    title: 'Daft Punk',
    description:
      'Daft Punk fue un dúo francés de french house formado por los DJ y productores Guy-Manuel de Homem-Christo y Thomas Bangalter. Daft Punk alcanzó una gran popularidad dentro del house a mediados de los años 90 en Francia y continuó con su éxito los años siguientes. El dúo también es acreditado por la producción de canciones que se consideran esenciales en el estilo french house.',
    videourl:
      'https://youtube.com/playlist?list=PLSdoVPM5WnneERBKycA1lhN_vPM6IGiAg',
    price: 36,
    coverImg: 'daftpunk.gif',
    category: 'Rock ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://daftpunk.lnk.to/essentials',
    youtube: 'https://www.youtube.com/channel/UC_kRDKYrUlrbtrSiyu5Tflg',
    facebook: 'https://www.facebook.com/daftpunk',
    instagram: 'https://www.instagram.com/daftpunk/',
    twitter: 'https://twitter.com/daftpunk',
    spotify: 'https://open.spotify.com/artist/4tZwfgrHOc3mvqYlEYSvVi',
    deezer: 'https://www.deezer.com/mx/artist/27',
    apple: 'https://music.apple.com/mx/artist/daft-punk/5468295',
    soundcloud: 'https://soundcloud.com/user7809901',
    tidal: 'https://listen.tidal.com/artist/8847',
    wiki: 'https://es.wikipedia.org/wiki/Daft_Punk',
    ticketmaster:
      'https://www.ticketmaster.com/daft-punk-tickets/artist/807213',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=Daft%20Punk&t=165416523',
    napster: 'https://music.amazon.com.mx/artists/B000S9ULT8/daft-punk',
  },
  {
    title: 'Oasis',
    description:
      'Oasis fue una banda de rock inglesa, formada en Mánchester en 1990. En sus inicios, conocidos como Rain (Español: Lluvia), el grupo contaba en sus filas con el cantante Chris Hutton, el guitarrista Paul Arthurs, el bajista Paul McGuigan y el baterista Daniel Alexander, a los que más tarde se unirían, en 1991, Liam Gallagher en la voz y Tony McCarroll como batería. El último en unírseles fue el hermano mayor de Liam, Noel Gallagher (a petición de su hermano) quien obtuvo el papel de compositor principal, guitarrista principal y cantante secundario.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8QP4rw8e6AhfXuViy2iaV_iiMNrPSfXI',
    price: 36,
    coverImg: 'oasis.gif',
    category: 'Rock ',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://oasisinet.com/',
    youtube: 'https://www.youtube.com/channel/UC_kRDKYrUlrbtrSiyu5Tflg',
    facebook: 'https://www.facebook.com/OasisOfficial',
    instagram: 'https://www.instagram.com/oasis/',
    twitter: 'https://twitter.com/oasis',
    spotify: 'https://open.spotify.com/artist/2DaxqgrOhkeH0fpeiQq2f4',
    deezer: 'https://www.deezer.com/mx/artist/927',
    apple: 'https://music.apple.com/us/artist/oasis/512633',
    soundcloud: 'https://soundcloud.com/oasisofficial',
    tidal: 'https://listen.tidal.com/artist/109',
    wiki: 'https://es.wikipedia.org/wiki/Oasis_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/oasis-tickets/artist/766720',
    tiktok: 'https://www.tiktok.com/@oasis',
    napster: 'https://music.amazon.com.mx/artists/B000QKJROE/oasis',
  },
  {
    title: 'Depeche Mode',
    description:
      'Depeche Mode  es una banda británica de música electrónica formada en 1980 por Vince Clarke, Andrew Fletcher, Martin Gore y David Gahan. . Según el documental Historia del rock de la BBC son los padres del rock electrónico; está considerado uno de los mejores exponentes del género, siendo importantes precursores del uso del sintetizador como instrumento y del sampler como recurso musical, así como de la realización de videos musicales. Ha vendido más de 120 millones de álbumes,5 sumando el total de ventas de sus discos en todo el mundo, incluyendo sencillos, convirtiéndose en el más exitoso grupo de música electrónica en la historia. También fue elegido entre los 50 mejores grupos de música de todos los tiempos y uno de los 10 más influyentes de la música contemporánea.',
    videourl:
      'http://youtube.com/playlist?list=PLzFp3qeLQkzew0gMq8Tfcc-hlQnMXLCJt',
    price: 36,
    coverImg: 'depeche.gif',
    category: 'Rock Alternativo',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://depechemode.com/',
    youtube: 'https://www.youtube.com/channel/UCM-CWGUijAC-8idv6k6Fygw',
    facebook: 'https://www.facebook.com/depechemode',
    instagram: 'https://www.instagram.com/depechemode/',
    twitter: 'https://twitter.com/depechemode',
    spotify: 'https://open.spotify.com/playlist/5koOX3Udy5HPMYqHnceRHE',
    deezer: 'https://www.deezer.com/mx/artist/545',
    apple: 'https://music.apple.com/mx/artist/depeche-mode/148377',
    soundcloud: 'https://soundcloud.com/depeche_mode',
    tidal: 'https://listen.tidal.com/artist/8877',
    wiki: 'https://es.wikipedia.org/wiki/Depeche_Mode',
    ticketmaster:
      'https://www.ticketmaster.com/depeche-mode-tickets/artist/734907',
    tiktok: 'https://www.tiktok.com/@depeche._.mode?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNJP8/depeche-mode',
  },
  {
    title: 'The Cure',
    description:
      'The Cure es una banda británica de rock formada en 1976 en Crawley (Inglaterra).En sus orígenes, se llamó Easy Cure por un breve período, y ya figuraba en sus filas uno de sus tres fundadores y futuro líder, Robert Smith, como guitarra solista. El grupo alcanzó la cota más alta de su éxito comercial entre finales de la década de los ochenta y la primera mitad de la década de los noventa.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_liOBEKtOLF16_O1XBslp0F1UUIeMRz_k8',
    price: 80,
    coverImg: 'thecure.gif',
    category: 'Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.thecure.com/',
    youtube: 'https://www.youtube.com/c/TheCurePL',
    facebook: 'https://www.facebook.com/thecure',
    instagram: 'https://www.instagram.com/thecure/',
    twitter: 'https://twitter.com/thecure',
    spotify: 'https://open.spotify.com/artist/7bu3H8JO7d0UbMoVzbo70s',
    deezer: 'https://www.deezer.com/mx/artist/381',
    apple: 'https://music.apple.com/mx/artist/the-cure/566519',
    soundcloud: 'https://soundcloud.com/thecureofficial',
    tidal: 'https://listen.tidal.com/artist/18695',
    wiki: 'https://es.wikipedia.org/wiki/The_Cure',
    ticketmaster:
      'https://www.ticketmaster.com/the-cure-tickets/artist/915776?sort=date%2Cname%2Casc&radius=75&unit=miles&daterange=all',
    tiktok: 'https://www.tiktok.com/@thecureargentina?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00G9BXSCG/the-cure',
  },
  {
    title: 'U2',
    description:
      'U2 es una banda de rock alternativo originaria de Dublín, formada en 1976 por Bono, The Edge, Adam Clayton, y Larry Mullen Jr.. El sonido inicial de U2 tenía sus raíces en el post-punk, pero posteriormente irán incorporando a su música elementos de otros géneros: Su cancionero es extenso y está repleto de variaciones: desde el rock más clásico hasta el pop más redondo, pasando por el coqueteo con la electrónica o los homenajes al góspel. A lo largo de la búsqueda musical de la banda, han mantenido un sonido construido a base de instrumentales melódicos, destacados por la textura musical de The Edge y la voz expresiva de Bono, a las que se suma una base característica integrada por la batería de Mullen y el bajo de Clayton. Sus letras, a menudo ordenadas con imágenes espirituales, se centran en temas personales y temática de justicia social y paz..',
    videourl:
      'https://youtube.com/playlist?list=PL80sr_OFD9CG16KAPA3GA2dQcAeFFpNUc',
    price: 80,
    coverImg: 'u2.gif',
    category: 'Rock',
    location: 'Irlanda',
    locationImg: 'ireland.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.u2.com/index/home',
    youtube: 'https://www.youtube.com/channel/UC4gPNusMDwx2Xm-YI35AkCA',
    facebook: 'https://www.facebook.com/u2',
    instagram: 'https://www.instagram.com/u2/',
    twitter: 'https://twitter.com/u2songs',
    spotify: 'https://open.spotify.com/artist/51Blml2LZPmy7TTiAg47vQ',
    deezer: 'https://www.deezer.com/search/U2',
    apple: 'https://music.apple.com/mx/artist/u2/78500',
    soundcloud: 'https://soundcloud.com/u2',
    tidal: 'https://listen.tidal.com/artist/17123',
    wiki: 'https://es.wikipedia.org/wiki/U2',
    ticketmaster: 'https://www.ticketmaster.com/u2-tickets/artist/736365',
    tiktok: 'https://www.tiktok.com/@u2?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP7SA/u2',
  },
  {
    title: 'Blur',
    description:
      'Blur es una banda de rock británica formada en Londres en 1988. Está formada por el cantante Damon Albarn, el guitarrista Graham Coxon, el bajista Alex James y el baterista Dave Rowntree. Su álbum debut, Leisure (1991), incorporó los sonidos de madchester y shoegazing. Tras un cambio estilístico influenciado por bandas pop británicas como The Kinks, The Beatles y XTC, Blur lanzó Modern Life Is Rubbish (1993), Parklife (1994) y The Great Escape (1995). Como resultado, la banda ayudó a popularizar el género britpop y logró una gran popularidad en el Reino Unido, con la ayuda de una batalla en las listas de éxitos con la banda rival Oasis en 1995 denominada «La batalla del britpop',
    videourl:
      'https://youtube.com/playlist?list=PLETB5bOsgcnI73WMhyN8HN7OUT9AKAqas',
    price: '$50',
    coverImg: 'blur.gif',
    category: 'Alternative Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.blur.co.uk/',
    youtube: 'https://www.youtube.com/channel/UCLdfTpBoh9G_DI3OURnIFOQ',
    facebook: 'https://www.facebook.com/blur',
    instagram: 'https://www.instagram.com/blur/',
    twitter: 'https://twitter.com/blurofficial',
    spotify: 'https://open.spotify.com/artist/7MhMgCo0Bl0Kukl93PZbYS',
    deezer: 'https://www.deezer.com/mx/artist/743',
    apple: 'https://music.apple.com/us/artist/blur/528564',
    soundcloud: 'https://soundcloud.com/blurofficial',
    tidal: 'https://listen.tidal.com/artist/10052',
    wiki: 'https://en.wikipedia.org/wiki/Blur_(band)',
    ticketmaster: 'https://www.ticketmaster.com/blur-tickets/artist/740429',
    tiktok: 'https://www.tiktok.com/@blur',
    napster: 'https://music.amazon.com.mx/artists/B000SA1AVU/blur',
  },
  {
    title: 'The Cranberries',
    description:
      "The Cranberries fue una banda irlandesa de rock alternativo que estuvo en activo desde 1989 hasta 2019. Fue fundada en Limerick, Irlanda en 1989 bajo el nombre The Cranberry Saw Us, denominación cambiada más tarde por la vocalista Dolores O'Riordan cuando entró a formar parte del grupo en 1990.Saltó a la fama internacional en la década de 1990 con su álbum debut, Everybody Else Is Doing It, So Why Can't We?, que se convirtió en un éxito comercial, vendiendo más de cinco millones de copias solo en Estados Unidos.",
    videourl: 'http://youtube.com/playlist?list=PL4088FD8D60584979',
    price: '$50',
    coverImg: 'cran.gif',
    category: 'Alternative Rock',
    location: 'Irlanda',
    locationImg: 'ireland.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://cranberries.com/',
    youtube: 'https://www.youtube.com/channel/UCb0vzLwvr7xbJgdqMzejDhw',
    facebook: 'https://www.facebook.com/TheCranberries',
    instagram: 'https://www.instagram.com/thecranberries/',
    twitter: 'https://twitter.com/The_Cranberries',
    spotify: 'https://open.spotify.com/artist/7t0rwkOPGlDPEhaOcVtOt9',
    deezer: 'https://www.deezer.com/mx/artist/196',
    apple: 'https://music.apple.com/mx/artist/the-cranberries/122615',
    soundcloud: 'https://soundcloud.com/the-cranberries',
    tidal: 'https://listen.tidal.com/artist/27538',
    wiki: 'https://es.wikipedia.org/wiki/The_Cranberries',
    ticketmaster:
      'https://www.ticketmaster.com/the-cranberries-tickets/artist/760882',
    tiktok: 'https://www.tiktok.com/@thecranberrieslat?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00G72AOSS/the-cranberries',
  },
  {
    title: 'Morrissey',
    description:
      'Steven Patrick Morrissey, o simplemente Morrissey, es un cantante, compositor y autor británico. Llegó a la prominencia como el líder de The Smiths, una banda de rock que estuvo activa desde 1982 hasta 1987. Desde entonces, ha creado una carrera comercialmente exitosa como solista. Su música se caracteriza por su estilo vocal barítono y su distintivo contenido lírico con temas recurrentes de aislamiento emocional y anhelo sexual, autodegradación y humor negro, y posturas antisistema.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_ml3JsJ5B_yeAkg2BE6OmZreuWqEYviuZo',
    price: 36,
    coverImg: 'morrissey.gif',
    category: 'Alernative',
    location: 'Uk',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.indierocks.mx/artistas/morrissey/',
    youtube: 'https://www.youtube.com/channel/UCd1yjHMjahhWKUnl2qRyo0A',
    facebook: 'https://www.facebook.com/Morrissey/',
    instagram: 'https://www.instagram.com/morrisseyofficial/',
    twitter: 'https://twitter.com/officialmoz',
    spotify: 'https://open.spotify.com/artist/3iTsJGG39nMg9YiolUgLMQ',
    deezer: 'https://www.deezer.com/search/morrissey',
    apple: 'https://music.apple.com/mx/artist/morrissey/383968',
    soundcloud: 'https://soundcloud.com/morrisseyofficial',
    tidal: 'https://listen.tidal.com/artist/10978',
    wiki: 'https://es.wikipedia.org/wiki/Morrissey',
    ticketmaster:
      'https://www.ticketmaster.com/morrissey-tickets/artist/770791',
    tiktok: 'https://www.tiktok.com/@morrisseyreal?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNSZY/morrissey',
  },

  {
    title: 'The Smiths',
    description:
      'The Smiths fue un grupo inglés de rock alternativo formado en Mánchester en 1982. ​ Estaba integrado por Morrissey (vocalista), Johnny Marr (guitarrista), Andy Rourke (bajista) y Mike Joyce (batería). Ha sido denominada por los críticos como la banda más importante de rock que surgió en la escena de música independiente británica de la década de 1980..',
    videourl:
      'https://youtube.com/playlist?list=PLFIhsqj9dojqMSmDjAf6xLl9limlz95-e',
    price: 36,
    coverImg: 'thesmiths.gif',
    category: 'Alernative',
    location: 'Uk',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.officialsmiths.co.uk/tqid/',
    youtube: 'https://www.youtube.com/channel/UC-sdfo_ho6Nq3OH8RUHBrNg',
    facebook: 'https://www.facebook.com/TheSmithsOfficial',
    instagram: 'https://www.instagram.com/thesmithsmatter/',
    twitter: 'https://twitter.com/smiths_lyrics',
    spotify: 'https://open.spotify.com/artist/3iTsJGG39nMg9YiolUgLMQ',
    deezer: 'https://www.deezer.com/mx/artist/1297',
    apple: 'https://music.apple.com/mx/artist/the-smiths/829538',
    soundcloud: 'https://soundcloud.com/thesmiths',
    tidal: 'https://listen.tidal.com/artist/12699',
    wiki: 'https://es.wikipedia.org/wiki/The_Smiths',
    ticketmaster:
      'https://www.ticketmaster.com/panic-morrissey-and-smiths-tribute-tickets/artist/2739328',
    tiktok: 'https://www.tiktok.com/@thesmithsmusic?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00G7K9SNW/the-smiths',
  },

  
  {
    title: 'AudioSlave',
    description:
      'Audioslave fue un supergrupo estadounidense de rock alternativo formado en Los Ángeles, California, en 2001 por el vocalista de Soundgarden Chris Cornell, y la sección instrumental de Rage Against the Machine: Tom Morello, Tim Commerford y Brad Wilk..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lq3wby_yWiZe-GKlIjbe7O-2MvBDCfEwY',
    price: 36,
    coverImg: 'audioslave.jpg',
    category: 'Rock Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.audioslaved.com/',
    youtube: 'https://www.youtube.com/channel/UCbJHfnWtshjo6zJg9Y8XzBw',
    facebook: 'https://www.facebook.com/AudioslaveOfficial',
    instagram: 'https://audioslave.lnk.to/listen',
    twitter: 'https://twitter.com/audioslave',
    spotify: 'https://open.spotify.com/artist/2ziB7fzrXBoh1HUPS6sVFn',
    deezer: 'https://www.deezer.com/mx/artist/843',
    apple: 'https://music.apple.com/mx/artist/audioslave/825191',
    soundcloud: 'https://soundcloud.com/audioslave-official',
    tidal: 'https://listen.tidal.com/artist/5027',
    wiki: 'https://es.wikipedia.org/wiki/Audioslave',
    ticketmaster:
      'https://www.ticketmaster.com/audioslave-tickets/artist/855060',
    tiktok: 'https://www.tiktok.com/@audioslave0?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0012292WC/audioslave',
  },
  {
    title: 'Pearl Jam',
    description:
      'Pearl Jam es un grupo de grunge formado en Seattle, Estados Unidos, en el año 1990, con integrantes de las bandas Mother Love Bone y Temple of the Dog. Con la edición de su álbum debut Ten en 1991, Pearl Jam irrumpiría con fuerza en el ámbito musical alternativo. Junto a Nirvana, Alice in Chains, Stone Temple Pilots y Soundgarden están considerados como una de las bandas más grandes e influyentes de toda la escena del movimiento Grunge. Sus miembros fundadores y que aun siguen en el grupo son Eddie Vedder, Mike McCready, Stone Gossard y Jeff Ament.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mCep8SGo3FYVTr9ynJDif8zxissBh020g',
    price: 36,
    coverImg: 'pearljam.gif',
    category: 'Rock Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pearljam.com/',
    youtube: 'https://www.youtube.com/channel/UClQT6Vnsm6BUm0I5kR26EkQ',
    facebook: 'https://www.facebook.com/PearlJam',
    instagram: 'https://www.instagram.com/pearljam/',
    twitter: 'https://twitter.com/pearlJam',
    spotify: 'https://open.spotify.com/artist/1w5Kfo2jwwIPruYS2UWh56',
    deezer: 'https://www.deezer.com/mx/artist/1319',
    apple: 'https://music.apple.com/mx/artist/pearl-jam/467464',
    soundcloud: 'https://soundcloud.com/pearl-jam-official',
    tidal: 'https://listen.tidal.com/artist/3116',
    wiki: 'https://es.wikipedia.org/wiki/Pearl_Jam',
    ticketmaster:
      'https://www.ticketmaster.com/pearl-jam-tickets/artist/735836',
    tiktok: 'https://www.tiktok.com/@pearljam?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RRFQPU/pearl-jam',
  },
  {
    title: 'Soundgarden',
    description:
      'Soundgarden fue una banda estadounidense de grunge formada en Seattle, Washington, en 1984 por el vocalista Chris Cornell, el bajista Hiro Yamamoto y el guitarrista Kim Thayil. Matt Cameron se unió al grupo como batería permanente en 1986 y Ben Shepherd se convirtió en reemplazo definitivo de Yamamoto en 1990. Cornell se mantuvo en la banda hasta su muerte en mayo de 2017. Soundgarden fue una de las bandas pioneras del grunge, género musical derivado del rock alternativo que se desarrolló en Seattle. Fue la primera banda grunge en ser fichada por una compañía discográfica de renombre (A&M Records en 1988), aunque la banda no alcanzó el éxito comercial hasta que popularizaron el género a principio de los noventa junto a sus contemporáneos de Seattle: Nirvana, Pearl Jam o Alice in Chains.',
    videourl:
      'https://youtube.com/playlist?list=PLDmC20dVXJuiXdHKEywvZXxfepXlPMv5r',
    price: 36,
    coverImg: 'soundgarden.jpeg',
    category: 'Rock Alternativo',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://soundgardenworld.com/',
    youtube: 'https://www.youtube.com/user/SoundgardenVEVO',
    facebook: 'https://www.facebook.com/Soundgarden',
    instagram: 'https://www.instagram.com/soundgarden/',
    twitter: 'https://twitter.com/soundgarden',
    spotify: 'https://open.spotify.com/artist/5xUf6j4upBrXZPg6AI4MRK',
    deezer: 'https://www.deezer.com/mx/artist/1865',
    apple: 'https://music.apple.com/mx/artist/soundgarden/133036',
    soundcloud: 'https://soundcloud.com/soundgarden',
    tidal: 'https://listen.tidal.com/artist/34477',
    wiki: 'https://es.wikipedia.org/wiki/Soundgarden',
    ticketmaster:
      'https://www.ticketmaster.com/soundgarden-tickets/artist/2172',
    tiktok: 'https://www.tiktok.com/@soundgardenaudioslave?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKN9UM/soundgarden',
  },
  {
    title: ' The Offspring',
    description:
      'The Offspring es una banda de punk estadounidense, formada en Huntington Beach, California, en 1984 bajo el nombre de Manic Subsidal. Actualmente está integrada por Dexter Holland, Noodles y Todd Morse.',
    videourl:
      'http://youtube.com/playlist?list=PL99E7hmNQn66t6TbfKvE_IFMH2tY42i0P',
    price: 36,
    coverImg: 'offspring.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.offspring.com/',
    youtube: 'https://www.youtube.com/user/offspringtv',
    facebook: 'https://www.facebook.com/Offspring/',
    instagram: 'https://www.instagram.com/offspring/',
    twitter: 'https://twitter.com/Offspring_Band',
    spotify: 'https://open.spotify.com/artist/5LfGQac0EIXyAN8aUwmNAQ',
    deezer: 'https://www.deezer.com/mx/artist/882',
    apple: 'https://music.apple.com/mx/search?term=the%20offspring',
    soundcloud: 'https://soundcloud.com/search?q=OffSpring',
    tidal: 'https://listen.tidal.com/artist/1009',
    wiki: 'https://es.wikipedia.org/wiki/The_Offspring',
    ticketmaster:
      'https://www.ticketmaster.com/the-offspring-tickets/artist/753206',
    tiktok: 'https://www.tiktok.com/@offspring?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00GBG63PI/the-offspring',
  },

  {
    title: 'System of a Down',
    description:
      'System of a Down es una banda de rock estadounidense de ascendencia armenia, formada en Glendale, California, en el año 1994. Está compuesta por Serj Tankian, Daron Malakian, Shavo Odadjian y John Dolmayan.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kYvOOhnr1a5kluwLptB3wOvhkYQ7mQS8E',
    price: 36,
    coverImg: 'systemofadown.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://systemofadown.com/',
    youtube: 'https://www.youtube.com/c/systemofadownchannel',
    facebook: 'https://www.facebook.com/systemofadown',
    instagram: 'https://www.instagram.com/systemofadown/',
    twitter: 'https://twitter.com/systemofadown',
    spotify: 'https://open.spotify.com/artist/5eAWCfyUhZtHHtBdNk56l1',
    deezer: 'https://www.deezer.com/search/system%20of%20a%20down',
    apple: 'https://music.apple.com/mx/artist/system-of-a-down/462715',
    soundcloud: 'https://soundcloud.com/system-of-a-down-official',
    tidal: 'https://listen.tidal.com/artist/721',
    wiki: 'https://es.wikipedia.org/wiki/System_of_a_Down',
    ticketmaster:
      'https://www.ticketmaster.com/system-of-a-down-tickets/artist/726146',
    tiktok: 'https://www.tiktok.com/@systemofadown_spider?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKPGGC/system-of-a-down',
  },
  {
    title: 'Deftones',
    description:
      'Deftones es una banda estadounidense de metal alternativo formada en Sacramento, California, en el año 1988. Son considerados los pioneros del género nu metal junto con Korn. Sus integrantes son Chino Moreno (vocalista), Stephen Carpenter (guitarrista), Abe Cunningham (baterista), y Frank Delgado (samplers). El bajista original de la banda era Chi Cheng, quien quedó en coma después de un accidente en 2008 y murió en 2013. Desde su formación, la banda ha lanzado nueve álbumes de estudio, dos EP, un álbum recopilatorio y veintitrés sencillos, además de un box set que incluye todos sus álbumes. La banda cuenta con un disco de oro y tres discos de platino.',
    videourl:
      'https://youtube.com/playlist?list=UULFQDsiRE--IQe5ycCLeetIxQ&si=JCLD6erohjlwctWr',
    price: 36,
    coverImg: 'deftones.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.deftones.com/',
    youtube: 'https://www.youtube.com/channel/UCQDsiRE--IQe5ycCLeetIxQ',
    facebook: 'https://www.facebook.com/deftones',
    instagram: 'https://www.instagram.com/deftones/',
    twitter: 'https://twitter.com/deftones',
    spotify: 'https://open.spotify.com/artist/6Ghvu1VvMGScGpOUJBAHNH',
    deezer: 'https://www.deezer.com/mx/artist/535',
    apple: 'https://music.apple.com/mx/artist/deftones/1092903',
    soundcloud: 'https://soundcloud.com/deftones_official',
    tidal: 'https://listen.tidal.com/artist/721',
    wiki: 'https://es.wikipedia.org/wiki/Deftones',
    ticketmaster: 'https://www.ticketmaster.com/deftones-tickets/artist/777416',
    tiktok: 'https://www.tiktok.com/@deftones?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0011TTKN2/deftones',
  },

  {
    title: 'The Killers',
    description:
      'The Killers es una banda de rock estadounidense formada en 2001 en Las Vegas (Nevada) por el vocalista, teclista y bajista Brandon Flowers y el guitarrista Dave Keuning. En sus inicios la formación de la banda sufrió diversos cambios, pero hacia 2002 se integraron el bajista y guitarrista Mark Stoermer y el baterista Ronnie Vannucci Jr., permaneciendo fija desde entonces. Después de llamar la atención de un busca talentos, el grupo firmó con el sello independiente británico Lizard King Records y el estadounidense Island Records.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kK2lylQMX_1v3oz0WcbPd5l2GlcXPEeck',
    price: '$50',
    coverImg: 'theKillers.jpg',
    category: 'Alternative Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.thekillersmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCkhyoTaWKuB-Rdbb6Z3Z5DA',
    facebook: 'https://www.facebook.com/Thekillers',
    instagram: 'https://www.instagram.com/thekillers/',
    twitter: 'https://twitter.com/thekillers',
    spotify: 'https://open.spotify.com/artist/0C0XlULifJtAgn6ZNCW2eu',
    deezer: 'https://www.deezer.com/mx/artist/897',
    apple: 'https://music.apple.com/mx/artist/the-killers/6483093',
    soundcloud: 'https://soundcloud.com/thekillers',
    tidal: 'https://listen.tidal.com/artist/15686',
    wiki: 'https://es.wikipedia.org/wiki/The_Killers',
    ticketmaster:
      'https://www.ticketmaster.com/the-killers-tickets/artist/924503',
    tiktok: 'https://www.tiktok.com/@thekillers?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00G72A5Z0/the-killers',
  },
  {
    title: 'Stone Temple Pilots',
    description:
      'Stone Temple Pilots (Español: Pilotes del Templo de Piedra) es un grupo estadounidense de Grunge, aunque una de sus principales características es la variedad de estilos musicales empleados en sus canciones. La banda ha fluctuado dentro de la ambigüedad del Rock alternativo de la década de 1990, gozando de gran popularidad en aquella época, vendiendo más de 17.5 millones de álbumes en EE. UU. y más de 40 millones a nivel mundial. A principios del 2008, el grupo sorprendió al anunciar su regreso a los escenarios, luego de haber estado separados desde 2003. Se destaca el hecho de que el vocalista principal en la historia de la banda, Scott Weiland, falleció en el año 2015 cuando ya no pertenecía al grupo, dejando un potente recuerdo en los fanes. Curiosamente, Chester Bennington, quien reemplazó a Weiland en la banda, también falleció al poco tiempo.',
    videourl:
      'https://youtube.com/playlist?list=PLi1-vVH0YVvTV89coO8OIOL36C_Ih6Jng&si=FUK8DzT1CZDiUdHH',
    price: '$50',
    coverImg: 'stp.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://stonetemplepilots.com/',
    youtube: 'https://www.youtube.com/user/stptv',
    facebook: 'https://www.facebook.com/stpband/',
    instagram: 'https://www.instagram.com/stpband/',
    twitter: 'https://x.com/STPBand',
    spotify:'https://open.spotify.com/artist/2UazAtjfzqBF0Nho2awK4z',
    deezer: 'https://www.deezer.com/mx/artist/4435',
    apple: 'https://music.apple.com/us/artist/stone-temple-pilots/889772',
    soundcloud: 'https://soundcloud.com/stpsoundcloud',
    tidal: 'https://listen.tidal.com/artist/14836',
    wiki: 'https://es.wikipedia.org/wiki/Stone_Temple_Pilots',
    ticketmaster: 'https://www.ticketmaster.com/stone-temple-pilots-tickets/artist/752624',
    tiktok:'https://www.tiktok.com/@stonetemplepilots',
    napster:'https://music.amazon.com/artists/B0011ZMGVO/stone-temple-pilots',
  },

  {
    title: 'Nickelback',
    description:
      'Nickelback es una banda de rock canadiense formada en 1995 en Hanna, Alberta. Está compuesta por el guitarrista principal y vocalista Chad Kroeger, el guitarrista rítmico, tecladista y vocalista de apoyo Ryan Peake, el bajista Mike Kroeger, y el baterista Daniel Adair. La banda experimentó varios cambios de baterista entre 1995 y 2005. La banda firmó con Roadrunner Records en 1999 y alcanzó un gran éxito comercial en 2002 con el sencillo How You Remind Me", que alcanzó el número uno en Estados Unidos y Canadá. Su álbum relacionado, Silver Side Up, sería certificado Platino en Canadá. El cuarto álbum de Nickelback, The Long Road, fue lanzado en 2003 y produjo cinco sencillos.',
    videourl:
      'https://youtube.com/playlist?list=PLFMns86uumjjX0zHm2hzKi_Yr18YzV5qr&si=RSA168sKsEumJ2Ck',
    price: '$50',
    coverImg: 'nickelback.jpg',
    category: 'Rock',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://nickelback.com/',
    youtube: 'https://www.youtube.com/nickelback',
    facebook: 'https://www.facebook.com/Nickelback',
    instagram: 'https://www.instagram.com/nickelback/',
    twitter: 'https://x.com/nickelback',
    spotify:'https://open.spotify.com/artist/6deZN1bslXzeGvOLaLMOIF',
    deezer: 'https://www.deezer.com/mx/artist/1559',
    apple: 'https://music.apple.com/us/artist/nickelback/5280361',
    soundcloud: 'https://soundcloud.com/nickelback',
    tidal: 'https://listen.tidal.com/artist/10672',
    wiki: 'https://es.wikipedia.org/wiki/Nickelback',
    ticketmaster: 'https://www.ticketmaster.com/nickelback-tickets/artist/710632',
    tiktok:'https://www.tiktok.com/@nickelback',
    napster:'https://music.amazon.com/artists/B000T2D830',
  },
  {
    title: 'Hole',
    description:
      'Hole fue una banda estadounidense de rock alternativo formada en Los Ángeles, California, en 1989. Fue fundada por la cantante y guitarrista Courtney Love y el guitarrista Eric Erlandson. La banda tuvo varios bajistas y bateristas a lo largo de su carrera, siendo los más prolíficos la baterista Patty Schemel y las bajistas Kristen Pfaff (fallecida en 1994) y Melissa Auf der Maur. Hole lanzó un total de cuatro álbumes de estudio a lo largo de dos periodos que abarcan desde la década de 1990 hasta principios de la década de 2010, y se convirtió en una de las bandas de rock más exitosas comercialmente en la historia, liderada por una mujer.Influenciados por la escena punk rock de Los Ángeles, el álbum debut de la banda, Pretty on the Inside (1991), fue producido por Kim Gordon de Sonic Youth y atrajo el interés crítico de la prensa alternativa británica y estadounidense. Su segundo álbum, Live Through This, lanzado en 1994 por DGC Records, combinó elementos de punk, grunge y pop rock, y fue ampliamente aclamado, alcanzando el estatus de platino dentro de un año de su lanzamiento. Su tercer álbum, Celebrity Skin (1998), marcó una notable desviación de sus influencias punk anteriores, con un sonido más comercialmente viable; el álbum vendió alrededor de 2 millones de copias en todo el mundo y recibió una considerable aclamación crítica.',
    videourl:
      'https://youtube.com/playlist?list=PLikdHz5IXBWh2PnUAD-3J0MP6y6rv60r9&si=PZM9Q9WzCrzyG7F5',
    price: '$50',
    coverImg: 'hole.jpg',
    category: 'Rock',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://blackmilkclothing.com/hole-muscle-band-tee',
    youtube: 'https://www.youtube.com/channel/UCGE7WjDQBpG6bFA153iRVVw',
    facebook: 'https://www.facebook.com/Hole',
    instagram: 'https://www.instagram.com/hole.band/?hl=en',
    twitter: 'https://x.com/holerock',
    spotify:'https://open.spotify.com/artist/5SHQUMAmEK5KmuSb0aDvsn',
    deezer: 'https://www.deezer.com/mx/artist/477',
    apple: 'https://music.apple.com/us/artist/hole/115269',
    soundcloud: 'https://soundcloud.com/hole-official',
    tidal: 'https://listen.tidal.com/artist/3501486',
    wiki: 'https://en.wikipedia.org/wiki/Hole_(band)',
    ticketmaster: 'https://www.ticketmaster.com/hole-tickets/artist/741111',
    tiktok:'https://www.tiktok.com/search/video?q=Hole%20The%20Band&t=1726251092354',
    napster:'https://music.amazon.com/artists/B000RW2HWA/hole',
  },
  {
    title: 'Mazzy Star',
    description:
      'Mazzy Star es una banda estadounidense de rock alternativo formada en 1989 en Santa Mónica, California, por David Roback y Kendra Smith, exintegrantes del grupo musical Opal. Posteriormente, Smith dejaría la banda, siendo reemplazada por su amiga Hope Sandoval en el rol de vocalista. Se hicieron conocidos con su canción «Fade into You», que les reportó gran éxito a mediados de los años 1990. Roback y Sandoval eran el centro creativo de la banda, con Sandoval escribiendo las letras y Roback componiendo la música.En julio de 2009 Hope Sandoval declaró que la banda no solo no estaba disuelta, sino que tenía "casi terminado" un disco nuevo, aunque desconocía si se iba a publicar algún día. En el año 2012 la banda anunció algunos conciertos así como la salida de un nuevo disco, que salió a la venta el 24 de septiembre de 2013.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nRYvtj0_SRhC7y5wpvJzmgbplA4t087wo&si=SAreMym7ew-BHSeQ',
    price: '$50',
    coverImg: 'mazzystar.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pitchfork.com/artists/24033-mazzy-star/',
    youtube: 'https://www.youtube.com/channel/UCiHpAuDVzGDIEA_Ggn1I2RQ',
    facebook: 'https://www.facebook.com/MazzyStarOfficial',
    instagram: 'https://www.instagram.com/mazzystarmusic/',
    twitter: 'https://x.com/mazzystar',
    spotify:'https://open.spotify.com/artist/37w38cCSGgKLdayTRjna4W',
    deezer: 'https://www.deezer.com/mx/artist/1306',
    apple: 'https://music.apple.com/us/artist/mazzy-star/532997',
    soundcloud: 'https://soundcloud.com/mazzy-star-official',
    tidal: 'https://listen.tidal.com/artist/10979',
    wiki: 'https://es.wikipedia.org/wiki/Mazzy_Star',
    ticketmaster: 'https://www.ticketmaster.com/mazzy-star-tickets/artist/735608',
    tiktok:'https://www.tiktok.com/search/video?q=Mazzy%20Star&t=1726252256614',
    napster:'https://music.amazon.com.mx/artists/B000SZHO06/mazzy-star',
  },
  {
    title: 'The Velvet Underground',
    description:
      'The Velvet Underground fue una banda de rock estadounidense, activa entre 1964 y 1973, formada en Nueva York por Lou Reed y John Cale, quienes también alcanzaron el éxito como artistas solistas.Aunque experimentando poco éxito comercial juntos, la banda es a menudo citada por muchos críticos como uno de los grupos más importantes e influyentes de la década de 1960. En una entrevista en 1982, Brian Eno declaró repetidamente que mientras el primer álbum de Velvet Underground pudo haber vendido solo 30 000 copias en sus primeros años, «todos los que compraron uno de esos 30 000 ejemplares comenzaron una banda.',
    videourl:
      'https://youtube.com/playlist?list=PLUm_5Qy9GaTwDf2lfzIfh3vd_wmACRw2K&si=Gzfkhcjpw7nieFgY',
    price: '$50',
    coverImg: 'velvetunderground.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.velvetundergroundmusic.com/',
    youtube: 'https://www.youtube.com/channel/UC_x7V9mdXWlXJ3PeCKtiMxA',
    facebook: 'https://www.facebook.com/TheVelvetUnderground',
    instagram: 'https://www.instagram.com/thevelvetundergroundofficial',
    twitter: 'https://twitter.com/velvetunderbot',
    spotify:
      'https://open.spotify.com/artist/1nJvji2KIlWSseXRSlNYsC?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/1559',
    apple: 'https://music.apple.com/us/artist/the-velvet-underground/136829',
    soundcloud: 'https://soundcloud.com/thevelvetundergroundofficial',
    tidal: 'https://listen.tidal.com/artist/13586',
    wiki: 'https://es.wikipedia.org/wiki/The_Velvet_Underground',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok:
      'https://www.tiktok.com/@therealmusicoftheday/video/7207925541909974314?q=velvet%20underground&t=1693368472326',
    napster:
      'https://music.amazon.com.mx/artists/B00G9Y6HYO/the-velvet-underground',
  },
 
  {
    title: 'Björk',
    description:
      'Björk Guðmundsdóttir Reikiavik, 21 de noviembre de 1965, conocida simplemente como Björk, es una cantante, compositora, multiinstrumentista, actriz, escritora, DJ y productora islandesa. Es reconocida por hacer música experimental, alternativa y vanguardista con la cual ha obtenido gran reconocimiento a nivel internacional, tanto de crítica como de público. Varios de sus álbumes han alcanzado el top 10 en la lista Billboard 200: el más reciente es Fossora (2022).Björk fue incluida en la lista de los 100 artistas más influyentes del siglo. Así mismo, también fue incluida por la revista Rolling Stone como una de las cantantes y compositoras más influyentes de la época actual, incluyendo su inclusión en la lista de los 200 artistas más influyentes de los últimos 25 años por Pitchfork en 2021.3​ Björk también ha sido nominada 16 veces a los Premios Grammy.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_neQVQ7L8dH5xV5QKsopBwMASWeFR0eSl8&si=exfbr8Vk6bUigsMq',
    price: '$50',
    coverImg: 'bjork.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bjorktour.com/',
    youtube: 'https://www.youtube.com/channel/UCFbRdRGijPR4oBjQ0fVCSmw',
    facebook: 'https://www.facebook.com/bjork',
    instagram: 'https://www.instagram.com/bjork/',
    twitter: 'https://twitter.com/bjork',
    spotify: 'https://open.spotify.com/artist/7w29UYBi0qsHi5RTcv3lmA',
    deezer: 'https://www.deezer.com/mx/artist/630',
    apple: 'https://music.apple.com/mx/artist/bj%C3%B6rk/295015',
    soundcloud: 'https://soundcloud.com/bjork',
    tidal: 'https://listen.tidal.com/artist/16992',
    wiki: 'https://es.wikipedia.org/wiki/Bj%C3%B6rk',
    ticketmaster: 'https://www.ticketmaster.com/bjork-tickets/artist/753508',
    tiktok: 'https://www.tiktok.com/@bjorkofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJK03W/bj%C3%B6rk',
  },
  
 
  {
    title: 'Bauhaus',
    description:
      'Bauhaus fue una banda de rock inglesa formada en Northampton en 1978. Conocidos por su imagen oscura y su sonido melancólico, Bauhaus son uno de los pioneros del rock gótico, aunque mezclaron muchos géneros, incluyendo dub, glam rock, psicodelia y funk. El grupo estaba compuesto por Daniel Ash (guitarra, saxofón), Peter Murphy (voz, instrumentos ocasionales), Kevin Haskins (batería) y David J (bajo)..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k6kcaEg-3XSGwKTghrMY9DIEV5sfDIFIg&si=8BIrYX-W4QXP1_LB',
    price: '$50',
    coverImg: 'bauhaus.jpg',
    category: 'Gothic Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.discogs.com/artist/85897-Bauhaus',
    youtube: 'https://www.youtube.com/channel/UCxtEHoDaGH83DiqzFR6nMcw',
    facebook: 'https://www.facebook.com/Bauhaustheband/',
    instagram: 'https://www.instagram.com/bauhausthebandofficial/',
    twitter: 'https://twitter.com/BauhausMovement',
    spotify:
      'https://open.spotify.com/artist/5N5tQ9Dx1h8Od7aRmGj7Fi?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/3657',
    apple: 'https://music.apple.com/mx/artist/bauhaus/93888',
    soundcloud: 'https://soundcloud.com/bauhaus-official',
    tidal: 'https://listen.tidal.com/artist/3565987',
    wiki: 'https://es.wikipedia.org/wiki/Bauhaus_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/bauhaus-tickets/artist/967677',
    tiktok: 'https://www.tiktok.com/@bauhaus.movement?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000S2B6NK/bauhaus',
  },
  {
    title: 'Love & Rockets',
    description:
      'Love and Rockets es una banda de rock inglesa formada en 1985 por antiguos miembros de Bauhaus: Daniel Ash (voz, guitarra y saxofón), David J (bajo y voz) y Kevin Haskins (batería y sintetizadores), después de que ese grupo se separara en 1983. Ash y Haskins habían grabado y actuado en otra banda, Tones on Tail, entre 1982 y 1984.La fusión de la música de rock underground de Love and Rockets con elementos de la música pop proporcionó un catalizador temprano para el rock alternativo. Su sencillo de 1989 "So Alive" llegó al puesto número 3 en la lista Billboard Hot 100.[1] Lanzaron siete álbumes de estudio antes de separarse en 1999 y se reunieron brevemente en 2007 para algunos conciertos en vivo, antes de separarse nuevamente en 2009. Se reunieron en 2023 y anunciaron varias fechas de gira..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_n88zd_mjhDOD7X4JxI5wD8ierONa3DrpY&si=jPM6i5if57ebWnXA',
    price: '$50',
    coverImg: 'loveandrockets.jpg',
    category: 'Gothic Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.loveandrockets.com/',
    youtube: 'https://www.youtube.com/channel/UCv5aAiPnLnu7S5t6HxEYbNQ',
    facebook: 'https://www.facebook.com/LoveandRocketspage',
    instagram: 'https://www.instagram.com/loveandrocketsofficial/',
    twitter: 'https://twitter.com/newtalestotell',
    spotify:
      'https://open.spotify.com/artist/09mvgMBvJkxarNIDGdwPWg?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/9106',
    apple: 'https://music.apple.com/mx/artist/love-and-rockets/3023070',
    soundcloud: 'https://soundcloud.com/loveandrocketsofficial',
    tidal: 'https://listen.tidal.com/artist/3566187',
    wiki: 'https://en.wikipedia.org/wiki/Love_and_Rockets_(band)',
    ticketmaster:
      'https://www.ticketmaster.com/love-and-rockets-tickets/artist/1105877',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Love%20and%20rockets&t=1693516753203',
    napster: 'https://music.amazon.com.mx/artists/B000S2CZZ8/love-and-rockets',
  },
  {
    title: 'Erasure',
    description:
      'Erasure (/əˈreɪʒər/) es un dúo británico de synth-pop formado en Londres en 1985,[1][2][3] compuesto por el vocalista principal y compositor Andy Bell y el compositor, productor y tecladista Vince Clarke, quien anteriormente fue cofundador de la banda Depeche Mode y miembro del dúo de synth-pop Yazoo. A partir de su cuarto sencillo, "Sometimes" (1986), Erasure se consolidó en la lista de sencillos del Reino Unido, convirtiéndose en uno de los actos más exitosos desde mediados de los años 1980 hasta mediados de los 1990. De 1986 a 2007, el dúo logró 24 entradas consecutivas en el top 40 de la lista de sencillos del Reino Unido. Para 2009, 34 de sus 37 sencillos y EPs elegibles para la lista habían alcanzado el top 40 del Reino Unido, incluyendo 17 en el top 10. En los Brit Awards de 1989, Erasure ganó el Brit Award a Mejor Grupo Británico.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKr4800JHBJmzMVf7aHkIQ6gxR1wDg72A&si=dy154-4oRWBzhKg_',
    price: '$50',
    coverImg: 'erasure.jpg',
    category: 'Alternativo',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.erasureinfo.com/',
    youtube: 'https://www.youtube.com/channel/UCYSJBcJ37dq0TJWTjJJu23Q',
    facebook: 'https://www.facebook.com/erasureinfo',
    instagram: 'https://www.instagram.com/erasureinfo',
    twitter: 'https://x.com/erasureinfo',
    spotify:
      'https://open.spotify.com/artist/0z5DFXmhT4ZNzWElsM7V89',
    deezer: 'https://www.deezer.com/mx/artist/2456',
    apple: 'https://music.apple.com/mx/artist/erasure/153089',
    soundcloud: 'https://soundcloud.com/erasureofficial',
    tidal: 'https://listen.tidal.com/artist/9234',
    wiki: 'https://en.wikipedia.org/wiki/Erasure_(duo)',
    ticketmaster:
      'https://www.ticketmaster.com/erasure-tickets/artist/735018',
    tiktok:'https://www.tiktok.com/@erasureinfo?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RHQEGA/erasure',
  },
  {
    title: 'Pet Shop Boys',
    description:
      'Pet Shop Boys son un dúo inglés de synth-pop formado en Londres en 1981. Compuesto por el vocalista principal Neil Tennant y el tecladista Chris Lowe, han vendido más de 100 millones de discos en todo el mundo, y fueron considerados el dúo más exitoso en la historia de la música del Reino Unido en la edición de 1999 del Libro Guinness de los Récords. Son conocidos por sus canciones pop comercialmente exitosas con influencias sofisticadas provenientes de la música clásica, la ópera, el cine, la moda y la literatura.Ganadores de tres Brit Awards y seis veces nominados a los Grammy, desde 1984 han logrado 42 sencillos en el Top 30, 22 de los cuales han sido éxitos en el Top 10 de la lista de sencillos del Reino Unido, incluyendo cuatro números uno en el Reino Unido: West End Girls (también número uno en el Billboard Hot 100 de EE. UU.), Its a Sin, una versión synth-pop de Always on My Mind, y Heart. Otros éxitos incluyen una versión de Go West, así como sus propias canciones "Opportunities (Lets Make Lots of Money) y What Have I Done to Deserve This?, a dúo con Dusty Springfield. Con cinco sencillos en el Top 10 de EE. UU. en la década de 1980, están asociados con la Segunda Invasión Británica.',
    videourl:
      'https://youtube.com/playlist?list=PLxFN5K79aSHA2KeEmr5Q-x2nylNdi8slT&si=JUa6D2hIo_TvtfyZ',
    price: '$50',
    coverImg: 'petshopboys.jpg',
    category: 'Alternativo',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.petshopboys.co.uk/',
    youtube: 'https://www.youtube.com/@officialpetshopboys',
    facebook: 'https://www.facebook.com/petshopboys',
    instagram: 'https://www.instagram.com/petshopboys/',
    twitter: 'https://x.com/petshopboys',
    spotify:'https://open.spotify.com/artist/2ycnb8Er79LoH2AsR5ldjh',
    deezer: 'https://www.deezer.com/mx/artist/986',
    apple: 'https://music.apple.com/mx/artist/pet-shop-boys/488020',
    soundcloud: 'https://soundcloud.com/petshopboysofficial',
    tidal: 'https://listen.tidal.com/artist/8900',
    wiki: 'https://en.wikipedia.org/wiki/Pet_Shop_Boys',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Dance-Pop/Pet-Shop-Boys-Boletos?agqi=b520aed34266b2dd23fccedd7ade4142&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
    tiktok:'https://www.tiktok.com/@petshopboysofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJPJYW/pet-shop-boys',
  },
  {
    title: 'Weezer',
    description:
      'Weezer es una banda estadounidense de rock alternativo formada en Los Ángeles en 1992. El grupo está formado, actualmente, por Rivers Cuomo (vocalista principal y guitarra líder), Brian Bell (guitarra rítmica, teclados y coros), Scott Shriner (bajo eléctrico y coros) y Patrick Wilson (batería y coros). La banda ha cambiado su formación varias veces desde su nacimiento en 1992.1​ Han publicado quince álbumes de estudio, seis EP y un DVD. Hasta la fecha han vendido más de nueve millones de discos en su país.',
    videourl:
      'https://youtube.com/playlist?list=PLg4pQIhMIijXph_pltMzVl7Xqvqew3jrD',
    price: '$50',
    coverImg: 'weezer.jpeg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://weezer.com/',
    youtube: 'https://www.youtube.com/channel/UC7JDBUzkcwRGtQGia3_mMgQ',
    facebook: 'https://www.facebook.com/weezer',
    instagram: 'https://www.instagram.com/weezer/',
    twitter: 'https://twitter.com/Weezer',
    spotify: 'https://open.spotify.com/artist/3jOstUTkEu2JkjvRdBA5Gu',
    deezer: 'https://www.deezer.com/mx/artist/418',
    apple: 'https://music.apple.com/us/artist/weezer/115234',
    soundcloud: 'https://soundcloud.com/weezer',
    tidal: 'https://listen.tidal.com/artist/30157',
    wiki: 'https://es.wikipedia.org/wiki/Weezer',
    ticketmaster: 'https://www.ticketmaster.com/weezer-tickets/artist/766591',
    tiktok: 'https://www.tiktok.com/@weezer',
    napster: 'https://music.amazon.com.mx/artists/B000QJO1PU/weezer',
  },
  
 
  {
    title: 'Coldplay',
    description:
      'Coldplay es una banda británica de pop rock y rock alternativo formada en Londres en 1996. Está integrada por Chris Martin, Jon Buckland, Guy Berryman y Will Champion. Es uno de los grupos más relevantes de principios de la década de los 2000..',
    videourl:
      'https://youtube.com/playlist?list=PLsvoYlzBrLFAJd4hNQSHw1lYjDKeQB_iU',
    price: 36,
    coverImg: 'ColdPlay.jpg',
    category: 'Alternativo',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.coldplay.com/',
    youtube: 'https://www.youtube.com/%20COLD%20PLAY',
    facebook: 'https://www.facebook.com/coldplay',
    instagram: 'https://www.instagram.com/coldplay/',
    twitter: 'https://twitter.com/coldplay',
    spotify: 'https://open.spotify.com/artist/4gzpq5DPGxSnKTe4SA8HAU',
    deezer: 'https://www.deezer.com/mx/artist/892',
    apple: 'https://music.apple.com/mx/artist/coldplay/471744',
    soundcloud: 'https://soundcloud.com/coldplay',
    tidal: 'https://listen.tidal.com/artist/8812',
    wiki: 'https://es.wikipedia.org/wiki/Coldplay',
    ticketmaster: 'https://www.ticketmaster.com/coldplay-tickets/artist/806431',
    tiktok: 'https://www.tiktok.com/@coldplay?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKGN8W/coldplay',
  },
  {
    title: 'Maroon 5',
    description:
      "Maroon 5 es una banda musical de pop rock estadounidense. Desde su debut en 2001, la banda ha vendido más de 30 millones de álbumes y 48 millones de sencillos mundialmente. Ganadores de tres Premios Grammy y trece nominaciones, la banda ha lanzado siete álbumes de estudio: Songs About Jane, It Won't Be Soon Before Long, Hands All Over, Overexposed, V, Red Pill Blues y su reciente álbum Jordi.",
    videourl: 'https://youtube.com/playlist?list=PL447CE9E513965C65',
    price: 36,
    coverImg: 'maroon5.jpg',
    category: 'Alternativo',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.maroon5.com/',
    youtube: 'https://www.youtube.com/channel/UCBVjMGOIkavEAhyqpxJ73Dw',
    facebook: 'https://www.facebook.com/maroon5',
    instagram: 'https://www.instagram.com/maroon5/',
    twitter: 'https://twitter.com/maroon5',
    spotify: 'https://open.spotify.com/artist/04gDigrS5kc9YWfZHwBETP',
    deezer: 'https://www.deezer.com/mx/artist/1188',
    apple: 'https://music.apple.com/mx/artist/maroon-5/1798556',
    soundcloud: 'https://soundcloud.com/maroon-5',
    tidal: 'https://listen.tidal.com/artist/1565',
    wiki: 'https://es.wikipedia.org/wiki/Maroon_5',
    ticketmaster: 'https://www.ticketmaster.com/maroon-5-tickets/artist/824144',
    tiktok: 'https://www.tiktok.com/@maroon5?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000V6KIMI/maroon-5',
  },
 
  {
    title: 'Radiohead',
    description:
      "Radiohead es una banda británica de rock alternativo originaria de Abingdon-on-Thames, Inglaterra, formada en 1985 inicialmente como una banda de versiones. Está integrada por Thom Yorke (voz, guitarra, piano), Jonny Greenwood (guitarra solista, teclados, otros instrumentos), Ed O'Brien (guitarra, segunda voz), Colin Greenwood (bajo, teclados) y Phil Selway (batería, percusión).Radiohead lanzó su primer sencillo, «Creep», en 1992. Si bien la canción fue en un comienzo un fracaso comercial, se convirtió en un éxito mundial tras el lanzamiento de su álbum debut, Pablo Honey (1993) debido al auge comercial del rock alternativo. La popularidad de Radiohead en el Reino Unido aumentó con su segundo álbum, The Bends (1995). El tercero, OK Computer (1997), con un sonido expansivo y temáticas como la alienación y la globalización, les dio fama mundial y ha sido aclamado como un disco histórico de la década de 1990 y uno de los mejores álbumes de todos los tiempos..",
    videourl:
      'https://youtube.com/playlist?list=PLukmsaXDPJie7L7Ihn63HJhA6YMp7tUUr&si=4VGt8jRzr0MINF3B',
    price: '$50',
    coverImg: 'radiohead.gif',
    category: 'Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.radiohead.com/',
    youtube: 'https://www.youtube.com/channel/UCq19-LqvG35A-30oyAiPiqA',
    facebook: 'https://www.facebook.com/radiohead',
    instagram: 'https://www.instagram.com/radiohead/',
    twitter: 'https://twitter.com/radiohead',
    spotify:
      'https://open.spotify.com/artist/4Z8W4fKeB5YxbusRsdQVPb?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/399',
    apple: 'https://music.apple.com/us/artist/radiohead/657515',
    soundcloud: 'https://soundcloud.com/radiohead',
    tidal: 'https://listen.tidal.com/artist/64518',
    wiki: 'https://music.apple.com/mx/artist/radiohead/657515',
    ticketmaster:
      'https://www.ticketmaster.com/radiohead-tickets/artist/763468',
    tiktok: 'https://www.tiktok.com/@radiohead',
    napster: 'https://music.amazon.com.mx/artists/B000QJXOGC/radiohead',
  },
  {
    title: 'Artic Monkeys',
    description:
      'Arctic Monkeys (Español: Los Monos Árticos) es una banda británica de indie rock, formada en Sheffield, Reino Unido.3​ El grupo está compuesto por el guitarrista principal y vocalista Alex Turner, el guitarrista Jamie Cook, el baterista Matt Helders y el bajista Nick O Malley. El bajista original de la banda, Andy Nicholson, dejó el proyecto en 2006 poco después del lanzamiento del álbum debut de la banda.',
    videourl:
      'https://youtube.com/playlist?list=PLXboAJo1ui6GUpe12EdZVGUKaMpLxmEUn&si=Kr9Ih9DdYLHXjbVM',
    price: '$50',
    coverImg: 'articmonkeys.jpeg',
    category: 'Alternative',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.arcticmonkeys.com/',
    youtube: 'https://www.youtube.com/channel/UC-KTRBl9_6AX10-Y7IKwKdw',
    facebook: 'https://www.facebook.com/ArcticMonkeys',
    instagram: 'https://www.instagram.com/arcticmonkeys',
    twitter: 'https://x.com/ArcticMonkeys',
    spotify: 'https://open.spotify.com/artist/7Ln80lUS6He07XvHI8qqHH?si=S7os8rUVSJOe3PtWTGbGYw&nd=1&dlsi=0f2c37ca0d4444e7',
    deezer: 'https://www.deezer.com/mx/album/531345892',
    apple: 'https://music.apple.com/us/artist/arctic-monkeys/62820413',
    soundcloud: 'https://soundcloud.com/articmonkeys-1',
    tidal: 'https://listen.tidal.com/artist/3520710',
    wiki: 'https://es.wikipedia.org/wiki/Arctic_Monkeys',
    ticketmaster: 'https://www.ticketmaster.com/arctic-monkeys-tickets/artist/991635',
    tiktok: 'https://www.tiktok.com/@arcticmonkeysmusic',
    napster: 'https://music.amazon.com.mx/artists/B00122BV7Q/arctic-monkeys',
  },
  {
    title: 'Primus',
    description:
      'Primus es una banda estadounidense de funk metal formada en San Francisco, California, en 1984, y actualmente compuesta por el bajista/vocalista Les Claypool, el guitarrista Larry LaLonde y el baterista Tim Herb Alexander. Primus se originó en 1984 con Claypool y el guitarrista Todd Huth, a quienes luego se unió Jay Lane, pero estos dos últimos abandonaron la banda en 1988. Junto con LaLonde y Alexander, Primus grabó un álbum en vivo titulado Suck on This en 1989, seguido de cuatro álbumes de estudio: Frizzle Fry, Sailing the Seas of Cheese, Pork Soda y Tales from the Punchbowl. Alexander dejó la banda en 1996, siendo reemplazado por Bryan "Brain" Mantia. Junto a él, Primus grabó la canción original para la serie televisiva South Park y lanzó dos nuevos álbumes, Brown Album y Antipop, antes de entrar en un hiato en el año 2000. En 2003, Claypool y LaLonde se reunieron nuevamente con Alexander y lanzaron el DVD/EP Animals Should Not Try to Act Like People. En 2010, Lane se reincorporó a Primus, reemplazando a Alexander. Con él, la banda lanzó su octavo álbum, Green Naugahyde, en 2011. En 2013, Lane dejó la banda para enfocarse en otros proyectos y Alexander se unió por tercera vez a la agrupación. En cuanto a su estilo musical, Primus se caracteriza por su irreverencia, con letras ficticias y portadas surrealistas. Robert Christgau afirmó en una ocasión: "Primus es seguramente una de las 10 bandas más extrañas de todos los tiempos, bien por ellos.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_krHX0hkiQEcMi2_WVQ2ytS3MKzCkH6epw&si=3dnjuUR9oZIsiqbN',
    price: '$50',
    coverImg: 'primus.png',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.primusville.com/',
    youtube: 'https://www.youtube.com/channel/UCfO3MSoheHzZL4GkK_gKfuw',
    facebook: 'https://www.facebook.com/primusville/',
    instagram: 'https://www.instagram.com/primusville/',
    twitter: 'https://x.com/primus',
    spotify: 'https://open.spotify.com/artist/64mPnRMMeudAet0E62ypkx',
    deezer: 'https://www.deezer.com/mx/artist/4056',
    apple: 'https://music.apple.com/us/artist/primus/105588',
    soundcloud: 'https://soundcloud.com/primus-official',
    tidal: 'https://listen.tidal.com/artist/27477',
    wiki: 'https://es.wikipedia.org/wiki/Primus',
    ticketmaster: 'https://www.ticketmaster.com/primus-tickets/artist/735894',
    tiktok: 'https://www.tiktok.com/search/video?q=primus%20&t=1726246982424',
    napster: 'https://music.amazon.com.mx/artists/B00122BV7Q/arctic-monkeys',
  },
  {
    title: "Jane's Addiction",
    description:
      "Jane's Addiction es una banda estadounidense de rock alternativo formada en Los Ángeles, California, en 1985. La banda está compuesta por Perry Farrell (voz), Dave Navarro (guitarra), Eric Avery (bajo) y Stephen Perkins (batería). Después de separarse en 1991, volvieron con una breve gira en 1997 y una breve reunión en 2001. Luego, volvieron a separarse en 2004. Jane's Addiction continúa activo desde 2008, pero desde 2010 sin la participación de su miembro fundador Eric Avery en el bajo.El estilo musical de la banda es una variedad de géneros incluyendo funk rock y heavy metal.Jane's Addiction fue una de las primeras bandas en surgir del movimiento de rock alternativo de los 90 que llamaron la atención de los medios de comunicación y consiguiendo éxito comercial en los Estados Unidos. Su gira de despedida inicial puso en marcha el festival Lollapalooza, un escaparate de rock alternativo. Como resultado, Jane's Addiction se convirtieron en iconos de lo que Farrell llamó la Alternative Nation.​ La banda está en el puesto 35 en 'VH1's 100 Greatest Artists of Hard Rock'.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kEEFVeeoxyo3HEjpOuECX_6zZvKarCi3k&si=Lr6eviQWNaJ_hdZd',
    price: '$50',
    coverImg: 'janesaddiction.jpg',
    category: 'Alternative',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://janesaddiction.com/',
    youtube: 'https://www.youtube.com/channel/UCS7Z__UPeaTzF6uB49zY6Cw',
    facebook: 'https://www.facebook.com/JanesAddiction',
    instagram: 'https://www.instagram.com/janesaddiction/',
    twitter: 'https://x.com/janesaddiction',
    spotify: 'https://open.spotify.com/artist/02NfyD6AlLA12crYzw5YcR',
    deezer: 'https://www.deezer.com/mx/artist/2559',
    apple: 'https://music.apple.com/us/artist/janes-addiction/175958',
    soundcloud: 'https://soundcloud.com/janesaddictionmusic',
    tidal: 'https://listen.tidal.com/artist/14029',
    wiki: 'https://es.wikipedia.org/wiki/Jane%27s_Addiction',
    ticketmaster: 'https://www.ticketmaster.com/janes-addiction-tickets/artist/735374',
    tiktok: 'https://www.tiktok.com/@janesaddictionofficial',
    napster: "https://music.amazon.com.mx/artists/B000TOMNFM/jane's-addiction",
  },
  {
    title: 'Porno for Pyros',
    description: "Porno For Pyros es una banda de rock alternativo estadounidense formada en Los Ángeles, California en 1992, después de la separación de Jane's Addiction. La banda estaba compuesta por los exmiembros de Jane's Addiction, Perry Farrell (voz) y Stephen Perkins (Batería), junto con Peter DiStefano (guitarra) y Martyn LeNoble (bajo).La banda lanzó dos álbumes de estudio, Porno for Pyros (1993) y Good God's Urge (1996), antes de separarse en 1998, después de que el guitarrista Peter DiStefano fuese diagnosticado con cáncer.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_n-d0aMs39T4zkwF3ZssVCKL0t6ScpD9fM&si=F_nowNlr6VW7Tf6S',
    price: '$50',
    coverImg: 'pornoforpyros.png',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pornoforpyrosofficial.com/',
    youtube: 'https://www.youtube.com/channel/UClqzPkb47v0ySigio6jV6hQ',
    facebook: 'https://www.facebook.com/pornoforpyros',
    instagram: 'https://www.instagram.com/pornoforpyros/',
    twitter: 'https://x.com/pornoforpyros',
    spotify: 'https://open.spotify.com/artist/58etTNn7xmsQZhjnfjpAXL',
    deezer: 'https://www.deezer.com/mx/artist/266682',
    apple: 'https://music.apple.com/us/artist/porno-for-pyros/175963',
    soundcloud: 'https://soundcloud.com/pornoforpyrosofficial',
    tidal: 'https://listen.tidal.com/artist/13897',
    wiki: 'https://es.wikipedia.org/wiki/Porno_for_Pyros',
    ticketmaster: 'https://www.ticketmaster.com/porno-for-pyros-tickets/artist/763399',
    tiktok: 'https://www.tiktok.com/@pornoforpyrosofficial',
    napster: 'https://music.amazon.com.mx/artists/B0011Z1C1E/porno-for-pyros',
  },
  {
    title: 'Queens of the Stone Age',
    description: "Queens of the Stone Age (también conocidos como QOTSA o simplemente Queens) (Español: Las Reinas de la Edad de Piedra) es una banda estadounidense del Stoner rock y del Rock alternativo. Fue formada en 1997 por Josh Homme, dos años después de la desintegración de Kyuss, su banda anterior, y contó con la participación de sus excompañeros Nick Oliveri y Alfredo Hernández. La banda ha lanzado siete álbumes de estudio y tres EPs. Su música, al igual que la de Kyuss, ha sido catalogada dentro del stoner rock, aunque la banda rechaza este término. Ha sido nominada a cuatro premios Grammy por sus canciones «No One Knows», «Go With the Flow», «Little Sister» y «Sick, Sick, Sick",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nAVFg7h8lmVt4nU2eBVBKX62GGRZb9dTE&si=P168oFXlsfN2SPbq',
    price: '$50',
    coverImg: 'qotsa.jpg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://qotsa.com/',
    youtube: 'https://www.youtube.com/channel/UCAqa6dDmCH4XwipdfxoMo4Q',
    facebook: 'https://www.facebook.com/QOTSA/',
    instagram: 'https://www.instagram.com/queensofthestoneage/',
    twitter: 'https://x.com/qotsa',
    spotify: 'https://open.spotify.com/artist/4pejUc4iciQfgdX6OKulQn',
    deezer: 'https://www.deezer.com/mx/artist/1177',
    apple: 'https://music.apple.com/us/artist/queens-of-the-stone-age/857919',
    soundcloud: 'https://soundcloud.com/qotsa',
    tidal: 'https://listen.tidal.com/artist/29902',
    wiki: 'https://es.wikipedia.org/wiki/Queens_of_the_Stone_Age',
    ticketmaster: 'https://www.ticketmaster.com/queens-of-the-stone-age-tickets/artist/730011',
    tiktok: 'https://www.tiktok.com/@qotsa',
    napster: 'https://music.amazon.com/artists/B000TE0AUM/queens-of-the-stone-age'
  },
    
    {
    title: 'The Breeders',
    description: "The Breeders es una banda americana de rock alternativo basada en Dayton, Ohio, y formada en 1988 por Kim Deal, de Pixies, y Tanya Donelly, de Throwing Muses, como un proyecto paralelo de sus respectivas bandas.El nombre de la banda proviene de un término derogatorio hacia los heterosexuales usado en la comunidad gay, y fue elegido por Kim Deal porque lo encontró divertido y también reflejaba su pasión por las películas de horror, especialmente The brood, de David Cronenberg.Su segundo álbum, Last splash, alcanzó el puesto número 33 en la lista Billboard 200 de 1993 y el número 5 en el chart del Reino Unido. Entre 1994 y 1999 la banda estuvo totalmente inactiva debido a problemas de sus integrantes con las drogas y el alcohol",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nCBS8d5GYKYZXumZOjnz413x06LWfBq9s&si=Aysa5tndmFsXoofm',
    price: '$50',
    coverImg: 'breeders.png',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.thebreedersmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCppjZczkZey544xDao8-jPw',
    facebook: 'https://www.facebook.com/thebreeders',
    instagram: 'https://www.instagram.com/thebreeders/',
    twitter: 'https://x.com/thebreeders',
    spotify: 'https://open.spotify.com/artist/1xgFexIwrf2QjbU0buCNnp',
    deezer: 'https://www.deezer.com/mx/artist/1625',
    apple: 'https://music.apple.com/us/artist/the-breeders/790125',
    soundcloud: 'https://soundcloud.com/thebreeders',
    tidal: 'https://listen.tidal.com/artist/3565238',
    wiki: 'https://es.wikipedia.org/wiki/The_Breeders',
    ticketmaster: 'https://www.ticketmaster.com/the-breeders-tickets/artist/823286',
    tiktok: 'https://www.tiktok.com/@thebreeders',
    napster: 'https://music.amazon.com.mx/artists/B00HVG2R5C/the-breeders',
  },
  {
    title: 'Foster The People',
    description: 'Foster the People es una banda estadounidense de indie rock formada en Los Ángeles, California, en 2009.​ El grupo está compuesto por Mark Foster (voz, teclados, piano, sintetizador, guitarra, programación y percusión), Isom Innis (teclados) y Sean Cimino (guitarras). Como antiguos miembros de la banda también se encuentran Jacob "Cubbie" Fink (bajo) y Mark Pontius (batería).La música del grupo incorpora instrumentos acústicos y sonidos electrónicos, o ha sido descrita como una infusión de danza melódica con pop y rock, abarcando muchos géneros. Foster fundó la banda en 2009 después de pasar varios años en Los Ángeles como músico en apuros y trabajando como escritor de jingles comerciales . Después de que la canción de Foster " Pumped Up Kicks " se convirtiera en un éxito viral en 2010, el grupo recibió un contrato discográfico con Startime International y ganó una base de fans a través de espectáculos en pequeños clubes y apariciones en festivales de música. Después de lanzar su álbum debut Torches en mayo de 2011, "Pumped Up Kicks" se convirtió en un éxito cruzado en la radio comercial a mediados de 2011 y finalmente alcanzó el número tres en el Billboard.',
    videourl:
      'https://youtube.com/playlist?list=PLcdlmi2Vp5QmcOEb8iIN1zwT2zehv6R1X&si=t_5X2wUIWREG75sn',
    price: '$50',
    coverImg: 'fosterthepeople.jpg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.fosterthepeople.com/',
    youtube: 'https://www.youtube.com/channel/UC6R1juCB5ArnJGMmUlEE_fg',
    facebook: 'https://www.facebook.com/fosterthepeople',
    instagram: 'https://www.instagram.com/fosterthepeople',
    twitter: 'https://x.com/fosterthepeople',
    spotify: 'https://open.spotify.com/artist/7Ln80lUS6He07XvHI8qqHH?si=S7os8rUVSJOe3PtWTGbGYw&nd=1&dlsi=0f2c37ca0d4444e7',
    deezer: 'https://www.deezer.com/mx/album/531345892',
    apple: 'https://music.apple.com/us/artist/arctic-monkeys/62820413',
    soundcloud: 'https://soundcloud.com/fosterthepeoplemusic',
    tidal: 'https://listen.tidal.com/artist/3933641',
    wiki: 'https://en.wikipedia.org/wiki/Foster_the_People',
    ticketmaster: 'https://www.ticketmaster.com/search?q=Foster+the+People',
    tiktok: 'https://www.tiktok.com/@fosterthepeople?is_from_webapp=1&sender_device=pc',
    napster: 'https://music.amazon.com.mx/artists/B0046DXYOQ/foster-the-people',
  },
  {
    title: 'Still Corners',
    description: "Murray conoció a Hughes por casualidad en una parada de tren en Londres cuando el tren fue desviado a una estación alternativa. Murray es originario de Inglaterra y Hughes creció en Arizona y Texas, antes de mudarse a Inglaterra durante varios años para seguir una carrera en la música. Still Corners auto-publicó su EP debut, Remember Pepper?, el 13 de junio de 2008, seguido por un sencillo de 7 pulgadas, Don't Fall in Love, lanzado por el sello británico The Great Pop Supplement el 30 de agosto de 2010. El dúo firmó con el sello discográfico Sub Pop en 2011 y lanzó su primer álbum de larga duración, Creatures of an Hour, que recibió críticas favorables. En octubre de 2012, la banda lanzó un nuevo sencillo, Fireflies, que fue nombrado Mejor Nueva Canción por Pitchfork. La banda lanzó su segundo álbum, Strange Pleasures, en Sub Pop el 7 de mayo de 2013. El segundo sencillo, Berlin Lovers, recibió una amplia cobertura. Still Corners lanzó su tercer álbum, Dead Blue, el 16 de septiembre de 2016 bajo su propio sello, Wrecking Light Records. Compartieron el video del primer sencillo del álbum, Lost Boys.El 18 de agosto de 2018, la banda lanzó su cuarto álbum, Slow Air, a través de Wrecking Light Records. Tras el lanzamiento, realizaron una extensa gira por Europa, América del Norte y Asia.Su quinto álbum, The Last Exit, nuevamente a través de Wrecking Light Records, fue lanzado el 22 de enero de 2021. El álbum fue precedido por tres sencillos digitales. La banda también anunció una gira por Europa en octubre de 2021 en apoyo del nuevo álbum en su sitio web.",
    videourl:
      'https://youtube.com/playlist?list=PLkHNbtVkIre-Ny2hUdtlojbhJknJmp5GK&si=QZKnXbT9kZETEvK1',
    price: '$50',
    coverImg: 'stillcorners.jpg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.stillcorners.com/',
    youtube: 'https://www.youtube.com/@stillcorners',
    facebook: 'https://www.facebook.com/stillcorners',
    instagram: 'https://www.instagram.com/stillcorners',
    twitter: 'https://x.com/stillcorners',
    spotify: 'https://open.spotify.com/artist/4zKYrXs8iN4AeHmO8ZxNqp?si=wFB-tGvSQsyKAUcM7we2yg&nd=1&dlsi=b2032adb34004326',
    deezer: 'https://www.deezer.com/mx/album/531345892',
    apple: 'https://music.apple.com/us/artist/still-corners/433710246',
    soundcloud: 'https://soundcloud.com/stillcorners',
    tidal: 'https://listen.tidal.com/artist/4014212',
    wiki: 'https://en.wikipedia.org/wiki/Still_Corners',
    ticketmaster: 'https://www.ticketmaster.com/still-corners-tickets/artist/1552802',
    tiktok: 'https://www.tiktok.com/@stillcornersofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B003WUIFU2/still-corners',
  },
  {
    title: 'The Wallflowers',
    description: "The Wallflowers es un proyecto de rock estadounidense del cantante y compositor multifacético Jakob Dylan. Originalmente, The Wallflowers fue una banda de roots rock formada en Los Ángeles en 1989 por Dylan y el guitarrista Tobi Miller. La banda ha experimentado varios cambios en su formación, pero ha permanecido centrada en Dylan.La banda firmó con Virgin Records para lanzar su álbum debut en 1992, el cual, a pesar de la crítica positiva, no logró posicionarse en las listas y fue su única publicación con el sello.",
    videourl:
      'https://youtube.com/playlist?list=UULFHZtqXzkot9AbU6jUC_iCpQ&si=4OjglM9Ykchttcol',
    price: '$50',
    coverImg: 'wallflowers.jpg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.wallflowersmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCo5omxucc1GfxRRwisE1QJw',
    facebook: 'https://www.facebook.com/thewallflowersmusic',
    instagram: 'https://www.instagram.com/wallflowersband/',
    twitter: 'https://x.com/TheWallflowers',
    spotify: 'https://open.spotify.com/artist/0jJNGWrpjGIHUdTTJiIYeB?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/3459',
    apple: 'https://music.apple.com/mx/artist/the-wallflowers/107946',
    soundcloud: 'https://soundcloud.com/wallflowersarchives',
    tidal: 'https://listen.tidal.com/artist/751',
    wiki: 'https://en.wikipedia.org/wiki/The_Wallflowers',
    ticketmaster: 'https://www.ticketmaster.com/the-wallflowers-tickets/artist/750506',
    tiktok: 'https://www.tiktok.com/@allwallflowers?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00GATIE8A/the-wallflowers',
  },
  {
    title: 'Franz Ferdinand',
    description: "Franz Ferdinand es una banda de rock escocesa formada en Glasgow en 2002. La banda es una de las más populares dentro del revival post-punk, obteniendo múltiples éxitos en el Top 20 del Reino Unido. Han sido nominados a varios premios Grammy y han recibido dos Brit Awards—ganando uno como Mejor Grupo Británico—además de un NME Award. La formación original de la banda estaba compuesta por Alex Kapranos (voz principal, guitarra líder, teclados), Nick McCarthy (guitarra rítmica, teclados, voces), Bob Hardy (bajo, percusión) y Paul Thomson (batería, percusión, voces de apoyo). Julian Corrie (teclados, guitarra líder, voces de apoyo) y Dino Bardot (guitarra rítmica, voces de apoyo) se unieron a la banda en 2017 después de que McCarthy se fuera durante el año anterior, y Audrey Tait (batería, percusión) se unió a la banda después de que Thomson se fuera en 2021. El primer sencillo de la banda, Darts of Pleasure, estuvo cerca de entrar en el Top 40 de la lista de sencillos del Reino Unido, alcanzando el número 44. Su segundo sencillo, Take Me Out, fue su gran avance comercial, alcanzando el número tres. Take Me Out se posicionó en varios otros países y recibió una nominación al Grammy por Mejor Interpretación de Rock por un Dúo o Grupo con Vocal; se convirtió en la canción emblemática de la banda. Su álbum debut homónimo ganó el Mercury Prize en 2004 y recibió una nominación al Grammy por Mejor Álbum Alternativo..",
    videourl:
      'https://youtube.com/playlist?list=UULFHZtqXzkot9AbU6jUC_iCpQ&si=4OjglM9Ykchttcol',
    price: '$50',
    coverImg: 'franzferdinard.jpg',
    category: 'Alternative',
    location: 'Scotland',
    locationImg: 'scotland.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://franzferdinand.com/',
    youtube: 'https://www.youtube.com/channel/UCrvJwuMaOeKg1Rb062glUJQ',
    facebook: 'https://www.facebook.com/officialfranzferdinand',
    instagram: 'https://www.instagram.com/franz_ferdinand/',
    twitter: 'https://x.com/Franz_Ferdinand',
    spotify: 'https://open.spotify.com/artist/0XNa1vTidXlvJ2gHSsRi4A',
    deezer: 'https://www.deezer.com/mx/artist/919',
    apple: 'https://music.apple.com/mx/album/take-me-out/315843479?i=315844084',
    soundcloud: 'https://soundcloud.com/franzferdinand',
    tidal: 'https://listen.tidal.com/artist/27439',
    wiki: 'https://en.wikipedia.org/wiki/Franz_Ferdinand_(band)',
    ticketmaster: 'https://www.ticketmaster.com/franz-ferdinand-tickets/artist/899375',
    tiktok: 'https://www.tiktok.com/@officialfranzferdinand?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00122DWNW/franz-ferdinand',
  },
]

export default CardData10
