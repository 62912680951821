const CardData33
 = [
  {
    title: 'Joan Manuel Serrat',
    description:
      'Joan Manuel Serrat Teresa​ (Barcelona; 27 de diciembre de 1943), conocido como Joan Manuel Serrat, es un cantautor, compositor, actor, escritor, poeta y guitarrista español, premiado con la Gran Cruz de la Orden Civil de Alfonso X el Sabio «por su brillante carrera y su contribución a la cultura y el arte españoles»​ y el Premio Princesa de Asturias de las Artes 2024, por un "trabajo (que), de honda raíz mediterránea, se aúna el arte de la poesía y la música al servicio de la tolerancia, los valores compartidos, la riqueza de la diversidad de lenguas y culturas, así como un necesario afán de libertad.',
    category: 'Trova',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mbCNJwbFM-XfK9t4POOH3x-KUDo4h3PI8&si=ZEVODxPoEz-0vasN',
    price: 80,
    coverImg: 'joanmanuelserrat.jpg',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.fdelgadillo.com.mx/',
    youtube: 'https://www.youtube.com/channel/UC9twmdozj_iXRhX_FRZMwzw',
    facebook: 'https://www.facebook.com/joanmanuelserrat.oficial',
    instagram: 'https://www.instagram.com/joanmanuelserrat/',
    twitter: 'https://x.com/SerratEscribe',
    spotify:
      'https://open.spotify.com/artist/1t7t8q4zoYHp22JLIx3FM7',
    deezer: 'https://www.deezer.com/mx/artist/3289',
    apple: 'https://music.apple.com/mx/artist/joan-manuel-serrat/6604936',
    soundcloud: 'https://soundcloud.com/joan-manuel-serrat-official',
    tidal: 'https://listen.tidal.com/artist/4588',
    wiki: 'https://es.wikipedia.org/wiki/Joan_Manuel_Serrat',
    ticketmaster:
      'https://www.ticketmaster.com/joan-manuel-serrat-tickets/artist/1171021',
    tiktok: 'https://www.tiktok.com/@joan.mauelserrat',
    napster: 'https://music.amazon.com.mx/artists/B000QKP4BE/joan-manuel-serrat',
  },
  {
    title: 'Fernando Delgadillo',
    description:
      'Fernando Delgadillo González (Tlalnepantla, 11 de noviembre de 1965) es un cantautor mexicano del género trova o nueva canción. Estudió en la Escuela de Música Libre José F. Vázquez y en la escuela de la Sociedad de Autores y Compositores de México. A los 16 años fue percusionista en una agrupación de música andina llamada Huancayo, con los cuales se presentaba en la peña El Sapo Cancionero;4​ e integró el colectivo SEYMUS.1​ Comenzó actividad artística en 1985. En 1997, Delgadillo fue invitado a China para participar en el Beijing International Film Festival. Después de dicho festival, algunas de sus canciones fueron traducidas al chino. Más tarde, en agosto, lo invitaron al Festival Internacional de la Juventud, con sede en La Habana, Cuba',
    category: 'Trova',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nzInDmkRTLzFkkj-lLHjOvQj9YpR9rKug',
    price: 80,
    coverImg: 'fernandodelgadillo.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.fdelgadillo.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCVeaNTYY8SGCMqiY31Kc-tA',
    facebook: 'https://www.facebook.com/FernandoDelgadilloOficial/',
    instagram: 'https://www.instagram.com/fernandodelgadillooficial/?hl=es-la',
    twitter: 'https://x.com/DelgadilloFer',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/14801',
    apple: 'https://music.apple.com/mx/artist/fernando-delgadillo/145982983',
    soundcloud: 'https://soundcloud.com/search?q=Fernando%20Delgadillo',
    tidal: 'https://listen.tidal.com/artist/4159503',
    wiki: 'https://es.wikipedia.org/wiki/Fernando_Delgadillo',
    ticketmaster:
      'https://www.ticketmaster.com/fernando-delgadillo-tickets/artist/1174042',
    tiktok: 'https://www.tiktok.com/search?q=Fernando%20Delgadillo&t=1726170971501',
    napster: 'https://music.amazon.com.mx/artists/B000QKD32G/fernando-delgadillo',
  },
{
  title: 'Silvio Rodriguez',
  description:
    'Silvio Rodríguez Domínguez (San Antonio de los Baños, Cuba; 29 de noviembre de 1946), conocido como Silvio Rodríguez, El Aprendiz o El Escaramujo, es un cantautor, guitarrista, poeta y político cubano, exponente característico de la música de su país surgida con la Revolución cubana, conocida como la Nueva Trova, que comparte con otros reconocidos cantautores tales como Pablo Milanés, Noel Nicola y Vicente Feliú.',

  videourl:
    'https://youtube.com/playlist?list=OLAK5uy_nkSWrqjiIW2Mpw5SUNiDlPF9Llu9nx7TY&si=EuoZqgCVG25ZJgDO',
  price: '$50',
  coverImg: 'sylvio.jpg',
  category: 'Trova',
  location: 'Cuba',
  locationImg: 'cubaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://www.zurrondelaprendiz.com/',
  youtube: 'https://www.youtube.com/channel/UC16aF70kysMIJdz51HdMx9Q',
  facebook: 'https://www.facebook.com/zurrondelaprendiz',
  instagram: 'https://www.instagram.com/zurron_del_aprendiz',
  twitter: 'https://x.com/zurron_aprendiz',
  spotify: 'https://open.spotify.com/artist/4rUyBlggM5tZUH5QZn9ZuO',
  deezer: 'https://www.deezer.com/mx/artist/4340',
  apple: 'https://music.apple.com/mx/artist/silvio-rodr%C3%ADguez/303508',
  soundcloud: 'https://soundcloud.com/silviorodriguezofficial',
  tidal: 'https://listen.tidal.com/artist/3521820',
  wiki: 'https://es.wikipedia.org/wiki/Silvio_Rodr%C3%ADguez',
  ticketmaster:'https://www.ticketmaster.com/silvio-rodriguez-tickets/artist/1171066',
  tiktok: 'https://www.tiktok.com/@silviorodriguesmusic',
  napster: 'https://music.amazon.com.mx/artists/B000QKFHVQ/silvio-rodr%C3%ADguez',
},
{
  title: 'Pablo Milanes',
  description:
    'Pablo Milanés Arias (Bayamo, 24 de febrero de 1943-Madrid, 22 de noviembre de 2022) Nació en Bayamo, provincia de Oriente, actual provincia de Granma, Cuba. Estudió música en el Conservatorio Municipal de La Habana. En sus comienzos estuvo muy influido por la música tradicional cubana y por el feeling (sentimiento, en inglés). El feeling es un estilo musical que se inició en Cuba en los años 1940 y suponía una nueva manera de afrontar la canción, donde el sentimiento definía la interpretación y estaba influido por las corrientes estadounidenses de la canción romántica y del jazz. El feeling se acompañaba de una guitarra, al estilo de los viejos trovadores pero enriquecido por armonizaciones jazzísticas. Así se establecía esta nueva forma de comunicación o "feeling" con el público.',

  videourl:
    'https://youtube.com/playlist?list=OLAK5uy_l47ADXdJJJQvnrxx6LkL9dNH30KN1aa84&si=A10mr6MWXZqDMWjO',
  price: '$50',
  coverImg: 'pablomilanes.jpg',
  category: 'Trova',
  location: 'Cuba',
  locationImg: 'cubaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://www.milanespablo.com/',
  youtube: 'https://www.youtube.com/channel/UCg1l44T5Irwm5K849uO9Hiw',
  facebook: 'https://www.facebook.com/pmilanesoficial',
  instagram: 'https://www.instagram.com/pablo_milanes_oficial/',
  twitter: 'https://x.com/pmilanesoficial',
  spotify: 'https://open.spotify.com/artist/4vOfKh5wz7lTcdqB3EwsC5',
  deezer: 'https://www.deezer.com/mx/artist/75087',
  apple: 'https://music.apple.com/mx/artist/pablo-milan%C3%A9s/132280',
  soundcloud: 'https://soundcloud.com/pablomilanes',
  tidal: 'https://listen.tidal.com/artist/35825',
  wiki: 'https://es.wikipedia.org/wiki/Pablo_Milan%C3%A9s',
  ticketmaster:'https://www.ticketmaster.com/pablo-milanes-tickets/artist/762879',
  tiktok: 'https://www.tiktok.com/search/video?q=Pablo%20Milanes&t=1726169450082',
  napster: 'https://music.amazon.com.mx/artists/B000QKA3M4/pablo-milan%C3%A9s',
},
  {
    title: 'Neto Bernal',
    description:
      'Ernesto Bernal Contreras, mejor conocido como Neto Bernal, es un cantante de música regional, procedente de Mexicali, Baja California, México. El joven, que nació el 1 de octubre de 1997, comparte junto con su madre el origen en la ciudad fronteriza, Mexicali, mientras que su padre viene de Sonora. El artista, de tan sólo 19 años, creció allí junto con su hermano menor, Alexis Bernal.',
    videourl:
      'http://youtube.com/playlist?list=PLaSnKw4FFePPPOXaDVDk3SObo2PbAeqbp',
    price: 50,
    coverImg: 'NetoBernal.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC0tWKR_TBob21vJTEO67UfA',
    facebook: 'https://www.facebook.com/NetoBernalOficial',
    instagram: 'https://www.instagram.com/netobernaloficial/?hl=en',
    twitter: 'https://twitter.com/elnetillobernal',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/10693157',
    apple: 'https://music.apple.com/mx/artist/neto-bernal/1133791209',
    soundcloud: 'https://soundcloud.com/neto-bernal',
    tidal: 'https://listen.tidal.com/artist/7990895',
    wiki:
      'https://laletrade.com/biografias/regional-mexicano/18635-neto-bernal',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@netobernal?lang=es',
    napster: 'https://web.napster.com/artist/neto-bernal',
  },
  {
    title: 'Camilo',
    description:
      'Camilo Andrés Echeverry Correa, conocido simplemente como Camilo, es un cantautor y músico colombiano. Debutó en 2008, después de ganar el concurso de talentos Factor X de su país en 2007.',
    videourl:
      'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
    price: '$50',
    coverImg: 'Camilo.jpg',
    category: 'Balada',
    location: 'Camilo',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCw0aKHSGFGyrnMeVnHeYMyw',
    facebook: 'https://www.facebook.com/camilomusica',
    instagram: 'https://www.instagram.com/camilo/',
    twitter: 'https://twitter.com/CamiloMusica',
    spotify: 'https://open.spotify.com/artist/28gNT5KBp7IjEOQoevXf9N',
    deezer: 'https://www.deezer.com/mx/artist/58568762',
    apple: 'https://music.apple.com/mx/artist/camilo/1457386767',
    soundcloud: 'https://soundcloud.com/camiloofficial',
    tidal: 'https://listen.tidal.com/artist/3824470',
    wiki: 'https://es.wikipedia.org/wiki/Camilo',
    ticketmaster: 'https://www.ticketmaster.com./camilo-tickets/artist/2773173',
    tiktok: 'https://www.tiktok.com/@camilo?lang=es',
    napster: 'https://app.napster.com/artist/camilo',
  },
  {
    title: 'Rauw Alejandro',
    description:
      'Raúl Alejandro Ocasio Ruiz (born January 10, 1993), known professionally as Rauw Alejandro, is a Puerto Rican singer and songwriter. Referred to as the King of Modern Reggaeton, he belongs to the new generation of Puerto Rican urban musicians. His debut studio album, Afrodisíaco, was released in November 2020. His second studio album, Vice Versa, released in June 2021, featured the lead single Todo de Ti. He has won one Latin Grammy Award from four nominations..',
    videourl:
      'https://youtube.com/playlist?list=PLhK8A9K_cnZS4LXKUgFVIU8ogoASU3FKO',
    price: 80,
    coverImg: 'Rauw.png',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rauwalejandro.com/en/',
    youtube: 'https://www.youtube.com/channel/UC_Av98lDjf5KvFib5elhpYg',
    facebook: 'https://www.facebook.com/rauwalejandro',
    instagram: 'https://www.instagram.com/rauwalejandro/',
    twitter: 'https://twitter.com/rauwalejandro',
    spotify: 'https://open.spotify.com/artist/1mcTU81TzQhprhouKaTkpq',
    deezer: 'https://www.deezer.com/en/artist/11289472',
    apple: 'https://music.apple.com/mx/artist/rauw-alejandro/1107186883',
    soundcloud: 'https://soundcloud.com/rauwalejandro',
    tidal: 'https://listen.tidal.com/artist/8239477',
    wiki: 'https://en.wikipedia.org/wiki/Rauw_Alejandro',
    ticketmaster:
      'https://www.ticketmaster.com/rauw-alejandro-tickets/artist/2628019',
    tiktok: 'https://www.tiktok.com/@rauwalejandro?lang=es',
    napster: 'https://us.napster.com/artist/rauw-alejandro',
  },
  {
    title: 'Farruko',
    description:
      'Debutó en 2007 con «Bla, bla, bla», la cual tuvo la producción de Phantom y Villa y con el cual logró posicionarse dentro del ámbito musical, por lo que gracias a esto, lanzó canciones como «El Penthouse», «El paseo por el bloque» con Ñengo Flow y «No me atrevo» en 2007, «Escala a mi cama» con Galante & Killatonez y Ñengo Flow, «Sata es» con Galante & Killatonez y «Sexo fuera del planeta» con Fifer en 2008, con las cuales logró consolidarse como unos los talentos nuevos del reguetón.',
    videourl: 'http://youtube.com/playlist?list=PL98809762BD444BAB',
    price: 80,
    coverImg: 'Farruko.jpg',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://farruko.com/',
    youtube: 'https://www.youtube.com/c/farruko',
    facebook: 'https://www.facebook.com/farrukoofficial/',
    instagram: 'https://www.instagram.com/farruko/',
    twitter: 'https://twitter.com/FarrukoOfficial',
    spotify: 'https://open.spotify.com/artist/329e4yvIujISKGKz1BZZbO',
    deezer: 'https://www.deezer.com/mx/artist/614223',
    apple: 'https://music.apple.com/mx/artist/farruko/364377482',
    soundcloud: 'https://soundcloud.com/farrukooficial',
    tidal: 'https://listen.tidal.com/artist/4016402',
    wiki: 'https://es.wikipedia.org/wiki/Farruko',
    ticketmaster:
      'https://www.ticketmaster.com./farruko-tickets/artist/1936418',
    tiktok: 'https://www.tiktok.com/@farruko.fans?lang=es',
    napster: 'https://app.napster.com/artist/farruko',
  },
  {
    title: 'Maluma',
    description:
      'Maluma has a number of singles that have charted within the top ten on Billboard Hot Latin Songs, including Felices los 4, Borró Cassette, and Corazón. His collaborative efforts Chantaje with Shakira and Medellín with Madonna have reached the top of the Hot Latin Songs and the Dance Club Songs chart, respectively. He has worked with other international artists, such as Ricky Martin, J Balvin, and The Weeknd. Maluma has won a Latin Grammy Award, an MTV Video Music Award, two Latin American Music Awards, and been nominated for a Grammy Award for Best Latin Pop Album.',
    videourl:
      'http://youtube.com/playlist?list=PLI2uE28GO_poIPrHuSGt2Ru6Er5AzCyk5',
    price: 36,
    coverImg: 'maluma.jpg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://maluma.online/?fbclid=IwAR2kZKmUfqJ3sRMh-jl3eHSM0apR2-lEeKxsGC9d_VT1XsfmqCbVgyzZ5eY',
    youtube: 'https://www.youtube.com/c/MalumaOfficialChannel',
    facebook: 'https://www.facebook.com/MALUMAMUSIK',
    instagram: 'https://www.instagram.com/maluma/?hl=en',
    twitter:
      'https://twitter.com/maluma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/1r4hJ1h58CWwUQe3MxPuau',
    deezer: 'https://www.deezer.com/mx/artist/1424602',
    apple: 'https://music.apple.com/mx/artist/maluma/448916501',
    soundcloud: 'https://soundcloud.com/malumaofficial',
    tidal: 'https://listen.tidal.com/artist/4098182',
    wiki: 'https://es.wikipedia.org/wiki/Maluma',
    ticketmaster: 'https://www.ticketmaster.com./maluma-tickets/artist/2095829',
    tiktok: 'https://www.tiktok.com/@maluma1472?lang=es',
    napster: 'https://app.napster.com/artist/maluma',
  },
  {
    title: 'JBalvin',
    description:
      'José Álvaro Osorio Balvín, conocido artísticamente como J Balvin, es un cantante, compositor y productor discográfico colombiano. Ha logrado posicionarse tanto en el mercado musical hispano como en el mercado musical de habla inglesa, llegando a colocar sus temas en número uno en varias listas musicales, entre ellas Billboard. También es conocido como el Príncipe del Reguetón.',
    videourl:
      'http://youtube.com/playlist?list=PLWgVwtnp9KujXASQJ5gPUqHlEeLx-A4IE',
    price: '$50',
    coverImg: 'jBalvin.jpg',
    category: 'Reggaeton',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://jbalvin.com/',
    youtube: 'https://www.youtube.com/channel/UCt-k6JwNWHMXDBGm9IYHdsg',
    facebook: 'https://www.facebook.com/JBalvinReal',
    instagram: 'https://www.instagram.com/jbalvin.man.0/',
    twitter: 'https://twitter.com/jbalvin',
    spotify: 'https://open.spotify.com/artist/1vyhD5VmyZ7KMfW5gqLgo5',
    deezer: 'https://www.deezer.com/mx/artist/4860761',
    apple: 'https://music.apple.com/mx/artist/j-balvin/444520760',
    soundcloud: 'https://soundcloud.com/j-balvin-official',
    tidal: 'https://listen.tidal.com/artist/5054713',
    wiki: 'https://es.wikipedia.org/wiki/J_Balvin',
    ticketmaster:
      'https://www.ticketmaster.com./j-balvin-tickets/artist/1907948',
    tiktok: 'https://www.tiktok.com/@jbalvin2794?lang=es',
    napster: 'https://app.napster.com/artist/j-balvin',
  },
  {
    title: 'Romeo Santos',
    description:
      'Anthony Santos (Bronx, Nueva York, 21 de julio de 1981),1​ conocido artísticamente como Romeo Santos, es un cantante estadounidense. Es considerado en la mayor parte de América como el Rey de la Bachata,2​3​ por ser el líder, vocalista y compositor principal de la agrupación Aventura.Como miembro de Aventura, Santos ha sido una figura clave en la popularización de la bachata a nivel internacional, llevando temas al top de las listas de Billboard Latino y a listas de Europa. Tras varios discos con la agrupación Aventura, Romeo anunció su separación de la agrupación para lanzarse como solista en abril de 2011. Como solista ha lanzado 5 álbumes de estudio: Fórmula, vol. 1 (2011), Fórmula, vol. 2 (2014), Golden (2017), Utopía (2019), Fórmula, vol. 3 (2022)..',
    videourl:
      'https://youtube.com/playlist?list=PLZhMg9qj7KFJH4VgsAAwf1ib7qoOZkj_G',
    price: 50,
    coverImg: 'romeosantos.jpg',
    category: 'Bachatta',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.formulavol3.com/',
    youtube: 'https://www.youtube.com/channel/UCyxbZF7_PK4nLiexj0kkCNg',
    facebook: 'https://www.facebook.com/RomeoSantosOfficial/',
    instagram: 'https://www.instagram.com/romeosantos/',
    twitter: 'https://twitter.com/RomeoSantosPage',
    spotify:
      'https://open.spotify.com/artist/5lwmRuXgjX8xIwlnauTZIP?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/1272674',
    apple: 'https://music.apple.com/mx/artist/romeo-santos/434832774',
    soundcloud: 'https://soundcloud.com/romeo-santos',
    tidal: 'https://listen.tidal.com/artist/3969346',
    wiki: 'https://es.wikipedia.org/wiki/Romeo_Santos',
    ticketmaster:
      'https://www.ticketmaster.com./romeo-santos-tickets/artist/1670057',
    tiktok: 'https://www.tiktok.com/@romeosantos?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B004YXX1T6/romeo-santos',
  },

  {
    title: 'Justin Timberlake',
    description:
      "Justin Randall Timberlake (Memphis; 31 de enero de 1981) es un cantante, compositor, productor discográfico, actor, bailarín y empresario estadounidense. Criado en la comunidad de Shelby Forest, ubicada entre Memphis y Millington, comenzó su carrera como cantante en los programas de televisión Star Search y Mickey Mouse Club. A finales de los años 1990 adquirió fama internacional al pertenecer a la boy band 'N Sync, cuyos dos primeros álbumes fueron certificados con disco de diamante tras vender más de 10 millones de copias solo en los Estados Unidos, además de haber alcanzado la cima de numerosos listados alrededor del mundo",
    videourl:
      'https://youtube.com/playlist?list=PLOivmTxGQl85Wfn2671PhKUbNWLYqBW14',
    price: '$50',
    coverImg: 'justintimberlake.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://justintimberlake.com/',
    youtube: 'https://www.youtube.com/channel/UC-y8ci7xfsu4L3zkSuHae0A',
    facebook: 'https://www.facebook.com/justintimberlake',
    instagram: 'https://www.instagram.com/justintimberlake/',
    twitter: 'https://twitter.com/jtimberlake',
    spotify: 'https://open.spotify.com/artist/31TPClRtHm23RisEBtV3X7',
    deezer: 'https://www.deezer.com/mx/artist/1147',
    apple: 'https://music.apple.com/mx/artist/justin-timberlake/398128',
    soundcloud: 'https://soundcloud.com/justintimberlake',
    tidal: 'https://listen.tidal.com/artist/1569',
    wiki: 'https://es.wikipedia.org/wiki/Justin_Timberlake',
    ticketmaster:
      'https://www.ticketmaster.com/justin-timberlake-tickets/artist/847841',
    napster: 'https://web.napster.com/artist/art.56237',
    tiktok: 'https://www.tiktok.com/@justintimberlake?lang=es',
  },
  {
    title: 'Bruno Mars',
    description:
      'Peter Gene Hernandez, conocido artísticamente como Bruno Mars, es un cantante, compositor, productor musical y bailarín estadounidense. Mars comenzó a crear música desde una edad muy temprana y actuó en muchos escenarios de su pueblo natal a lo largo de su niñez realizando imitaciones. Se graduó de la escuela secundaria y se mudó a Los Ángeles para proseguir con su carrera musical..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m0ApIZblcRk9r_9DXSyf69qfT6RhNmy0U',
    price: '$50',
    coverImg: 'BrunoMars.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.brunomars.com/',
    youtube: 'https://www.youtube.com/channel/UCoUM-UJ7rirJYP8CQ0EIaHA',
    facebook: 'https://www.facebook.com/brunomars',
    instagram: 'https://www.instagram.com/brunomars/?hl=en',
    twitter:
      'https://twitter.com/brunomars?fbclid=IwAR0dFGgCSmr_rVTyHvrSn3-9vjjrsSYJUFXsulRL2ssMjipNopi-eZNOMY0',
    spotify: 'https://open.spotify.com/artist/0du5cEVh5yTK9QJze8zA0C',
    deezer: 'https://www.deezer.com/mx/artist/429675',
    apple: 'https://music.apple.com/mx/artist/bruno-mars/278873078',
    soundcloud: 'https://soundcloud.com/brunomars',
    tidal: 'https://listen.tidal.com/artist/3658521',
    wiki: 'https://www.tiktok.com/@brunomars?lang=es',
    ticketmaster:
      'https://www.ticketmaster.com/bruno-mars-tickets/artist/1466801',
    tiktok: 'https://www.tiktok.com/@brunomars?lang=es',
    napster: 'https://web.napster.com/artist/art.26871501',
  },
  {
    title: 'Bad Bunny',
    description:
      'Benito Antonio Martínez Ocasio (Almirante Sur, Vega Baja, 10 de marzo de 1994), conocido artísticamente como Bad Bunny, es un rapero, cantante, compositor y actor puertorriqueño.Su estilo de música es generalmente definido como trap latino y reguetón, pero también ha interpretado otros géneros como rock, kizomba y soul. Se caracteriza por su entonación grave y rasposa y su estilo de vestir, considerado uno de los artistas urbanos latinos más importantes de la historia',
    videourl:
      'https://www.youtube.com/playlist?list=PLRW7iEDD9RDStpKHAckdbGs3xaCChAL7Z',
    price: 50,
    coverImg: 'BadBunny.jpeg',
    category: 'Trap ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.badbunnytour.com/',
    youtube: 'https://www.youtube.com/channel/UCmBA_wu8xGg1OfOkfW13Q0Q',
    facebook: 'https://www.facebook.com/BadBunnyOfficial',
    instagram: 'https://www.instagram.com/badbunnyofficiials/',
    twitter: 'https://twitter.com/badbunnyinfope',
    spotify: 'https://open.spotify.com/artist/4q3ewBCX7sLwd24euuV69X',
    deezer: 'https://www.deezer.com/mx/artist/10583405',
    apple: 'https://music.apple.com/mx/artist/bad-bunny/1126808565',
    soundcloud: 'https://soundcloud.com/badbunny15',
    tidal: 'https://listen.tidal.com/artist/8027279',
    wiki: 'https://es.wikipedia.org/wiki/Bad_Bunny',
    ticketmaster:
      'https://www.ticketmaster.com/bad-bunny-tickets/artist/2317395',
    tiktok: 'https://www.tiktok.com/@badbunny?lang=es',
    napster: 'https://web.napster.com/artist/art.231403288',
  },
  
  {
    title: 'Gera MX',
    description:
      'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
    price: '$50',
    coverImg: 'GeraMX.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://richvagosent.com/',
    youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
    facebook: 'https://www.facebook.com/GERAMX444/',
    instagram: 'https://www.instagram.com/geramx1/',
    twitter: 'https://twitter.com/mxgeramx',
    spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
    deezer: 'https://www.deezer.com/mx/artist/6661704',
    apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
    soundcloud: 'https://soundcloud.com/geramx-music',
    tidal: 'https://listen.tidal.com/artist/8511790',
    wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
    ticketmaster:
      'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
    tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
    napster: 'https://app.napster.com/artist/gera-mx',
  },
  {
    title: 'El Fantasma',
    description:
      "Alexander García was born in Las Canas, Durango. He worked as a gardener for six years before becoming a singer.He performs regional Mexican music[2] and corrido songs about hardworking people. On social media, fans nickname him the King from the Underground.His nickname began because his friends and family call him El Fanta.[3] He was finalist at the 2019 Billboard Latin Music Awards.In 2019, El Fantasma's single Encantadora charted number 1 on Regional Mexican Airplay. On August 14, 2021, his single Soy Buen Amigo charted number 1 on Regional Mexican Airplay. It also charted 27 on Hot Latin Songs",
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLaKik8hFaPZGymkNFbeU8zvZVj0QvugDV',
    price: 80,
    coverImg: 'elfantasma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC9kHcAu8pGPY7PyKOnK7pfg',
    facebook: 'https://www.facebook.com/elfantasmaoficial/',
    instagram: 'https://www.instagram.com/elfantasmaoficial/',
    twitter: 'https://twitter.com/El_Fanta45',
    spotify: 'https://open.spotify.com/artist/0my6Pg4I28dVcZLSpAkqhv',
    deezer: 'https://www.deezer.com/mx/artist/5230088',
    apple: 'https://music.apple.com/mx/artist/el-fantasma/1245916228',
    soundcloud: 'https://soundcloud.com/elfantasma-music',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/El_Fantasma_(singer)',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@elfantasma.oficial',
    napster: 'https://music.amazon.com.mx/artists/B003DHLCDQ/el-fantasma',
  },
  {
    title: 'Natanael Cano',
    description: ' Auditorio Telmex - Jueves 01 Junio 21:00 hrs ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natan.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/profile.php?id=100077546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://app.napster.com/artist/natanael-cano',
  },

  {
    title: 'C Kan',
    description:
      'José Luis Maldonado Ramos (Guadalajara, Jalisco, 26 de julio de 1987),1​ más conocido por su nombre artístico C-Kan, es un rapero, compositor, productor y actor mexicano.2​ Su nombre artístico proviene de la palabra Can, (Perro)3​ y la letra C, de cancha: por el lugar en el que vivía La Cancha 98 C, Perro», estilizándose como C-Kan).4​ Ganó gran fama en 2012 con su álbum de estudio Voy por el sueño de muchos, alcanzando el número 1 de ventas en iTunes México​',
    videourl: 'https://youtube.com/playlist?list=UUkD89TEFWsTZluY6tk84yqg',
    price: 50,
    coverImg: 'ckan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.masteredtrax.com/',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/CKanMEX/',
    instagram: 'https://www.instagram.com/ckan98/',
    twitter: 'https://twitter.com/ckan98',
    spotify:
      'https://open.spotify.com/artist/1QhaqxeqF9sipS2gwbEKpu?si=qt0Wjxi4SiuG0poydNjOxA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4252576',
    apple: 'https://music.apple.com/mx/artist/c-kan/305830705',
    soundcloud: 'https://soundcloud.com/ckan98',
    tidal: 'https://listen.tidal.com/artist/4893770',
    wiki: 'https://es.wikipedia.org/wiki/C-Kan',
    ticketmaster: 'https://www.ticketmaster.com./ckan-tickets/artist/2258547',
    tiktok: 'https://www.tiktok.com/@c.kan98?lang=es',
    napster: 'https://app.napster.com/artist/c-kan',
  },
  {
    title: 'Eden Muñoz',
    description:
      'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
    coverImg: 'edenmunoz.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
    facebook: 'https://www.facebook.com/EdenMunozOficial/',
    instagram: 'https://www.facebook.com/EdenMunozOficial/',
    twitter: 'https://twitter.com/edenmunozof',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/4414006',
    apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
    soundcloud: 'https://soundcloud.com/edenmunoz-music',
    tidal: 'https://listen.tidal.com/artist/12062930',
    wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
    ticketmaster:
      'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
    tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
    napster: 'https://web.napster.com/artist/eden-munoz-4',
  },

  {
    title: 'The Weeknd',
    description:
      'Abel Makkonen Tesfaye (Toronto, Ontario; 16 de febrero de 1990), conocido por su nombre artístico The Weeknd, es un cantante, compositor y productor canadiense, conocido por éxitos como «Save Your Tears», «Blinding Lights», «The Hills», «Starboy», «Earned It», entre otros.Empezó a ganar reconocimiento a finales de 2010, cuando subió de forma anónima varias canciones a YouTube bajo el nombre de The Weeknd. Durante el 2011 lanzó tres mixtapes de nueve pistas, titulados House of Balloons, Thursday y Echoes of Silence, que fueron muy aclamados por la crítica.1​ En el siguiente año, lanzó su primer trabajo recopilatorio titulado Trilogy, con treinta pistas remasterizadas de sus mixtapes y tres canciones más. Fue lanzado al mercado por la compañías discográficas Republic Records y XO..',
    videourl:
      'https://youtube.com/playlist?list=PLK2zhq9oy0K6rjySCH1nARTssbv8m2Kfm',
    price: '$50',
    coverImg: 'theweeknd.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UC0WP5P-ufpRfjbNrmOWwLBQ',
    facebook: 'https://www.facebook.com/theweeknd',
    instagram: 'https://www.instagram.com/theweeknd/',
    twitter: 'https://twitter.com/theweeknd',
    spotify: 'https://open.spotify.com/artist/1Xyo4u8uXC1ZmMpatF05PJ',
    deezer: 'https://www.deezer.com/mx/artist/4050205',
    apple: 'https://music.apple.com/mx/artist/the-weeknd/479756766',
    soundcloud: 'https://soundcloud.com/theweeknd',
    tidal: 'https://listen.tidal.com/artist/4761957',
    wiki: 'https://es.wikipedia.org/wiki/The_Weeknd',
    ticketmaster:
      'https://www.ticketmaster.com/the-weeknd-tickets/artist/1697014',
    tiktok: 'https://www.tiktok.com/@theweeknd?lang=es',
    napster: 'https://us.napster.com/artist/the-weeknd',
  },
  {
    title: 'Quevedo',
    description:
      'Pedro Luis Domínguez Quevedo nació en Madrid .A la edad de un año, se trasladó a Brasil y regresó a España a la edad de cinco, instalándose en Las Palmas , Islas Canarias.Después de incursionar en el rap de estilo libre , Quevedo lanzó su carrera musical en 2020 junto al productor musical Linton. Su sencillo Ahora y Siempre (2021) alcanzó el decimonoveno y segundo puesto en las listas Global Viral 50 de Spotify y España Viral 50, respectivamente.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUd88g-4QLth1IBBRt38xvX',
    price: 50,
    coverImg: 'quevedo.jpg',
    category: 'Rap Latino',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://genius.com/Bizarrap-and-quevedo-quevedo-bzrp-music-sessions-vol-52-lyrics',
    youtube: 'https://www.youtube.com/channel/UCYEmGiiM21tGuCEtDJTfP1w',
    facebook: 'https://www.facebook.com/bizarrap',
    instagram: 'https://www.instagram.com/quevedo.pd/',
    twitter: 'https://twitter.com/pedrodquevedo',
    spotify:
      'https://open.spotify.com/artist/52iwsT98xCoGgiGntTiR7K?si=woV5b46PT7aKn3z1nmc7fg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/quevedo/377761784',
    soundcloud: 'https://soundcloud.com/quevedo-music',
    tidal: 'https://listen.tidal.com/artist/8997487',
    wiki: 'https://en.wikipedia.org/wiki/Quevedo_(rapper)',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0029L8HTY/quevedo',
  },
  {
    title: 'Post Malone',
    description:
      'Austin Richard Post (Siracusa, Nueva York, Estados Unidos; 4 de julio de 1995), más conocido por su nombre artístico Post Malone, es un cantante y compositor estadounidense.1​ Ganó un gran reconocimiento en agosto de 2015 gracias al sencillo debut «White Iverson». Más adelante pasó a lanzar «Congratulations» y «Rockstar», que, respectivamente, alcanzaron los números 8 y 1 en el Billboard Hot 100, de los Estados Unidos. Posteriormente consiguió un contrato discográfico con Republic Records.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUKA-troAfBM-xSZzJAz5Uw',
    price: '$50',
    coverImg: 'postmalone.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UCeLHszkByNZtPKcaVXOCOQQ',
    facebook: 'https://www.facebook.com/postmalone',
    instagram: 'https://www.instagram.com/postmalone/',
    twitter: 'https://twitter.com/postmalone',
    spotify: 'https://open.spotify.com/artist/246dkjvS1zLTtiykXe5h60',
    deezer: 'https://www.deezer.com/mx/artist/7543848',
    apple: 'https://music.apple.com/mx/artist/post-malone/966309175',
    soundcloud: 'https://soundcloud.com/postmalone',
    tidal: 'https://listen.tidal.com/artist/6521080',
    wiki: 'https://es.wikipedia.org/wiki/Post_Malone',
    ticketmaster:
      'https://www.ticketmaster.com/post-malone-tickets/artist/2119390',
    tiktok: 'https://www.tiktok.com/@postmalone?lang=es',
    napster: 'https://us.napster.com/artist/post-malone',
  },
  {
    title: 'Peso Pluma',
    description:
      'Hassan Emilio Kabande Laija (Zapopan, Jalisco; 15 de junio de 1999), conocido como Peso Pluma, es un cantante mexicano. Se especializa en el subgénero de corridos tumbados.Su fama internacional comenzó en 2022, después de colaborar con cantantes como Luis R. Conriquez y Natanael Cano.3​ Algunos de sus éxitos son «El Belicón», «Siempre pendientes», «PRC», «Por las noches», «AMG», «Ella baila sola» y «La bebé (Remix)». Debido a su fama ha logrado colaborar también con artistas de éxito mundial como Becky G,5​ Nicki Nicole6​, Marshmello,7​y Ovy on the Drums.Su estilo musical se caracteriza por un toque acústico con influencias de la música urbana como el trap mezclados con sonido de los corridos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXbLwh-AdLa1iHtuW_y_J9b',
    price: 80,
    coverImg: 'pesopluma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rioranchoeventscenter.com/event/peso-pluma/',
    youtube: 'https://www.youtube.com/channel/UCzrM_068Odho89mTRrrxqbA',
    facebook: 'https://www.facebook.com/PesoPlumaOficial',
    instagram: 'https://www.instagram.com/pesopluma/',
    twitter: 'https://twitter.com/ElPesoPluma',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/80365122',
    apple: 'https://music.apple.com/mx/artist/peso-pluma/1500139475?l=en',
    soundcloud: 'https://soundcloud.com/pesopluma',
    tidal: 'https://listen.tidal.com/artist/17614478',
    wiki: 'https://es.wikipedia.org/wiki/Peso_Pluma_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com/peso-pluma-tickets/artist/2979041',
    tiktok: 'https://www.tiktok.com/@therealpesopluma',
    napster: 'https://music.amazon.com.mx/artists/B082C6RXYG',
  },
  {
    title: 'Junior H',
    description:
      'Antonio Herrera Pérez (Cerano, Guanajuato, México; 23 de abril de 2000) mejor conocido como Junior H, es un cantante y compositor mexicano. Se especializa en el subgénero de corridos tumbados..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVQKFIhIcWGLTCXsFlB7b5K',
    price: 80,
    coverImg: 'juniorh.jpeg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://elpais.com/mexico/2023-05-17/junior-h-el-chico-triste-que-triunfa-a-base-de-corridos-tumbados.html',
    youtube: 'https://www.youtube.com/channel/UCwA5HHGsAlpU1UlhwWD52uA',
    facebook: 'https://www.facebook.com/juniorhoficial/photos',
    instagram: 'https://www.instagram.com/juniorh/',
    twitter: 'https://twitter.com/juniorh_oficial',
    spotify: 'https://open.spotify.com/artist/7Gi6gjaWy3DxyilpF1a8Is',
    deezer: 'https://www.deezer.com/mx/artist/54047652',
    apple: 'https://music.apple.com/mx/artist/junior-h/1442059565',
    soundcloud: 'https://soundcloud.com/juniorh-music',
    tidal: 'https://listen.tidal.com/artist/10590337',
    wiki: 'https://es.wikipedia.org/wiki/Junior_H',
    ticketmaster:
      'https://www.ticketmaster.com/junior-h-tickets/artist/2820826',

    tiktok: 'https://www.tiktok.com/@juniorh_h',
    napster: 'https://music.amazon.com.mx/artists/B07KFMJCTH/junior-h',
  },
  {
    title: 'Luis R Conriquez',
    description:
      'Luis R Conriquez es un compositor que se hizo conocido entre el público gracias a su estilo interpretativo, la honestidad de sus letras, sus mensajes de superación personal y un talento innato para componer corridos modernos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVhgiYg1lFWJMnQ2A_3COmX',
    price: 80,
    coverImg: 'luisrconriquez.jpeg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://kartelmusic.com/',
    youtube: 'https://www.youtube.com/@kartelmusicoficial',
    facebook: 'https://www.facebook.com/profile.php?id=100050488239777',
    instagram: 'https://www.instagram.com/luisrconriquez.fan.page/',
    twitter: 'https://twitter.com/LuisRConriquez',
    spotify: 'https://open.spotify.com/artist/0pePYDrJGk8gqMRbXrLJC8',
    deezer: 'https://www.deezer.com/en/artist/14004075',
    apple: 'https://music.apple.com/mx/artist/luis-r-conriquez/1252271456',
    soundcloud: 'https://soundcloud.com/luisrconriquez',
    tidal: 'https://listen.tidal.com/artist/9471448',
    wiki: 'https://en.wikipedia.org/wiki/Luis_R_Conriquez',
    ticketmaster:
      'https://www.ticketmaster.com/luis-r-conriquez-tickets/artist/2832072',
    tiktok: 'https://www.tiktok.com/@luisrconriquezoficial?lang=es',
    napster: 'https://us.napster.com/artist/luis-r-conriquez',
  },
  {
    title: 'Pedro Infante',
    description:
      'Pedro Infante Cruz (Mazatlán, Sinaloa, 18 de noviembre de 1917-Mérida, Yucatán, 15 de abril de 1957) fue un actor y cantante mexicano. Es considerado como uno de los actores más recordados del cine en México.Por su actuación en la película Tizoc (1956), fue ganador de un Oso de Plata​ del Festival Internacional de Cine de Berlín en la categoría a «mejor actor principal», y también obtuvo un premio Globo de Oro​ por mejor película extranjera, el cual se le fue otorgado por la prensa extranjera acreditada en Hollywood..',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVODBGJurCWQdYWZ7VH_Nhd',
    price: '$50',
    coverImg: 'pedroinfante.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.biografiasyvidas.com/biografia/i/infante_pedro.htm#:~:text=Pedro%20Infante%20%28Mazatl%C3%A1n%2C%20Sinaloa%2C%201917%20-%20M%C3%A9rida%2C%20Yucat%C3%A1n%2C,musical%20que%20actuaba%20en%20la%20localidad%20de%20Guasave.',
    youtube: 'https://www.youtube.com/channel/UCCZRmXAChQJF1P6xsX28TTQ',
    facebook: 'https://www.facebook.com/profile.php?id=100052259945722',
    instagram: 'https://www.instagram.com/pedro_infante_official/',
    twitter: 'https://twitter.com/Pedro_Infante1',
    spotify: 'https://open.spotify.com/artist/7y33enVLfDvft6HGNmcxdV',
    deezer: 'https://www.deezer.com/mx/artist/264106',
    apple: 'https://music.apple.com/mx/artist/pedro-infante/2137889',
    soundcloud: 'https://soundcloud.com/pedro-infante-official',
    tidal: 'https://listen.tidal.com/artist/13256',
    wiki: 'https://es.wikipedia.org/wiki/Pedro_Infante',
    ticketmaster:
      'https://www.noroeste.com.mx/entretenimiento/espectaculos/llenaran-de-tributos-a-pedro-infante-PVNO1078632',
    napster: 'https://web.napster.com/artist/pedro-infante',
    tiktok: 'https://www.tiktok.com/@pedroinfanteidolo',
  },
  {
    title: 'Jorge Negrete',
    description:
      'Jorge Alberto Negrete Moreno (Guanajuato, 30 de noviembre de 1911-Los Ángeles, California, 5 de diciembre de 1953), conocido como El Charro Cantor, fue un actor y cantante mexicano. Fundó el Sindicato de Trabajadores de la Producción Cinematográfica de la República Mexicana1​ y reorganizó, junto con un grupo selecto de actores, la Asociación Nacional de Actores.',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVngSBUUO1Yyb7E9KNPUvjl',
    price: '$50',
    coverImg: 'jorgenegrete2.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '.',
    youtube: 'https://www.youtube.com/c/jorgenegretefans',
    facebook: 'https://www.facebook.com/profile.php?id=100059758101482',
    instagram: 'https://www.instagram.com/jorgenegreteforever/',
    twitter: 'https://twitter.com/DonJorgeNegrete',
    spotify: 'https://open.spotify.com/artist/4W5RbLZybsLfAzE7QqdibE',
    deezer: 'https://www.deezer.com/mx/artist/299515',
    soundcloud: 'https://soundcloud.com/jorgenegreteofficial',
    tidal: 'https://listen.tidal.com/artist/3661015',
    wiki: 'https://es.wikipedia.org/wiki/Jorge_Negrete',
    ticketmaster:
      'https://www.ticketmaster.com.mx/teatro-jorge-negrete-boletos-mexico/venue/163990',
    napster: 'https://web.napster.com/artist/art.50312',
    tiktok: 'https://www.tiktok.com/search?q=Jorge%20Negrete&t=1667333198086',
  },
  {
    title: 'Jose Alfredo Jimenez',
    description:
      'José Alfredo Jiménez Sandoval (Dolores Hidalgo, Guanajuato, 19 de enero de 1926-Ciudad de México, 23 de noviembre de 1973) fue un cantante y compositor mexicano. Jiménez fue el autor de varios temas musicales del género regional mexicano, específicamente de rancheras, sones, corridos, y huapangos a ritmo de mariachi.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_k2kN1d58ypIxZcl6DEtIT_2tsuaZpeWJM',
    price: '$50',
    coverImg: 'JoseAlfredo.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    youtube: 'https://www.youtube.com/channel/UC3w7TN1QkWk9ecC0q_ebm7Q',
    facebook: 'https://www.facebook.com/JoseAlfredoJimenezElRey/',
    instagram: 'https://www.instagram.com/josealfredojimenezoficial/',
    twitter: 'https://twitter.com/JoseAlfredoJmz',
    spotify: 'https://open.spotify.com/artist/2T06whb4s6UiufL1j5Qtz9',
    deezer: 'https://www.deezer.com/mx/artist/343074',
    apple:
      'https://music.apple.com/mx/artist/jos%C3%A9-alfredo-jim%C3%A9nez/332254',
    soundcloud: 'https://soundcloud.com/josealfredojimenez',
    tidal: 'https://listen.tidal.com/artist/4029320',
    wiki: 'https://en.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    ticketmaster:
      'https://www.ticketmaster.com/jose-alfredo-jimenez-medel-tickets/artist/2036602',

    tiktok:
      'https://www.tiktok.com/search?q=Jos%C3%A9%20Alfredo%20Jim%C3%A9nez&t=1658091294171',
    napster: 'https://web.napster.com/artist/jose-alfredo-jimenez',
  },
  {
    title: 'Juan Gabriel',
    description:
      'Alberto Aguilera Valadez, conocido como Juan Gabriel, fue un cantante, compositor, arreglista, productor musical, músico, filántropo y actor mexicano. Son notables sus contribuciones a la música popular en español en diferentes géneros como balada, ranchera, bolero, pop, música norteña, rumba flamenca, huapango, música chicana, salsa, cumbia, tango, polka, samba, vals, son de mariachi, joropo, banda sinaloense, disco, jazz, new age, country, rock and roll, big band y hasta canciones de cuna que escribió a cada uno de sus hijos.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_ldwlb88-oemhNHFY2wJDvWnwu56vjdy2o',
    price: '$50',
    coverImg: 'JuanGabriel.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://juangabriel.com/',
    youtube: 'https://www.youtube.com/channel/UCnLJnKKCxLRsAktAB9iMSeQ',
    facebook: 'https://www.facebook.com/JuanGabrielPorLosSiglos',
    instagram: 'https://www.instagram.com/soyjuangabriel_/?hl=en',
    twitter: 'https://twitter.com/soyjuangabriel',
    spotify: 'https://open.spotify.com/artist/2MRBDr0crHWE5JwPceFncq',
    deezer: 'https://www.deezer.com/mx/artist/71813',
    apple: 'https://music.apple.com/mx/artist/juan-gabriel/295294',
    soundcloud: 'https://soundcloud.com/juan-gabriel-official',
    tidal: 'https://listen.tidal.com/artist/4347',
    wiki: 'https://en.wikipedia.org/wiki/Juan_Gabriel',
    ticketmaster:
      'https://www.ticketmaster.com/juan-gabriel-tickets/artist/761622',

    tiktok: 'https://www.tiktok.com/@soyjuangabriel',
    napster: 'https://web.napster.com/artist/juan-gabriel',
  },
  {
    title: 'Jose Jose',
    description:
      'José Rómulo Sosa Ortiz (Clavería, Azcapotzalco, Ciudad de México, 17 de febrero de 1948-Homestead, Florida, 28 de septiembre de 2019), conocido como José José, fue un cantante y actor mexicano. Se le considera como un icono musical',

    videourl: 'https://youtube.com/playlist?list=PL26274DCDCD4770D8',
    price: '$50',
    coverImg: 'josejose.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCSPwNgHHyAtHanVaGN2LLWA',
    facebook: 'https://www.facebook.com/OficialJoseJose',
    instagram: 'https://www.instagram.com/explore/tags/josejose/?hl=en',
    twitter: 'https://twitter.com/JoseJoseOficial',
    spotify: 'https://open.spotify.com/artist/4mN0qcMxWX8oToqfDPM5yV',
    deezer: 'https://www.deezer.com/mx/artist/71835',
    apple: 'https://music.apple.com/mx/artist/jos%C3%A9-jos%C3%A9/333303',
    soundcloud: 'https://soundcloud.com/jose-jose-official',
    tidal: 'https://listen.tidal.com/artist/4343',
    wiki: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Jos%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/jose-jose-tickets/artist/756645',
    tiktok: 'https://www.tiktok.com/@josejoseoficial',
    napster: 'https://web.napster.com/artist/jose-jose',
  },
  {
    title: 'Alex Fernandez',
    description:
      'Saltó a la fama en las redes sociales como hijo del reconocido cantante mexicano Alejandro Fernández. Ha heredado un seguimiento de Instagram de más de 650.000 fanáticos de miembros famosos de su familia. También es cantante y ha grabado dos discos. Nacido el 4 de noviembre de 1993, Alex Fernández Jr. es oriundo de México. Al igual que en 2022, la edad de Alex Fernández Jr. es de 29 años. Consulte a continuación para obtener más detalles sobre Alex Fernández Jr.. Esta página arrojará luz sobre la biografía, wiki, edad, cumpleaños, detalles familiares, aventuras, controversias, casta, altura, peso, rumores y hechos menos conocidos de Alex Fernández Jr. , y más.',
    videourl:
      'https://youtube.com/playlist?list=PLutFSHdaf1CL5TLFaNFDiAuYjsUoOlTKV',
    price: 50,
    coverImg: 'alexfernandez.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.seitrack.mx/alexfernandez?fbclid=IwAR0gfNb2bj5mfqgc1tcd88sERPHwgp5OY5qkOxjeBh8NuqCA74x8LkIGqBI',
    youtube: 'https://www.youtube.com/channel/UC5-ZtcsyTQNjrsR_9yRCZJg',
    facebook: 'https://www.facebook.com/AlexFdezMusica/',
    instagram: 'https://www.instagram.com/alexfernandez.g/',
    twitter: 'https://twitter.com/AlexFdezMusica',
    spotify: 'https://open.spotify.com/artist/4Xwvz864z2uP3bwPcjKJjC',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/alex-fern%C3%A1ndez/1453744801',
    soundcloud: 'https://soundcloud.com/alexfernandez-music',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://celebs.filmifeed.com/wiki/alex-fernandez-jr/',
    ticketmaster:
      'https://www.ticketmaster.com/alex-fernandez-tickets/artist/2729965',
    tiktok: 'https://www.tiktok.com/@alexfernandez.g?lang=es',
    napster: 'https://us.napster.com/artist/alex-fernandez-2',
  },

  {
    title: 'Cristian Nodal',
    description:
      'Christian Jesús González Nodal (Caborca , Sonora; 11 de enero de 1999), conocido como Christian Nodal, es un cantante, compositor mexicano. Su primer sencillo, «Adiós amor», publicado en 2016 bajo el sello discográfico Fonovisa, lo lanzó a la fama en diversos medios de comunicación en México y Estados Unidos.',
    videourl:
      'https://youtube.com/playlist?list=PL2cg72xd9U-ioO2IpH7OZrT43hVgC6fmm',
    price: 80,
    coverImg: 'nodal.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.christiannodal.com/',
    youtube: 'https://www.youtube.com/channel/UCtqOng3GVhQVu1Gl-3UlyAQ',
    facebook: 'https://www.facebook.com/christiannodaloficialmx/',
    instagram: 'https://www.instagram.com/nodal/',
    twitter: 'https://twitter.com/elnodal?lang=en',
    spotify:
      'https://open.spotify.com/artist/0XwVARXT135rw8lyw1EeWP?si=xMgobcktQD6v1nbtGIxGhQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9763952',
    apple: 'https://music.apple.com/mx/artist/christian-nodal/1079597775',
    soundcloud: 'https://soundcloud.com/christiannodal',
    tidal: 'https://listen.tidal.com/artist/7745729',
    wiki: 'https://en.wikipedia.org/wiki/Christian_Nodal',
    ticketmaster:
      'https://www.ticketmaster.com/christian-nodal-tickets/artist/2405178',

    tiktok: 'https://www.tiktok.com/@christiannodal',
    napster: 'https://web.napster.com/artist/christian-nodal',
  },
  {
    title: 'Marco Antonio Solis',
    description:
      'Marco Antonio Solís Sosa es un cantautor, músico, compositor, actor y productor musical mexicano. Junto a su primo Joel Solís, fue fundador de la agrupación musical Los Bukis, del cual es vocalista, músico, productor y autor de la mayoría de sus canciones; al inicio tocaba los teclados y posteriormente los timbales y la guitarra. .',
    videourl:
      'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
    price: '$50',
    coverImg: 'MarcoAntonioSolis.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.marcoantoniosolis.com/',
    youtube: 'https://www.youtube.com/channel/UCIKg4QVg91aCWxXWlUkKDLw',
    facebook:
      'https://www.facebook.com/marcoantoniosolis/?eid=ARDZGcP8TaKskxpqO14J-o-2ZdAB_UU65p1ySVV2fFgb2ZsWGTAYo7NAzUnvpDSg9C_KCvXTmUYllfNG',
    instagram: 'https://www.instagram.com/marcoantoniosolis_oficial/',
    twitter: 'https://twitter.com/MarcoASolis',
    spotify: 'https://open.spotify.com/artist/3tJnB0s6c3oXPq1SCCavnd',
    deezer: 'https://www.deezer.com/mx/artist/9032',
    apple: 'https://music.apple.com/mx/artist/marco-antonio-sol%C3%ADs/332900',
    soundcloud: 'https://soundcloud.com/marco-antonio-solis-official',
    tidal: 'https://listen.tidal.com/artist/3675096',
    wiki: 'https://es.wikipedia.org/wiki/Marco_Antonio_Sol%C3%ADs',
    ticketmaster:
      'https://www.ticketmaster.com./marco-antonio-solis-tickets/artist/781016',
    tiktok: 'https://www.tiktok.com/@marcosantoniosolisoff?lang=es',
    napster: 'https://app.napster.com/artist/marco-antonio-solis',
  },

  {
    title: 'Joan Sebastian',
    description:
      'José Manuel Figueroa, conocido artísticamente como Joan Sebastian, fue un cantante, compositor y actor mexicano, que fue conocido por el público bajo los sobrenombres de «El Rey del Jaripeo»,«El Poeta del Pueblo», «El Poeta de Juliantla» y «El Huracán del Sur.',
    videourl:
      'http://youtube.com/playlist?list=PL9CTSVO6wu7LNGcWzK-VheoJ1NavmEFjZ',
    price: '$50',
    coverImg: 'JoanSebastian.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://joansebastianoficial.com/',
    youtube: 'https://www.youtube.com/channel/UC9oI0Vxnp-jJRH2StsXaIzQ',
    facebook: 'https://www.facebook.com/oficialjoansebastian',
    instagram: 'https://www.instagram.com/joansebastian/?hl=es-la',
    twitter: 'https://twitter.com/joansebastianof',
    spotify:
      'https://open.spotify.com/artist/7FsRH5bw8iWpSbMX1G7xf1?si=HX11vdfgQXuFFy6h5bdteg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12141',
    apple: 'https://music.apple.com/mx/artist/joan-sebastian/295941',
    soundcloud: 'https://soundcloud.com/el-mimoso-luis-antonio-lopez',
    tidal: 'https://listen.tidal.com/artist/4672027',
    wiki: 'https://es.wikipedia.org/wiki/Joan_Sebastian',
    ticketmaster:
      'https://www.ticketmaster.com/joan-sebastian-tickets/artist/755195',
    tiktok: 'https://www.tiktok.com/@joansebastianoficial?lang=es',
    napster: 'https://web.napster.com/artist/joan-sebastian',
  },

  {
    title: 'Reyli',
    description:
      'Reyli Barba Arrocha es un cantautor y actor mexicano. Es conocido por haber sido el primer vocalista de la agrupación Elefante; siendo reemplazado por Jorge Guevara a partir de 2004. Desde su separación del grupo, trabaja como solista..',
    videourl: 'http://youtube.com/playlist?list=PL37D2450D17D43D48',
    price: '$50',
    coverImg: 'Reyli.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.reylibarbamusic.com/',
    youtube: 'https://www.youtube.com/channel/UCVk903Ollml-lwINrNo6K6w',
    facebook: 'https://www.facebook.com/ReyliBarbaOficial',
    instagram: 'https://www.instagram.com/reylibarbaoficial/',
    twitter: 'https://twitter.com/Reyli_Barba',
    spotify: 'https://open.spotify.com/artist/69BUYvpG9MbjCyIZfsFdhJ',
    deezer: 'https://www.deezer.com/mx/artist/194042',
    apple: 'https://music.apple.com/mx/artist/reyli-barba/20456368',
    soundcloud: 'https://soundcloud.com/reylibarba',
    tidal: 'https://listen.tidal.com/artist/3602829',
    wiki: 'https://es.wikipedia.org/wiki/Reyli_Barba',
    ticketmaster:
      'https://www.ticketmaster.com.mx/reyli-barba-boletos/artist/1197375',
    tiktok: 'https://www.tiktok.com/@reylibarbaoficial?lang=es',
    napster: 'https://app.napster.com/artist/reyli-barba',
  },

  {
    title: 'Michael Jackson',
    description:
      'Michael Joseph Jackson (Gary, Indiana, 29 de agosto de 1958-Los Ángeles, 25 de junio de 2009),1​ fue un cantante, compositor, productor y bailarín estadounidense.2​3​4​ Apodado como el Rey del Pop,5​ sus contribuciones y reconocimiento en la historia de la música y el baile durante más de cuatro décadas, así como su publicitada vida personal, lo convirtieron en una figura internacional en la cultura popular. Su música incluye una amplia acepción de géneros como el pop, rhythm and blues (soul y funk), rock, disco y dance, y es reconocido como el «artista musical más exitoso de todos los tiempos» por los Guinness World Records.',
    videourl:
      'https://youtube.com/playlist?list=PLUeQUfurHN6bjde_KttpUONL9C0wjJvGF',
    price: '$50',
    coverImg: 'michaelJackson.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.michaeljackson.com/',
    youtube: 'https://www.youtube.com/channel/UC5OrDvL9DscpcAstz7JnQGA',
    facebook: 'https://www.facebook.com/michaeljackson',
    instagram: 'https://www.instagram.com/michaeljackson/',
    twitter: 'https://twitter.com/michaeljackson',
    spotify: 'https://open.spotify.com/artist/3fMbdgg4jU18AjLCKBhRSm',
    deezer: 'https://www.deezer.com/mx/artist/259',
    apple: 'https://music.apple.com/mx/artist/michael-jackson/32940',
    soundcloud: 'https://soundcloud.com/mjimmortal',
    tidal: 'https://listen.tidal.com/artist/606',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Jackson',
    ticketmaster:
      'https://www.ticketmaster.com/michael-jackson-tribute-tickets/artist/1454599',
    tiktok: 'https://www.tiktok.com/@michael_jackson_08?lang=es',
    napster: 'https://us.napster.com/artist/michael-jackson',
  },
  {
    title: 'Prince',
    description:
      'Prince Rogers Nelson (7 de junio de 1958 - 21 de abril de 2016), más comúnmente conocido como Prince , fue un cantautor, músico y productor discográfico estadounidense. Ganador de numerosos premios y nominaciones , es ampliamente considerado como uno de los mejores músicos de su generación. [3] Era conocido por su personalidad extravagante y andrógina ; [4] [5] su amplio rango vocal, que incluía un falsete de largo alcance y gritos agudos; y su habilidad como multiinstrumentista, a menudo prefiriendo tocar todos o la mayoría de los instrumentos en sus grabaciones. [6] Prince produjo sus propios álbumes, siendo pionero en laSonido de Mineápolis . Su música incorporó una amplia variedad de estilos, incluyendo funk , R&B , rock , new wave , soul , synth-pop , pop , jazz y hip hop ..',
    videourl:
      'https://youtube.com/playlist?list=PLrwXzbX3SWntQDX7LNhvTSVuXU_bujnLw',
    price: '$50',
    coverImg: 'prince.jpeg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.prince.com/?fbclid=IwAR0rvAyV-OG2baN0CTL4YwCp9LJe4mKeKFKkKohULZ1Mw2QviE41O0pGOWg',
    youtube: 'https://www.youtube.com/channel/UCv3mNSNjuWldihk1DUdnGtw',
    facebook: 'https://www.facebook.com/prince',
    instagram: 'https://store.prince.com/store',
    twitter: 'https://twitter.com/prince',
    spotify: 'https://open.spotify.com/artist/5a2EaR3hamoenG9rDuVn8j',
    deezer: 'https://www.deezer.com/mx/artist/1878',
    apple: 'https://music.apple.com/us/artist/prince/155814',
    soundcloud: 'https://soundcloud.com/prince',
    tidal: 'https://listen.tidal.com/artist/4847',
    wiki: 'https://es.wikipedia.org/wiki/Prince',
    ticketmaster:
      'https://www.ticketmaster.com/the-prince-experience-tickets/artist/1466441',
    tiktok: 'https://www.tiktok.com/@prince.4.ever?lang=es',
    napster: 'https://us.napster.com/artist/prince',
  },
  {
    title: 'Rod Stewart',
    description:
      'Roderick David Rod Stewart es un músico, compositor y productor británico de rock, conocido mundialmente por haber sido vocalista en las bandas The Jeff Beck Group y Faces, como también por su exitosa y extensa carrera como solista. Con su distinguida voz rasposa inició su carrera como músico callejero para luego participar en The Dimensions y en el supergrupo The Steampacket durante los primeros años de la década de los sesenta. Sin embargo alcanzó el éxito después de ingresar a The Jeff Beck Group, donde solo participó en sus dos primeros álbumes, y luego en Faces hasta 1975 cuando la agrupación inglesa se separó..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nDMPXRotjxJ2k77CTaUXdq_NVPTMv9gwc',
    price: '$50',
    coverImg: 'RodStewart.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rodstewart.com/',
    youtube: 'https://www.youtube.com/user/RodStewart',
    facebook: 'https://www.facebook.com/rodstewart',
    instagram: 'https://www.instagram.com/sirrodstewart/',
    twitter: 'https://twitter.com/rodstewart',
    spotify: 'https://open.spotify.com/artist/2y8Jo9CKhJvtfeKOsYzRdT',
    deezer: 'https://www.deezer.com/mx/artist/1440',
    apple: 'https://music.apple.com/mx/artist/rod-stewart/57471',
    soundcloud: 'https://soundcloud.com/rodstewartofficial',
    tidal: 'https://listen.tidal.com/artist/1639',
    wiki: 'https://es.wikipedia.org/wiki/Rod_Stewart',
    ticketmaster: 'https://www.tiktok.com/@sirrodstewart?lang=es',
    tiktok: 'https://listen.tidal.com/artist/1639',
    napster: 'https://music.amazon.com/artists/B000RSHJUE/rod-stewart',
  },
  {
    title: 'Joe Walsh',
    description:
      'Fidler Joseph «Joe» Walsh (Wichita, Kansas, 20 de noviembre de 1947) es un guitarrista, cantante y compositor estadounidense de hard rock, country rock y blues rock. Conocido por su maestría con la guitarra eléctrica, ha sido miembro de tres bandas de éxito: James Gang, Barnstorm y The Eagles. Además, ha experimentado como artista en solitario obteniendo un gran éxito. Con James Gang compuso temas reconocidos como "Walk Away" y "Funk #49". En solitario compuso su éxito cómico "Lifes Been Good" y "Rocky Mountain Way", siendo con este último tema que se utilizó por primera vez el talk box (inventado por un amigo de Joe, Bob Hulzon, para tocar el tema en vivo). Con The Eagles, ayudó a Don Henley, Glenn Frey y Don Felder con el tema "Hotel California", como guitarrista principal...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kO-C0vdcx2Eqb83ShUUNQgSQ5famxrtBQ&si=FGhZ-BuoBp_CyFOB',
    price: '$50',
    coverImg: 'joewalsh.jpg',
    category: 'Classic Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://joewalsh.com/',
    youtube: 'https://www.youtube.com/user/JoeWalshOfficial',
    facebook: 'https://www.facebook.com/JoeWalshOfficial/',
    instagram: 'https://www.instagram.com/joewalshofficial//',
    twitter: 'https://twitter.com/JoeWalsh',
    spotify: 'https://open.spotify.com/artist/5bDxAyJiTYBat1YnFJhvEK',
    deezer: 'https://www.deezer.com/mx/artist/688',
    apple: 'https://music.apple.com/mx/artist/joe-walsh/34507',
    soundcloud: 'https://soundcloud.com/joewalshmusic',
    tidal: 'https://listen.tidal.com/artist/4063',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Walsh',
    ticketmaster: 'https://www.tiktok.com/@joewalshofficial?lang=es',
    tiktok: 'https://listen.tidal.com/artist/4063',
    napster: 'https://music.amazon.com/artists/B000QJPE1K/joe-walsh',
  },
  {
    title: 'Calvin Harris',
    description:
      'Adam Richard Wiles (Dumfries, Dumfries y Galloway, 17 de enero de 1984), más conocido como Calvin Harris, es un DJ, cantante y productor británico. Actualmente ocupa el puesto 16 de los 100 mejores DJ de la revista DJ Magazine. Su álbum debut, seleccionado por la Industria Fonográfica Británica, I Created Disco, fue lanzado en 2007 y contenía sencillos tales como Acceptable in the 80s y The Girls que ingresaron en el Top 10 del Reino Unido.',
    videourl:
      'https://youtube.com/playlist?list=PLCnkz7la5RxMxu9cbTxpXhYrwWDrweJVA',
    price: '$50',
    coverImg: 'calvinharris.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://loveregenerator.com/',
    youtube: 'https://www.youtube.com/channel/UCIjYyZxkFucP_W-tmXg_9Ow',
    facebook: 'https://www.facebook.com/search/top?q=calvin%20harris',
    instagram: 'https://www.instagram.com/calvinharris/',
    twitter: 'https://twitter.com/CalvinHarris',
    spotify: 'https://open.spotify.com/artist/7CajNmpbOovFoOoasH2HaY',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/calvin-harris/201955086',
    soundcloud: 'https://soundcloud.com/calvinharris',
    tidal: 'https://listen.tidal.com/artist/8401',
    wiki: 'https://es.wikipedia.org/wiki/Calvin_Harris',
    ticketmaster:
      'https://www.ticketmaster.com/calvin-harris-tickets/artist/1149552',
    napster: 'https://web.napster.com/artist/art.13622534',
    tiktok: 'https://www.tiktok.com/@calvinharris?lang=es',
  },

  {
    title: 'Harry Styles',
    description:
      'Harry Edward Styles es un cantante, compositor y actor británico. Inició su carrera como cantante en 2010 como integrante de la boy band One Direction, con la que participó en el programa The X Factor y quedó en tercer lugar. Pese a no ganar, la agrupación firmó un contrato discográfico con el sello Syco, con el que publicaron los álbumes Up All Night, Take Me Home, Midnight Memories, Four y Made in the A.M., los cuales alcanzaron la primera posición en las listas de los más vendidos en numerosos países, entre estos los Estados Unidos y el Reino Unido, además de registrar altas ventas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kzrF1uoBy1nqk215mLiPzzhUnc_hfFs40',
    price: '$50',
    coverImg: 'harrystyles.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.hstyles.co.uk/',
    youtube: 'https://www.youtube.com/c/HarryStyles',
    facebook: 'https://www.facebook.com/harrystyles',
    instagram: 'https://www.instagram.com/harrystyles/',
    twitter: 'https://twitter.com/Harry_Styles',
    spotify: 'https://open.spotify.com/artist/6KImCVD70vtIoJWnq6nGn3',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/harry-styles/471260289',
    soundcloud: 'https://soundcloud.com/harrystyles',
    tidal: 'https://listen.tidal.com/artist/8669861',
    wiki: 'https://es.wikipedia.org/wiki/Harry_Styles',
    ticketmaster:
      'https://www.ticketmaster.com/harry-styles-tickets/artist/2366444',
    napster: 'https://web.napster.com/artist/art.146985808',
    tiktok: 'https://www.tiktok.com/@officialharry?lang=es',
  },
  {
    title: 'Sam Smith',
    description:
      'Samuel Frederick Smith, cuyo nombre artístico es Sam Smith, es una celebridad británica profesional en el canto y la composición. En 2019, Smith se declaró de género no binario y tener los pronombres they/them en inglés.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nrECM3EEps3lyUkyO8L3uQTJsclWhcYlM',
    price: '$50',
    coverImg: 'samsmith.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://samsmithworld.com/',
    youtube: 'https://www.youtube.com/channel/UCvpDeGlR5wLP9Z3Tb6K0Xfg',
    facebook: 'https://www.facebook.com/samsmithworld',
    instagram: 'https://www.instagram.com/samsmith/',
    twitter: 'https://twitter.com/samsmith',
    spotify: 'https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI',
    deezer: 'https://www.deezer.com/mx/artist/1097709',
    apple: 'https://music.apple.com/mx/artist/sam-smith/156488786',
    soundcloud: 'https://soundcloud.com/samsmithworld',
    tidal: 'https://listen.tidal.com/artist/4306587',
    wiki: 'https://es.wikipedia.org/wiki/Sam_Smith',
    ticketmaster:
      'https://www.ticketmaster.com/sam-smith-tickets/artist/1785301',
    napster: 'https://web.napster.com/artist/art.21025078',
    tiktok: 'https://www.tiktok.com/@samsmith?lang=es',
  },
  {
    title: 'John Legend',
    description:
      "John Roger Stephens, conocido como John Legend, es un cantante, compositor, pianista y actor estadounidense. Ha ganado once premios Grammy, un Premio Globo de Oro y un Oscar. En 2007, Legend recibió el Premio 'Hal David Starlight'  del Salón de la Fama de los Compositores.",
    videourl:
      'https://youtube.com/playlist?list=PL5odlUFMuUzzKmkiEuqFk3YFBossORfYB',
    price: '$50',
    coverImg: 'johnlegend.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.johnlegend.com/',
    youtube: 'https://www.youtube.com/channel/UCEa-JnNdYCIFn3HMhjGEWpQ',
    facebook: 'https://www.facebook.com/johnlegend',
    instagram: 'https://www.instagram.com/johnlegend_fc/',
    twitter: 'https://twitter.com/johnlegend',
    spotify: 'https://open.spotify.com/artist/5y2Xq6xcjJb2jVM54GHK3t',
    deezer: 'https://www.deezer.com/mx/artist/313',
    apple: 'https://music.apple.com/mx/artist/john-legend/16586443',
    soundcloud: 'https://soundcloud.com/johnlegend',
    tidal: 'https://listen.tidal.com/artist/490',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/john-legend-tickets/artist/936461',
    napster: 'https://web.napster.com/artist/art.6432383',
    tiktok: 'https://www.tiktok.com/@johnlegend570?lang=es',
  },
  {
    title: 'Chris Issak',
    description:
      "Christopher Joseph Isaak (nacido el 26 de junio de 1956) es un cantante, compositor, guitarrista y ocasional actor estadounidense. Conocido por su estilo revivalista de rockabilly cargado de reverberación y su amplio rango vocal, es popularmente conocido por su éxito revelador y canción distintiva Wicked Game, así como por éxitos internacionales como Blue Hotel, Baby Did a Bad Bad Thing y Somebody's Crying.Con una carrera que abarca cuatro décadas, Isaak ha lanzado un total de 13 álbumes de estudio, ha realizado extensas giras con su banda Silvertone y ha recibido numerosas nominaciones a premios. Su sonido e imagen a menudo se comparan con los de Roy Orbison, Elvis Presley, Ricky Nelson y Duane Eddy.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m-ZNDwDmQf8YHK94j1TVvOu93sjC4iEJU&si=znHlrATfAG-waIzW',
    price: '$50',
    coverImg: 'chrisissak.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://chrisisaak.com/',
    youtube: 'https://www.youtube.com/channel/UCSCS5v9K-fNc9oS0AZx-OqA',
    facebook: 'https://www.facebook.com/chrisisaak/',
    instagram: 'https://www.instagram.com/chrisisaak/',
    twitter: 'https://x.com/ChrisIsaak',
    spotify: 'https://open.spotify.com/artist/7290H8m1Dwt8G7jm1y9CQx?autoplay=true',
    deezer: 'https://www.deezer.com/mx/playlist/13048441063',
    apple: 'https://music.apple.com/mx/artist/chris-isaak/151329',
    soundcloud: 'https://soundcloud.com/chrisisaak',
    tidal: 'https://listen.tidal.com/artist/6891',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/chris-isaak-tickets/artist/735342',
    napster: 'https://music.amazon.com/artists/B000QJHLZM/chris-isaak',
    tiktok: 'https://www.tiktok.com/@chrisisaakfanpage?lang=es',
  },
  {
    title: 'Elton John',
    description:
      'Sir Elton John es un cantante, pianista, compositor y músico británico. Con una carrera de más de 50 años, ha lanzado más de 30 álbumes de estudio y ha vendido más de 300 millones de copias en todo el mundo, siendo uno de los artistas musicales más exitosos de la historia.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mr6JQDWNhkKYRCe0LUbmROXYR8XUD0N6A',
    price: 36,
    coverImg: 'Elton John.jpg',
    category: 'Soft Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.eltonjohn.com/',
    youtube: 'https://www.youtube.com/channel/UCcd0tBtip8YzdTCUw3OVv_Q',
    facebook: 'https://www.facebook.com/EltonJohn/',
    instagram: 'https://www.instagram.com/eltonjohn/',
    twitter: 'https://twitter.com/eltonofficial',
    spotify: 'https://open.spotify.com/artist/3PhoLpVuITZKcymswpck5b',
    deezer: 'https://www.deezer.com/mx/artist/413',
    apple: 'https://music.apple.com/mx/artist/elton-john/54657',
    soundcloud: 'https://soundcloud.com/elton-john',
    tidal: 'https://listen.tidal.com/artist/6890',
    wiki: 'https://es.wikipedia.org/wiki/Elton_John',
    ticketmaster:
      'https://www.ticketmaster.com/elton-john-tickets/artist/735394',
    tiktok: 'https://www.tiktok.com/@eltonjohn?lang=es',
    napster: 'https://us.napster.com/artist/elton-john',
  },
  {
    title: 'Sting',
    description:
      'Gordon Matthew Thomas Sumner, conocido artísticamente como Sting, es un músico británico que se desempeñó inicialmente como bajista, y más tarde como cantante y bajista del grupo musical The Police, formando luego su propia banda..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kk7sE13ypRgPTNaJTq3yvhZ2CavuO0U8w',
    price: '$50',
    coverImg: 'Sting.jpg',
    category: 'New Age',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sting.com/splash',
    youtube: 'https://www.youtube.com/channel/UCwERP8ZAI10RtZIAPsZQDDw',
    facebook: 'https://www.facebook.com/sting',
    instagram: 'https://www.instagram.com/theofficialsting/',
    twitter: 'https://twitter.com/OfficialSting',
    spotify: 'https://open.spotify.com/artist/0Ty63ceoRnnJKVEYP0VQpk',
    deezer: 'https://www.deezer.com/mx/artist/368',
    apple: 'https://music.apple.com/mx/artist/sting/94804',
    soundcloud: 'https://soundcloud.com/sting',
    tidal: 'https://listen.tidal.com/artist/17356',
    wiki: 'https://es.wikipedia.org/wiki/Sting',
    ticketmaster: 'https://www.ticketmaster.com/sting-tickets/artist/723578',
    tiktok: 'https://www.tiktok.com/@official_sting?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNH82/sting',
  },
  {
    title: 'Stevie Ray Vaughan',
    description:
      'Stephen Stevie Ray Vaughan (Dallas, Texas; 3 de octubre de 1954 - East Troy, Wisconsin; 27 de agosto de 1990) fue un guitarrista, cantante, compositor y productor discográfico estadounidense que fusionó los géneros de blues y rock en la década de 1980. Desarrolló un sonido y estilo basado en artistas de blues como B. B. King, Albert King, Freddie King, Buddy Guy, Otis Rush y Jimmi Hendrix. Después formó su propia agrupación, Triple Threat Revue, cambiando su nombre a Double Trouble tras contratar al baterista Chris Layton y al bajista Tommy Shannon. Logró popularidad tras su participación en el Festival de Jazz de Montreux de 1982, y su álbum debut Texas Flood ocupó la posición número 38 en 1983, una exitosa producción que vendió cerca de medio millón de copias. Compartió escenario con Jeff Beck en 1989 y con Joe Cocker en 1990, pero falleció trágicamente en un accidente de helicóptero el 27 de agosto de 1990 a los 35 años...',
    videourl:
      'https://youtube.com/playlist?list=PLDsFO1NXFTIIYLXp2OZlxHiv7tSOJfTNf&si=VqQACR3bszgnXVYO',
    price: '$50',
    coverImg: 'stevierayvaughan.jpg',
    category: 'Blues Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.srvofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCxPlXqVP-0GvvGG-WrE_6Iw',
    facebook: 'https://www.facebook.com/stevierayvaughan',
    instagram: 'https://www.instagram.com/stevierayvaughan_official/',
    twitter: 'https://twitter.com/SRVOfficial',
    spotify: 'https://open.spotify.com/artist/5fsDcuclIe8ZiBD5P787K1',
    deezer: 'https://www.deezer.com/mx/artist/12297',
    apple: 'https://music.apple.com/us/artist/stevie-ray-vaughan/484957',
    soundcloud: 'https://soundcloud.com/stevie-ray-vaughan-official',
    tidal: 'https://listen.tidal.com/artist/111',
    wiki: 'https://es.wikipedia.org/wiki/Stevie_Ray_Vaughan',
    ticketmaster:
      'https://www.ticketmaster.com/stevie-ray-vaughan-tribute-tickets/artist/834523',
    tiktok: 'https://www.tiktok.com/@stevie_ray_vaughan?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B00123XBKK/stevie-ray-vaughan',
  },

  {
    title: 'Joe Satriani',
    description:
      "Joe 'Satch' Satriani (Westbury, Nueva York, 15 de julio de 1956) es un virtuoso guitarrista de rock instrumental estadounidense. Fue nominado en 15 ocasiones a los Premios Grammy y ha vendido más de 10 millones de discos en todo el mundo.Según otros guitarristas ha conseguido dominar casi todas las técnicas de ejecución de su instrumento, incluyendo tapping a dos manos, sweep picking, volume swells y tap harmonics. Además es reconocido como un gran profesor de guitarra, entre sus alumnos se incluyen: Tom Morello, Alex Skolnick, Larry LaLonde, Andy Timmons, Reb Beach, Rick Hunolt, Charlie Hunter, Kirk Hammett y Steve Vai.",
    videourl:
      'https://youtube.com/playlist?list=PLY90ZuSuNbRhsWk4hLVadAvUC60_mtzPr',
    price: 36,
    coverImg: 'JoeSatriani.jpg',
    category: 'Rock ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.satriani.com/',
    facebook: 'https://www.facebook.com/joesatriani/',
    instagram: 'https://www.instagram.com/joesatriani/',
    twitter: 'https://twitter.com/chickenfootjoe',
    youtube: 'https://www.youtube.com/channel/UCiynI3-MFYbiZ6FQqWNLh7w',
    spotify: 'https://open.spotify.com/artist/2yzxX2DI9LFK8VFTyW2zZ8',
    deezer: 'https://www.deezer.com/mx/artist/496',
    apple: 'https://music.apple.com/mx/artist/joe-satriani/466941',
    soundcloud: 'https://soundcloud.com/joesatrianiofficial',
    tidal: 'https://listen.tidal.com/artist/686',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Satriani',
    ticketmaster:
      'https://www.ticketmaster.com/joe-satriani-tickets/artist/736048',
    tiktok: 'https://www.tiktok.com/@joesatriani?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0010VKX64/joe-satriani',
  },
  {
    title: 'Seal',
    description:
      'Seal Henry Olusegun Olumide Adeola Samuel (nacido el 19 de febrero de 1963), mejor conocido por su nombre artístico Seal, es un cantante, compositor y productor musical británico. Ha vendido más de 20 millones de discos en todo el mundo.Estos incluyen canciones exitosas como Crazy y Killer, esta última llegó al número uno en el Reino Unido, y su canción más celebrada, Kiss from a Rose, que fue lanzada en 1994. Seal es reconocido por su distintiva y emotiva voz.A lo largo de su carrera, Seal ha ganado múltiples premios, incluidos tres Brit Awards; obtuvo el premio al Mejor Artista Británico Masculino en 1992. También ha ganado cuatro premios Grammy y un Premio MTV Video Music.Como compositor, Seal recibió dos premios Ivor Novello a la Mejor Canción Musical y Líricamente de la Academia Británica de Compositores, Autores y Compositores por Killer (1990) y Crazy (1991).',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l9BlMERDcybcHbsuc1x9vtYVOhrvxvA_c&si=HCOu-LRUDl4KakDH',
    price: 36,
    coverImg: 'seal.jpg',
    category: 'Rock ',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sealofficial.com/',
    facebook: 'https://www.facebook.com/Seal/',
    instagram: 'https://www.instagram.com/seal/',
    twitter: 'https://twitter.com/seal',
    youtube: 'https://www.youtube.com/channel/UCzLsQjn1nZPRGmRdg0TX15g',
    spotify: 'https://open.spotify.com/artist/5GtMEZEeFFsuHY8ad4kOxv',
    deezer: 'https://www.deezer.com/mx/artist/1444',
    apple: 'https://music.apple.com/mx/artist/seal/156209',
    soundcloud: 'https://soundcloud.com/seal',
    tidal: 'https://listen.tidal.com/artist/6889',
    wiki: 'https://en.wikipedia.org/wiki/Seal_(musician)',
    ticketmaster: 'https://www.ticketmaster.com/seal-tickets/artist/736073',
    tiktok: 'https://www.tiktok.com/@seal',
    napster: 'https://music.amazon.com.mx/artists/B000RSHBK2/seal',
  },
  {
    title: 'Michael Buble',
    description:
      "Michael Steven Bublé es un cantante, compositor y actor canadiense. Ha ganado diversos premios, incluyendo cuatro Grammy y varios Juno. Los logros obtenidos alcanzaron modestas posiciones en las listas de los Estados Unidos, pero su álbum homónimo estuvo entre los diez primeros del Líbano, del Reino Unido y en su propio país. Pero no fue hasta 2005 en donde se convirtió en todo un éxito comercial en los Estados Unidos con su álbum It's time, del que vendió 8 millones de copias. Está casado con la actriz argentina Luisana Lopilato. Michael también ha aparecido en la serie Rove en cuatro ocasiones..",
    videourl: 'https://youtube.com/playlist?list=PLC54F72FA80FCD5A3',
    price: '$50',
    coverImg: 'michaelbuble.jpeg',
    category: 'Rythm & Blues',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://www.michaelbuble.com/higher?ref=Typed/Bookmarked',
    youtube: 'https://www.youtube.com/channel/UCHqQruhGENdmWy_oeH1f8QA',
    facebook: 'https://www.facebook.com/MichaelBuble',
    instagram: 'https://www.instagram.com/michael.buble_official/',
    twitter: 'https://twitter.com/MichaelBuble',
    spotify: 'https://open.spotify.com/artist/1GxkXlMwML1oSg5eLPiAz3',
    deezer: 'https://www.deezer.com/mx/artist/1478',
    apple: 'https://music.apple.com/mx/artist/michael-bubl%C3%A9/799597',
    soundcloud: 'https://soundcloud.com/michaelbuble',
    tidal: 'https://listen.tidal.com/artist/12263',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Bubl%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/michael-buble-tickets/artist/869115',
    napster:
      'https://music.amazon.com.mx/artists/B0011Z76FK/michael-bubl%C3%A9',
    tiktok: 'https://www.tiktok.com/@michaelbuble?lang=es',
  },
  {
    title: 'Chet Atkins',
    description:
      'Chester Burton "Chet" Atkins (20 de junio de 1924 – 30 de junio de 2001) fue un influyente guitarrista y productor de country. Su estilo —inspirado por Merle Travis, Django Reinhardt, George Barnes y Les Paul— le trajo admiradores dentro y fuera de la escena de EE. UU. Atkins produjo discos para Eddy Arnold, Don Gibson, Jim Reeves, Connie Smith, y Waylon Jennings. Creó, junto a Owen Bradley, el estilo de música country más suave y tranquilizador conocido como sonido Nashville, que llevó el gusto por la música country a admiradores de la música pop para adultos..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWh3XECQmwAggRs2-WMnlr0&si=_ajc7XmACic8_bnW',
    price: 80,
    coverImg: 'chetatkins.jpeg',
    category: 'Country Guitar',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.misterguitar.us/',
    youtube: 'https://www.youtube.com/channel/UCBH-9udLbWZBO3gIrWnwIiw',
    facebook: 'https://www.facebook.com/chet.atkins.121',
    instagram: 'https://www.instagram.com/officialchetatkins/',
    twitter: 'https://twitter.com/chetatkins757',
    spotify: 'https://open.spotify.com/artist/4dZrt8Ong5t7YYpvbfp0RU',
    deezer: 'https://www.deezer.com/mx/artist/7635',
    apple: 'https://music.apple.com/mx/artist/chet-atkins/212083',
    soundcloud: 'https://soundcloud.com/chetatkinsofficial',
    tidal: 'https://listen.tidal.com/artist/137',
    wiki: 'https://es.wikipedia.org/wiki/Chet_Atkins',
    ticketmaster:
      'https://www.ticketmaster.com/chet-atkins-tickets/artist/732681',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Chet%20Atkins&t=1693371013916',
    napster: 'https://music.amazon.com.mx/artists/B000QJO81C/chet-atkins',
  },
  {
    title: 'John Mellencamp',
    description:
      'John Mellencamp, anteriormente conocido como Johnny Cougar, John Cougar y John Cougar Mellencamp (Seymour, Indiana; 7 de octubre de 1951), es un cantante, compositor y guitarrista de rock estadounidense.John Mellencamp comenzó su carrera a finales de los 70 (de 1976 es su primer disco, que resultó un fracaso comercial); su popularidad se fue acrecentando aprovechando el tirón de la música de Bruce Springsteen, a la que le unían muchos elementos en común; sin embargo, a medida que avanzaba su carrera, su música se volvía más personal, desarrollando una particular mezcla entre folk rock y hard rock...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nfgfyECUR8V4YfR3YNf-EwDQrKPWNDcA0&si=oYTEQWu9-x-gJwbs',
    price: 80,
    coverImg: 'mellencamp.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.mellencamp.com/',
    youtube: 'https://www.youtube.com/channel/UCzYJCeitN60eTc9UZvvRDoA',
    facebook: 'https://www.facebook.com/JohnMellencamp/?ref=s',
    instagram: 'https://www.instagram.com/johnmellencamp/',
    twitter: 'https://twitter.com/johnmellencamp',
    spotify: 'https://open.spotify.com/artist/3lPQ2Fk5JOwGWAF3ORFCqH',
    deezer: 'https://www.deezer.com/mx/artist/5734',
    apple: 'https://music.apple.com/us/artist/john-mellencamp/79969',
    soundcloud: 'https://soundcloud.com/john-mellencamp',
    tidal: 'https://listen.tidal.com/artist/241',
    wiki: 'https://es.wikipedia.org/wiki/John_Mellencamp',
    ticketmaster:
      'https://www.ticketmaster.com/john-mellencamp-tickets/artist/770615',
    tiktok: 'https://www.tiktok.com/@johnmellencamp?lang=es',
    napster: '',
  },
  {
    title: 'Bryan Adams',
    description:
      'Bryan Guy Adams (Kingston, Ontario; 5 de noviembre de 1959) es un guitarrista, cantante, compositor, fotógrafo y filántropo canadiense.1​ En más de cuarenta años de carrera ha vendido más de 100 millones de copias, incluyendo álbumes y sencillos en todo el mundo..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nd7dqorN_hoxxIF1beWZ7S6f9j---lO7E&si=Pk6G0VfCE7TZ2XqG',
    price: 80,
    coverImg: 'bryanadams.jpg',
    category: 'Rock',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.bryanadams.com/',
    youtube: 'https://www.youtube.com/@bryanadams',
    facebook: 'https://www.facebook.com/bryanadamsofficial',
    instagram: 'https://www.instagram.com/bryanadams/',
    twitter: 'https://twitter.com/bryanadams',
    spotify: 'https://open.spotify.com/artist/3Z02hBLubJxuFJfhacLSDc',
    deezer: 'https://www.deezer.com/mx/artist/170',
    apple: 'https://music.apple.com/mx/artist/bryan-adams/85932',
    soundcloud: 'https://soundcloud.com/bryan-adams-official',
    tidal: 'https://listen.tidal.com/artist/7436',
    wiki: 'https://es.wikipedia.org/wiki/Bryan_Adams',
    ticketmaster:
      'https://www.ticketmaster.com/bryan-adams-tickets/artist/734390',
    tiktok: 'https://www.tiktok.com/@bryanadams?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSC7VA/bryan-adams',
  },
  {
    title: 'Bruce Springsteen',
    description:
      'Bruce Frederick Joseph Springsteen (Long Branch, Nueva Jersey, 23 de septiembre de 1949)1​ es un cantante, músico y compositor estadounidense. Apodado a menudo the Boss —en español: el Jefe—, Springsteen es ampliamente conocido por su trabajo con el grupo E Street Band y considerado uno de los artistas más exitosos de la música rock, con ventas que superan los 64,5 millones de álbumes en los Estados Unidos y más de 120 millones a nivel mundial,4​5​ y un total de diez discos números uno, un registro solo superado por The Beatles y Jay-Z..',
    videourl:
      'https://youtube.com/playlist?list=PLJ3gKh8Ty5pYsEs3X6-T-MGmg1Kq1qK23&si=6ca7AS2AdsZesSA4',
    price: 80,
    coverImg: 'brucespringsteen.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://brucespringsteen.net/',
    youtube: 'https://www.youtube.com/channel/UCcu7ANuD9J7hnTQCREqIc4Q',
    facebook: 'https://www.facebook.com/brucespringsteen/',
    instagram: 'https://www.instagram.com/springsteen/?hl=en',
    twitter: 'https://twitter.com/springsteen',
    spotify: 'https://open.spotify.com/album/4XJaXh57G3rZtAzqeVZSfn',
    deezer: 'https://www.deezer.com/en/artist/215563125',
    apple: 'https://music.apple.com/mx/artist/bruce-springsteen/178834',
    soundcloud: 'https://soundcloud.com/brucespringsteen',
    tidal: 'https://listen.tidal.com/artist/509',
    wiki: 'https://es.wikipedia.org/wiki/Bruce_Springsteen',
    ticketmaster:
      'https://www.ticketmaster.com/bruce-springsteen-tickets/artist/736179',
    tiktok: 'https://www.tiktok.com/@brucespringsteen180?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJW2M/bruce-springsteen',
  },
  {
    title: 'Billy Ocean',
    description:
      'Leslie Sebastian Charles (Fyzabad, Trinidad y Tobago; 21 de enero de 1950), más conocido como Billy Ocean, es un cantautor trinitense nacionalizado británico que comenzó a hacerse famoso a partir de la década de los 70s. Entre sus éxitos, se pueden destacar las canciones When the Going Gets Tough, the Tough Gets Going (parte de la banda sonora de la película La joya del Nilo, de 1985), Suddenly, Get Outta of My Dreams, Get Into My Car (parte de la banda sonora del filme License to Drive), Caribbean Queen, Loverboy y Therell Be Sad Songs to Make You Cry...​',
    videourl:
      'https://youtube.com/playlist?list=PLJn3EUUdQu2KYujKLc24qS3neFXBsUINo&si=W5aN8icZCqDlo5QN',
    price: 36,
    coverImg: 'billieOcean.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'trinidadytobago.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billyocean.com/',
    youtube: 'https://www.youtube.com/channel/UCjoGX0EUeb9zXF_0zLIs5CA',
    facebook: 'https://www.facebook.com/billyoceanofficial/?fref=ts',
    instagram: 'https://www.instagram.com/billyoceanofficial/?hl=en',
    twitter: 'https://twitter.com/MrWongo',
    spotify: 'https://open.spotify.com/artist/5IDs1CK15HegSAhGEbSYXo',
    deezer: 'https://www.deezer.com/mx/artist/4700',
    apple: 'https://music.apple.com/us/artist/billy-ocean/2320137',
    soundcloud: 'https://soundcloud.com/billyocean-official',
    tidal: 'https://listen.tidal.com/artist/1138',
    wiki: 'https://es.wikipedia.org/wiki/Billy_Ocean',
    ticketmaster:
      'https://www.ticketmaster.com/billy-ocean-tickets/artist/1017332',
    tiktok: 'https://www.tiktok.com/@billyoceanofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSCFQM/billy-ocean',
  },
  {
    title: 'Billy Joel',
    description:
      'William Martin "Billy" Joel (El Bronx, Nueva York, 9 de mayo de 1949) es un cantante, compositor y pianista estadounidense. Billy ha grabado muchos éxitos populares y logrado más de 40 hits desde 1973 (empezando con el sencillo "Piano Man") hasta su retirada en 1993, aunque sigue haciendo tours. Es uno de los pocos artistas de rock y pop que consiguieron éxitos en el top ten de los 70, 80 y 90. Ganador del Premio Grammy en seis ocasiones, ha vendido más de 100 millones de discos en el mundo y es el sexto artista con más ventas en Estados Unidos, de acuerdo con la RIAA.2​ Ha realizado exitosas giras artísticas, algunas veces con Elton John3​ además de escribir y grabar música clásica. Fue incluido en el salón de la fama del rock and roll en 1999, así como también, en el salón de la fama de los compositores y cantantes en 1992...​',
    videourl:
      'https://youtube.com/playlist?list=PLJn3EUUdQu2KYujKLc24qS3neFXBsUINo&si=W5aN8icZCqDlo5QN',
    price: 36,
    coverImg: 'billyjoel.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billyjoel.com//',
    youtube: 'https://www.youtube.com/@billyjoel',
    facebook: 'https://www.facebook.com/billyjoel',
    instagram: 'https://www.instagram.com/billyjoel/',
    twitter: 'https://twitter.com/billyjoel',
    spotify: 'https://open.spotify.com/artist/5IDs1CK15HegSAhGEbSYXo',
    deezer: 'https://www.deezer.com/en/artist/423',
    apple: 'https://music.apple.com/us/artist/billy-joel/485953',
    soundcloud: 'https://soundcloud.com/billyocean-official',
    tidal: 'https://listen.tidal.com/artist/59',
    wiki: 'https://es.wikipedia.org/wiki/Billy_Joel',
    ticketmaster:
      'https://www.ticketmaster.com/billy-joel-tickets/artist/735392',
    tiktok: 'https://www.tiktok.com/@official_billy__joel?lang=es',
    napster:
      'https://music.amazon.com/artists/B000QJPZLE/billy-joel?ref=dm_wcp_artist_link_pr_s',
  },
  {
    title: 'Lionel Richie',
    description:
      'Lionel Brockman Richie, Jr. (Tuskegee, Alabama; 20 de junio de 1949)1​ es un cantautor y actor estadounidense.En los años 1970 formó parte de la banda musical The Commodores, propiedad del famoso sello musical Motown de Detroit (Míchigan). Un grupo estadounidense, famoso por su estilo musical funk/soul, que destacó en las décadas de 1970 y 1980. Se formaron en Detroit en 1968, y al año firmaron contrato con Motown Records. El grupo es más conocido por baladas como "Easy", "Still2 y "Three Times A Lady", aunque grabó principalmente funk y música bailable en temas como "Brick house", "Say Yeah", "Fancy Dancer", "Too Hot Ta Trot", "Nightshift", entre otros..​',
    videourl:
      'https://youtube.com/playlist?list=PLv6gVwjfKeYSrFAGcsYRI5fksu6-v1k0p',
    price: 36,
    coverImg: 'lionelritchie.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lionelrichie.com/',
    youtube: 'https://www.youtube.com/channel/UCM3iG_kXUM_9HHVIDI7vEtg',
    facebook: 'https://www.facebook.com/LionelRichie',
    instagram: 'https://www.instagram.com/lionelrichie/',
    twitter: 'https://twitter.com/LionelRichie',
    spotify: 'https://open.spotify.com/artist/3gMaNLQm7D9MornNILzdSl',
    deezer: 'https://www.deezer.com/mx/artist/3445',
    apple: 'https://music.apple.com/us/artist/lionel-richie/48397',
    soundcloud: 'https://soundcloud.com/lionelrichieofficial',
    tidal: 'https://listen.tidal.com/artist/17277',
    wiki: 'https://es.wikipedia.org/wiki/Lionel_Richie',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@lionelrichie?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP758/lionel-richie',
  },
  {
    title: 'Santana',
    description:
      'Carlos Humberto Santana Barragan (Autlán de Navarro, Jalisco; 20 de julio de 1947) es un guitarrista mexicano nacionalizado estadounidense.En 1966 fundó la banda Santana, pionera en fusionar la música latina con el rock. Santana ha vendido más de 100 millones de álbumes en todo el mundo (contando las ventas de su banda y su carrera en solitario). Ha ganado diez premios Grammy y tres premios Grammy Latino.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nFhKKg7Ch_9lnfxTogq0VhkZZdvB7wBg4',
    price: 50,
    coverImg: 'santana.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.santana.com/',
    youtube: 'https://www.youtube.com/channel/UCI7pNDDat9jR8TjgF48aBDA',
    facebook: 'https://www.facebook.com/carlossantana',
    instagram: 'https://twitter.com/santanacarlos',
    twitter: 'https://twitter.com/santanacarlos',
    spotify: 'https://open.spotify.com/artist/6GI52t8N5F02MxU0g5U69P',
    deezer: 'https://www.deezer.com/mx/artist/553',
    apple: 'https://music.apple.com/mx/artist/santana/217174',
    soundcloud: 'https://soundcloud.com/santana-official',
    tidal: 'https://listen.tidal.com/artist/74',
    wiki: 'https://es.wikipedia.org/wiki/Carlos_Santana',
    ticketmaster: 'https://www.ticketmaster.com/santana-tickets/artist/741473',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Santana%20music&t=1654465241940',
    napster: 'https://app.napster.com/artist/santana',
  },
  {
    title: 'Alejandro Fernandez',
    description:
      'Alejandro Fernández Abarca es un cantante de música ranchera y pop latino mexicano, hijo del fallecido cantante ranchero Vicente Fernández. En un principio se especializó en tipos tradicionales de música regional mexicana como mariachi. Posteriormente se ha diversificado hacia el pop latino con toques urbanos, la balada y el bolero.',

    videourl: 'http://youtube.com/playlist?list=PLEB89B6DF1EE964B5',
    price: '$50',
    coverImg: 'AlejandroFernandez.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://alejandrofernandez.com/',
    youtube: 'https://www.youtube.com/channel/UC0K-1_31jfkcA4Nd294gMyA',
    facebook: 'https://www.facebook.com/alejandrofernandezoficial',
    instagram: 'https://www.instagram.com/alexoficial/',
    twitter: 'https://twitter.com/alexoficial',
    spotify:
      'https://open.spotify.com/artist/6sq1yF0OZEWA4xoXVKW1L9?si=Nbmjb1PvTiGojQaSs1z1cQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/8794',
    apple: 'https://music.apple.com/mx/artist/alejandro-fern%C3%A1ndez/893583',
    soundcloud: 'https://soundcloud.com/alejandro-fernandez-official',
    tidal: 'https://listen.tidal.com/artist/1410',
    wiki: 'https://en.wikipedia.org/wiki/Alejandro_Fern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com/alejandro-fernandez-tickets/artist/761444',
    tiktok: 'https://www.tiktok.com/@alejandrofernandezfans',
    napster: 'https://web.napster.com/artist/alejandro-fernandez',
  },
  {
    title: 'Pepe Aguilar',
    description:
      'José Antonio Aguilar Jiménez, más conocido como Pepe Aguilar, es un cantante, compositor, músico, productor y empresario mexicano. Desde muy joven, Aguilar acompañó de gira a sus padres, los cantantes y actores mexicanos Antonio Aguilar y Flor Silvestre. Ahora hace lo mismo con sus propios hijos, Leonardo Aguilar y Ángela Aguilar, quienes también han seguido los pasos de su padre y abuelos..',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_km79fnJX9-enVlkDxmmUczWzgFBYo-mbY',
    price: '$50',
    coverImg: 'pepeaguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pepeaguilar.com/',
    youtube: 'https://www.youtube.com/c/pepeaguilaroficial',
    facebook: 'https://www.facebook.com/pepeaguilaroficial',
    instagram: 'https://www.instagram.com/pepeaguilar_oficial/',
    twitter: 'https://twitter.com/PepeAguilar',
    spotify: 'https://open.spotify.com/artist/03Yb3iBy9GCifXiATEFcit',
    deezer: 'https://www.deezer.com/mx/artist/8793',
    apple: 'https://music.apple.com/mx/artist/pepe-aguilar/21421637',
    soundcloud: 'https://soundcloud.com/search?q=Pepe%20Aguilar',
    tidal: 'https://listen.tidal.com/artist/3506026',
    wiki: 'https://en.wikipedia.org/wiki/Pepe_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/pepe-aguilar-tickets/artist/754764',

    tiktok: 'https://www.tiktok.com/@pepeaguilar_oficial',
    napster: 'https://web.napster.com/artist/pepe-aguilar',
  },
  {
    title: 'Luis Miguel',
    description:
      'Luis Miguel Gallego Basteri (San Juan, 19 de abril de 1970),conocido como Luis Miguel y apodado en ocasiones como «El Sol de México», es un cantante y productor musical mexicano.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUMRA7E0-zntqkMrhNKwYri',
    price: 80,
    coverImg: 'luismiguel.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://luismigueloficial.com/',
    youtube: 'https://www.youtube.com/c/LuisMiguelOfficial',
    facebook: 'https://www.facebook.com/luismiguelofficial',
    instagram: 'https://www.instagram.com/lmxlm/',
    twitter: 'https://twitter.com/LMXLM',
    spotify: 'https://open.spotify.com/artist/2nszmSgqreHSdJA3zWPyrW',
    deezer: 'https://www.deezer.com/mx/artist/6123',
    apple: 'https://music.apple.com/mx/artist/luis-miguel/336904',
    soundcloud: 'https://soundcloud.com/luismiguel_oficial',
    tidal: 'https://listen.tidal.com/artist/11993',
    wiki: 'https://en.wikipedia.org/wiki/Luis_Miguel',
    ticketmaster:
      'https://www.ticketmaster.com/luis-miguel-tickets/artist/762874',

    tiktok: 'https://www.tiktok.com/@lmxlm',
    napster: 'https://web.napster.com/artist/luis-miguel',
  },
]
export default CardData33

