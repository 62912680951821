const CardData12 = [
  {
    title: 'Los Tucanes de Tijuana',
    description:
      'Los Tucanes de Tijuana son una agrupación de música regional mexicana liderada por Mario Quintero Lara, especializada en el estilo norteño y corrido. Fue creada en la ciudad de Tijuana, Baja California, México en el año de 1983 y formalizada en 1987. Durante su carrera han cosechado diversos premios y reconocimientos, entre los que destacan un Grammy Latino en 2012 por el álbum 365 días, cinco nominaciones a los Premios Grammy, nueve a los Premios Lo Nuestro y múltiples Premios BMI para Quintero como compositor. Son la primera banda de música norteña en ganar un premio de cine internacional al obtener el galardón «Un Certain Regard Angel Film» en el Festival Internacional de Cine de Mónaco por su participación en el documental Los ilegales. En 2008 el grupo recibió una estrella en el Paseo de la Fama de Las Vegas.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kVyzS5YvKNUaKdLtqU0C7djL5D8vMM52U',
    price: '$50',
    coverImg: 'TucanesdeTijuana.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lostucanesdetijuana.com/',
    youtube: 'https://www.youtube.com/channel/UCQfVQ8ycXpY9zzEhci6-0wg',
    facebook: 'https://www.facebook.com/lostucanesdetijuana',
    instagram: 'https://www.instagram.com/lostucanesdetijuana/',
    twitter: 'https://twitter.com/LosTucanesdeTJ',
    spotify: 'https://open.spotify.com/artist/014WIDx7H4BRCHB1faiisK',
    deezer: 'https://www.deezer.com/mx/artist/192548',
    apple: 'https://music.apple.com/mx/artist/los-tucanes-de-tijuana/90197',
    soundcloud: 'https://soundcloud.com/los-tucanes-de-tijuana-official',
    tidal: 'https://listen.tidal.com/artist/17521',
    wiki: 'https://es.wikipedia.org/wiki/Los_Tucanes_de_Tijuana',
    ticketmaster:
      'https://www.ticketmaster.com/los-tucanes-de-tijuana-tickets/artist/764354',
    tiktok: 'https://www.tiktok.com/@lostucanesdetijuana?lang=es',
    napster: 'https://web.napster.com/artist/los-tucanes-de-tijuana',
  },
  {
    title: 'Luis R Conriquez',
    description:
      'Luis R Conriquez es un compositor que se hizo conocido entre el público gracias a su estilo interpretativo, la honestidad de sus letras, sus mensajes de superación personal y un talento innato para componer corridos modernos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVhgiYg1lFWJMnQ2A_3COmX',
    price: 80,
    coverImg: 'luisrconriquez.jpeg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://kartelmusic.com/',
    youtube: 'https://www.youtube.com/@kartelmusicoficial',
    facebook: 'https://www.facebook.com/profile.php?id=100050488239777',
    instagram: 'https://www.instagram.com/luisrconriquez.fan.page/',
    twitter: 'https://twitter.com/LuisRConriquez',
    spotify: 'https://open.spotify.com/artist/0pePYDrJGk8gqMRbXrLJC8',
    deezer: 'https://www.deezer.com/en/artist/14004075',
    apple: 'https://music.apple.com/mx/artist/luis-r-conriquez/1252271456',
    soundcloud: 'https://soundcloud.com/luisrconriquez',
    tidal: 'https://listen.tidal.com/artist/9471448',
    wiki: 'https://en.wikipedia.org/wiki/Luis_R_Conriquez',
    ticketmaster:
      'https://www.ticketmaster.com/luis-r-conriquez-tickets/artist/2832072',
    tiktok: 'https://www.tiktok.com/@luisrconriquezoficial?lang=es',
    napster: 'https://us.napster.com/artist/luis-r-conriquez',
  },
  {
    title: 'Natanael Cano',
    description:
      'Natanael Cano, nombre artístico de Nathanahel Rubén Cano Monge (Hermosillo, 27 de marzo de 2001), es un rapero y cantautor del género regional mexicano. Es el creador del concepto corridos tumbados, nombre de uno de sus discos. Su música fusiona géneros de música regional mexicana tales como el corrido y sierreño-banda con rap, trap, y hip-hop ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natanael.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/profile.php?id=100077546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://app.napster.com/artist/natanael-cano',
  },
  {
    title: 'Rancho Humilde Records',
    description:
      'Jimmy Humilde, es considerado como “uno de los hombres que más misterio genera entorno a su persona” en el mundo del espectáculo musical.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL90dAa2YBl3WBt_zO-drgdq',
    price: 50,
    coverImg: 'RanchoHumilde.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://ranchohumildestore.com/',
    youtube: 'https://www.youtube.com/c/RanchoHumildeoficial',
    facebook: 'https://www.facebook.com/ranchohumildeofficial',
    instagram: 'https://www.instagram.com/ranchohumilde/',
    twitter: 'https://twitter.com/ranchohumilde',
    spotify: 'https://open.spotify.com/artist/7iSpMTdILobashwyZorN25',
    deezer: 'https://www.deezer.com/mx/artist/89145252',
    apple: 'https://music.apple.com/mx/artist/rancho-humilde/1077575821',
    soundcloud: 'https://soundcloud.com/ranchohumilde',
    tidal: 'https://listen.tidal.com/artist/14100262',
    wiki:
      'https://www.latimes.com/espanol/entretenimiento/articulo/2021-05-21/rancho-humilde-records-con-sede-en-downey',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://web.napster.com/artist/art.203603174',
  },

  {
    title: 'Los Cadetes de Linares',
    description:
      ' Los Cadetes de Linares fue un dúo de música norteña, integrado por el cantante y bajo sextista Homero Guerreronota 1​ y el cantante y acordeonista Lupe Tijerinanota 2​ en el año de 1974.1​ Algunas de sus composiciones e interpretaciones son consideradas clásicas dentro de su género musical2​ y les allegaron éxito y reconocimientos,1​ al grado de ser considerados un conjunto emblemático del género norteño en particular y del género regional mexicano en general.3​ Este éxito y fama fueron alcanzados entre finales de los años setenta e inicios de los ochenta, cuando su trayectoria finalizó, debido a la muerte de su fundador, Homero Guerrero. El nombre: «Los Cadetes de Linares», ha sido muchas veces utilizado e incluso plagiado por un notable número de grupos musicales de corte o estilo norteño,4​2​ provocando por un lado una serie de conflictos, equívocos y confusiones, y por otro, una muestra o evidencia de la gran popularidad del dueto original..',

    videourl: 'https://youtube.com/playlist?list=UULFrCzQHlnP6iHB4od8lMcskA',
    price: '$50',
    coverImg: 'loscadetesdelinares.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://loscadetesdelinares.com/',
    youtube: 'https://www.youtube.com/channel/UCrCzQHlnP6iHB4od8lMcskA',
    facebook: 'https://www.facebook.com/profile.php?id=100077482343788',
    instagram: 'https://www.instagram.com/loscadetesdelinares_oficial/',
    twitter: 'https://twitter.com/CadetesDelin',
    spotify: 'https://open.spotify.com/artist/1iIxNEvPPmdFIIP0tdpw6G',
    deezer: 'https://www.deezer.com/mx/artist/323520',
    apple: 'https://music.apple.com/mx/artist/los-cadetes-de-linares/135633187',
    soundcloud: 'https://soundcloud.com/loscadetesdelinares',
    tidal: 'https://listen.tidal.com/artist/3854270',
    wiki: 'https://es.wikipedia.org/wiki/Los_Cadetes_de_Linares',
    ticketmaster:
      'https://www.ticketmaster.com/los-cadetes-de-linares-tickets/artist/760527',
    tiktok: 'https://www.tiktok.com/@cadeteshistoria',
    napster:
      'https://music.amazon.com.mx/artists/B000TOOWC4/los-cadetes-de-linares',
  },
  {
    title: 'Chalino Sanchez',
    description:
      'Rosalino Sánchez Félix (Rancho el Guayabo, Sinaloa, 30 de agosto de 1960-Culiacán, Sinaloa, 16 de mayo de 1992), conocido como Chalino Sánchez o El rey del corrido, fue un cantante y compositor mexicano. Se especializó en el género de música regional mexicana.',
    videourl:
      'http://youtube.com/playlist?list=PLevztPGArk6flhsITZWCeTwjQ8Xdtbnyw',
    price: '$50',
    coverImg: 'ChalinoSanchez.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Chalino_S%C3%A1nchez',
    youtube: 'https://www.youtube.com/channel/UC5wwVhsK9j26eewBHAucumw',
    facebook: 'https://www.facebook.com/ElReyDelosCorridos',
    instagram: 'https://www.instagram.com/rosalinosanchezfelix/',
    twitter: 'https://twitter.com/ElReyDeICorrido',
    spotify: 'https://open.spotify.com/artist/7u9m43vPVTERaALXXOzrRq',
    deezer: 'https://www.deezer.com/mx/artist/97125',
    apple: 'https://music.apple.com/mx/artist/chalino-s%C3%A1nchez/55367740',
    soundcloud: 'https://soundcloud.com/chalino-sanchez-official',
    tidal: 'https://listen.tidal.com/artist/3665787',
    wiki: 'https://en.wikipedia.org/wiki/Chalino_S%C3%A1nchez',
    ticketmaster:
      'https://www.ticketmaster.com/homenaje-a-chalino-sanchez-tickets/artist/Z7r9jZakxu',
    tiktok: 'https://www.tiktok.com/@chalinosanchezoficial?lang=es',
    napster: 'https://web.napster.com/artist/chalino-sanchez',
  },
  {
    title: 'Ramon Ayala',
    description:
      'Ramón Ayala Garza conocido como Ramón Ayala o El rey del acordeón, es un acordeonista, cantante y compositor Mexicano. Su destacada trayectoria musical lo ha convertido en uno de los íconos de la música popular..',

    videourl:
      'http://youtube.com/playlist?list=PLPkr_uYmuNmTiy_9Glr_ZvekY8CcmUb77',
    price: '$50',
    coverImg: 'RamonAyala.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Ram%C3%B3n_Ayala',
    youtube: 'https://www.youtube.com/channel/UCMCAZ-nRKHhOBISLX5yG_4w',
    facebook: 'https://www.facebook.com/iamramonayala',
    instagram: 'https://www.instagram.com/iamramonayala/',
    twitter: 'https://twitter.com/iamramonayala',
    spotify: 'https://open.spotify.com/artist/0fIdQWpwzU2oEtsoyArDOL',
    deezer: 'https://www.deezer.com/mx/artist/551542',
    apple: 'https://music.apple.com/mx/artist/ram%C3%B3n-ayala/128895696',
    soundcloud: 'https://soundcloud.com/ramon-ayala-official',
    tidal: 'https://listen.tidal.com/artist/3842696',
    wiki: 'https://en.wikipedia.org/wiki/Ram%C3%B3n_Ayala',
    ticketmaster:
      'https://www.ticketmaster.com/ramon-ayala-tickets/artist/879889',

    tiktok: 'https://www.tiktok.com/@ramon.ayala_',
    napster: 'https://web.napster.com/artist/ramon-ayala',
  },
  {
    title: 'Los Hijos de Garcia',
    description:
      'Los Hijos De Garcia Proveniente de la ciudad de Las Vegas, Nevada ha captado la atención por su peculiar manera para interpretar sus corridos y canciones. A Los Hijos de García los categorizan como “Regional Urbano”, debido a sus líricas y manera de tocar la música norteña. Su sonido único de la acordeón los hacen notar, lo cual se demuestra en sus éxitos underground como son “18 Libras”, “Los Lujos de La Vida”, “El Tuna”, entre otros más.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWnKytmIAEKPrCV4ONP_1VP',
    price: 50,
    coverImg: 'LosHijosDeGarcia.jpeg',
    category: 'Norteño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.lyricasdelrap.com/2020/12/letra-mundo-desgraciado-los-hijos-de.html',
    youtube: 'https://www.youtube.com/channel/UCkyH1NljG_ebXZeTuz9wF9w',
    facebook: 'https://www.facebook.com/LosHijosdeGarcia',
    instagram: 'https://www.instagram.com/loshijosdegarcia/',
    twitter: 'https://twitter.com/hijosdegarcia',
    spotify: 'https://open.spotify.com/artist/3Aw1WaC6Xm8cOOppvXcnfa',
    deezer: 'https://www.deezer.com/mx/artist/13959247',
    apple: 'https://music.apple.com/mx/artist/los-hijos-de-garcia/1104717895',
    soundcloud: 'https://soundcloud.com/loshijosdegarcia',
    tidal: 'https://listen.tidal.com/artist/9417940',
    wiki:
      'https://www.lyricasdelrap.com/2020/12/letra-mundo-desgraciado-los-hijos-de.html',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@hijosdegarcia?lang=es',
    napster: 'https://web.napster.com/artist/art.295877457',
  },
  {
    title: 'Herencia de Patrones',
    description:
      'Biografía de Herencia de Patrones Inicios en la vida de Herencia de Patrones . Herencia de Patrones es un grupo exponente del regional mexicano, banda y corridos originarios del norte de México quienes fusionaron la música urbana.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL-Kd808w8x8f-kFj7_HyT0_',
    price: 50,
    coverImg: 'HerenciaDePatrones.jpeg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandera.netlify.app/',
    youtube: 'https://www.youtube.com/channel/UCkyH1NljG_ebXZeTuz9wF9w',
    facebook: 'https://www.facebook.com/Herencia-de-patrones-110852401173688',
    instagram: 'https://www.instagram.com/herenciadepatronesoficial/',
    twitter: 'https://twitter.com/joru_hp',
    spotify: 'https://open.spotify.com/artist/1Q6SZxTvaE3HhslV0iXbI6',
    deezer: 'https://www.deezer.com/mx/artist/13387589',
    apple: 'https://music.apple.com/mx/artist/herencia-de-patrones/1295294734',
    soundcloud: 'https://soundcloud.com/herenciadepatrones',
    tidal: 'https://listen.tidal.com/artist/9180832',
    wiki: '',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@herencia.de.patronesfp?lang=es',
    napster: 'https://web.napster.com/artist/art.279357512',
  },
  {
    title: 'Grupo Marca Registrada',
    description:
      'Marca Registrada es una agrupación originaria de Culiacán que se fundó durante 2014 y sus integrantes son Fidel Castro, Ángel Mondragón, Fernando Medina, Sergio Espinozam y Rudy Mascareño. Estudié Ciencias de la Comunicación en la Universidad Autónoma de Sinaloa..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nNvXohVC7ZkjMAR4Fv-2v8j2brqIkFcyU',
    price: 50,
    coverImg: 'MarcaRegistrada.jpeg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandera.netlify.app/',
    youtube: 'https://www.youtube.com/channel/UCJrXHTk9F1cFhjc88PALfgA',
    facebook: 'https://www.facebook.com/MarcaRegistradaMROficial',
    instagram: 'https://www.instagram.com/herenciadepatronesoficial/',
    twitter: 'https://twitter.com/grupo_marcareg',
    spotify: 'https://open.spotify.com/artist/1gW6pz5n1aK249L0GvfQCC',
    deezer: 'https://www.deezer.com/mx/artist/9095678',
    apple:
      'https://music.apple.com/mx/artist/grupo-marca-registrada/1051127522',
    soundcloud: 'https://soundcloud.com/grupomarcaregistradaofficial',
    tidal: 'https://listen.tidal.com/artist/7317857',
    wiki:
      'https://www.debate.com.mx/show/Jesus-Ortiz-y-el-Grupo-Marca-Registrada-tiene-mancuerna-de-lujo-en-una-noche-inolvidable-20220227-0293.html',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://web.napster.com/artist/art.203603174',
  },
  {
    title: 'Marca-MP',
    description:
      'Marca MP es una agrupación de música regional mexicana, principalmente de banda, mariachi y ranchera. Incursionaron al mundo de la música profesionalmente en 2019 con su sencillo “El Güero”, que interpretaron al lado de Grupo Firme. Se ganaron al público desde su primer sencillo, muestra de ello, es que “El Güero” permaneció en la cima de la lista como el video grupero más visto de ese año.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lbDCxSZ88e4zNta116vD4z3LCWJovkhmA',
    price: 50,
    coverImg: 'marca-mp.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: ' https://www.facebook.com/grupomarcamp',
    youtube: 'https://www.youtube.com/c/MarcaMP​',
    facebook: ' https://www.facebook.com/grupomarcamp/​',
    instagram: 'https://instagram.com/mmp_oficial​',
    twitter: 'https://twitter.com/marcampoficial',
    spotify: 'https://open.spotify.com/artist/44mEtidu0VdRkIqO4IbkNa',
    deezer: 'https://www.deezer.com/mx/artist/61384072',
    apple: 'https://music.apple.com/mx/artist/marca-mp/1493050737',
    soundcloud: 'https://soundcloud.com/mmp_oficial',
    tidal: 'https://listen.tidal.com/artist/15257396',
    wiki:
      'https://popnable.com/mexico/artists/52383-marca-mp/biography-and-facts#:~:text=Marca%20Mp%20Wiki%20Latin%20group%20with%20a%20notable,prominently%20performed%20in%20Mexico%20and%20the%20United%20States.',
    ticketmaster:
      'https://www.ticketmaster.com/marca-mp-tickets/artist/2802450',
    tiktok: 'https://www.tiktok.com/@mmp.oficial?lang=es',
    napster: 'https://web.napster.com/artist/marca-mp',
  },
  {
    title: 'Gerardo Ortiz',
    description:
      'Gerardo Ortiz Medina es un cantante y compositor mexicano-estadounidense de música regional mexicana, especializado en los estilos de banda, norteño, norteño-banda, sierreño, sierreño-banda y mariachi. Es especialmente famoso por sus corridos progresivos.',
    videourl:
      'http://youtube.com/playlist?list=PLByjh6DfxKTKdej2wbJqw6UmA2KHszPyG',
    price: 50,
    coverImg: 'gerardoOrtiz.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.comerecallado.com/',
    youtube: 'https://www.youtube.com/channel/UCfxLuDIVfwArsz5waYMMLDQ',
    facebook: 'https://www.facebook.com/GerardoOrtizNet',
    instagram: 'https://www.instagram.com/gerardoortizoficial/',
    twitter: 'https://twitter.com/gerardoortiznet',
    spotify: 'https://GerardoOrtiz.lnk.to/ElComando... ',
    deezer: 'https://www.deezer.com/mx/artist/468783',
    apple: 'https://music.apple.com/mx/artist/gerardo-ortiz/339929849',
    soundcloud: 'https://soundcloud.com/gerardoortizofficial',
    tidal: 'https://listen.tidal.com/artist/6174510',
    wiki: 'https://es.wikipedia.org/wiki/Gerardo_Ortiz',
    ticketmaster:
      'https://www.ticketmaster.com/gerardo-ortiz-tickets/artist/1386548',
    tiktok: 'https://www.tiktok.com/@....gerardoortizoficial?lang=es',
    napster: 'https://web.napster.com/artist/gerardo-ortiz',
  },  {
    title: 'Tercer Elemento',
    description:
      'T3R Elemento fue formado por tres jóvenes de origen mexicano y un cubano en Las Vegas, Estados Unidos. Dado que realizaron dos intentos previos de formar un grupo, ante esos fracasos decidieron llamar al grupo T3R Elemento en referencia a que era la tercera vez que lo intentaban.Kristopher Nava fue el fundador del grupo. En 2016 publicaron su primer disco llamado Rafa Caro en Vivo y en 2018 publicaron the green trip. En 2018 fueron nominados por Álbum Favorito-Regional Mexicano a los Premios Latin American Music debido a su creciente popularidad la cual se ha dado en plataformas como Youtube. Sus temas han sido incluidos en la lista Billboard en los Estados Unidos .',
    videourl:
      'http://youtube.com/playlist?list=PLkzsyPO-ybMTw1qR-AdG9_urQq3-nfr6b',
    price: 50,
    coverImg: '3erElemento.jpg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    youtube: 'https://www.youtube.com/channel/UCucxqba8ReybE6oIs9D1_sg',
    facebook: 'https://www.facebook.com/t3relemento/',
    instagram: 'https://www.instagram.com/t3relementooficial/',
    twitter: 'https://twitter.com/t3r_elemento?lang=en',
    spotify: 'https://open.spotify.com/artist/34nbQa7Hug9DYkRJpfKNFv',
    deezer: 'https://www.deezer.com/mx/artist/72293892',
    apple: 'https://music.apple.com/mx/artist/t3r-elemento/1099155524',
    soundcloud: 'https://soundcloud.com/tercer-elemento',
    tidal: 'https://listen.tidal.com/album/129171726',
    wiki: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@t3relementoofficial?lang=es',
    napster: 'https://web.napster.com/artist/t3r-elemento',
    bandera: '/tercerelemento',
  },
  {
    title: 'Grupo 360',
    description:
      'Conjunto de Musica Norteña ,Sierreña y Comica de la ciudad de Tijuana Baja California .SEGUIMOS LABORANDO,AQUILESORGANIGRAMA, THE PANIN ,LA BArDA , ZONA DE GUERRA ,SOMOS DE ACCIÓN ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=PLF-cRDh2mrlXPMOHbKSqgWtqH2kZjNMvA',
    price: 80,
    coverImg: 'tres60.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/grupo360oficiall',
    instagram: 'https://www.instagram.com/grupo360oficial_/',
    twitter: 'https://twitter.com/GrupoTr3s60',
    spotify: 'https://open.spotify.com/artist/2h9VgLswbVrXfdjW8UVqHC',
    deezer: 'https://www.deezer.com/mx/artist/5159420',
    apple: 'https://music.apple.com/mx/artist/grupo-360/1313996832',
    soundcloud: 'https://soundcloud.com/grupo360official',
    tidal: 'https://listen.tidal.com/artist/5679524',
    wiki: 'https://www.buenamusica.com/grupo-360/biografia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupo360oficiall?lang=es',
    napster: 'https://app.napster.com/artist/grupo-360',
  },
  {
    title: 'El Tigrillo Palma',
    description:
      'El Tigrillo Palma” nació en El Baral, Guasave, municipio del estado de Sinaloa, a los seis años de edad, Efrén descubrió el gusto por el canto, y a los diez años ya había grabado su primer disco en un grupo que formó con su hermana y que se llamaba “Los Vaqueritos de El Baral”. En ese tiempo, Efrén y su hermana vivían en la ciudad de México, D.F., ya que pa… Más información',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_luleD3J1PjmzogRZZOegcd6scqvgXF0ys',
    price: '$50',
    coverImg: 'TigrilloPalma.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCrnyN77Ui3c66i00uym7oWg',
    facebook: 'https://www.facebook.com/ElTigrilloPalmaOficial',
    instagram: 'https://www.instagram.com/eltigrillopalma/',
    twitter: 'https://twitter.com/eltigrillopalm2',
    spotify: 'https://open.spotify.com/artist/2usAJ9Mtrw570XlQ5MHJek',
    deezer: 'https://www.deezer.com/mx/artist/256206',
    apple: 'https://music.apple.com/mx/artist/el-tigrillo-palma/211631447',
    soundcloud: 'https://soundcloud.com/el-tigrillo-palma',
    tidal: 'https://listen.tidal.com/artist/3581077',
    wiki: 'https://www.buenamusica.com/el-tigrillo-palma/biografia',
    ticketmaster:
      'https://www.ticketmaster.com/el-tigrillo-palma-tickets/artist/1623190',
    tiktok: 'https://www.tiktok.com/@eltigrillopalmaoficial?lang=es',
    napster: 'https://web.napster.com/artist/el-tigrillo-palma',
  },
  {
    title: 'El Compa Sacra',
    description:
      '¿Quién es El Compa Sacra? Sacramento Ramírez, conocido artísticamente como El Compa Sacra, nació en La Piedad, Michuacán, México. Llego a Estados Unidos de Norte America a comienzos de 1980. Comenzó su carrera musical con la agrupación Los Razos, la cual alcanzó reconocimiento en los años 80 y 90.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kJ5AizQuObUTIu65sr1wXNEw2Sft1-D1M',
    price: '$50',
    coverImg: 'CompaSacra.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/ElCompaSacraOficial',
    youtube: 'https://www.youtube.com/c/CompaSacraOficial',
    facebook: 'https://www.facebook.com/ElCompaSacraOficial',
    instagram: 'https://www.instagram.com/elcompasacra1/',
    twitter: 'https://twitter.com/1COMPASACRA',
    spotify: 'https://open.spotify.com/artist/5agbiu3kZu8DaZMkUnB3gm',
    deezer: 'https://www.deezer.com/mx/artist/14102571',
    apple: 'https://music.apple.com/mx/artist/el-compa-sacra/467743622',
    soundcloud: 'https://soundcloud.com/elcompasacraelultimorazo',
    tidal: 'https://listen.tidal.com/artist/5473567',
    wiki:
      'https://www.buenamusica.com/el-compa-sacra/biografia#:~:text=%C2%BFQui%C3%A9n%20es%20El%20Compa%20Sacra%3F%20Sacramento%20Ram%C3%ADrez%2C%20conocido,alcanz%C3%B3%20reconocimiento%20en%20los%20a%C3%B1os%20%E2%80%9980%20y%20%E2%80%9990.',
    ticketmaster:
      'https://www.ticketmaster.com/el-compa-sacra-tickets/artist/1607456',
    tiktok: 'https://www.tiktok.com/@elcompasacrachingon?lang=es',
  },
  {
    title: 'Antonio Delgado',
    description:
      'Antonio Delgado es Originario de Tijuana. El Muchacho heredo su gran talento atravez de su padre quien tambien fue un gran cantante y tambien tuvo varios exitos musicales. El Joven ha demostrado ser muy Serio y Firme con Exitos como La 9 y Verde Veneno.',
    videourl:
      'http://youtube.com/playlist?list=PLF-cRDh2mrlUEifrg_eT-P0htj5fUBspN',
    price: 50,
    coverImg: 'antoniodelgado.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://degomusic.biz/',
    youtube: 'https://www.youtube.com/channel/UCxaRMrZC10TcK1dc2I5paFw',
    facebook: 'https://www.facebook.com/DegoMusic/',
    instagram: 'https://www.instagram.com/antonio_delgado_oficial/',
    twitter: 'https://twitter.com/MusicDego',
    spotify: 'https://open.spotify.com/album/4nzOCZMGXkxfhkaMhrIpr0',
    deezer: 'https://www.deezer.com/mx/artist/4656364',
    apple: 'https://music.apple.com/mx/artist/antonio-delgado/280737526',
    soundcloud: 'https://soundcloud.com/antoniodelgadomusic',
    tidal: 'https://listen.tidal.com/album/197732007',
    wiki: 'https://www.facebook.com/jeronimo.velardedelgado',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@antoniodelgadooficial0?lang=es',
    napster: 'https://app.napster.com/artist/antonio-delgado',
  },
  {
    title: 'Los Dos Carnales',
    description:
      'Los Dos Carnales comenzaron su carrera desde muy jóvenes, respectivamente, pero luego empezaron a realizar show de payasos en donde tocaban sus canciones. “Tocando en sí tenemos un buen rato, mi hermano empezó a los 9 años y yo tenía 14. Cuando empezamos en este rollo de la música, nos tocó ser payasos. Hacíamos show y tocabamos”, mencionó Poncho Quezada  .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mw2bd-OH5SC66vMYVJWLGnPHXcY2s64rg',
    price: 50,
    coverImg: 'losDosCarnales.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCHU1sgg0A354C5-jtunzHPQ',
    facebook: 'https://www.facebook.com/losdoscarnales',
    instagram: 'https://www.instagram.com/los2carnales/',
    twitter: 'https://twitter.com/DosCarnales',
    spotify: 'https://open.spotify.com/playlist/4BHoJpgGu79T7pfltNBIS9',
    deezer: 'https://www.deezer.com/mx/artist/14858167',
    apple: 'https://music.apple.com/mx/artist/los-dos-carnales/1463668529',
    soundcloud: 'https://soundcloud.com/losdoscarnales',
    tidal: 'https://listen.tidal.com/artist/3665785c',
    wiki:
      'https://www.laletrade.com/biografias/regional-mexicano/20821-los-dos-carnales',
    ticketmaster:
      'https://www.ticketmaster.com/los-dos-carnales-tickets/artist/2778512',
    tiktok: 'https://www.tiktok.com/@losdoscarnales?lang=es',
    napster: 'https://web.napster.com/artist/los-dos-carnales',
  },
  {
    title: 'DEL Records',
    description:
      'DEL Records es un sello discográfico estadounidense de música en español fundado por Ángel del Villar en 2008. Su sede se encuentra en el suburbio de Bell Gardens en Los Ángeles, California, e incluye DEL Records, DEL Publishing, DEL Studios y DEL Entertainment, los cuales se centran en la música regional mexicana, específicamente en géneros del pacífico mexicano cómo la banda sinaloense, el norteño estilo pacífico, el norteño-banda, el sierreño, el sierreño-banda y el mariachi. DEL Records ha dado inicio a carreras musicales de artistas que mantienen actualmente buen desempeño comercial como Tercer Elemento, Lenin Ramírez, Ulices Chaidez, Luis Carrazco, entre otros. DEL Records y sus artistas son ganadores frecuentes en los Premios Billboard de la música latina..',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mhD873MzMdsLA0BGNtyoc_7znFASWHWUI',
    price: '$50',
    coverImg: 'DELrecords.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.delrecords.com/',
    youtube: 'https://www.youtube.com/user/DELRECORDS',
    facebook: 'https://www.facebook.com/DelRecordsOficial',
    instagram: 'https://www.instagram.com/delrecords/',
    twitter: 'https://twitter.com/delrecords',
    spotify: 'https://open.spotify.com/user/del_records',
    deezer: 'https://www.deezer.com/mx/artist/7655148',
    apple: 'https://music.apple.com/mx/curator/del-records/1171184109',
    soundcloud: 'https://soundcloud.com/delrecordsoficial',
    tidal: 'https://listen.tidal.com/artist/9548427',
    wiki: 'https://es.wikipedia.org/wiki/DEL_Records',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@delrecordsofficial?lang=es',
    napster: 'https://web.napster.com/artist/del-records',
  },
  {
    title: 'Fuerza Regida',
    description:
      'FUERZA REGIDA es un grupo de música Regional Mexicana, conformado por Jesús Ortiz Paz, Samuel Jaimez, Khrystian Ramos y José, originarios de Sinaloa, México. ',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mbBjDr6BF9efxuubR7uCa4j7nO3rxXlL4',
    price: '$50',
    coverImg: 'FuerzaRegida.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://fuerzaregida.shop/',
    youtube: 'https://www.youtube.com/channel/UCFLI6KAjrKRtydD7KqiYvAw',
    facebook: 'https://www.facebook.com/fuerzaregidaoficial',
    instagram: 'https://www.instagram.com/fuerzaregidaoficial/',
    twitter: 'https://twitter.com/FuerzaRegida1',
    spotify: 'https://open.spotify.com/artist/0ys2OFYzWYB5hRDLCsBqxt',
    deezer: 'https://www.deezer.com/mx/artist/13916083',
    apple: 'https://music.apple.com/mx/artist/fuerza-regida/1275690475',
    soundcloud: 'https://soundcloud.com/fuerzaregida',
    tidal: 'https://listen.tidal.com/artist/9435954',
    wiki: 'https://www.buenamusica.com/fuerza-regida/biografia',
    ticketmaster: 'https://www.ticketmaster.com/artist/2499958',
    tiktok: 'https://www.tiktok.com/@fuerzaregidaoficial?lang=es',
    napster: 'https://web.napster.com/artist/fuerza-regida',
  },
  {
    title: 'Los de La Noria',
    description:
      'Los de la noria está conformado por: En el 2015 la empresa de representación artística Andaluz Music presenta a su nuevo y talentoso grupo Los de la Noria un conjunto de 3 jóvenes músicos Sinaloenses que unen sus acordes para dar un sonido dinámico y fresco al estilo sierreño.',
    videourl:
      'https://youtube.com/playlist?list=PLx8xu1SPDGv_inyAMZ605De9lbYzxvR_u',
    price: '$50',
    coverImg: 'LosDeLaNoria.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://losdelanoria.com/',
    youtube: 'https://www.youtube.com/channel/UC0ueTmm7Om50V566Y5OGMXA',
    facebook: 'https://www.facebook.com/LosDeLaNoria',
    instagram: 'https://www.instagram.com/losdelanoriaoficial/',
    twitter: 'https://twitter.com/losdelanoria',
    spotify: 'https://open.spotify.com/artist/4S5t9CJJNjl6zEp5e1OMiO',
    deezer: 'https://www.deezer.com/mx/artist/11474306',
    apple: 'https://music.apple.com/mx/artist/los-de-la-noria/1165820147',
    soundcloud: 'https://soundcloud.com/losdelanoria',
    tidal: 'https://listen.tidal.com/artist/8291166',
    wiki: 'https://losdelanoria.com/',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@losdelanoria?lang=es',
    napster: 'https://web.napster.com/artist/art.242132068',
  },
  {
    title: 'El Mimoso',
    description:
      'Luis Antonio Lopez Flores mejor conocido como “El Mimoso” nació el 21 de noviembre de 1979 en Concordia, Sinaloa. El tercero de cuatro hermanos, sus padres José Cruz López Salazar y Guadalupe Flores Rodríguez; vieron que desde muy chico le nació el gusto por el canto, en su natal Concordia.',
    videourl:
      'http://youtube.com/playlist?list=PLO8c1UjEPsQvoDnx4IMtFBHm0SljWDWj6',
    price: '$50',
    coverImg: 'ElMimoso.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCOMwD1nrJZ6-0Ml5KyanVSg',
    facebook: 'https://www.facebook.com/mimosoyooficial?_rdr=p',
    instagram: 'https://www.instagram.com/mimosoyo/',
    twitter: 'https://twitter.com/mimosoyo',
    spotify: 'https://open.spotify.com/artist/7AUgYiThuW80zSOwY7Ub2g',
    deezer: 'https://www.deezer.com/mx/artist/1595755',
    apple:
      'https://music.apple.com/mx/artist/el-mimoso-luis-antonio-l%C3%B3pez/434844110',
    soundcloud: 'https://soundcloud.com/el-mimoso-luis-antonio-lopez',
    tidal: 'https://listen.tidal.com/artist/4672027',
    wiki: 'https://www.musica.com/letras.asp?biografia=36424',
    ticketmaster:
      'https://www.ticketmaster.com/el-mimoso-tickets/artist/2833728',
    tiktok: 'https://www.tiktok.com/@mimosoyo?lang=es',
    napster: 'https://web.napster.com/artist/art.45943024',
  },
  {
    title: 'Los Rieleros del Norte',
    description:
      'Los Rieleros Del Norte son una banda mexicana de música regional mexicana nominada al Grammy en tres ocasiones. Son originarios de Ojinaga, Chihuahua, México, pero actualmente tienen su base en El Paso, Texas. Se especializan en el género Norteño-Sax.Formaron la banda en Ojinaga, Chihuahua, en 1980 y posteriormente se mudaron a Texas. Siguen siendo una de las bandas norteñas más antiguas que aún están activas hoy en día (junto con Conjunto Primavera, Los Tigres del Norte, Los Huracanes del Norte y Conjunto Río Grande). El nombre de la banda, "Los Rieleros", se basa en el trabajo que algunos miembros realizaron en los ferrocarriles de Pecos, Texas. A diferencia de muchas otras bandas norteñas, Los Rieleros del Norte utilizan tanto el saxofón como el acordeón en su música, creando un híbrido de dos instrumentos. Todos los miembros originales de la banda son originarios de Ojinaga, Chihuahua, una ciudad de la que también surgieron muchas otras bandas de Norteño-Sax.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_knbXnh1BOGjUsvrnebEGqBonLCKJ7ZDoo',
    price: '$50',
    coverImg: 'rieleros.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rielerosdelnorte.com/',
    youtube: 'https://www.youtube.com/channel/UCiqvumKhL3fgJRw7chlebYA',
    facebook: 'https://www.facebook.com/RielerosDelNorte',
    instagram: 'https://www.instagram.com/losrielerosdelnorte/',
    twitter: 'https://twitter.com/losrieleros',
    spotify:
      'https://open.spotify.com/artist/59xlizZFIFUvLC0I8iV2Jv?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/320162',
    apple: 'https://music.apple.com/mx/artist/los-rieleros-del-norte/65434848',
    soundcloud: 'https://soundcloud.com/los-rieleros-del-norte',
    tidal: 'https://listen.tidal.com/artist/3642157',
    wiki: 'https://en.wikipedia.org/wiki/Los_Rieleros_del_Norte',
    ticketmaster:
      'https://www.ticketmaster.com/los-rieleros-del-norte-tickets/artist/763565',
    tiktok: 'https://www.tiktok.com/@losrielerosdelnorte?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000QKPGJE/los-rieleros-del-norte',
  },

  {
    title: 'Lupillo Rivera',
    description:
      'Guadalupe Martín Rivera Saavedra conocido como Lupillo Rivera es un cantante y compositor de música regional, estadounidense de origen mexicano.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kuI9H6qIYevoWHaK6WAwVr0lxALer6cZA',
    price: '$50',
    coverImg: 'LupilloRivera.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://elreydelosborrachos.com/es/',
    youtube: 'https://www.youtube.com/channel/UCRClyr2rk75NdcEUtICewOA',
    facebook: 'https://www.facebook.com/LupilloRiveraOficial',
    instagram: 'https://www.instagram.com/LupilloRiveraOfficial/',
    twitter: 'https://twitter.com/LUPILLO_LUPILLO',
    spotify: 'https://open.spotify.com/artist/341CfLcUdaBGixB8IJjYwW',
    deezer: 'https://www.deezer.com/mx/artist/147304',
    apple: 'https://music.apple.com/mx/artist/lupillo-rivera/65432879',
    soundcloud: 'https://soundcloud.com/lupillo-rivera-official',
    tidal: 'https://listen.tidal.com/artist/3675105',
    wiki: 'https://es.wikipedia.org/wiki/Lupillo_Rivera',
    ticketmaster:
      'https://www.ticketmaster.com/lupillo-rivera-tickets/artist/786508',
    tiktok: 'https://www.tiktok.com/@lupilloriveraoficial?lang=es',
    napster: 'https://web.napster.com/artist/lupillo-rivera',
  },
  {
    title: 'Los Invasores de Nuevo Leon',
    description:
      "Los Invasores de Nuevo León are a Mexican Norteño set founded in 1977. Led by Lalo Mora, the first members of the group was Eduardo Lalo Mora, Isidro Chilo Rodriguez, Luis González y Arturo Vargas.Javier Ríos was the accordion player of Luis y Julian. Then all change and the next Los Invasores was Lalo Mora, Javier Rios, Homero de Leon and Eliud Lopez. The group would become very popular on both sides of the Texas-Mexico border during the 1980s and 1990s. Many of their songs are still played on radio to this day, such as Laurita Garza, Eslabón por Eslabón, Ni Dada La Quiero, Amor a la Ligera, Ni que Tuvieras tanta Suerte, Playa Sola, Aguanta Corazón, Mi Casa Nueva and A Mí que Me Quedo. Singer Lalo Mora left for a solo career with EMI Records in 1993. The group's songs have included political themes, and criticism of US involvement in Latin America.",
    videourl:
      'http://youtube.com/playlist?list=PLqwGC-HQw6KeY3SygbAgNt3z1U8Kba_fD',
    price: '$50',
    coverImg: 'LosInvasoresdeNuevoLeon.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://en.wikipedia.org/wiki/Los_Invasores_de_Nuevo_Le%C3%B3n',
    youtube: 'https://www.youtube.com/channel/UCrUaTcdw9MShJyR29bmBQCw',
    facebook: 'https://www.facebook.com/LOSINVASORESDENUEVOLEONOFICIAL/',
    instagram: 'https://www.instagram.com/grupoinvasores/',
    twitter: 'https://twitter.com/LosInvasoresNL',
    spotify: 'https://open.spotify.com/artist/5CGtBYmVPeLhI1kM2Fn9Gv',
    deezer: 'https://www.deezer.com/mx/artist/245220',
    apple:
      'https://music.apple.com/mx/artist/los-invasores-de-nuevo-le%C3%B3n/549766',
    soundcloud: 'https://soundcloud.com/los-invasores-de-nuevo-leon',
    tidal: 'https://listen.tidal.com/artist/56977',
    wiki: 'https://en.wikipedia.org/wiki/Los_Invasores_de_Nuevo_Le%C3%B3n',
    ticketmaster:
      'https://www.ticketmaster.com/los-invasores-de-nuevo-leon-tickets/artist/887564',
    tiktok: 'https://www.tiktok.com/@losinvasoresdenl.oficial?lang=es',
    napster: 'https://web.napster.com/artist/Art.26283',
  },{
    title: 'Sergio Vega',
    description:
      'José Sergio Vega Cuamea (Ciudad Obregón, Sonora, 12 de septiembre de 1969 – Los Mochis, Sinaloa, 26 de junio de 2010) conocido también como El Shaka, fue un cantante de música regional mexicana; especializado en los estilos de banda sinaloense y norteño.El 26 de junio del 2010, fue asesinado por un comando armado que le disparó en más de 30 ocasiones.​ Horas antes, el cantante había desmentido los rumores de su supuesta muerte en su Cadillac CTS, comentando que a raíz de la ola de violencia sufrida por los músicos de su estilo, había reforzado su seguridad.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nnq-iAiOZPvXLDHPBzXMRbnbjzHAAJhdU&si=VH_FlSBDDn9jqE_f',
    price: 50,
    coverImg: 'sergiovega.jpeg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCu6jHQSjJSfp6inCNTcepCw',
    facebook: 'https://www.facebook.com/sergio.vega.71653318/',
    instagram: 'https://www.instagram.com/sergiovegamusica/',
    twitter: 'https://x.com/ElShakaV',
    spotify: 'https://open.spotify.com/artist/5YNi3BopLa4XtYRtzML80L?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/206348',
    apple: 'https://music.apple.com/mx/artist/sergio-vega-el-shaka/15038220',
    soundcloud: 'https://soundcloud.com/sergio-vega-official',
    tidal: 'https://listen.tidal.com/artist/3615899',
    wiki: 'https://es.wikipedia.org/wiki/Sergio_Vega',
    ticketmaster: 'https://www.ticketmaster.com./sergio-vega-tickets/artist/723192',
    tiktok: 'https://www.tiktok.com/@sergio.vega.el.sh',
    napster: 'https://music.amazon.com/artists/B00123R55C/sergio-vega',
  },
  {
    title: 'Los Tigres del Norte',
    description:
      'Los Tigres del Norte son un grupo de música regional mexicana, especializado en el estilo de la música norteña, fundado en Mocorito, Sinaloa, México. Con ventas que ascienden a las 60 millones de copias es una de las agrupaciones más reconocidas del género, debido a su larga trayectoria y a sus éxitos a nivel de la comunidad mexicana en la diáspora. Su campo principal son los corridos, los cuales han sido censurados en varias ocasiones, incluso en su propio país. Es la única agrupación mexicana que ha logrado ganar 6 premios Grammy y 12 Grammy latino y también su música ha traspasado fronteras llegando a conquistar los cinco continentes, aparte han filmado 40 películas al lado de los hermanos Almada entre otros actores de altísima trayectoria.',
    videourl:
      'https://youtube.com/playlist?list=PLkEA9yu8jg8qBqXzqkf6NDErKix8rF_8y&si=MumqX45nBsseBNGO',
    price: '$50',
    coverImg: 'LosTigresDelNorte.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://lostigresdelnorte.com/main/',
    youtube: 'https://www.youtube.com/channel/UCcAeCxhKNEZ-KkCr6yTLhEw',
    facebook: 'https://www.facebook.com/LosTigresDelNorte',
    instagram: 'https://www.instagram.com/LosTigresDelNorte/',
    twitter: 'https://twitter.com/TigresDelNorte',
    spotify: 'https://open.spotify.com/artist/3hYtANQYrE6pd2PbtEyTIy',
    deezer: 'https://www.deezer.com/mx/artist/13611',
    apple: 'https://music.apple.com/mx/artist/los-tigres-del-norte/34829626',
    soundcloud: 'https://soundcloud.com/los-tigres-del-norte-1',
    tidal: 'https://listen.tidal.com/artist/3679429',
    wiki: 'https://es.wikipedia.org/wiki/Los_Tigres_del_Norte',
    ticketmaster:
      'https://www.ticketmaster.com/los-tigres-del-norte-tickets/artist/755233',
    tiktok: 'https://www.tiktok.com/@lostigresdelnorte?lang=es',
    napster: 'https://web.napster.com/artist/los-tigres-del-norte',
  },
  {
    title: 'Cornelio Reyna',
    description:
      'Cornelio Reyna Cisneros, fue un cantante, compositor, bajo sextista y actor mexicano. Grabó 60 discos de música ranchera y norteña. Fue la primera voz del grupo Los Relámpagos del Norte. Se le considera originario de la ciudad de Reynosa Tamaulipas por su gran cariño que le tuvo a la ciudad y a que su carrera creció ahí. Participó en alrededor de 30 películas sobre la cultura popular mexicana',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lxBYhP70Xng46KoMdrAW-jjTmKOzk8PlE',
    price: '$50',
    coverImg: 'CornelioReyna.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Cornelio_Reyna',
    youtube: 'https://www.youtube.com/channel/UCBS_aZt-ZLJv7coS1lVFqCQ',
    facebook: 'https://www.facebook.com/profile.php?id=100055390518691',
    instagram: 'https://www.instagram.com/cornelio.reyna/',
    twitter: 'https://twitter.com/Cornelio_Reyna_',
    spotify:
      'https://open.spotify.com/artist/2hbA9AbMYcgXBGgBK6MCfx?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/93909',
    apple: 'https://music.apple.com/mx/artist/cornelio-reyna/2357883',
    soundcloud: 'https://web.napster.com/artist/art.29698',
    tidal:
      'https://www.tiktok.com/search?lang=es&q=Cornelio%20Reyna&t=1655502336626',
    wiki: 'https://en.wikipedia.org/wiki/Cornelio_Reyna',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Cornelio%20Reyna&t=1655502336626',
    napster: 'https://web.napster.com/artist/art.29698',
  },
  {
    title: 'Joan Sebastian',
    description:
      'José Manuel Figueroa, conocido artísticamente como Joan Sebastian, fue un cantante, compositor y actor mexicano, que fue conocido por el público bajo los sobrenombres de «El Rey del Jaripeo»,«El Poeta del Pueblo», «El Poeta de Juliantla» y «El Huracán del Sur.',
    videourl:
      'http://youtube.com/playlist?list=PL9CTSVO6wu7LNGcWzK-VheoJ1NavmEFjZ',
    price: '$50',
    coverImg: 'JoanSebastian.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://joansebastianoficial.com/',
    youtube: 'https://www.youtube.com/channel/UC9oI0Vxnp-jJRH2StsXaIzQ',
    facebook: 'https://www.facebook.com/oficialjoansebastian',
    instagram: 'https://www.instagram.com/joansebastian/?hl=es-la',
    twitter: 'https://twitter.com/joansebastianof',
    spotify:
      'https://open.spotify.com/artist/7FsRH5bw8iWpSbMX1G7xf1?si=HX11vdfgQXuFFy6h5bdteg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12141',
    apple: 'https://music.apple.com/mx/artist/joan-sebastian/295941',
    soundcloud: 'https://soundcloud.com/el-mimoso-luis-antonio-lopez',
    tidal: 'https://listen.tidal.com/artist/4672027',
    wiki: 'https://es.wikipedia.org/wiki/Joan_Sebastian',
    ticketmaster:
      'https://www.ticketmaster.com/joan-sebastian-tickets/artist/755195',
    tiktok: 'https://www.tiktok.com/@joansebastianoficial?lang=es',
    napster: 'https://web.napster.com/artist/joan-sebastian',
  },
  {
    title: 'Ariel Kamacho y Los Plebes del Rancho',
    description:
      'Los Plebes del Rancho de Ariel Camacho es una agrupación de música regional mexicana, especializado en el estilo del Sierreño-Banda. Son originarios de Guamúchil, Sinaloa. El grupo fue fundado en 2013 como Ariel Camacho y los Plebes del Rancho, por Ariel Camacho y su amigo César Sánchez. Firmaron originalmente con DEL Records en 2014, pero luego se retiraron en 2016 debido a disputas generadas con el incumplimiento del contrato y firmaron con JG Entertainment. El fallecimiento de Ariel Camacho provocó cambios en el grupo.',
    videourl:
      'http://youtube.com/playlist?list=PL16_wFPiPzHKJGkgBVgOnZolTNmLtVU4E',
    price: 50,
    coverImg: 'ArielKamacho.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    likes: 1000,
    openSpots: null,
    websiteurl: 'https://es.wikipedia.org/wiki/Ariel_Camacho',
    youtube: 'https://www.youtube.com/channel/UCzuCcWDyNg0SeqNiTZD9MqA',
    facebook: 'https://www.facebook.com/groups/277878853754154',
    instagram: 'https://www.instagram.com/arielcamacho_oficial/',
    twitter: 'https://twitter.com/ArielCamachoMx',
    spotify: 'https://open.spotify.com/artist/2Lxa3SFNEW0alfRvtdXOul',
    deezer: 'https://www.deezer.com/mx/artist/6229004',
    apple:
      'https://music.apple.com/mx/artist/ariel-camacho-y-los-plebes-del-rancho/904130604',
    soundcloud: 'https://soundcloud.com/arielcamachopromo',
    tidal: 'https://listen.tidal.com/artist/5863539',
    wiki: 'https://es.wikipedia.org/wiki/Ariel_Camacho',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@arielcamacho_official?lang=es',
    napster: 'https://web.napster.com/artist/art.172977623',
  },
  {
    title: 'Lorenzo de Montecarlo',
    description:
      'Lorenzo Hernández, n. 5 de septiembre de 1939, mejor conocido como Lorenzo de Monteclaro, es un famoso cantante de la música regional mexicana, especialmente en el estilo Norteño-Sax. Cantó en el radio por primera vez en la década de 1950, en un programa llamado Aficionados de los Ejidos, que se trasmitía cada domingo por XEDN. Aunque no ganó el premio, le sirvió para darse a conocer. Ha colaborado en más de 105 álbumes y actuado en casi 51 películas, sin haberse retirado aún, más de cinco décadas después. El menor de sus hijos varones, Ricardo de Monteclaro, también canta profesionalmente.',
    videourl:
      'http://youtube.com/playlist?list=PLyCFkT0sGaxza-i5ez6aGepdAfgJtjW-r',
    price: '$50',
    coverImg: 'LorenzdeMonteclaro.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Lorenzo_de_Monteclaro',
    youtube: 'https://www.youtube.com/c/LorenzoDeMonteclaroOficial',
    facebook: 'https://www.facebook.com/LorenzodeMonteclaroOficial',
    instagram: 'https://www.instagram.com/lorenzodemonteclarooficial/',
    twitter: 'https://twitter.com/ldmonteclaro',
    spotify: 'https://open.spotify.com/artist/4YnO1u0lWE4lXZKy8wXjZX',
    deezer: 'https://www.deezer.com/mx/artist/662818',
    apple: 'https://music.apple.com/mx/artist/lorenzo-de-monteclaro/104918',
    soundcloud: 'https://web.napster.com/artist/lorenzo-de-monteclaro',
    tidal: 'https://listen.tidal.com/artist/3591996',
    wiki: 'https://es.wikipedia.org/wiki/Lorenzo_de_Monteclaro',
    ticketmaster:
      'https://www.ticketmaster.com/lorenzo-de-monteclaro-tickets/artist/755871',
    tiktok: 'https://www.tiktok.com/@lorenzodemonteclarofans?lang=es',
    napster: 'https://web.napster.com/artist/lorenzo-de-monteclaro',
  },
  
]
export default CardData12
