const CardData16 = [
  {
    title: 'Royal Tj Band',
    description:
      ' Royal Tj es una destacada banda especializada en Oldies y Éxitos Versátiles, dirigida por el talentoso Sr. Carlos Denton Casillas. Con una amplia trayectoria como artista y compositor en Estados Unidos y México, el Sr. Casillas ha sabido imprimir su experiencia y pasión en cada presentación de la banda. Bajo su liderazgo, Royal Tj ofrece una experiencia musical que combina lo mejor de los clásicos con un toque versátil, cautivando a audiencias en ambos lados de la frontera.',

    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nJTYEMSZNMPkeNEvxBsYk_pb4nWbWj7cE',
    price: '$50',
    coverImg: 'royaltj.jpg',
    category: 'Exitos Versatiles',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/profile.php?id=1000762817078181',
    youtube: 'https://www.youtube.com/channel/UC_zud4Ek9DshahEZY5qoXGw',
    facebook: 'https://www.facebook.com/profile.php?id=1000762817078181',
    instagram: 'https://www.instagram.com/explore/tags/royaltj/',
    twitter: 'https://twitter.com/RoyalTj',
    spotify: 'https://open.spotify.com/album/7K7YDUkYRE4bJS38pMGmJF',
    deezer: 'https://www.deezer.com/en/artist/1822121',
    apple: 'https://music.apple.com/mx/artist/royal-tj-band/1529969997',
    soundcloud: 'https://soundcloud.com/user-63940145',
    tidal: 'https://music.amazon.com.mx/artists/B08H4ML16F/royal-tj-band',
    wiki:
      'https://www.amazon.com/music/player/albums/B08H4QSSNT?*entries*=0&*Version*=1',
    ticketmaster: 'https://www.viagogo.com.mx/',
    tiktok:
      'https://www.tiktok.com/music/To-Love-Some-Body-6867685115263207426',
    napster: 'https://music.amazon.com.mx/artists/B08H4ML16F/royal-tj-band',
  },
  {
    title: 'Ernesto Cardenas Bazaldua',
    description:
      ' Ernesto Bazaldua  es un Cantautor de Origen Mexicano cual  radica en Guadalajara, Jalisco, Mexico, Su   musica es versatil,Regional y sus exitos son ,1:Por Ser Pobre, 2:Tu Conciencia Te hara Llorar, 3. Dia de las Madres 4.Ese Soy Yo 5: Te Esperare 7.Con una Llamada 7. Se te acabado el juego 8.Libro Cerrado 9.Tomados de la mano, 10.Carta de Retiro.',

    videourl: 'https://youtube.com/playlist?list=UUF0QfCEV_8ZpA0B2Yv56xBg',
    price: '$50',
    coverImg: 'ErnestoCardenas.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://wynk.in/music/album/las-inspiraciones-de-ernesto-c%C3%A1rdenas/bl_3617050935625',
    youtube: 'https://www.youtube.com/@ernestocardenas5939',
    facebook: 'https://www.facebook.com/ernesto.cardenasbazaldua.1',
    instagram: '',
    twitter: '',
    spotify: 'https://open.spotify.com/artist/7KZNDFnwppqWlfoVcCh1bg',
    deezer: 'https://www.deezer.com/en/artist/1822121',
    apple:
      'https://music.apple.com/us/album/las-inspiraciones-de-ernesto-c%C3%A1rdenas/1644034684',
    soundcloud: 'https://soundcloud.com/search?q=Ernesto%20Crdenas%20Bazaldua',
    tidal: 'https://listen.tidal.com/artist/6254720',
    wiki: 'https://www.amazon.co.uk/Con-una-Llamada/dp/B0BDKTQJXJ',
    ticketmaster: 'https://www.viagogo.com.mx/',
    tiktok: 'https://www.tiktok.com/@ernestocardenasba',
    napster: 'https://music.amazon.com.mx/albums/B0BDKM5VGD',
  },
]

export default CardData16
