const CardData23 = [
  {
    title: 'Alejandro Fernandez',
    description:
      'Alejandro Fernández Abarca es un cantante de música ranchera y pop latino mexicano, hijo del fallecido cantante ranchero Vicente Fernández. En un principio se especializó en tipos tradicionales de música regional mexicana como mariachi. Posteriormente se ha diversificado hacia el pop latino con toques urbanos, la balada y el bolero.',

    videourl: 'http://youtube.com/playlist?list=PLEB89B6DF1EE964B5',
    price: '$50',
    coverImg: 'AlejandroFernandez.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://alejandrofernandez.com/',
    youtube: 'https://www.youtube.com/channel/UC0K-1_31jfkcA4Nd294gMyA',
    facebook: 'https://www.facebook.com/alejandrofernandezoficial',
    instagram: 'https://www.instagram.com/alexoficial/',
    twitter: 'https://twitter.com/alexoficial',
    spotify:
      'https://open.spotify.com/artist/6sq1yF0OZEWA4xoXVKW1L9?si=Nbmjb1PvTiGojQaSs1z1cQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/8794',
    apple: 'https://music.apple.com/mx/artist/alejandro-fern%C3%A1ndez/893583',
    soundcloud: 'https://soundcloud.com/alejandro-fernandez-official',
    tidal: 'https://listen.tidal.com/artist/1410',
    wiki: 'https://en.wikipedia.org/wiki/Alejandro_Fern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com/alejandro-fernandez-tickets/artist/761444',
    tiktok: 'https://www.tiktok.com/@alejandrofernandezfans',
    napster: 'https://web.napster.com/artist/alejandro-fernandez',
  },
  {
    title: 'Pepe Aguilar',
    description:
      'José Antonio Aguilar Jiménez, más conocido como Pepe Aguilar, es un cantante, compositor, músico, productor y empresario mexicano. Desde muy joven, Aguilar acompañó de gira a sus padres, los cantantes y actores mexicanos Antonio Aguilar y Flor Silvestre. Ahora hace lo mismo con sus propios hijos, Leonardo Aguilar y Ángela Aguilar, quienes también han seguido los pasos de su padre y abuelos..',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_km79fnJX9-enVlkDxmmUczWzgFBYo-mbY',
    price: '$50',
    coverImg: 'pepeaguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pepeaguilar.com/',
    youtube: 'https://www.youtube.com/c/pepeaguilaroficial',
    facebook: 'https://www.facebook.com/pepeaguilaroficial',
    instagram: 'https://www.instagram.com/pepeaguilar_oficial/',
    twitter: 'https://twitter.com/PepeAguilar',
    spotify: 'https://open.spotify.com/artist/03Yb3iBy9GCifXiATEFcit',
    deezer: 'https://www.deezer.com/mx/artist/8793',
    apple: 'https://music.apple.com/mx/artist/pepe-aguilar/21421637',
    soundcloud: 'https://soundcloud.com/search?q=Pepe%20Aguilar',
    tidal: 'https://listen.tidal.com/artist/3506026',
    wiki: 'https://en.wikipedia.org/wiki/Pepe_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/pepe-aguilar-tickets/artist/754764',

    tiktok: 'https://www.tiktok.com/@pepeaguilar_oficial',
    napster: 'https://web.napster.com/artist/pepe-aguilar',
  },
  {
    title: 'Angela Aguilar',
    description:
      'Ángela Aguilar Álvarez (Los Ángeles, California; 8 de octubre de 2003), es una cantante mexicana-estadounidense de música regional mexicana. Obtuvo un notable reconocimiento después de interpretar La Llorona en la 19.ª Entrega Anual de los Premios Grammy Latinos en 2018. Es nieta de El Charro de México, Antonio Aguilar.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kSBDJlpXRi2Cy2v5fYI8ag4M1ku2STWAE',
    price: '$50',
    coverImg: 'AngelaAguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.angelaaguilaroficial.com/',
    youtube: 'https://www.youtube.com/channel/UCugX5B6n1jtQq2ihzhRk-Ag',
    facebook: 'https://www.facebook.com/AngelaAguilarOficial/',
    instagram: 'https://www.instagram.com/angela_aguilar_/?hl=en',
    twitter: 'https://twitter.com/AngelaAguilar__',
    spotify: 'https://open.spotify.com/artist/3abT87tqQ4Q5PA5nw6CYyH',
    deezer: 'https://www.deezer.com/mx/artist/4028765',
    apple: 'https://music.apple.com/mx/artist/%C3%A1ngela-aguilar/565880444',
    soundcloud: 'https://soundcloud.com/angelaaguilarofficial',
    tidal: 'https://listen.tidal.com/artist/4734467',
    wiki: 'https://en.wikipedia.org/wiki/%C3%81ngela_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/angela-aguilar-tickets/artist/2584974',

    tiktok: 'https://www.tiktok.com/@angelaaguilar676',
    napster: 'https://web.napster.com/artist/art.66144484',
  },
  {
    title: 'Cristian Nodal',
    description:
      'Christian Jesús González Nodal (Caborca , Sonora; 11 de enero de 1999), conocido como Christian Nodal, es un cantante, compositor mexicano. Su primer sencillo, «Adiós amor», publicado en 2016 bajo el sello discográfico Fonovisa, lo lanzó a la fama en diversos medios de comunicación en México y Estados Unidos.',
    videourl:
      'https://youtube.com/playlist?list=PL2cg72xd9U-ioO2IpH7OZrT43hVgC6fmm',
    price: 80,
    coverImg: 'nodal.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.christiannodal.com/',
    youtube: 'https://www.youtube.com/channel/UCtqOng3GVhQVu1Gl-3UlyAQ',
    facebook: 'https://www.facebook.com/christiannodaloficialmx/',
    instagram: 'https://www.instagram.com/nodal/',
    twitter: 'https://twitter.com/elnodal?lang=en',
    spotify:
      'https://open.spotify.com/artist/0XwVARXT135rw8lyw1EeWP?si=xMgobcktQD6v1nbtGIxGhQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9763952',
    apple: 'https://music.apple.com/mx/artist/christian-nodal/1079597775',
    soundcloud: 'https://soundcloud.com/christiannodal',
    tidal: 'https://listen.tidal.com/artist/7745729',
    wiki: 'https://en.wikipedia.org/wiki/Christian_Nodal',
    ticketmaster:
      'https://www.ticketmaster.com/christian-nodal-tickets/artist/2405178',

    tiktok: 'https://www.tiktok.com/@christiannodal',
    napster: 'https://web.napster.com/artist/christian-nodal',
  },
  {
    title: 'Intocable',
    description:
      'Intocable es un grupo estadounidense de origen mexicano de música tejana y norteña de Zapata, Texas, Estados Unidos, fundado a principios de la década de los 90 por Ricky Muñoz y René Martínez.1​ Dentro de algunos años, la banda logró subir a la cima de la música tejana y norteña, con un estilo musical que fusionó la música conjunto de Texas y los ritmos folclóricos de la música norteña con baladas de letras modernas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mEpEPj__3rc-1-CiPwYP0GS-JE_jY93CA',
    price: null,
    coverImg: 'intocable.jpg',
    category: 'Regional Mexicano',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://grupointocable.com/',
    youtube: 'https://www.youtube.com/channel/UCvQcMlXzM9JdAgzkBo1B0Ew',
    facebook: 'https://www.facebook.com/grupointocable/',
    instagram: 'https://www.instagram.com/grupointocable/?hl=en',
    twitter:
      'https://twitter.com/grupointocable?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/108moq3rq6bm1M4Ypz0J02',
    deezer: 'https://www.deezer.com/mx/artist/16644',
    apple: 'https://music.apple.com/mx/artist/intocable/549010',
    soundcloud: 'https://soundcloud.com/intocable-official',
    tidal: 'https://listen.tidal.com/artist/56242',
    wiki: 'https://es.wikipedia.org/wiki/Intocable_(banda)',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Intocable',
    tiktok: 'https://www.tiktok.com/@grupo_intocable?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000SA1FUQ/intocable',
  },

  {
    title: 'Grupo Bronco',
    description:
      'Bronco es una agrupación de música regional mexicana especializada en el estilo grupero y famosa por sus baladas, cumbias, rancheras y huapangos. Sus miembros son originarios de Apodaca, Nuevo León. El grupo ha lanzado hasta la actualidad más de 20 álbumes..',

    videourl: 'https://youtube.com/playlist?list=UUmXveBEdi2vv42Qs3sQYkow',
    price: '$50',
    coverImg: 'bronco.jpeg',
    category: 'Grupo Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://broncoesbronco.com/',
    youtube: 'https://www.youtube.com/channel/UCmXveBEdi2vv42Qs3sQYkow',
    facebook: 'https://www.facebook.com/grupobronco/',
    instagram: 'https://www.instagram.com/grupobronco/',
    twitter: 'https://twitter.com/Grupo_Bronco',
    spotify: 'https://open.spotify.com/user/31sb73idiytc2hl5vscywdaff4ue',
    deezer: 'https://www.deezer.com/mx/artist/269769',
    apple: 'https://music.apple.com/mx/artist/bronco/74585929',
    soundcloud: 'https://soundcloud.com/bronco-official',
    tidal: 'https://listen.tidal.com/artist/3617245',
    wiki: 'https://es.wikipedia.org/wiki/Bronco_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/bronco-tickets/artist/743616',
    tiktok: 'https://www.tiktok.com/@grupobronco',
    napster: 'https://web.napster.com/artist/bronco',
  },
  {
    title: 'Pedro Infante',
    description:
      'Pedro Infante Cruz (Mazatlán, Sinaloa, 18 de noviembre de 1917-Mérida, Yucatán, 15 de abril de 1957) fue un actor y cantante mexicano. Es considerado como uno de los actores más recordados del cine en México.Por su actuación en la película Tizoc (1956), fue ganador de un Oso de Plata​ del Festival Internacional de Cine de Berlín en la categoría a «mejor actor principal», y también obtuvo un premio Globo de Oro​ por mejor película extranjera, el cual se le fue otorgado por la prensa extranjera acreditada en Hollywood..',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVODBGJurCWQdYWZ7VH_Nhd',
    price: '$50',
    coverImg: 'pedroinfante.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.biografiasyvidas.com/biografia/i/infante_pedro.htm#:~:text=Pedro%20Infante%20%28Mazatl%C3%A1n%2C%20Sinaloa%2C%201917%20-%20M%C3%A9rida%2C%20Yucat%C3%A1n%2C,musical%20que%20actuaba%20en%20la%20localidad%20de%20Guasave.',
    youtube: 'https://www.youtube.com/channel/UCCZRmXAChQJF1P6xsX28TTQ',
    facebook: 'https://www.facebook.com/profile.php?id=100052259945722',
    instagram: 'https://www.instagram.com/pedro_infante_official/',
    twitter: 'https://twitter.com/Pedro_Infante1',
    spotify: 'https://open.spotify.com/artist/7y33enVLfDvft6HGNmcxdV',
    deezer: 'https://www.deezer.com/mx/artist/264106',
    apple: 'https://music.apple.com/mx/artist/pedro-infante/2137889',
    soundcloud: 'https://soundcloud.com/pedro-infante-official',
    tidal: 'https://listen.tidal.com/artist/13256',
    wiki: 'https://es.wikipedia.org/wiki/Pedro_Infante',
    ticketmaster:
      'https://www.noroeste.com.mx/entretenimiento/espectaculos/llenaran-de-tributos-a-pedro-infante-PVNO1078632',
    napster: 'https://web.napster.com/artist/pedro-infante',
    tiktok: 'https://www.tiktok.com/@pedroinfanteidolo',
  },
  {
    title: 'Jorge Negrete',
    description:
      'Jorge Alberto Negrete Moreno (Guanajuato, 30 de noviembre de 1911-Los Ángeles, California, 5 de diciembre de 1953), conocido como El Charro Cantor, fue un actor y cantante mexicano. Fundó el Sindicato de Trabajadores de la Producción Cinematográfica de la República Mexicana1​ y reorganizó, junto con un grupo selecto de actores, la Asociación Nacional de Actores.',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVngSBUUO1Yyb7E9KNPUvjl',
    price: '$50',
    coverImg: 'jorgenegrete2.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '.',
    youtube: 'https://www.youtube.com/c/jorgenegretefans',
    facebook: 'https://www.facebook.com/profile.php?id=100059758101482',
    instagram: 'https://www.instagram.com/jorgenegreteforever/',
    twitter: 'https://twitter.com/DonJorgeNegrete',
    spotify: 'https://open.spotify.com/artist/4W5RbLZybsLfAzE7QqdibE',
    deezer: 'https://www.deezer.com/mx/artist/299515',
    soundcloud: 'https://soundcloud.com/jorgenegreteofficial',
    tidal: 'https://listen.tidal.com/artist/3661015',
    wiki: 'https://es.wikipedia.org/wiki/Jorge_Negrete',
    ticketmaster:
      'https://www.ticketmaster.com.mx/teatro-jorge-negrete-boletos-mexico/venue/163990',
    napster: 'https://web.napster.com/artist/art.50312',
    tiktok: 'https://www.tiktok.com/search?q=Jorge%20Negrete&t=1667333198086',
  },
  {
    title: 'Rocio Durcal',
    description:
      'María de los Ángeles de las Heras Ortiz, conocida por su nombre artístico Rocío Dúrcal, fue una actriz y cantante española. Dúrcal es considerada como la «reina de las rancheras» y es una de las mujeres de habla hispana con más ventas en la industria. En 2005, un año antes de su muerte, recibió el Grammy Latino a la excelencia musical.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lMv8ZIewIbDR8z4ckj4pbzhXV8GtrWh58',
    price: '$50',
    coverImg: 'rociodurcal.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    youtube: 'https://www.youtube.com/channel/UCBgAZZ95M0pSBo69Qb6EuTQ',
    facebook: 'https://www.facebook.com/RocioDurcalMusic',
    instagram:
      'https://www.instagram.com/rociodurcal.music/?fbclid=IwAR1j7EblxeIYpgKWQfpesMeVe2l-6TfNhzfv7Hterp7PVJPLcWjCSPR6n_I',
    twitter:
      'https://twitter.com/rocio_durcal?fbclid=IwAR0QUd4K5u9dWS3S72dobhOD_ktY5dPbr-386AiKlv411DTQPTAhsnOY-vA',
    spotify: 'https://open.spotify.com/artist/2uyweLa0mvPZH6eRzDddeB',
    deezer: 'https://www.deezer.com/mx/artist/75284',
    apple: 'https://music.apple.com/mx/artist/roc%C3%ADo-d%C3%BArcal/6742584',
    soundcloud: 'https://soundcloud.com/rocio-durcal-official',
    tidal: 'https://listen.tidal.com/artist/3660925',
    wiki: 'https://en.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    ticketmaster:
      'https://www.ticketmaster.com/rocio-durcal-tickets/artist/754908',
    napster: 'https://web.napster.com/artist/rocio-durcal-latin',
    tiktok: 'https://www.tiktok.com/@rociodurcal.oficial',
  },
  {
    title: 'Jose Alfredo Jimenez',
    description:
      'José Alfredo Jiménez Sandoval (Dolores Hidalgo, Guanajuato, 19 de enero de 1926-Ciudad de México, 23 de noviembre de 1973) fue un cantante y compositor mexicano. Jiménez fue el autor de varios temas musicales del género regional mexicano, específicamente de rancheras, sones, corridos, y huapangos a ritmo de mariachi.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_k2kN1d58ypIxZcl6DEtIT_2tsuaZpeWJM',
    price: '$50',
    coverImg: 'JoseAlfredo.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    youtube: 'https://www.youtube.com/channel/UC3w7TN1QkWk9ecC0q_ebm7Q',
    facebook: 'https://www.facebook.com/JoseAlfredoJimenezElRey/',
    instagram: 'https://www.instagram.com/josealfredojimenezoficial/',
    twitter: 'https://twitter.com/JoseAlfredoJmz',
    spotify: 'https://open.spotify.com/artist/2T06whb4s6UiufL1j5Qtz9',
    deezer: 'https://www.deezer.com/mx/artist/343074',
    apple:
      'https://music.apple.com/mx/artist/jos%C3%A9-alfredo-jim%C3%A9nez/332254',
    soundcloud: 'https://soundcloud.com/josealfredojimenez',
    tidal: 'https://listen.tidal.com/artist/4029320',
    wiki: 'https://en.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    ticketmaster:
      'https://www.ticketmaster.com/jose-alfredo-jimenez-medel-tickets/artist/2036602',

    tiktok:
      'https://www.tiktok.com/search?q=Jos%C3%A9%20Alfredo%20Jim%C3%A9nez&t=1658091294171',
    napster: 'https://web.napster.com/artist/jose-alfredo-jimenez',
  },
  {
    title: 'Juan Gabriel',
    description:
      'Alberto Aguilera Valadez, conocido como Juan Gabriel, fue un cantante, compositor, arreglista, productor musical, músico, filántropo y actor mexicano. Son notables sus contribuciones a la música popular en español en diferentes géneros como balada, ranchera, bolero, pop, música norteña, rumba flamenca, huapango, música chicana, salsa, cumbia, tango, polka, samba, vals, son de mariachi, joropo, banda sinaloense, disco, jazz, new age, country, rock and roll, big band y hasta canciones de cuna que escribió a cada uno de sus hijos.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_ldwlb88-oemhNHFY2wJDvWnwu56vjdy2o',
    price: '$50',
    coverImg: 'JuanGabriel.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://juangabriel.com/',
    youtube: 'https://www.youtube.com/channel/UCnLJnKKCxLRsAktAB9iMSeQ',
    facebook: 'https://www.facebook.com/JuanGabrielPorLosSiglos',
    instagram: 'https://www.instagram.com/soyjuangabriel_/?hl=en',
    twitter: 'https://twitter.com/soyjuangabriel',
    spotify: 'https://open.spotify.com/artist/2MRBDr0crHWE5JwPceFncq',
    deezer: 'https://www.deezer.com/mx/artist/71813',
    apple: 'https://music.apple.com/mx/artist/juan-gabriel/295294',
    soundcloud: 'https://soundcloud.com/juan-gabriel-official',
    tidal: 'https://listen.tidal.com/artist/4347',
    wiki: 'https://en.wikipedia.org/wiki/Juan_Gabriel',
    ticketmaster:
      'https://www.ticketmaster.com/juan-gabriel-tickets/artist/761622',

    tiktok: 'https://www.tiktok.com/@soyjuangabriel',
    napster: 'https://web.napster.com/artist/juan-gabriel',
  },
  {
    title: 'Vicente Fernandez',
    description:
      'Vicente Fernández Gómez, también conocido como El Charro de Huentitán o Chente, fue un cantante de ranchera, empresario, productor discográfico y actor mexicano. Su trabajo en la música le valió dos premios Grammy, ocho premios Grammy Latinos, catorce premios Lo Nuestro y una estrella en el paseo de la fama de Hollywood. En abril del 2010, alcanzó la cifra de 60 millones de copias vendidos en todo el mundo. Su hijo, Alejandro Fernández, también es cantante.',

    videourl: 'https://youtube.com/playlist?list=PL47228A7CBC410B5F',
    price: '$50',
    coverImg: 'VicenteFernandez.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.vicentefernandez.mx/',
    youtube: 'https://www.youtube.com/channel/UCwPTC2n6D8asyi1G5uBZlpw',
    facebook: 'https://www.facebook.com/VicenteFernandezOficial',
    instagram: 'https://www.instagram.com/_vicentefdez/',
    twitter: 'https://twitter.com/_VicenteFdez',
    spotify: 'https://open.spotify.com/artist/4PPoI9LuYeFX8V674Z1R6l',
    deezer: 'https://www.deezer.com/mx/artist/8798',
    apple: 'https://music.apple.com/mx/artist/vicente-fern%C3%A1ndez/6742830',
    soundcloud: 'https://soundcloud.com/vicentefernandez',
    tidal: 'https://listen.tidal.com/artist/4030361',
    wiki: 'https://en.wikipedia.org/wiki/Vicente_Fern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com.mx/vicente-fernandez-boletos/artist/754927',

    tiktok: 'https://www.tiktok.com/@_vicentefdez',
    napster: 'https://web.napster.com/artist/vicente-fernandez',
  },
  {
    title: 'Antonio Aguilar',
    description:
      'Don Antonio Aguilar representa y encarna a la música mexicana. Es un símbolo y un referente cuando se habla de nuestras raíces culturales, ya sea con su amplio y exitoso repertorio musical o en sus inolvidables trabajos cinematográficos. Por algo orgullosamente se le conoce como El Charro de México.',

    videourl:
      'http://youtube.com/playlist?list=PLfjaVNeEP9fSdn5r0C6thkKVPs23g9rZ2',
    price: '$50',
    coverImg: 'AntonioAguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://found.ee/mmq-antonioaguilar-digital-lat',
    youtube: 'https://music.youtube.com/channel/UCrudP827nGhBGLHCUDQzSPg',
    facebook: 'https://www.facebook.com/AntonioAguilarFans1/',
    instagram: 'https://www.instagram.com/el_charro_de.mexico/',
    twitter: '',
    spotify: 'https://open.spotify.com/artist/0PN0fbe41KbuzlRYnoajNm',
    deezer: 'https://www.deezer.com/mx/artist/96684',
    apple: 'https://music.apple.com/mx/artist/antonio-aguilar/6545576',
    soundcloud: 'https://soundcloud.com/antonioaguilarofficial',
    tidal: 'https://listen.tidal.com/artist/3665841',
    wiki: 'https://en.wikipedia.org/wiki/Antonio_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/antonio-aguilar-tickets/artist/754762',

    tiktok: 'https://www.tiktok.com/@antonioaguilar.2022',
    napster: 'https://web.napster.com/artist/antonio-aguilar',
  },
]
export default CardData23
