const CardData36=[
    {
        title: 'Blink -  182',
        description:
          'Blink-182[a] es una banda de rock estadounidense formada en 1992 en Poway, California. Su formación actual y más conocida está compuesta por el bajista y vocalista Mark Hoppus, el guitarrista y vocalista Tom DeLonge, y el batería Travis Barker. Aunque su sonido ha evolucionado a lo largo de su carrera, su estilo musical, descrito como pop-punk, combina melodías pop pegajosas con punk rock rápido. Sus letras se centran principalmente en relaciones, frustración adolescente y madurez—o la falta de ella. El grupo surgió de la escena de skate punk suburbana del sur de California y ganó notoriedad por sus enérgicos conciertos en vivo y su humor irreverente.',
        videourl:
          'https://youtube.com/playlist?list=PLeKpZI54gF0tKuElCT3EaEftVkHmc59Wt&si=r0lJsU0TnWo4mysU',
        price: '$50',
        coverImg: 'blink182.jpg',
        category: 'Alternative',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.blink182.com/',
        youtube: 'https://www.youtube.com/channel/UCdvlHk5SZWwr9HjUcwtu8ng',
        facebook: 'https://www.facebook.com/blink182',
        instagram: 'https://www.instagram.com/blink182',
        twitter: 'https://x.com/blink182',
        spotify: 'https://open.spotify.com/artist/6FBDaR13swtiWwGhX1WQsP',
        deezer: 'https://www.deezer.com/mx/artist/409',
        apple: 'https://music.apple.com/us/artist/blink-182/116851',
        soundcloud: 'https://soundcloud.com/blink-182',
        tidal: 'https://listen.tidal.com/artist/29850',
        wiki: 'https://es.wikipedia.org/wiki/Weezer',
        ticketmaster: 'https://www.ticketmaster.com/blink182-tickets/artist/790708',
        tiktok: 'https://www.tiktok.com/@blink182',
        napster: 'https://music.amazon.com/artists/B000TDU58U/blink-182',
      }, {
        title: 'Sublime',
        description:
          "Sublime fue una banda estadounidense que combinaba el reggae, el hardcore punk y el ska punk, originaria de Long Beach (California), formada en 1988.1​ Los miembros de la banda, los cuales no cambiaron hasta su separación, eran Bradley Nowell (cantante y guitarra), Eric Wilson (bajo eléctrico) y Bud Gaugh en la batería.Lou Dog, el dálmata de Nowell, era la mascota de la banda. Michael 'Miguel' Happoldt y Marshall 'Field' Goodman contribuyeron con algunas canciones de la banda. El cantante Nowell murió de una sobredosis de heroína en 1996. Dos meses después el grupo tendría gran éxito comercial. En 1997, algunas canciones póstumas como -'Santeria', -'The Wrong Way', -'Doin Time' y 'April 29, 1992 (Miami)' fueron emitidas en la radio en los Estados Unidos",
        videourl: 'https://youtube.com/playlist?list=PL5971EBE5A63EA1C5',
        price: 36,
        coverImg: 'sublime.jpeg',
        category: 'Alternativo',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://sublimelbc.com/#!/',
        youtube: 'https://www.youtube.com/channel/UCEJ-A6sXsxg0PxdUjgrF2Jw',
        facebook: 'https://www.facebook.com/Sublime',
        instagram: 'https://www.instagram.com/sublime/',
        twitter: 'https://twitter.com/sublime',
        spotify: 'https://open.spotify.com/artist/0EdvGhlC1FkGItLOWQzG4J',
        deezer: 'https://www.deezer.com/mx/artist/410',
        apple: 'https://music.apple.com/mx/artist/sublime/63480',
        soundcloud: 'https://soundcloud.com/sublimeofficial',
        tidal: 'https://listen.tidal.com/artist/3500750',
        wiki: 'https://es.wikipedia.org/wiki/Sublime_(banda)',
        ticketmaster:
          'https://www.ticketmaster.com/sublime-with-rome-tickets/artist/1417095',
        tiktok: 'https://www.tiktok.com/@sublimeofficial?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QJSQA6/sublime',
      },{
        title: 'The Ramones',
        description:
          'Ramones fue una banda estadounidense de punk formada en Forest Hills, en el distrito de Queens (Nueva York, Estados Unidos) en 1974, y disuelta veintidós años más tarde, en 1996. Pioneros y líderes del naciente punk,​ cimentaron las bases de este género musical con composiciones simples, minimistas, repetitivas y letras muy simples o incluso sin sentido, en clara oposición a la pomposidad y la fastuosidad de las bandas que triunfaban en el mercado de los años 1970​ con sus largos solos de guitarra, las complejas canciones de rock progresivo y sus enigmáticas letras.5​ Su sonido se caracteriza por ser rápido y directo, con influencias del rockabilly de los años 1950, el surf rock, The Beatles, The Who, The Velvet Underground, las bandas de chicas de los años 1960 como The Shangri-Las y el garage protopunk de MC5 y The Stooges.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_n974sK3BaqCpJasG-u9-FxEW-0aB2lc8w&si=MM--bQLcTEbksLEX',
        price: '$50',
        coverImg: 'ramones.jpg',
        category: 'Punk Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.ramones.com/',
        youtube: 'https://www.youtube.com/@ramones',
        facebook: 'https://www.facebook.com/theramones',
        instagram: 'https://www.instagram.com/ramones/',
        twitter: 'https://twitter.com/Ramones',
        spotify: 'https://open.spotify.com/artist/1co4F2pPNH8JjTutZkmgSm',
        deezer: 'https://www.deezer.com/mx/artist/1725',
        apple: 'https://music.apple.com/mx/artist/ramones/60715',
        soundcloud: 'https://soundcloud.com/ramones',
        tidal: 'https://listen.tidal.com/artist/14283',
        wiki: 'https://es.wikipedia.org/wiki/Ramones',
        ticketmaster: 'https://www.ticketmaster.com/ramones-tickets/artist/933298',
        tiktok: 'https://www.tiktok.com/@ramones_forever?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QJJY8O/ramones',
      },
      {
        title: 'The Strokes',
        description:
          'The Strokes es una banda de rock estadounidense de la ciudad de Nueva York. Formada en 1998, la banda está compuesta por el cantante Julian Casablancas, los guitarristas Nick Valensi y Albert Hammond, Jr., el bajista Nikolai Fraiture y el baterista Fabrizio Moretti.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_lM_fbPPCTserEdQJiHIflY03o4O2AU72o&si=4TF1m7QBuh1yBbiQ',
        price: '$50',
        coverImg: 'thestrokes.jpg',
        category: 'Punk ',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.thestrokes.com/',
        youtube: 'https://www.youtube.com/@thestrokes',
        facebook: 'https://www.facebook.com/thestrokes',
        instagram: 'https://www.instagram.com/thestrokes/',
        twitter: 'https://x.com/thestrokes',
        spotify: 'https://open.spotify.com/artist/0epOFNiUfyON9EYx7Tpr6V',
        deezer: 'https://www.deezer.com/mx/artist/1725',
        apple: 'https://music.apple.com/mx/artist/the-strokes/560289',
        soundcloud: 'https://soundcloud.com/thestrokes',
        tidal: 'https://listen.tidal.com/artist/29037',
        wiki: 'https://es.wikipedia.org/wiki/The_Strokes',
        ticketmaster: 'https://www.ticketmaster.com/the-strokes-tickets/artist/807068',
        tiktok: 'https://www.tiktok.com/@thestrokes?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B00G70DLAS/the-strokes',
      }, {
        title: 'Siouxsie and the Banshees',
        description:
          'Siouxsie and the Banshees (pronunciado [suːzi æn ðə ˈbænʃiːz]) fue una banda británica formada en 1976 por la vocalista Siouxsie Sioux y el bajista Steven Severin. Pioneros del movimiento post-punk, su música evolucionó hacia el rock alternativo, con sencillos pop. Siouxsie and the Banshees fue una de las bandas más importantes, innovadoras e influyentes del movimiento post-punk.Lanzaron su primer sencillo, «Hong Kong Garden», en 1978; la canción, con una melodía original del guitarrista John McKay, fue un éxito en el Reino Unido, con una entrada en el top 10. Su álbum debut, The Scream, fue aclamado por la crítica británica.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_lt6dACBH8ncgqjBg6D5Xu3u3rOFJDhF_U&si=WAJreONXA9I6HGz0',
        price: '$50',
        coverImg: 'siouxsieandthebanshees.jpg',
        category: 'Pop',
        location: 'UK',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl:
          'https://www.alohacriticon.com/musica/grupos-y-solistas/siouxsie-the-banshees/',
        youtube: 'https://www.youtube.com/channel/UCrSQ_CLYKSbQpw8Ol7xTrvQ',
        facebook: 'https://www.facebook.com/siouxsieandthebanshees',
        instagram: 'https://www.instagram.com/siouxsieandthebanshees/',
        twitter: 'https://twitter.com/siouxsieandtheb',
        spotify: 'https://open.spotify.com/artist/1n65zfwYIj5kKEtNgxUlWb',
        deezer: 'https://www.deezer.com/mx/artist/1719',
        apple: 'https://music.apple.com/mx/artist/siouxsie-the-banshees/92623',
        soundcloud: 'https://soundcloud.com/siouxsie-and-the-banshees-official',
        tidal: 'https://listen.tidal.com/artist/30182',
        wiki: 'https://es.wikipedia.org/wiki/Siouxsie_And_The_Banshees',
        ticketmaster:
          'https://www.ticketmaster.com/siouxsie-and-the-banshees-tickets/artist/821070',
        tiktok: 'https://www.tiktok.com/@siouxsie.and.thebanshees?lang=es',
        napster:
          'https://music.amazon.com.mx/artists/B000QKPILK/siouxsie-and-the-banshees',
      },{
        title: 'The Misfits',
        description:
          'The Misfits son una banda estadounidense de punk rock reconocida a menudo como los pioneros del subgénero de horror punk, fusionando influencias del punk y otras corrientes musicales con temáticas e imágenes de películas de terror. El grupo fue fundado en 1977 en Lodi, Nueva Jersey, por el vocalista, compositor y tecladista Glenn Danzig. El primer recluta de Danzig para los Misfits fue el baterista Mr. Jim y la bajista Diane DiPazza, sin embargo, DiPiazza nunca se presentó. Mr. Jim fue reemplazado poco después por Manny Martinez. Jerry Only se unió como bajista poco después. Durante los siguientes seis años, la membresía cambiaría con frecuencia, siendo Danzig y Only los únicos miembros constantes.',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_nfnXwNY1vi9yULfGsK6KG9hddJB5kM-gw&si=s_XNM7WzJF-umrMN',
        price: '$50',
        coverImg: 'misfits.jpg',
        category: 'Pop',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'http://www.misfits.com/',
        youtube: 'https://www.youtube.com/channel/UCUAa1fv7JsIFuBUWtH0ZaOA',
        facebook: 'https://www.facebook.com/Misfits/',
        instagram: 'https://www.instagram.com/officialmisfits/',
        twitter: 'https://www.instagram.com/officialmisfits',
        spotify: 'https://open.spotify.com/artist/1cXi8ALPQCBHZbf0EgP4Ey',
        deezer: 'https://www.deezer.com/mx/artist/957',
        apple: 'https://music.apple.com/mx/artist/the-misfits/116250',
        soundcloud: 'https://soundcloud.com/misfitsofficial',
        tidal: 'https://listen.tidal.com/artist/16992',
        wiki: 'https://es.wikipedia.org/wiki/Misfits_(banda)',
        ticketmaster:
          'https://www.ticketmaster.com/the-original-misfits-tickets/artist/735666',
        tiktok: 'https://www.tiktok.com/@discordmisfits?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B00G89X1QM/misfits',
      }, {
        title: 'Sex Pistols',
        description:
          'Sex Pistols fue una banda de punk formada en Londres en 1975, considerada por algunas fuentes la responsable de haber iniciado el movimiento punk en el Reino Unido y de inspirar a muchos músicos de punk rock, heavy metal y el rock contemporáneo en general. Su primera etapa apenas duró dos años y medio y en ella produjo únicamente cuatro sencillos y un álbum de estudio: Never Mind the Bollocks, Here s the Sex Pistols (1977).Los miembros de Sex Pistols eran originalmente el vocalista Johnny Rotten, el guitarrista Steve Jones, el batería Paul Cook y el bajista Glen Matlock —este último reemplazado por Sid Vicious a principios de 1977—. Con el empresario Malcolm McLaren como mánager, la banda protagonizó diversos escándalos que la convirtieron en centro de atención de la opinión pública británica, esto gracias a sus letras con fuertes contenidos satíricos.',
        videourl:
          'https://youtube.com/playlist?list=PLaZ-C3Reyh5TElxXl5Dnop0ARZaskt4Lv&si=JuyRevjhLXpbeB1f',
        price: '$50',
        coverImg: 'sexpistols.jpg',
        category: 'Rock',
        location: 'UK',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.sexpistolsofficial.com/',
        youtube: 'https://www.youtube.com/channel/UCcjWoLUPOkEVhevjI8DB5vQ',
        facebook: 'https://www.facebook.com/sexpistolsofficial',
        instagram: 'https://www.instagram.com/sexpistols/',
        twitter: 'https://twitter.com/sexpistols',
        spotify: 'https://open.spotify.com/artist/1u7kkVrr14iBvrpYnZILJR',
        deezer: 'https://www.deezer.com/mx/artist/3299',
        apple: 'https://music.apple.com/us/artist/sex-pistols/3184277',
        soundcloud: 'https://soundcloud.com/sexpistolsofficial',
        tidal: 'https://listen.tidal.com/artist/9219',
        wiki: 'https://es.wikipedia.org/wiki/Sex_Pistols',
        ticketmaster: 'https://www.ticketmaster.com/',
        tiktok: 'https://www.tiktok.com/@sexpistols',
        napster: 'https://music.amazon.com.mx/artists/B000QJPXUW/sex-pistols',
      }, 
      {
        title: 'Incubus',
        description:
          'Incubus es una banda de rock alternativo​ estadounidense formado por el vocalista Brandon Boyd, guitarrista Mike Einziger y batería José Pasillas cuando estudiaban juntos y después expandieron con la inclusión del bajista Alex Dirk Lance Katunich y Gavin DJ Lyfe Koppell; ambos reemplazados finalmente por Ben Kenney y DJ Kilmore respectivamente. Para el 2001 tuvieron un enorme éxito con el sencillo Drive, seguido de su álbum Morning View.En 2003, la banda establece la fundación Make Yourself Foundation para donar dinero a fundaciones de beneficencia.',
        videourl:
          'https://music.youtube.com/playlist?list=OLAK5uy_nVn0lxDAdY-B5tKniN0FYgQMDeOHFEjh0',
        price: 36,
        coverImg: 'incubus.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.incubushq.com/',
        youtube: 'https://music.youtube.com/channel/UC8FhfVPXx7ac7araOiNkTYw',
        facebook: 'https://www.facebook.com/Incubus/',
        instagram: 'https://www.instagram.com/incubusofficial/',
        twitter: 'https://x.com/incubusband',
        spotify: 'https://open.spotify.com/artist/3YcBF2ttyueytpXtEzn1Za',
        deezer: 'https://www.deezer.com/mx/artist/693',
        apple: 'https://music.apple.com/mx/artist/incubus/465707',
        soundcloud: 'https://soundcloud.com/nofx-official',
        tidal: 'https://listen.tidal.com/artist/1086',
        wiki: 'https://es.wikipedia.org/wiki/Incubus_(banda)',
        ticketmaster: 'https://www.ticketmaster.com/incubus-tickets/artist/741166',
        tiktok: 'https://www.tiktok.com/@incubusofficial?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QKUHTI/incubus',
      },
        {
        title: 'Rammstein',
        description:
          "Rammstein (German pronunciation: [ˈʁamʃtaɪn], lit. ramming stone) is a German Neue Deutsche Härte band formed in Berlin in 1994. The band's lineup—consisting of lead vocalist Till Lindemann, lead guitarist Richard Kruspe, rhythm guitarist Paul Landers, bassist Oliver Riedel, drummer Christoph Schneider, and keyboardist Christian Flake Lorenz—has remained unchanged throughout their history, along with their approach to songwriting, which consists of Lindemann writing and singing the lyrics over instrumental pieces the rest of the band has completed beforehand. Prior to their formation, some members were associated with the punk rock acts Feeling B and First Arsch..",
        videourl:
          'https://youtube.com/playlist?list=PLVTLbc6i-h_iuhdwUfuPDLFLXG2QQnz-x',
        price: '$50',
        coverImg: 'rammstein.jpg',
        category: 'Rock',
        location: 'Germany',
        locationImg: 'germany.jpeg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.rammstein.de/en/',
        youtube: 'https://www.youtube.com/channel/UCYp3rk70ACGXQ4gFAiMr1SQ',
        facebook: 'https://www.facebook.com/Rammstein',
        instagram: 'https://www.instagram.com/rammsteinofficial/',
        twitter: 'https://twitter.com/RSprachrohr',
        spotify: 'https://open.spotify.com/artist/6wWVKhxIU2cEi0K81v7HvP',
        deezer: 'https://www.deezer.com/mx/artist/464',
        apple: 'https://music.apple.com/us/artist/rammstein/408932',
        soundcloud: 'https://soundcloud.com/rammstein-official',
        tidal: 'https://listen.tidal.com/artist/24972',
        wiki: 'https://en.wikipedia.org/wiki/Rammstein',
        ticketmaster:
          'https://www.ticketmaster.com/rammstein-tickets/artist/781114',
        tiktok: 'https://www.tiktok.com/@rammstein',
        napster: 'https://music.amazon.com.mx/artists/B000S9XUG4/rammstein',
      },{
        title: 'Linkin Park',
        description:
          'Linkin Park es una banda estadounidense de rock alternativo procedente de Agoura Hills, California. Se formó en 1996. Integrada por Mike Shinoda, Dave Farrell, Joe Hahn, Brad Delson, Rob Bourdon y Chester Bennington, este último como voz principal. La banda comenzó con sus primeros trabajos musicales de manera independiente, en la que grabaron su primer material, llamado Xero; sin embargo, no tuvieron éxito en la búsqueda de un sello discográfico, ya que nadie mostraba interés por su trabajo. Fue hasta 1999, que con el apoyo de Jeff Blue, el entonces vicepresidente de Warner Records, lograron firmar su primer contrato. El nombre del grupo es un juego de palabras que hace referencia al Lincoln Park en Santa Mónica.',
        videourl:
          'http://youtube.com/playlist?list=PLlqZM4covn1G3hqrvNwpRy19pGDTYkUK6',
        price: 36,
        coverImg: 'linkinpark.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl:
          'https://www.linkinpark.com/hybridtheory20?ref=https://www.bing.com/',
        youtube: 'https://www.youtube.com/c/linkin-park',
        facebook: 'https://www.facebook.com/linkinpark/',
        instagram: 'https://www.instagram.com/linkinpark/',
        twitter: 'https://twitter.com/linkinpark',
        spotify: 'https://open.spotify.com/artist/5eAWCfyUhZtHHtBdNk56l1',
        deezer: 'https://www.deezer.com/mx/artist/92',
        apple: 'https://music.apple.com/mx/artist/linkin-park/148662',
        soundcloud: 'https://soundcloud.com/linkin_park',
        tidal: 'https://listen.tidal.com/artist/14123',
        wiki: 'https://es.wikipedia.org/wiki/Linkin_Park',
        ticketmaster:
          'https://www.ticketmaster.com/linkin-park-tickets/artist/703831',
        tiktok: 'https://www.tiktok.com/@linkinpark?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000VK762Y/linkin-park',
      }, {
        title: 'System of a Down',
        description:
          'System of a Down es una banda de rock estadounidense de ascendencia armenia, formada en Glendale, California, en el año 1994. Está compuesta por Serj Tankian, Daron Malakian, Shavo Odadjian y John Dolmayan.',
        videourl:
          'http://youtube.com/playlist?list=OLAK5uy_kYvOOhnr1a5kluwLptB3wOvhkYQ7mQS8E',
        price: 36,
        coverImg: 'systemofadown.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://systemofadown.com/',
        youtube: 'https://www.youtube.com/c/systemofadownchannel',
        facebook: 'https://www.facebook.com/systemofadown',
        instagram: 'https://www.instagram.com/systemofadown/',
        twitter: 'https://twitter.com/systemofadown',
        spotify: 'https://open.spotify.com/artist/5eAWCfyUhZtHHtBdNk56l1',
        deezer: 'https://www.deezer.com/search/system%20of%20a%20down',
        apple: 'https://music.apple.com/mx/artist/system-of-a-down/462715',
        soundcloud: 'https://soundcloud.com/system-of-a-down-official',
        tidal: 'https://listen.tidal.com/artist/721',
        wiki: 'https://es.wikipedia.org/wiki/System_of_a_Down',
        ticketmaster:
          'https://www.ticketmaster.com/system-of-a-down-tickets/artist/726146',
        tiktok: 'https://www.tiktok.com/@systemofadown_spider?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QKPGGC/system-of-a-down',
      },{
        title: ' The Offspring',
        description:
          'The Offspring es una banda de punk estadounidense, formada en Huntington Beach, California, en 1984 bajo el nombre de Manic Subsidal. Actualmente está integrada por Dexter Holland, Noodles y Todd Morse.',
        videourl:
          'http://youtube.com/playlist?list=PL99E7hmNQn66t6TbfKvE_IFMH2tY42i0P',
        price: 36,
        coverImg: 'offspring.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.offspring.com/',
        youtube: 'https://www.youtube.com/user/offspringtv',
        facebook: 'https://www.facebook.com/Offspring/',
        instagram: 'https://www.instagram.com/offspring/',
        twitter: 'https://twitter.com/Offspring_Band',
        spotify: 'https://open.spotify.com/artist/5LfGQac0EIXyAN8aUwmNAQ',
        deezer: 'https://www.deezer.com/mx/artist/882',
        apple: 'https://music.apple.com/mx/search?term=the%20offspring',
        soundcloud: 'https://soundcloud.com/search?q=OffSpring',
        tidal: 'https://listen.tidal.com/artist/1009',
        wiki: 'https://es.wikipedia.org/wiki/The_Offspring',
        ticketmaster:
          'https://www.ticketmaster.com/the-offspring-tickets/artist/753206',
        tiktok: 'https://www.tiktok.com/@offspring?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B00GBG63PI/the-offspring',
      },
      {
        title: 'L7',
        description:
          'L7 (pronunciado /ɛl ˈsɛvən/ el seven) es una banda estadounidense de Los Ángeles. Siguen activas desde 1985. Debido a su imagen y sonido, a menudo se asocian con el movimiento grunge de finales de 1980 y principios de 1990, aunque eran inicialmente una banda de punk rock.El 27 de enero de 2015 confirmaron su regreso.El grupo se formó en 1985 cuando se conocieron la vocalista y guitarrista Donita Sparks y la también guitarrista Suzi Gardner. En un periodo de tres años grabaron su álbum debut para Epitaph Records y telonearon en una gira a Bad Religion. En 1990 editaron uno de los discos más importantes de la compañía discográfica Sub Pop, Smell the Magic y en 1992 Bricks Are Heavy, que supuso el primer éxito del grupo con la canción Pretend We’re Dead (Fingir que estamos muertas). Vinieron un montón de giras y festivales.',
        videourl:
          'https://youtube.com/playlist?list=PL3jP6TVv1QUbDmrGUfqpDwGN6SwBuuyGw&si=BJTa7Vp0S8o5hEuA',
        price: 36,
        coverImg: 'l7.jpg',
        category: 'Punk',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.l7theband.com/',
        youtube: 'https://www.youtube.com/user/L7TheBand',
        facebook: 'https://www.facebook.com/L7theband',
        instagram: 'https://www.instagram.com/l7theband',
        twitter: 'https://x.com/L7officialhq',
        spotify: 'https://open.spotify.com/artist/2zMQOJ4Cyl4BYbw6WqaO3h',
        deezer: 'https://www.deezer.com/mx/artist/1738',
        apple: 'https://music.apple.com/mx/artist/l7/192131',
        soundcloud: 'https://soundcloud.com/l7theband',
        tidal: 'https://listen.tidal.com/artist/13998',
        wiki: 'https://es.wikipedia.org/wiki/L7',
        ticketmaster: 'https://www.ticketmaster.com/l7-tickets/artist/735474',
        tiktok: 'https://www.tiktok.com/@l7band?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QJPG9A/l7',
      },{
        title: 'Minor Threat',
        description:
          'Minor Threat fue una banda estadounidense de hardcore punk, activa desde 1980 hasta 1983, siendo participantes activos de la escena de Washington DC, denominada posteriormente DC Hardcore. Su actividad musical fue relativamente corta, pero pese a eso, su influencia en la escena punk mundial es enorme, tanto musical como ideológicamente, son responsables de expandir la ética do it yourself dentro del ambiente underground estadounidense, con ejemplos claros como la distribución y grabación de su música, la política de all ages para la inclusión a menores de edad en sus conciertos y una red independiente para salir de gira dentro o fuera de su país. All Music ha descrito la música de Minor Threat como icónica y señaló que su música se mantuvo y definió mejor en comparación a sus contemporáneos.',
        videourl:
          'http://youtube.com/playlist?list=OLAK5uy_nR5qqYxWEFViIO08fo8GhARWjeh0ZspUA',
        price: 36,
        coverImg: 'minorthreat.gif',
        category: 'Rock',
        location: 'UK',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.dischord.com/band/minor-threat',
        youtube: 'https://www.youtube.com/channel/UCsyKXqTdBP7vRHJ6ZBHln0A',
        facebook: 'https://www.facebook.com/minorthreat',
        instagram: 'https://www.instagram.com/minorthreat/',
        twitter: 'https://twitter.com/MinorThreatBand',
        spotify: 'https://open.spotify.com/artist/07PiZYrhllpSXtELkUxlrf',
        deezer: 'https://www.deezer.com/mx/artist/2950',
        apple: 'https://music.apple.com/mx/artist/minor-threat/49249573',
        soundcloud: 'https://soundcloud.com/search?q=Minor%20Threat',
        tidal: 'https://listen.tidal.com/artist/3649627',
        wiki: 'https://es.wikipedia.org/wiki/Minor_Threat',
        ticketmaster: 'https://www.ticketmaster.com/search?q=Minor+Threat',
        tiktok: 'https://www.tiktok.com/tag/minorthreat?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000S2H5M6/minor-threat',
      },
      {
        title: 'NOFX',
        description:
          'NOFX es una banda californiana de punk rock formada en 19836​ por Fat Mike como bajista, vocalista y compositor, y el guitarrista Eric Melvin. El baterista Erik Sandin se les unió poco después, y junto con El Hefe (guitarra principal y trompeta desde 1991) conforman la banda en la actualidad. El éxito de NOFX tuvo en principio mucho que ver con el auge mainstream del punk rock en los 90, aunque a diferencia de muchos de sus contemporáneos (como Bad Religion, Green Day o The Offspring), jamás firmaron con ninguna gran compañía discográfica. Publicaron trece discos de estudio, dieciséis EP y varios sencillos. Alcanzaron mucha popularidad internacional en 1994 con su quinto LP Punk in Drublic, que fue disco de oro sin haber sido difundido en radios ni televisión; dieron entrevistas solamente a medios independientes. Han vendido cerca de seis millones de copias en todo el mundo, lo que les convierte en una de las bandas independientes más exitosas',
        videourl:
          'https://youtube.com/playlist?list=OLAK5uy_ksVWQBqy5paIHtoPCfYPToEx2xLNudSvY&si=3IeRyd8X7XVY3WPy',
        price: 36,
        coverImg: 'nofx.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.nofxofficialwebsite.com/',
        youtube: 'https://www.youtube.com/channel/UCLOMDtSVuVeQJrd0Cq2tGAA',
        facebook: 'https://www.facebook.com/NOFX/',
        instagram: 'https://www.instagram.com/nofx/',
        twitter: 'https://x.com/NOFXband',
        spotify: 'https://open.spotify.com/artist/4S2yOnmsWW97dT87yVoaSZ',
        deezer: 'https://www.deezer.com/mx/artist/941',
        apple: 'https://music.apple.com/mx/artist/nofx/2819846',
        soundcloud: 'https://soundcloud.com/nofx-official',
        tidal: 'https://listen.tidal.com/artist/3517802',
        wiki: 'https://es.wikipedia.org/wiki/NOFX',
        ticketmaster: 'https://www.ticketmaster.com/nofx-tickets/artist/741612',
        tiktok: 'https://www.tiktok.com/@nofx_official?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QK8J3O/nofx',
      },
      {
        title: 'Pennywise',
        description:
          'Pennywise es una banda estadounidense de punk rock procedente de Hermosa Beach, California y fundada en 1988 por Jim Lindberg, Fletcher Dragge, Byron McMackin y Jason Thirsk. El nombre del grupo fue tomado del monstruo de la novela de Stephen King, It. Desde su álbum homónimo de 1991, Pennywise ha lanzado un álbum cada dos años en Epitaph Records, sello discográfico propiedad del guitarrista de Bad Religion Brett Gurewitz. Hasta la fecha, la banda ha lanzado diez álbumes de estudio, un disco en directo, dos EP y un DVD. Su noveno álbum de estudio, Reason to Believe de 2008, fue lanzado gratuitamente mediante un acuerdo con MySpace y Textango, un distribuidor musical de móviles. El álbum estuvo disponible en las tiendas a través de MySpace Records en los Estados Unidos, y mediante Epitaph en Europa.',
        videourl:
          'https://youtube.com/playlist?list=PLcZMZxR9uxC9HAzS8YdcdpHabhVGdxMNb&si=8R_aopL3uhINGymc',
        price: 36,
        coverImg: 'pennywise.jpg',
        category: 'Rock',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://pennywisdom.com/',
        youtube: 'https://www.youtube.com/PennywiseOfficial',
        facebook: 'https://www.facebook.com/pennywise',
        instagram: 'https://www.instagram.com/_pennywise/',
        twitter: 'https://x.com/pennywise',
        spotify: 'https://open.spotify.com/artist/6i0KVTOvm96T55mbp742ks',
        deezer: 'https://www.deezer.com/mx/artist/2238',
        apple: 'https://music.apple.com/mx/artist/pennywise/2820315',
        soundcloud: 'https://soundcloud.com/pennywisdom',
        tidal: 'https://listen.tidal.com/artist/17713',
        wiki: 'https://es.wikipedia.org/wiki/Pennywise_(banda)',
        ticketmaster: 'https://www.ticketmaster.com/pennywise-tickets/artist/741706',
        tiktok: 'https://www.tiktok.com/@pennywise.kz_official?lang=es',
        napster: 'https://music.amazon.com.mx/artists/B000QJNZVQ/pennywise',
      },
]
export default CardData36;