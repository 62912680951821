const CardData25 = [
  {
    title: 'Björk',
    description:
      'Björk Guðmundsdóttir Reikiavik, 21 de noviembre de 1965, conocida simplemente como Björk, es una cantante, compositora, multiinstrumentista, actriz, escritora, DJ y productora islandesa. Es reconocida por hacer música experimental, alternativa y vanguardista con la cual ha obtenido gran reconocimiento a nivel internacional, tanto de crítica como de público. Varios de sus álbumes han alcanzado el top 10 en la lista Billboard 200: el más reciente es Fossora (2022).Björk fue incluida en la lista de los 100 artistas más influyentes del siglo. Así mismo, también fue incluida por la revista Rolling Stone como una de las cantantes y compositoras más influyentes de la época actual, incluyendo su inclusión en la lista de los 200 artistas más influyentes de los últimos 25 años por Pitchfork en 2021.3​ Björk también ha sido nominada 16 veces a los Premios Grammy.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_neQVQ7L8dH5xV5QKsopBwMASWeFR0eSl8&si=exfbr8Vk6bUigsMq',
    price: '$50',
    coverImg: 'bjork.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bjorktour.com/',
    youtube: 'https://www.youtube.com/channel/UCFbRdRGijPR4oBjQ0fVCSmw',
    facebook: 'https://www.facebook.com/bjork',
    instagram: 'https://www.instagram.com/bjork/',
    twitter: 'https://twitter.com/bjork',
    spotify: 'https://open.spotify.com/artist/7w29UYBi0qsHi5RTcv3lmA',
    deezer: 'https://www.deezer.com/mx/artist/630',
    apple: 'https://music.apple.com/mx/artist/bj%C3%B6rk/295015',
    soundcloud: 'https://soundcloud.com/bjork',
    tidal: 'https://listen.tidal.com/artist/16992',
    wiki: 'https://es.wikipedia.org/wiki/Bj%C3%B6rk',
    ticketmaster: 'https://www.ticketmaster.com/bjork-tickets/artist/753508',
    tiktok: 'https://www.tiktok.com/@bjorkofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJK03W/bj%C3%B6rk',
  },
  {
    title: 'Britney Spears',
    description:
      'Britney Jean Spears (McComb, Misisipi; 2 de diciembre de 1981) es una cantante, bailarina, compositora, modelo, actriz, diseñadora de modas y empresaria estadounidense. Comenzó a actuar desde niña, a través de papeles en producciones teatrales. Después adquirió fama al participar en el programa de televisión The Mickey Mouse Club.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8FKUB87QCDXkCddEw2uzuxL6PDp-vFCg',
    price: '$50',
    coverImg: 'britneyspears.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://britneyspears.com/',
    youtube: 'https://www.youtube.com/channel/UCgffc95YDBlkGrBAJUHUmXQ',
    facebook: 'https://www.facebook.com/britneyspears',
    instagram: 'https://www.instagram.com/britneyspears/',
    twitter: 'https://twitter.com/britneyspears',
    spotify: 'https://open.spotify.com/artist/26dSoYclwsYLMAKD3tpOr4',
    deezer: 'https://www.deezer.com/mx/artist/483',
    apple: 'https://music.apple.com/mx/artist/britney-spears/217005',
    soundcloud: 'https://soundcloud.com/britneyspears',
    tidal: 'https://listen.tidal.com/artist/1532',
    wiki: 'https://es.wikipedia.org/wiki/Britney_Spears',
    ticketmaster:
      'https://www.ticketmaster.com/britney-spears-tickets/artist/723427',
    tiktok: 'https://www.tiktok.com/@britneyspears?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJO2TU/britney-spears',
  },
  {
    title: 'Madonna',
    description:
      'Madonna Louise Ciccone, conocida simplemente como Madonna, es una cantante, bailarina, compositora, actriz, empresaria e icono estadounidense. Madonna pasó su infancia en Bay City y en 1978 se mudó a la ciudad de Nueva York para realizar una carrera de danza contemporánea. Después de participar en dos grupos musicales, Breakfast Club y Emmy, en 1982 firmó con Sire Records y lanzó su álbum debut Madonna al año siguiente.',
    videourl:
      'https://youtube.com/playlist?list=PLvHf4SnA7f8uK47aAgk6EzgbNcRxK98LM',
    price: '$50',
    coverImg: 'madonna.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.madonna.com/',
    youtube: 'https://www.youtube.com/c/madonna',
    facebook: 'https://www.facebook.com/madonna',
    instagram: 'https://www.instagram.com/madonna',
    twitter: 'https://twitter.com/madonna',
    spotify: 'https://open.spotify.com/artist/6tbjWDEIzxoDsBA1FuhfPW',
    deezer: 'https://www.deezer.com/mx/artist/290',
    apple: 'https://music.apple.com/mx/artist/madonna/20044',
    soundcloud: 'https://soundcloud.com/madonna',
    tidal: 'https://listen.tidal.com/artist/15545',
    wiki: 'https://es.wikipedia.org/wiki/Madonna',
    ticketmaster: 'https://www.ticketmaster.com/madonna-tickets/artist/768011',
    tiktok: 'https://www.tiktok.com/@madonna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP7VW/madonna',
  },

  {
    title: 'Sade',
    description:
      'Helen Folasade Adu Ibadán, 16 de enero de 1959), más conocida como Sade, es una cantante y compositora nigeriana nacionalizada británica Durante su adolescencia no paraba de escuchar discos de Nina Simone, Peggy Lee y Astrud Gilberto.Sus estudios de diseño la llevaron a abrir una boutique, pero su amor por la música la llevó a cantar en un grupo funky de cierto carácter latino que respondía al nombre de Arriba. Desde ese momento descubrió un extraño placer al escribir letras. Más tarde, esta banda pasó a llamarse Pride, y luego cambió a Sade, que es una abreviatura de Folasade. Y con Sade comenzó a conocerse también a su vocalista..',

    videourl:
      'https://youtube.com/playlist?list=PL7A67YeJT9U7oMK2xMU5JJcEj6vwQHcEt',
    price: '$50',
    coverImg: 'sade.jpg',
    category: 'Soul',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sade.com/',
    youtube: 'https://www.youtube.com/channel/UCegKDBFMUSQNeWe4FAB7aig',
    facebook: 'https://www.facebook.com/sadeofficial',
    instagram: 'https://www.instagram.com/sade/',
    twitter: 'https://twitter.com/SadeOfficial',
    spotify: 'https://open.spotify.com/artist/47zz7sob9NUcODy0BTDvKx',
    deezer: 'https://www.deezer.com/mx/artist/202',
    apple: 'https://music.apple.com/mx/artist/sade/475591',
    soundcloud: 'https://soundcloud.com/sadeofficial',
    tidal: 'https://listen.tidal.com/artist/485',
    wiki: 'https://es.wikipedia.org/wiki/Sade_Adu',
    ticketmaster: 'https://www.ticketmaster.com/sade-tickets/artist/736033',
    napster: 'https://music.amazon.com.mx/artists/B000QKKF3G/sade',
    tiktok: 'https://www.tiktok.com/@sadeaduwife',
  },
  {
    title: 'Selena',
    description:
      'Selena Quintanilla Pérez (Lake Jackson, Texas, 16 de abril de 1971-Corpus Christi, Texas, 31 de marzo de 1995), conocida monónimamente como Selena, fue una cantante estadounidense. Llamada la «Reina de la música tejana», sus contribuciones a la música y la moda la convirtieron en una de las artistas mexicanoestadounidenses más célebres de finales del siglo xx. En 2020, la revista Billboard la colocó en el tercer lugar de su lista de «Mejores artistas latinos de todos los tiempos», basado en la lista de álbumes latinos y canciones latinas.3​ Los medios de comunicación la llamaron la «Madonna tejana» por sus elecciones de ropa.A​ También se encuentra entre las artistas latinas más influyentes de todos los tiempos y se le atribuye haber catapultado el género tejano al mercado principal..',

    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nZ2PDxHpWHOslsCoBGMvmh9mmRB8ZHCZo',
    price: '$50',
    coverImg: 'selena.jpg',
    category: 'Regional',
    location: 'UK',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://selena-official.com/',
    youtube: 'https://www.youtube.com/@SelenaQOfficial',
    facebook: 'https://www.facebook.com/selenalaleyenda',
    instagram: 'https://www.instagram.com/selenaqofficial/',
    twitter: 'https://twitter.com/SelenaLaLeyenda',
    spotify: 'https://open.spotify.com/artist/6IE6z7DcZIT4Ml3Fh5Ivch',
    deezer: 'https://www.deezer.com/mx/artist/5644',
    apple: 'https://music.apple.com/mx/artist/selena/574870',
    soundcloud: 'https://soundcloud.com/selenaquintanillanewsongs',
    tidal: 'https://listen.tidal.com/artist/9541',
    wiki: 'https://es.wikipedia.org/wiki/Selena',
    ticketmaster:
      'https://www.ticketmaster.com/almost-selena-tickets/artist/2622145',
    napster: 'https://music.amazon.com.mx/artists/B000RHRQTY/selena',
    tiktok: 'https://www.tiktok.com/@selenalaleyenda',
  },
  {
    title: 'Rocio Durcal',
    description:
      'María de los Ángeles de las Heras Ortiz, conocida por su nombre artístico Rocío Dúrcal, fue una actriz y cantante española. Dúrcal es considerada como la «reina de las rancheras» y es una de las mujeres de habla hispana con más ventas en la industria. En 2005, un año antes de su muerte, recibió el Grammy Latino a la excelencia musical.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lMv8ZIewIbDR8z4ckj4pbzhXV8GtrWh58',
    price: '$50',
    coverImg: 'rociodurcal.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    youtube: 'https://www.youtube.com/channel/UCBgAZZ95M0pSBo69Qb6EuTQ',
    facebook: 'https://www.facebook.com/RocioDurcalMusic',
    instagram:
      'https://www.instagram.com/rociodurcal.music/?fbclid=IwAR1j7EblxeIYpgKWQfpesMeVe2l-6TfNhzfv7Hterp7PVJPLcWjCSPR6n_I',
    twitter:
      'https://twitter.com/rocio_durcal?fbclid=IwAR0QUd4K5u9dWS3S72dobhOD_ktY5dPbr-386AiKlv411DTQPTAhsnOY-vA',
    spotify: 'https://open.spotify.com/artist/2uyweLa0mvPZH6eRzDddeB',
    deezer: 'https://www.deezer.com/mx/artist/75284',
    apple: 'https://music.apple.com/mx/artist/roc%C3%ADo-d%C3%BArcal/6742584',
    soundcloud: 'https://soundcloud.com/rocio-durcal-official',
    tidal: 'https://listen.tidal.com/artist/3660925',
    wiki: 'https://en.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    ticketmaster:
      'https://www.ticketmaster.com/rocio-durcal-tickets/artist/754908',
    napster:
      'https://music.amazon.com.mx/artists/B00386NT7O/roc%C3%ADo-d%C3%BArcal',
    tiktok: 'https://www.tiktok.com/@rociodurcal.oficial',
  },
  {
    title: 'Tina Turner',
    description:
      'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
    videourl:
      'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
    price: '$50',
    coverImg: 'TinaTurner.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tinaturnerofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
    facebook: 'https://www.facebook.com/TinaTurner/',
    instagram: 'https://www.instagram.com/tinaturner/?hl=en',
    twitter: 'https://twitter.com/LoveTinaTurner',
    spotify:
      'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1454',
    apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
    soundcloud: 'https://soundcloud.com/tina-turner-official',
    tidal: 'https://listen.tidal.com/artist/9214',
    wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
    ticketmaster:
      'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
    tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNPQG/tina-turner',
  },
  {
    title: 'Lauryn Hill',
    description:
      'Lauryn Hill (East Orange, Nueva Jersey, 26 de mayo de 1975) es una rapera, cantante, actriz y productora discográfica estadounidense galardonada 8 veces con el premio Grammy. Fue miembro de The Fugees antes de empezar una carrera en solitario.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m0PVFfyOJH8trqZucyaODwytdicqitwGQ',
    price: '$50',
    coverImg: 'laurynHill.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://mslaurynhill.com/',
    youtube: 'https://www.youtube.com/results?search_query=lauryn+hill',
    facebook: 'https://www.facebook.com/mslaurynhill',
    instagram: 'https://www.instagram.com/mslaurynhill/',
    twitter: 'https://twitter.com/MsLaurynHill',
    spotify: 'https://open.spotify.com/artist/2Mu5NfyYm8n5iTomuKAEHl',
    deezer: 'https://www.deezer.com/mx/artist/1117',
    apple: 'https://music.apple.com/mx/artist/lauryn-hill/21434',
    soundcloud: 'https://soundcloud.com/lauryn-hill-official',
    tidal: 'https://listen.tidal.com/artist/8201663',
    wiki: 'https://es.wikipedia.org/wiki/Lauryn_Hill',
    ticketmaster:
      'https://www.ticketmaster.com/ms-lauryn-hill-tickets/artist/795402',
    tiktok: 'https://www.tiktok.com/@laurynhillfannn?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSHLF2/lauryn-hill',
  },
  {
    title: 'Mariah Carey',
    description:
      'Mariah Carey, Huntington, Nueva York; 27 de marzo de 1969 es una cantante, compositora, productora musical y actriz estadounidense. Referida como el «Ave Cantora Suprema Songbird Supreme por el Libro Guinness de los récords,3​45​ es reconocida por su registro vocal de cinco octavas, su estilo melismático y por el uso del registro de silbido.Bajo la dirección del productor ejecutivo del sello Columbia Records, Tommy Mottola, Carey lanzó su álbum debut homónimo, Mariah Carey (1990). De este se desprendieron cuatro sencillos que llegaron al primer puesto en la lista Billboard Hot 100. Después de su matrimonio con Mottola y el éxito de sus álbumes posteriores, Music Box (1993) y Merry Christmas (1994), Carey se estableció como la artista con mayores ventas de Columbia.',
    videourl:
      'https://youtube.com/playlist?list=PLMmBcihs-L-CP-vbRp3uPyGOgmCr51FU3&si=xoD1FW0asBcqh1DZ',
    price: '$50',
    coverImg: 'mariahcarey.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://mariahcarey.store/',
    youtube: 'https://www.youtube.com/channel/UCurpiDXSkcUbgdMwHNZkrCg',
    facebook: 'https://www.facebook.com/mariahcarey',
    instagram: 'https://www.instagram.com/mariahcarey',
    twitter: 'https://twitter.com/mariahcarey',
    spotify: 'https://open.spotify.com/artist/4iHNK0tOyZPYnBU7nGAgpQ',
    deezer: 'https://www.deezer.com/mx/artist/65',
    apple: 'https://music.apple.com/mx/artist/mariah-carey/91853',
    soundcloud: 'https://soundcloud.com/mariahcarey',
    tidal: 'https://listen.tidal.com/artist/518',
    wiki: 'https://es.wikipedia.org/wiki/Mariah_Carey',
    ticketmaster:
      'https://www.ticketmaster.com/mariah-carey-tickets/artist/767870',
    tiktok: 'https://www.tiktok.com/@mariahcarey?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSDIBI/mariah-carey',
  },
  {
    title: 'Beyonce',
    description:
      "Beyoncé Giselle Knowles-Carter (Houston, 4 de septiembre de 1981),​ conocida simplemente como Beyoncé, es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.En su ciudad natal se presentó a diversos concursos de canto y baile cuando era niña y saltó a la fama a finales de los años 1990 como vocalista principal del grupo femenino de R&B, Destiny's Child. Dirigido por su padre, Mathew Knowles, se convirtió en uno de los grupos femeninos con mayores ventas de la historia.",
    videourl:
      'https://youtube.com/playlist?list=PLHFeMk_LSwG5WMlOMrny0r3SQyFYx7W2M',
    price: '$50',
    coverImg: 'beyonce.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.beyonce.com/',
    youtube: 'https://www.youtube.com/channel/UCuHzBCaKmtaLcRAOoazhCPA',
    facebook: 'https://www.facebook.com/beyonce',
    instagram: 'https://www.instagram.com/beyonce/',
    twitter: 'https://twitter.com/Beyonce',
    spotify: 'https://open.spotify.com/artist/6vWDO969PvNqNYHIOW5v0m',
    deezer: 'https://www.deezer.com/mx/artist/145',
    apple: 'https://music.apple.com/mx/artist/beyonc%C3%A9/1419227',
    soundcloud: 'https://soundcloud.com/beyonce',
    tidal: 'https://listen.tidal.com/artist/1566',
    wiki: 'https://es.wikipedia.org/wiki/Beyonc%C3%A9',
    ticketmaster: 'https://www.ticketmaster.com/beyonce-tickets/artist/894191',
    tiktok: 'https://www.tiktok.com/@beyonce?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000T2D81C/beyonc%C3%A9',
  },
  {
    title: 'Alejandra Guzman',
    description:
      'Gabriela Alejandra Guzmán Pinal (Ciudad de México; 9 de febrero de 1968), es una cantante mexicana. Ha incursionado en la actuación de telenovelas, series y programas de televisión.Hija del cantante de rock and roll y actor venezolano Enrique Guzmán y de la actriz mexicana Silvia Pinal, es reconocida en Latinoamérica, Estados Unidos y Europa. Se estima que Alejandra Guzmán ha vendido más de treinta millones de discos desde su debut, lo que la convierte en una de las cantantes latinas con mayores ventas..',
    videourl: 'https://youtube.com/playlist?list=UULFuHEt7VkfYYY0_ucAuMJ3dg',
    price: 50,
    coverImg: 'alejandraguzman.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.aleguzman.com/',
    youtube: 'https://www.youtube.com/channel/UCuHEt7VkfYYY0_ucAuMJ3dg',
    facebook: 'https://www.facebook.com/laguzmanoficial',
    instagram: 'https://www.instagram.com/laguzmanmx/',
    twitter: 'https://twitter.com/Al3jandraGuzman',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/9033',
    apple: 'https://music.apple.com/mx/artist/alejandra-guzm%C3%A1n/248791',
    soundcloud: 'https://soundcloud.com/alejandraguzman-music',
    tidal: 'https://listen.tidal.com/artist/5463',
    wiki: 'https://es.wikipedia.org/wiki/Alejandra_Guzm%C3%A1n',
    ticketmaster:
      'https://www.ticketmaster.com./alejandra-guzman-tickets/artist/761876',
    tiktok: 'https://www.tiktok.com/@laguzmanoficial?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000YPV0T6/alejandra-guzm%C3%A1n',
  },
  {
    title: 'Thalia',
    description:
      'Ariadna Thalía Sodi Miranda (Ciudad de México, 26 de agosto de 1971),nota 1​ conocida como Thalía, es una cantante, actriz y empresaria mexicana.Inició su carrera musical a principios de los años 1980 como vocalista de un grupo infantil llamado Din-Din y en 1986 se integró a la banda Timbiriche, con la que grabó tres álbumes de estudio,3​ y de la que se separó en 1989.4​ Poco después viajó a Los Ángeles (California) para prepararse como solista.5​ Regresó a México en 1990 y publicó su primer álbum como solista titulado Thalía, bajo el sello discográfico Fonovisa, al que siguieron Mundo de cristal en 1991 y Love en 1992, los cuales lograron éxito en ventas en territorio mexicano',
    videourl:
      'https://youtube.com/playlist?list=PL1Xix0gugm8tJnlJklE9F78MVWWvB6mCS',
    price: 50,
    coverImg: 'thalia.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thalia.com/',
    youtube: 'https://www.youtube.com/channel/UCgyFSSvdNv3MC_atQPqM_XA',
    facebook: 'https://www.facebook.com/Thalia',
    instagram: 'https://www.instagram.com/thalia/',
    twitter: 'https://twitter.com/thalia',
    spotify: 'https://open.spotify.com/artist/23wEWD21D4TPYiJugoXmYb',
    deezer: 'https://www.deezer.com/mx/artist/710',
    apple: 'https://music.apple.com/mx/artist/thalia/88612122',
    soundcloud: 'https://soundcloud.com/thalia-54826',
    tidal: 'https://listen.tidal.com/artist/3505335',
    wiki: 'https://es.wikipedia.org/wiki/Thal%C3%ADa',
    ticketmaster: 'https://www.ticketmaster.com./thalia-tickets/artist/910999',
    tiktok: 'https://www.tiktok.com/@thalia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001JQEVBI/thalia',
  },
  {
    title: 'Paulina Rubio',
    description:
      'Paulina Susana Rubio Dosamantes1​ (Ciudad de México, 17 de junio de 1971),2​ ​conocida artísticamente como Paulina Rubio y apodada «La Chica Dorada», es una cantante, compositora, actriz, presentadora de televisión, modelo y empresaria mexicana.Paulina Rubio es hija de la actriz Susana Dosamantes, cuya proyección en el cine mexicano la influenció desde su infancia para perseguir la misma aspiración de convertirse en famosa. Logró el reconocimiento en la industria de la música hispana como miembro fundadora de la banda Timbiriche en la década de los años de 1980, destacándose como una de las principales integrantes de la agrupación. ',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lYa9h96c5vG2jb0GyfCDV6B2GKKyAFbBI',
    price: 50,
    coverImg: 'paulinarubio.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://paulinarubio.com/',
    youtube: 'https://www.youtube.com/channel/UCuxn1zFiLGD-T43TSk9YqSg',
    facebook: 'https://www.facebook.com/paulinarubio/',
    instagram: 'https://www.instagram.com/paulinarubio/',
    twitter: 'https://twitter.com/paulinarubio',
    spotify: 'https://open.spotify.com/artist/1d6dwipPrsFSJVmFTTdFSS',
    deezer: 'https://www.deezer.com/mx/artist/1464',
    apple: 'https://music.apple.com/mx/artist/paulina-rubio/264450',
    soundcloud: 'https://soundcloud.com/paulina-rubio-official',
    tidal: 'https://listen.tidal.com/artist/24900',
    wiki: 'https://es.wikipedia.org/wiki/Paulina_Rubio',
    ticketmaster:
      'https://www.ticketmaster.com./paulina-rubio-tickets/artist/759442',
    tiktok: 'https://www.tiktok.com/@thalia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKKI2E/paulina-rubio',
  },

  {
    title: 'Mary J Blige',
    description:
      'Mary Jane Blige (El Bronx, Nueva York, 11 de enero de 1971)​ es una actriz y cantante estadounidense de R&B contemporáneo, de soul y hip-hop, influida por Chaka Khan, Aretha Franklin y Anita Baker entre otras muchas figuras de la música soul.Nació en Nueva York y pasó parte de su infancia en Savannah, para en su juventud volver a Yonkers en su ciudad natal.3​ Cuando vivía en White Plains (Nueva York) se grabó en un karaoke cantando el tema de Anita Baker Caught Up in the Rapture. La grabación resultante fue enviada a Andre Harrel, gerente de Uptown Records, por el padrastro de Mary. Harrel se quedó impresionado por su voz y la contrató para que hiciera los coros a Father MC..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mL4V3aGUV7dbQ6an2fL0Q4DpjlYeN6OKI',
    price: '$50',
    coverImg: 'maryjblige.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.maryjblige.com/',
    youtube: 'https://www.youtube.com/channel/UCj5CgwX_iJEqJsqUJyYjg4A',
    facebook: 'https://www.facebook.com/maryjblige',
    instagram: 'https://www.instagram.com/therealmaryjblige/',
    twitter: 'https://twitter.com/maryjblige',
    spotify: 'https://open.spotify.com/artist/1XkoF8ryArs86LZvFOkbyr',
    deezer: 'https://www.deezer.com/mx/artist/152',
    apple: 'https://music.apple.com/mx/artist/mary-j-blige/1392280',
    soundcloud: 'https://soundcloud.com/officialmaryjblige',
    tidal: 'https://listen.tidal.com/artist/7036',
    wiki: 'https://es.wikipedia.org/wiki/Mary_J._Blige',
    ticketmaster:
      'https://www.ticketmaster.com/mary-j-blige-tickets/artist/767544',
    tiktok: 'https://www.tiktok.com/@maryjblige?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000RW2KGS/mary-j-blige',
  },
  {
    title: 'Whitney Houston',
    description:
      'Whitney Elizabeth Houston (Newark, Nueva Jersey, 9 de agosto de 1963-Beverly Hills, California, 11 de febrero de 2012) fue una cantante, compositora, productora discográfica, actriz, empresaria y modelo estadounidense. Apodada «la Voz», es una de las artistas musicales más vendidas de todos los tiempos, con más de doscientos veinte millones de discos vendidos en todo el mundo.2​3​ En 2023, Rolling Stone la nombró la segunda mejor cantante de todos los tiempos.4​ Houston influyó en muchos cantantes de música popular y era conocida por su voz poderosa y conmovedora, sus habilidades de improvisación vocal y el uso de técnicas de canto góspel en la música pop.5​6​ Tuvo once sencillos número uno en el Billboard Hot 100 y es la única artista que tiene siete sencillos número uno consecutivos en la lista. Houston logró una mayor popularidad al ingresar a la industria del cine.',

    videourl:
      'https://youtube.com/playlist?list=PLyxWCv_X0MiSCXJDwRIsvmRzpdUUJ_v9L&si=140dBMSP-CJx8PV2',
    price: '$50',
    coverImg: 'whitneyhouston.jpg',
    category: 'Baladas',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.whitneyhouston.com/',
    youtube: 'https://www.youtube.com/channel/UC7fzrpTArAqDHuB3Hbmd_CQ',
    facebook: 'https://www.facebook.com/WhitneyHouston',
    instagram: 'https://www.instagram.com/whitneyhouston/',
    twitter: 'https://twitter.com/RealWhitney',
    spotify:
      'https://open.spotify.com/artist/6XpaIBNiVzIetEPCWDvAFP?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/5284515',
    apple: 'https://music.apple.com/mx/artist/whitney-houston/13952',
    soundcloud: 'https://soundcloud.com/whitneyhouston',
    tidal: 'https://listen.tidal.com/artist/1728',
    wiki: 'https://es.wikipedia.org/wiki/Whitney_Houston',
    ticketmaster:
      'https://www.ticketmaster.com/the-greatest-love-of-all-a-tickets/artist/1870905',
    tiktok: 'https://www.tiktok.com/@whitneyhouston',
    napster: 'https://music.amazon.com.mx/artists/B000RSFQJU/whitney-houston',
  },
  {
    title: 'Natalia Lafourcade',
    description:
      'María Natalia Lafourcade Silva (Ciudad de México, 26 de febrero de 1984), conocida como Natalia Lafourcade, es una cantante,​ compositora,​actriz,​productora musical,​ arreglista,​ diseñadora​ y activista mexicana.En su trayectoria artística ha ganado numerosos reconocimientos nacionales e internacionales, entre ellos tres Premios Grammy,​ quince premios Grammy Latino,​ cinco premios MTV Video Music Awards Latinoamérica,​ entre otros.Natalia fue la primera persona educada musicalmente bajo el Método Macarsi desarrollado por su madre para ayudarla en la rehabilitación tras un accidente con un caballo que sufrió a los 6 años.​ Sus éxitos iniciales llegaron en la década de los 2000 con apariciones sonoras en películas como Amar te duele y sus primeros sencillos, además de su álbum debut, que llegaron a los primeros lugares de popularidad en México y son considerados como algunas de las canciones más influyentes de esa época.',

    videourl: 'https://youtube.com/playlist?list=PL5F92A5550F076B41',
    price: '$50',
    coverImg: 'natalialafourcade.jpg',
    category: 'Baladas',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.lafourcade.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCKw8oP0rF37jTEq_3R8O5Mg',
    facebook: 'https://www.facebook.com/NataliaLafourcadeMx',
    instagram: 'https://www.instagram.com/natalialafourcade/',
    twitter: 'https://twitter.com/lafourcade',
    spotify: 'https://open.spotify.com/artist/1hcdI2N1023RvSwLzTtdsp',
    deezer: 'https://www.deezer.com/mx/artist/6722',
    apple: 'https://music.apple.com/mx/artist/natalia-lafourcade/14937569',
    soundcloud: 'https://soundcloud.com/natalialafourcade',
    tidal: 'https://listen.tidal.com/artist/3618024',
    wiki: 'https://es.wikipedia.org/wiki/Natalia_Lafourcade',
    ticketmaster:
      'https://www.ticketmaster.com/natalia-lafourcade-tickets/artist/1492041',
    tiktok: 'https://www.tiktok.com/@natalialafourcadeoficial',
    napster:
      'https://music.amazon.com.mx/artists/B000WXQ8L0/natalia-lafourcade',
  },

  {
    title: 'Gloria Trevi',
    description:
      ' Gloria de los Ángeles Treviño Ruiz (Monterrey, Nuevo León, 15 de febrero de 1968), conocida artísticamente como Gloria Trevi, es una cantante, actriz, productora, compositora y empresaria mexicana.En 1985 se mudó a la Ciudad de México para comenzar su carrera artística. Allí conoció al productor y arreglista Sergio Andrade, quien formó el grupo musical "Boquitas Pintadas". Cuatro años más tarde, de la mano de Andrade, publicó su álbum debut ...Qué hago aquí? , distribuido por BMG Ariola. En los 90, debutó en el cine con tres películas: Pelo suelto (1991), Zapatos viejos (1993) y Una papa sin catsup (1995). Terminó la década con cinco álbumes que sumaban casi 5 millones de ventas.',
    videourl:
      'https://youtube.com/playlist?list=PLszZ23pLzkUvIXPmh_x5TGeVpTazENODe',
    price: '$50',
    coverImg: 'gloriatrevi.jpg',
    category: 'Regional Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://gloriatrevi.lnk.to/IslaDivinaAlbum!YTD',
    youtube: 'https://www.youtube.com/channel/UCNZazBoobtvziOGR65QQVog',
    facebook: 'https://www.facebook.com/GloriaTreviOficial',
    instagram: 'https://www.instagram.com/gloriatrevi/',
    twitter: 'https://twitter.com/GloriaTrevi',
    spotify: 'https://open.spotify.com/artist/1Db5GsIoVWYktPoD2nnPZZ',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/mx/artist/gloria-trevi/6711586',
    soundcloud: 'https://soundcloud.com/gloria-trevi-official',
    tidal: 'https://listen.tidal.com/artist/3752902',
    wiki: 'https://es.wikipedia.org/wiki/Gloria_Trevi',
    ticketmaster:
      'https://www.ticketmaster.com/gloria-trevi-tickets/artist/956252',
    tiktok: 'https://www.tiktok.com/@gloriatrevioficial',
    napster: 'https://music.amazon.com.mx/artists/B00130DI78/gloria-trevi',
  },

  {
    title: 'Shakira',
    description:
      'Shakira Isabel Mebarak Ripoll (Barranquilla, Atlántico, Colombia; 2 de febrero de 1977), conocida simplemente como Shakira, es una cantautora, bailarina, actriz, embajadora de buena voluntad de UNICEF y empresaria colombiana.',
    videourl: 'https://youtube.com/playlist?list=PL4324C6BD2E9BA7F8',
    price: 50,
    coverImg: 'Shakira.jpg',
    category: 'Pop',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.shakira.com/',
    youtube: 'https://www.youtube.com/results?search_query=Shakira',
    facebook: 'https://www.facebook.com/shakira',
    instagram: 'https://www.instagram.com/shakira/',
    twitter: 'https://www.facebook.com/shakira',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/160',
    apple: 'https://music.apple.com/mx/artist/shakira/889327',
    soundcloud: 'https://soundcloud.com/shakira',
    tidal: 'https://listen.tidal.com/artist/1087',
    wiki: 'https://es.wikipedia.org/wiki/Shakira',
    ticketmaster: 'https://www.ticketmaster.com./shakira-tickets/artist/779049',
    tiktok: 'https://www.tiktok.com/@shakira?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKEIZM/shakira',
  },
  {
    title: 'Karol G',
    description:
      'Carolina Giraldo Navarro Medellín, Colombia; 14 de febrero de 1991, conocida artísticamente como Karol G, es una cantante y compositora colombiana de reguetón, pop y trap latino.Se lanzó internacionalmente con la canción «301» junto con el cantante Reykon el líder en 2012, posicionado en el top de varias estaciones de radios y canales de televisión,3​ y le permitió realizar conciertos en países como Aruba, Colombia, Ecuador y Estados Unidos.4​ Obtuvo mayor reconocimiento en 2013, cuando lanzó la canción «Amor de dos» junto con Nicky Jam.5​ En 2016, luego de que lanzó varios sencillos, firmó un contrato con la discográfica Universal Music Latin Entertainment, y hubo un ascenso en su carrera..',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'karolG.jpeg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.karolgmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCZuPJZ2kGFdlbQu1qotZaHw',
    facebook: 'https://www.facebook.com/KarolGOficial',
    instagram: 'https://www.instagram.com/karolg/',
    twitter: 'https://twitter.com/KarolGOnStats',
    spotify: 'https://open.spotify.com/artist/790FomKkXshlbRYZFtlgla',
    deezer: 'https://www.deezer.com/mx/artist/5297021',
    apple: 'https://music.apple.com/us/artist/karol-g/290814601',
    soundcloud: 'https://soundcloud.com/karolgofficial',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://es.wikipedia.org/wiki/Karol_G',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Karol+G',
    tiktok: 'https://www.tiktok.com/@karolg',
    napster: 'https://music.amazon.com.mx/artists/B00FN5IY46',
  },
  {
    title: 'Becky G',
    description:
      'Rebbeca Marie Gómez1​ (Inglewood, California; 2 de marzo de 1997),​ conocida artísticamente como Becky G, es una cantante y actriz estadounidense de origen mexicano. Obtuvo reconocimiento por primera vez en 2011 cuando comenzó a publicar videos de ella misma interpretando canciones populares en YouTube. Uno de sus videos llamó la atención del productor Dr. Luke, quien posteriormente le ofreció un contrato discográfico conjunto con Kemosabe Records y RCA Records. Mientras trabajaba en su debut, Gómez colaboró ​​​​con los artistas will.i.am, Cody Simpson y Cher Lloyd. Su sencillo debut oficial, «Becky from the Block» (2013), recibió una recepción positiva tras su lanzamiento. Lanzó su EP debut, Play It Again (2013), más tarde ese mismo año. Su segundo sencillo, Cant Get Enough» (2014), contó con la voz invitada de Pitbull y llegó a lo más alto de la lista Latin Rhythm Airplay en los Estados Unidos..',
    videourl:
      'https://youtube.com/playlist?list=PLAE2PW67Xm8biSTHd94VEUmvu7pbAnRDe',
    price: '$50',
    coverImg: 'beckyg.jpg',
    category: 'Pop Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.iambeckyg.com/',
    youtube: 'https://www.youtube.com/channel/UCgtNC51EUSgcZ6kKyVoPxKA',
    facebook: 'https://www.facebook.com/iambeckyg',
    instagram: 'https://www.instagram.com/iambeckyg/',
    twitter: 'https://twitter.com/iambeckyg',
    spotify:
      'https://open.spotify.com/artist/4obzFoKoKRHIphyHzJ35G3?si=3aYrWCo9RIemtKGtcwyDWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/becky-g/550411604',
    soundcloud: 'https://soundcloud.com/iambeckyg',
    tidal: 'https://listen.tidal.com/artist/4903321',
    wiki: 'https://es.wikipedia.org/wiki/Becky_G',
    ticketmaster:
      'https://www.ticketmaster.com.mx/becky-g-boletos/artist/1896954',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B0088V0L5W/becky-g',
  },
  {
    title: 'Yuridia',
    description:
      'Yuridia Francisca Gaxiola Flores (Hermosillo, 4 de octubre de 1986), conocida como Yuridia, es una cantante y compositora mexicana. Saltó a la fama en 2005 como participante de la cuarta generación del reality show mexicano La Academia, donde obtuvo el segundo lugar.Cuenta con seis álbumes de estudio: La voz de un ángel, (2005), Habla el corazón (2006), Entre mariposas (2007), Nada es color de rosa (2009), Para mí (2011) y 6. (2015) y un álbum en vivo Primera fila: Yuridia(2017). Es una de las intérpretes de origen mexicano con gran logro comercial en la industria musical, registrando más de 2 millones de álbumes vendidos...',
    videourl:
      'https://youtube.com/playlist?list=PLyzN_J2WwjznyWCf3y9LQrG1Kz132CBFk',
    price: '$50',
    coverImg: 'Yuridia.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://yuritaflowers.com/',
    youtube: 'https://www.youtube.com/channel/UCrkMQGSWGoNokFc0vKfciKA',
    facebook: 'https://www.facebook.com/yuritaflowers',
    instagram: 'https://www.instagram.com/yuritaflowers/',
    twitter: 'https://twitter.com/yuritaflowers',
    spotify:
      'https://open.spotify.com/artist/5B8ApeENp4bE4EE3LI8jK2?autoplay=true',
    deezer: 'https://www.deezer.com/en/artist/274393',
    apple: 'https://music.apple.com/mx/artist/yuridia/119903555',
    soundcloud: 'https://soundcloud.com/yuridiamusic',
    tidal: 'https://listen.tidal.com/artist/3633725',
    wiki: 'https://es.wikipedia.org/wiki/Yuridia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B00136B3EC/yuridia',
  },
  {
    title: 'Belinda',
    description:
      'Belinda Peregrín Schüll (Madrid; 15 de agosto de 1989​), conocida monónimamente como Belinda, es una cantante y actriz hispanomexicana.Inició su carrera como actriz infantil cuando tenía apenas diez años de edad. En 2002, firmó con BMG y dos años más tarde lanzó su álbum debut, Belinda. Durante su primera década en la industria pop mexicana, Belinda se convirtió en una figura prominente de la música pop latina y de la cultura popular, con una vida personal muy publicitada, lo que la llevó a ser considerada «la princesa del pop latino».Canciones como «Boba niña nice» y «Ángel» se convirtieron en éxitos internacionales. En esta época colaboró con el grupo Moderatto para el tema «Muriendo lento». ',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mdDHjusH-QjBJqHRXdDPiOwwiCVlkPKXs',
    price: 50,
    coverImg: 'belinda.jpg',
    category: 'Pop',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://belinda.com/',
    youtube: 'https://www.youtube.com/channel/UCUi-2UQydc8Ws5NgsnwkhFQ',
    facebook: 'https://www.facebook.com/belindapop',
    instagram: 'https://www.instagram.com/belindapop/',
    twitter: 'https://twitter.com/belindapop',
    spotify: 'https://open.spotify.com/artist/5LeiVcEnsZcwc133TUhJNW',
    deezer: 'https://www.deezer.com/mx/artist/6329',
    apple: 'https://music.apple.com/mx/artist/belinda/6489626',
    soundcloud: 'https://soundcloud.com/belinda-official',
    tidal: 'https://listen.tidal.com/artist/56270',
    wiki: 'https://es.wikipedia.org/wiki/Belinda_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com.mx/belinda-tickets/artist/1170935',
    tiktok: 'https://www.tiktok.com/@belindatok?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000T17X9Q/belinda',
  },
  {
    title: 'Angela Aguilar',
    description:
      'Ángela Aguilar Álvarez (Los Ángeles, California; 8 de octubre de 2003), es una cantante mexicana-estadounidense de música regional mexicana. Obtuvo un notable reconocimiento después de interpretar La Llorona en la 19.ª Entrega Anual de los Premios Grammy Latinos en 2018. Es nieta de El Charro de México, Antonio Aguilar.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kSBDJlpXRi2Cy2v5fYI8ag4M1ku2STWAE',
    price: '$50',
    coverImg: 'AngelaAguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.angelaaguilaroficial.com/',
    youtube: 'https://www.youtube.com/channel/UCugX5B6n1jtQq2ihzhRk-Ag',
    facebook: 'https://www.facebook.com/AngelaAguilarOficial/',
    instagram: 'https://www.instagram.com/angela_aguilar_/?hl=en',
    twitter: 'https://twitter.com/AngelaAguilar__',
    spotify: 'https://open.spotify.com/artist/3abT87tqQ4Q5PA5nw6CYyH',
    deezer: 'https://www.deezer.com/mx/artist/4028765',
    apple: 'https://music.apple.com/mx/artist/%C3%A1ngela-aguilar/565880444',
    soundcloud: 'https://soundcloud.com/angelaaguilarofficial',
    tidal: 'https://listen.tidal.com/artist/4734467',
    wiki: 'https://en.wikipedia.org/wiki/%C3%81ngela_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/angela-aguilar-tickets/artist/2584974',

    tiktok: 'https://www.tiktok.com/@angelaaguilar676',
    napster: 'https://music.amazon.com.mx/artists/B009IAAD46/angela-aguilar',
  },
  {
    title: 'Mon LaFerte',
    description:
      'Norma Monserrat Bustamante Laferte (Viña del Mar; 2 de mayo de 1983), conocida como Mon Laferte, es una cantautora chilenomexicana.1​ Toca diferentes instrumentos y se caracteriza por la confluencia de numerosas formas y géneros como la balada, el pop, el heavy metal —principalmente a raíz de su actividad como cantante entre 2012 y 2014 de las bandas Mystica Girls y Abaddon—,7​ el bolero, el vals, la cumbia, el reggae o el ska, entre otros.8Ha sido la artista chilena con más nominaciones en una sola edición de los Grammy Latinos (cinco en 2017),9​ así como la primera en conseguir más de estas en los mencionados premios (quince en seis ocasiones).9​10​11​ Mon ha vendido más de 1,5 millones de grabaciones en todo el mundo, entre álbumes y sencillos, lo que la convierte en la artista chilena con más ventas de la era digital.',

    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nQQXiY63k7z5AIcVXsvMnEVoWPBPRB4Js',
    price: '$50',
    coverImg: 'MonLaFerte.jpg',
    category: 'Baladas',
    location: 'Chile',
    locationImg: 'chileflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.cmtv.com.ar/biografia/show.php?bnid=2474&banda=Mon_Laferte',
    youtube: 'https://www.youtube.com/channel/UCn51E6eKnnLklWOcdFT3w5A',
    facebook: 'https://www.facebook.com/monlaferte/',
    instagram: 'https://www.instagram.com/monlaferte/',
    twitter: 'https://twitter.com/monlaferte',
    spotify:
      'https://open.spotify.com/artist/4boI7bJtmB1L3b1cuL75Zr?si=DMp24bVrT361wHaANVSQhg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/5284515',
    apple: 'https://music.apple.com/mx/artist/mon-laferte/447436990',
    soundcloud: 'https://soundcloud.com/monlaferte',
    tidal: 'https://listen.tidal.com/artist/5635583',
    wiki: 'https://es.wikipedia.org/wiki/Mon_Laferte',
    ticketmaster:
      'https://www.ticketmaster.com/mon-laferte-tickets/artist/2144574',
    tiktok: 'https://www.tiktok.com/@mon.laferte',
    napster: 'https://music.amazon.com.mx/artists/B0059FCKVS/mon-laferte',
  },

  {
    title: 'Julieta Venegas',
    description:
      'Julieta Venegas Percevault9​(Long Beach, California;10​11​ 24 de noviembre de 1970) es una cantante, compositora, multinstrumentista, productora, actriz y activista mexicana catalogada como una «leyenda» de la música en español. Es una de las artistas más reconocidas a nivel mundial.Fue integrante de la banda mexicana de ska y reggae Tijuana No!, en la que compuso la canción más exitosa de la agrupación: «Pobre de ti», junto a Álex Zúñiga. En 1996 comenzó su carrera como solista cuando viajó a la Ciudad de México apoyada por la banda Café Tacvba.19​ Consiguió un contrato con el sello discográfico BMG/Ariola, lanzando su álbum debut Aquí (1997) al igual que su siguiente producción Bueninvento (2000) discos producidos por Gustavo Santaolalla, los cuales fueron alabados por los críticos y propagándose en el público del rock en México, Centroamérica y América del Sur.',
    videourl: 'https://youtube.com/playlist?list=PL9783B3ED0003A1B3',
    price: '$50',
    coverImg: 'julietavenegas.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.julietavenegas.net/',
    youtube: 'https://www.youtube.com/channel/UCwWwGhSVOjbhZSBh-Wa3xTw',
    facebook: 'https://www.facebook.com/julietavenegas.oficial',
    instagram: 'https://www.instagram.com/julietavenegasp/',
    twitter: 'https://twitter.com/julietav',
    spotify: 'https://open.spotify.com/artist/2QWIScpFDNxmS6ZEMIUvgm',
    deezer: 'https://www.deezer.com/mx/artist/4477',
    apple: 'https://music.apple.com/mx/artist/julieta-venegas/302960',
    soundcloud: 'https://soundcloud.com/julietav',
    tidal: 'https://listen.tidal.com/artist/4322',
    wiki: 'https://es.wikipedia.org/wiki/Julieta_Venegas',
    ticketmaster:
      'https://www.ticketmaster.com/julieta-venegas-tickets/artist/821476',
    tiktok: 'https://www.tiktok.com/@julietavenegasoficial',
    napster: 'https://music.amazon.com.mx/artists/B000QJQM0C/julieta-venegas',
  },
  {
    title: 'Alejandra Guzman',
    description:
      'Gabriela Alejandra Guzmán Pinal (Ciudad de México; 9 de febrero de 1968), es una cantante mexicana. Ha incursionado en la actuación de telenovelas, series y programas de televisión.Hija del cantante de rock and roll y actor venezolano Enrique Guzmán y de la actriz mexicana Silvia Pinal, es reconocida en Latinoamérica, Estados Unidos y Europa. Se estima que Alejandra Guzmán ha vendido más de treinta millones de discos desde su debut, lo que la convierte en una de las cantantes latinas con mayores ventas..',
    videourl: 'https://youtube.com/playlist?list=UULFuHEt7VkfYYY0_ucAuMJ3dg',
    price: 50,
    coverImg: 'alejandraguzman.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.aleguzman.com/',
    youtube: 'https://www.youtube.com/channel/UCuHEt7VkfYYY0_ucAuMJ3dg',
    facebook: 'https://www.facebook.com/laguzmanoficial',
    instagram: 'https://www.instagram.com/laguzmanmx/',
    twitter: 'https://twitter.com/Al3jandraGuzman',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/9033',
    apple: 'https://music.apple.com/mx/artist/alejandra-guzm%C3%A1n/248791',
    soundcloud: 'https://soundcloud.com/alejandraguzman-music',
    tidal: 'https://listen.tidal.com/artist/5463',
    wiki: 'https://es.wikipedia.org/wiki/Alejandra_Guzm%C3%A1n',
    ticketmaster:
      'https://www.ticketmaster.com./alejandra-guzman-tickets/artist/761876',
    tiktok: 'https://www.tiktok.com/@laguzmanoficial?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000YPV0T6/alejandra-guzm%C3%A1n',
  },
  {
    title: 'Danna Paola',
    description:
      'Danna Paola Rivera Munguía (Ciudad de México, 23 de junio de 1995) es una cantante, actriz, modelo y compositora mexicana. Ha sido ganadora de diferentes premios, entre los que se encuentran 9 Kids Choice Awards, 4 MTV Millennial Awards, 4 MTV Italian Music Awards, 1 MTV Europe Music Awards y 2 premios de la Sociedad de Autores y Compositores de México por «Oye Pablo» y «Sodio». Además fue nominada al Grammy Latino por su sexto álbum de estudio K.O. en 2021..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mGnO3i2McuE6K6O4qSKx4F9shuqnG0bKg',
    price: '$50',
    coverImg: 'dannapaola.png',
    category: 'Bachatta',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://universalmusicmexico.lnk.to/TourXT4S1S',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/DannaPaola',
    instagram: 'https://www.instagram.com/dannapaola/',
    twitter: 'https://twitter.com/dannapaola',
    spotify: 'https://open.spotify.com/artist/5xSx2FM8mQnrfgM1QsHniB',
    deezer: 'https://www.deezer.com/mx/artist/318108',
    apple: 'https://music.apple.com/mx/artist/danna-paola/23148284',
    soundcloud: 'https://soundcloud.com/danna-paola-official',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://en.wikipedia.org/wiki/Danna_Paola',
    ticketmaster:
      'https://www.ticketmaster.com.mx/danna-paola-boletos/artist/2581279',
    tiktok: 'https://www.tiktok.com/@dannapaola?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000V65TKE/danna-paola',
  },
  {
    title: 'Alicia Keys',
    description:
      'Alicia Augello Cook (Nueva York, 25 de enero de 1981) conocida como Alicia Keys, es una cantante compositora, productora musical y actriz estadounidense. Ha vendido más de 75 millones de discos a nivel mundial y ha ganado numerosos premios, incluyendo 16 Grammys, 17 Premios Billboard y tres American Music Awards. Su álbum debut Songs in A Minor (2001) ganó cinco Grammys.',
    videourl:
      'https://youtube.com/playlist?list=PL9_UKVdymCPeiMreTKiHFcKIoXA9_moVN',
    price: '$50',
    coverImg: 'aliciakeys.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.aliciakeys.com/',
    youtube: 'https://www.youtube.com/channel/UCK5X3f0fxO4YnVKVZP8p6hg',
    facebook: 'https://www.facebook.com/aliciakeys/',
    instagram: 'https://www.instagram.com/aliciakeys/',
    twitter: 'https://twitter.com/aliciakeys',
    spotify: 'https://open.spotify.com/artist/3DiDSECUqqY1AuBP8qtaIa',
    deezer: 'https://www.deezer.com/mx/artist/228',
    apple: 'https://music.apple.com/mx/artist/alicia-keys/316069',
    soundcloud: 'https://soundcloud.com/aliciakeys',
    tidal: 'https://listen.tidal.com/artist/1552',
    wiki: 'https://es.wikipedia.org/wiki/Alicia_Keys',
    ticketmaster:
      'https://www.ticketmaster.com/alicia-keys-tickets/artist/807171',
    tiktok: 'https://www.tiktok.com/@aliciakeys',
    napster: 'https://music.amazon.com.mx/artists/B000RHRHPM/alicia-keys',
  },
  {
    title: 'Dido',
    description:
      'Florian Cloud de Bounevialle O Malley Armstrong (nacida el 25 de diciembre de 1971), conocida profesionalmente como Dido (/ˈdaɪdoʊ/ DY-doh), es una cantante y compositora inglesa. Alcanzó el éxito internacional con su álbum debut No Angel (1999). Los sencillos exitosos del álbum incluyen "Here with Me" y "Thank You". Vendió más de 21 millones de copias en todo el mundo, y le valió varios premios, incluidos dos Brit Awards; adicionalmente, ganó el premio al Mejor Álbum Británico y a la Mejor Intérprete Femenina Británica, así como el premio MTV Europe Music Award al Mejor Nuevo Artista. El primer verso de "Thank You" está sampleado en "Stan", una colaboración aclamada con el rapero estadounidense Eminem. Su próximo álbum, Life for Rent (2003), continuó su éxito con los sencillos "White Flag" y "Life for Rent".',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k_kl9OOsob6Flw1jhuYyy5cxm5kUEwDaY&si=CDEfFO2Xjy0sohu7',
    price: '$50',
    coverImg: 'dido.jpg',
    category: 'Rock-Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.didomusic.com/',
    youtube: 'https://www.youtube.com/user/Dido',
    facebook: 'https://www.facebook.com/dido/',
    instagram: 'https://www.instagram.com/dido',
    twitter: 'https://twitter.com/didoofficial',
    spotify: 'https://open.spotify.com/artist/2mpeljBig2IXLXRAFO9AAs',
    deezer: 'https://www.deezer.com/mx/artist/143',
    apple: 'https://music.apple.com/mx/artist/dido/20646',
    soundcloud: 'https://soundcloud.com/dido',
    tidal: 'https://listen.tidal.com/artist/1736',
    wiki: 'https://en.wikipedia.org/wiki/Dido_(singer)',
    ticketmaster: 'https://www.ticketmaster.com/dido-tickets/artist/768449',
    tiktok: 'https://www.tiktok.com/search?q=Dido&t=1693502040824',
    napster: 'https://music.amazon.com.mx/artists/B000VJUKO6/dido',
  },
  {
    title: 'Dua Lipa',
    description:
      'Dua Lipa (Londres, 22 de agosto de 1995) es una cantante, compositora, modelo y actriz británica de origen albanokosovar. Después de trabajar como modelo, firmó con Warner Bros. Records en 2014 y lanzó su álbum debut homónimo en 2017. El álbum alcanzó el número 3 en la UK Albums Chart y lanzó ocho sencillos, incluidos «Be the One» e «IDGAF», y el sencillo número 1 del Reino Unido «New Rules», que también llegó al puesto número 6 en Estados Unidos. En 2018, ganó dos premios Brit a la artista solista femenina británica y artista revelación británica.',
    videourl:
      'https://youtube.com/playlist?list=PLNrotoZZ8BaoXT_LJuwEyESQlctWNDCwD&si=yyEOmZRxEigJ3bgw',
    price: '$50',
    coverImg: 'dualipa.jpg',
    category: 'Pop Americano',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.dualipa.com/?frontpage=true',
    youtube: 'https://www.youtube.com/channel/UC-J-KZfRV8c13fOCkhXdLiQ',
    facebook: 'https://www.facebook.com/DuaLipa',
    instagram: 'https://www.instagram.com/dualipa/',
    twitter: 'https://twitter.com/DUALIPA',
    spotify: 'https://open.spotify.com/artist/6M2wZ9GZgrQXHCFfjv46we',
    deezer: 'https://www.deezer.com/mx/artist/8706544',
    apple: 'https://music.apple.com/mx/artist/dua-lipa/1031397873',
    soundcloud: 'https://soundcloud.com/dualipa',
    tidal: 'https://listen.tidal.com/artist/7162333',
    wiki: 'https://es.wikipedia.org/wiki/Dua_Lipa',
    ticketmaster:
      'https://www.ticketmaster.com/dua-lipa-tickets/artist/2179476',
    tiktok: 'https://www.tiktok.com/@dualipaofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0143OIF9G/dua-lipa',
  },
  {
    title: 'Rihanna',
    description:
      'Robyn Rihanna Fenty, conocida simplemente como Rihanna, es una cantante, empresaria, diseñadora de moda, actriz, diplomática, escritora y bailarina barbadense. Es conocida por fusionar algunos géneros caribeños con música pop y por reinventar su imagen a través de los años. Su impacto en la cultura popular la ha llevado a convertirse en un icono de la música y de la moda, por lo que se refieren a ella como la «Princesa del R&B» y «Reina de la Moda». Rihanna es además considerada la artista musical más influyente y exitosa del siglo XXI.',
    videourl:
      'https://youtube.com/playlist?list=PL-8M5stZkYjoIoESZb5jRfOs1mWHS4LPH',
    price: '$50',
    coverImg: 'Rihanna.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.rihannanow.com/',
    youtube: 'https://www.youtube.com/channel/UCcgqSM4YEo5vVQpqwN-MaNw',
    facebook: 'https://www.facebook.com/rihannaitalia',
    instagram: 'https://www.instagram.com/BadGalRiRi/',
    twitter: 'https://twitter.com/rihanna',
    spotify: 'https://open.spotify.com/artist/5pKCCKE2ajJHZ9KAiaK11H',
    deezer: 'https://www.deezer.com/mx/artist/564',
    apple: 'https://music.apple.com/mx/artist/rihanna/63346553',
    soundcloud: 'https://soundcloud.com/rihanna',
    tidal: 'https://listen.tidal.com/artist/10665',
    wiki: 'https://es.wikipedia.org/wiki/Rihanna',
    ticketmaster: 'https://www.ticketmaster.com/rihanna-tickets/artist/1013826',
    tiktok: 'https://www.tiktok.com/@rihanna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000TDU6TS/rihanna',
  },

  {
    title: 'Lady Gaga',
    description:
      'Stefani Joanne Angelina Germanotta, más conocida por su nombre artístico Lady Gaga, es una cantante, compositora, productora, bailarina, actriz, activista y diseñadora de moda estadounidense. Nacida y criada en la ciudad de Nueva York, estudió en la escuela Convent of the Sacred Heart y asistió por un tiempo breve a la Tisch School of the Arts, perteneciente a la Universidad de Nueva York, hasta que abandonó sus estudios para enfocarse en su carrera musical. Fue así como irrumpió en la escena del rock en el Lower East Side de Manhattan y firmó un contrato con Streamline Records hacia fines de 2007. En la época en que trabajaba como compositora para dicha discográfica, su voz llamó la atención del artista Akon, quien la hizo firmar un contrato con Kon Live Distribution',
    videourl:
      'https://youtube.com/playlist?list=PLf5BnbCRqFj5c1wwBIIOU5ir7VVAgiAEO',
    price: '$50',
    coverImg: 'LadyGaga.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ladygaga.com/',
    youtube: 'https://www.youtube.com/channel/UCNL1ZadSjHpjm4q9j2sVtOA',
    facebook: 'https://www.facebook.com/ladygaga',
    instagram: 'https://www.instagram.com/ladygaga/',
    twitter: 'https://twitter.com/ladygaga',
    spotify: 'https://open.spotify.com/artist/1HY2Jd0NmPuamShAr6KMms',
    deezer: 'https://www.deezer.com/mx/artist/75491',
    apple: 'https://music.apple.com/mx/artist/lady-gaga/277293880',
    soundcloud: 'https://soundcloud.com/ladygaga',
    tidal: 'https://listen.tidal.com/artist/3534754',
    wiki: 'https://es.wikipedia.org/wiki/Lady_Gaga',
    ticketmaster:
      'https://www.ticketmaster.com/lady-gaga-tickets/artist/1249444',
    tiktok: 'https://www.tiktok.com/@ladygaga?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0016SK9OW/lady-gaga',
  },
  {
    title: 'Kylie Minogue',
    description:
      'Kylie Ann Minogue (/ˈkaɪliː mɨˈnoʊɡ/; Melbourne, 28 de mayo de 1968) es una cantante, compositora y actriz australiana. Es ampliamente reconocida por su octavo álbum de estudio, Fever (2001), y su exitoso sencillo «Cant Get You Out of My Head», conocida como la canción más sonada del año, la cual le valió dos distinciones en los Premios Brit en 2002.​ Posteriormente, en 2023, escala de nuevo a los conteos musicales con «Padam Padam». Con un total de ochenta millones de producciones musicales vendidas en el mundo a lo largo de su carrera,​ Minogue es una de los artistas australianos de mayores ventas..',
    videourl:
      'https://youtube.com/playlist?list=PLzOGAM1vNU1qgpBKtjZwpGJu1FWBQtAVP&si=XoEAvHQS7eosFqlZ',
    price: '$50',
    coverImg: 'kylieminogue.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://music.kylie.com/',
    youtube: 'https://www.youtube.com/@kylieminogue',
    facebook: 'https://www.facebook.com/kylieminogue',
    instagram: 'https://www.instagram.com/kylieminogue',
    twitter:
      'https://x.com/kylieminogue',
    spotify: 'https://open.spotify.com/artist/4RVnAU35WRWra6OZ3CbbMA',
    deezer: 'https://www.deezer.com/mx/artist/479',
    apple: 'https://music.apple.com/mx/artist/kylie-minogue/465031',
    soundcloud: 'https://soundcloud.com/kylie-minogue-official',
    tidal: 'https://listen.tidal.com/artist/2573',
    wiki: 'https://es.wikipedia.org/wiki/Kylie_Minogue',
    ticketmaster:
      'https://www.ticketmaster.com/kylie-minogue-tickets/artist/735661',
    tiktok: 'https://www.tiktok.com/@kylieminogue?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSDP0C/kylie-minogue',
  },
  {
    title: 'Carrie Underwood',
    description:
      'Carrie Marie Underwood (Muskogee, Oklahoma; 10 de marzo de 1983)2​ es una cantante de música country y actriz estadounidense. Underwood saltó a la fama tras ganar la 4° temporada de American Idol en 2005. Su álbum debut, Some Hearts, fue lanzado en 2005 y cuenta con los éxitos "Jesus, Take The Wheel", "Before He Cheats" y "Wasted". Es el álbum debut de un artista country que más rápido se ha vendido en la historia de Nielsen SoundScan.3​ También se convirtió en el álbum debut más vendido por una artista country en la historia de RIAA, también el álbum debut más vendido por una ex-American Idol.',
    videourl:
      'https://youtube.com/playlist?list=PLzOGAM1vNU1qgpBKtjZwpGJu1FWBQtAVP&si=XoEAvHQS7eosFqlZ',
    price: '$50',
    coverImg: 'carrieunderwood.jpg',
    category: 'Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.carrieunderwoodofficial.com/',
    youtube: 'https://www.youtube.com/@carrieunderwood',
    facebook: 'https://www.facebook.com/carrieunderwood',
    instagram: 'https://www.instagram.com/carrieunderwood/',
    twitter:
      'https://x.com/carrieunderwood',
    spotify: 'https://open.spotify.com/artist/4xFUf1FHVy696Q1JQZMTRj',
    deezer: 'https://www.deezer.com/mx/artist/4388',
    apple: 'https://music.apple.com/mx/artist/carrie-underwood/63399334',
    soundcloud: 'https://soundcloud.com/carrieunderwoodofficial',
    tidal: 'https://listen.tidal.com/artist/27941',
    wiki: 'https://es.wikipedia.org/wiki/Carrie_Underwood',
    ticketmaster:
      'https://www.ticketmaster.com/kylie-minogue-tickets/artist/735661',
    tiktok: 'https://www.tiktok.com/@carrieunderwood?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00137J9VK/carrie-underwood',
  },
  {
    title: 'Demi Lovato',
    description:
      'Demetria Devonne Lovato, cuyo nombre artístico es Demi Lovato, es una celebridad estadounidense profesional en el canto, la actuación y la composición. Fundó junto con Nick Jonas el sello discográfico Safehouse Records..',
    videourl:
      'https://youtube.com/playlist?list=PLy4Kg0J0TkebIr4dkt4rEgDns_ZwP0rzh',
    price: '$50',
    coverImg: 'demiLovato.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.demilovato.com/',
    youtube: 'https://www.youtube.com/channel/UCZkURf9tDolFOeuw_4RD7XQ',
    facebook: 'https://www.facebook.com/DemiLovato',
    instagram: 'https://www.instagram.com/ddlovato/',
    twitter:
      'https://twitter.com/ddlovato?fbclid=IwAR0O4EFAwe-J9akxjfltKN7XRDEwy5wAi1_pjdm_cbFTQCY4KB-twhabAms',
    spotify: 'https://open.spotify.com/artist/6S2OmqARrzebs0tKUEyXyp',
    deezer: 'https://www.deezer.com/mx/artist/193875',
    apple: 'https://music.apple.com/mx/artist/demi-lovato/280215821',
    soundcloud: 'https://soundcloud.com/ddlovato',
    tidal: 'https://listen.tidal.com/artist/3544816',
    wiki: 'https://es.wikipedia.org/wiki/Demi_Lovato',
    ticketmaster:
      'https://www.ticketmaster.com/demi-lovato-tickets/artist/1224909',
    tiktok: 'https://www.tiktok.com/@ddlovato?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001940DS0/demi-lovato',
  },
  {
    title: 'Adele',
    description:
      'Adele Laurie Blue Adkins (Londres, 5 de mayo de 1988), conocida simplemente como Adele, es una cantautora y multinstrumentista británica. Es una de las artistas musicales con mayores ventas del mundo, con más de 120 millones de ventas entre discos y sencillos.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_n5Ls1CYg63QxBxeuJf4ICzSY-kvAMLQu8',
    price: '$50',
    coverImg: 'adele.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    merchsiteurl: 'https://shop.adele.com/',
    websiteurl: 'https://www.adele.com/',
    youtube: 'https://www.youtube.com/channel/UCsRM0YB_dabtEPGPTKo-gcw',
    facebook: 'https://www.facebook.com/adele?fref=ts',
    instagram: 'https://www.instagram.com/adele/',
    twitter: 'https://twitter.com/Adele',
    spotify: 'https://open.spotify.com/artist/4dpARuHxo51G3z768sgnrY',
    deezer: 'https://www.deezer.com/mx/artist/75798',
    apple: 'https://music.apple.com/mx/artist/adele/262836961',
    soundcloud: 'https://soundcloud.com/adelemusic',
    tidal: 'https://listen.tidal.com/artist/3521920',
    wiki: 'https://es.wikipedia.org/wiki/Adele',
    ticketmaster: 'https://www.ticketmaster.com/adele-tickets/artist/1159272',
    tiktok: 'https://www.tiktok.com/@adele.offi?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0014DEFTE/adele',
  },
  {
    title: 'Avril Lavigne',
    description:
      'Avril Ramona Lavigne es una cantante, compositora, diseñadora de moda, productora musical, actriz y filántropa franco-canadiense. Algunos críticos de revistas como Billboard la llaman la princesa del pop punk. Comenzó su carrera musical en diciembre de 2001, cuando después de una presentación en una feria country, despertó el interés del productor L.A. Reid y firmó para Arista Records.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lXBMcqqDcHMW9fNqwE6UNC05-8DORHzbI',
    price: '$50',
    coverImg: 'AvrilLavigne.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lovesux.avrillavigne.com/?ref=https://www.bing.com/',
    youtube: 'https://www.youtube.com/channel/UC_k8KFBzEf0uQ8LRFSAgSUA',
    facebook: 'https://www.facebook.com/avrillavigne',
    instagram: 'https://www.instagram.com/AvrilLavigne/',
    twitter: 'https://twitter.com/AvrilLavigne',
    spotify: 'https://open.spotify.com/artist/0p4nmQO2msCgU4IF37Wi3j',
    deezer: 'https://www.deezer.com/mx/artist/35',
    apple: 'https://music.apple.com/mx/artist/avril-lavigne/459885',
    soundcloud: 'https://soundcloud.com/avrillavigne',
    tidal: 'https://listen.tidal.com/artist/1567',
    wiki: 'https://es.wikipedia.org/wiki/Avril_Lavigne',
    ticketmaster:
      'https://www.ticketmaster.com/avril-lavigne-tickets/artist/833634',
    tiktok: 'https://www.tiktok.com/@avrillavigne?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSH7L0/avril-lavigne',
  },
  {
    title: 'Grimes',
    description:
      'Claire Elise Boucher ; nacida el 18 de marzo de 1988, conocida profesionalmente como Grimes, es una músico, cantante, compositora y productora discográfica canadiense. Su trabajo temprano ha sido descrito como que abarca desde "R&B lo-fi" hasta dance-pop futurista, e ha incorporado influencias de la música electrónica, hip hop y rock. Sus letras a menudo tratan temas de ciencia ficción y feminismo. Ha lanzado cinco álbumes de estudio.Nacida y criada en Vancouver, Grimes comenzó a lanzar música de manera independiente después de mudarse a Montreal en 2006. Luego lanzó dos álbumes, "Geidi Primes" y "Halfaxa", en 2010 con Arbutus Records y posteriormente firmó con 4AD y se destacó con el lanzamiento de su álbum de estudio de 2012, "Visions". "Visions" incluye los sencillos "Genesis" y "Oblivion" y recibió el premio Juno de la industria musical canadiense al Mejor Álbum Electrónico del Año..',
    videourl:
      'https://youtube.com/playlist?list=UULFRXaOr2-9CJTMqQH5A5GYZQ&si=o0tyNa7Pt_Aj4xCJ',
    price: '$50',
    coverImg: 'grimes.jpg',
    category: 'Lo-Fi',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://elf.tech/connect',
    youtube: 'https://www.youtube.com/@grimes',
    facebook: 'https://www.facebook.com/Grimes',
    instagram: 'https://www.instagram.com/grimes/',
    twitter: 'https://twitter.com/GRIMES_V1',
    spotify: 'https://open.spotify.com/artist/053q0ukIDRgzwTr4vNSwab',
    deezer: 'https://www.deezer.com/mx/artist/807493',
    apple: 'https://music.apple.com/mx/artist/grimes/2756920',
    soundcloud: 'https://soundcloud.com/actuallygrimes',
    tidal: 'https://listen.tidal.com/artist/3819688',
    wiki: 'https://en.wikipedia.org/wiki/Grimes',
    ticketmaster: 'https://www.ticketmaster.com/grimes-tickets/artist/1731174',
    tiktok: 'https://www.tiktok.com/@grimes',
    napster: 'https://music.amazon.com.mx/artists/B0010X3K6W/grimes',
  },
  {
    title: 'Taylor Swift',
    description:
      'Taylor Alison Swift (Reading, Pensilvania; 13 de diciembre de 1989) es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.Criada en Wyomissing (Pensilvania), se mudó a Nashville (Tennessee) a los 14 años para realizar una carrera de música country. Firmó con la compañía discográfica independiente Big Machine Records y se convirtió en la artista más joven contratada por Sony/ATV Music Publishing House. En 2006 lanzó su álbum debut homónimo Taylor Swift, el cual la estableció como una estrella en la música country. «Our Song», su tercer sencillo, la convirtió en la persona más joven en escribir temas sin ayuda de nadie e interpretar una canción número uno en la lista de Hot Country Songs. Recibió una nominación a mejor artista nuevo en los Premios Grammy de 2008.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kOjcMYd-zFhGK7iPzOb1m2-3ituAZSNe0',
    price: '$50',
    coverImg: 'TaylorSwift.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.taylorswift.com/',
    youtube: 'https://www.youtube.com/channel/UCqECaJ8Gagnn7YCbPEzWH6g',
    facebook: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify: 'https://open.spotify.com/artist/06HL4z0CvFAxyc27GXpf02',
    deezer: 'https://www.deezer.com/mx/artist/12246',
    apple: 'https://music.apple.com/mx/artist/taylor-swift/159260351',
    soundcloud: 'https://soundcloud.com/taylorswiftofficial',
    tidal: 'https://listen.tidal.com/artist/3557299',
    wiki: 'https://es.wikipedia.org/wiki/Taylor_Swift',
    ticketmaster:
      'https://www.ticketmaster.com/taylor-swift-tickets/artist/1094215',
    tiktok: 'https://www.tiktok.com/@taylorswift',
    napster: 'https://music.amazon.com.mx/artists/B00157GJ20/taylor-swift',
  },
  {
    title: 'Ariana Grande',
    description:
      'Ariana Grande-Butera (Boca Ratón, Florida, 26 de junio de 1993)2​ es una cantante, empresaria y actriz estadounidense.3​ Comenzó su carrera musical en Brodway a los 15 años en el musical 13 (2008). Saltó a la fama por interpretar a Cat Valentine en las series de televisión de Nickelodeon Victorious (2010-2013) y Sam & Cat (2013-2014). Grande firmó con Republic Records en 2011 después de que los ejecutivos del sello vieran vídeos de YouTube de sus versiones de canciones. Su álbum debut de pop y R&B influenciado por el doo wop de la década de 1950,4​ Yours Truly (2013), encabezó el Billboard 200 de Estados Unidos, mientras que su sencillo principal, «The Way», llegó al top 10 del Billboard Hot 100 de Estados Unidos. La voz y el registro de silbidos de Grande en el álbum generaron comparaciones inmediatas con Mariah Carey...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mj1bt9ZXZPsL_92-NUh6nNESsw0w1QoAg&si=BkpanS4tOiwY-I6o',
    price: '$50',
    coverImg: 'arianagrande.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.arianagrande.com/',
    youtube: 'https://www.youtube.com/c/arianagrande',
    facebook: 'https://www.facebook.com/arianagrande',
    instagram: 'https://www.instagram.com/arianagrande/',
    twitter: 'https://twitter.com/ArianaGrande',
    spotify: 'https://open.spotify.com/artist/66CXWjxzNUsdJxJ2JdwvnR',
    deezer: 'https://www.deezer.com/search/Ariana%20Grande',
    apple: 'https://music.apple.com/mx/artist/ariana-grande/412778295',
    soundcloud: 'https://soundcloud.com/arianagrande',
    tidal: 'https://listen.tidal.com/artist/4332277',
    wiki: 'https://es.wikipedia.org/wiki/Ariana_Grande',
    ticketmaster:
      'https://www.ticketmaster.com/ariana-grande-tickets/artist/1688071',
    tiktok: 'https://www.tiktok.com/@arianagrande',
    napster: 'https://music.amazon.com.mx/artists/B004IUAQ9S/ariana-grande',
  },
  {
    title: 'Billie Eilish',
    description:
      'Billie Eilish Pirate Baird O Connell (Los Ángeles, 18 de diciembre de 2001), conocida simplemente como Billie Eilish, es una cantante y actriz estadounidense. Adquirió fama como artista cuando tenía 13 años, a raíz del sencillo «Ocean Eyes» que se publicó en 2015 en SoundCloud y volvió a lanzarse con un vídeo musical en YouTube en 2016, a la edad de 14 años, lo que la convirtió en un fenómeno viral. En 2017, publicó su EP Dont Smile at Me, producido por su hermano Finneas O Connell..',
    videourl:
      'https://youtube.com/playlist?list=PLsCPTY_MPoPbCftVtuvquFs9ayLDwD237&si=G_KtiHN0fRU8EeDC',
    price: '$50',
    coverImg: 'billieeilish.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billieeilish.com/#/',
    youtube: 'https://www.youtube.com/channel/UCiGm_E4ZwYSHV3bcW1pnSeQ',
    facebook: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify:
      'https://open.spotify.com/artist/6qqNVTkY8uBg9cP3Jd7DAH?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/9635624',
    apple: 'https://music.apple.com/mx/artist/billie-eilish/1065981054',
    soundcloud: 'https://soundcloud.com/billieeilish',
    tidal: 'https://listen.tidal.com/album/107563608/track/107563609',
    wiki: 'https://es.wikipedia.org/wiki/Billie_Eilish',
    ticketmaster:
      'https://www.ticketmaster.com/billie-eilish-tickets/artist/2257710',
    tiktok: 'https://www.tiktok.com/@billieeilish',
    napster: 'https://music.amazon.com.mx/artists/B01A7VBHJ4/billie-eilish',
  },
]
export default CardData25
