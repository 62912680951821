const CardData26 = [
  {
    title: 'Los Angeles Azules',
    description:
      'Los hermanos Mejía Avante incursionaron en la música en los años 70, tocando melodías sencillas de manera lírica en una estudiantina, forjando rápidamente su talento musical. Apoyados por sus padres Martha Avante Barrón y Porfirio Mejía García, quienes consiguieron préstamos de dinero empeñando algunas de sus propiedades, y con empleos como conductor de autobuses suburbanos (en el caso de su padre) lograron comprar, en pagos, el primer equipo de instrumentos musicales, bocinas y amplificadores.',
    videourl:
      'http://youtube.com/playlist?list=PLF-cRDh2mrlUHPW6deKoe0C771xYTJ6SY',
    price: null,
    coverImg: 'angelesazules.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.losangelesazules.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCYTPYsuIRSu7o9xmeB_L4_g',
    facebook: 'https://www.facebook.com/angelesazulesmx',
    instagram: 'https://www.instagram.com/angelesazulesmx/',
    twitter: 'https://twitter.com/angelesazulesmx',
    spotify: 'https://open.spotify.com/artist/0ZCO8oVkMj897cKgFH7fRW',
    deezer: 'https://www.deezer.com/mx/artist/116523',
    apple: 'https://music.apple.com/mx/artist/los-%C3%A1ngeles-azules/3500477',
    soundcloud: 'https://soundcloud.com/los-angeles-azules',
    tidal: 'https://listen.tidal.com/artist/3672176',
    wiki: 'https://es.wikipedia.org/wiki/Los_%C3%81ngeles_Azules',
    ticketmaster: 'https://www.ticketmaster.com/artist/1039844',
    tiktok: 'https://www.tiktok.com/@losangelesazules_fans?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B004WMEZEY/los-%C3%A1ngeles-azules',
  },
  {
    title: 'ICC',
    description:
      'Ismael Cortés Cortes, de 32 años, dejó nuestro país en búsqueda del sueño americano. Junto con su familia se fue de mojado a Estados Unidos, donde lleva viviendo más de la mitad de su vida. Ismael, quien es conocido como ‘ICC’, nació en Puebla y desde muy joven junto a sus hermanos empezó a hacer música en un grupo de cumbia, aunque para sus hermanos era un hobby, él lo tomó en serio y se comenzó a componer y lanzar sus propios temas. Con trabajo y las redes sociales comenzó a subir su cumbia sonidera romántica en las mismas, por lo que rápidamente se hicieron un éxito. Conversamos con el cantante y compositor que no sabe de límites y sueña con grabar junto a Marco Antonio Solís y Los Temerarios. Ahora ya prepara gira en Perú, Chile y Argentina.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l4p-BDvV_jAEUSzs7q3nZam8chn0tYSws',
    price: null,
    coverImg: 'ICC.jpeg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://iccoficial.com/',
    youtube: 'https://www.youtube.com/channel/UCo-bXjTL9amLkRWjf1xtsLw',
    facebook: 'https://www.facebook.com/OFICIALICC/?locale=es_LA',
    instagram: 'https://www.instagram.com/iccoficial/?hl=en',
    twitter: 'https://twitter.com/iccoficialmx?lang=en',
    spotify: ' https://open.spotify.com/artist/66UyAOM9ECEDzsfUm9UXeF',
    deezer: 'https://www.deezer.com/mx/artist/558098',
    apple: 'https://music.apple.com/mx/artist/icc/381733603',
    soundcloud: 'https://soundcloud.com/grupoicc',
    tidal: 'https://listen.tidal.com/artist/8337434',
    wiki:
      'https://www.tvnotas.com.mx/noticias-espectaculos-mexico/ismael-cortes-cortes-paso-de-indocumentado-cantante-de-cumbia',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@iccoficial',
    napster: 'https://music.amazon.com.mx/artists/B003XPB7TM/icc',
  },
  {
    title: 'Los LLayras',
    description:
      'Buscando un estilo propio y diferente, el productor Francisco Corchado se dedicó a la tarea de formar un grupo de música andina que se caracterizará por tener dos voces, una femenina y una masculina, lo cual le dio un toque muy original a la agrupación, es así que en 1995 nacen LOS LLAYRAS, palabra que significa Dios proveerá en Aymar (lengua que se habla en los alrededores del Lago Titicaca de Bolivia y Perú) y sin duda, Dios le regaló un don el talento musical desde sus inicios.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_myPpZDF4OCsc10z3biQHszPGYmP1ScI60',
    price: null,
    coverImg: 'llayras.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.losllayras.com.mx/contenido.html',
    youtube: 'https://www.youtube.com/channel/UCtN-Tm8ZIFX_5sYGQQlIXRA',
    facebook: 'https://www.facebook.com/losllayrascorchado/',
    instagram: 'https://www.instagram.com/losllayrasoficial/',
    twitter: 'https://twitter.com/LOSLLAYRAS',
    spotify: 'https://open.spotify.com/artist/2ArV1M689cANDANMu9OfBz',
    deezer: 'https://www.deezer.com/mx/artist/1251638',
    apple: 'https://music.apple.com/mx/artist/los-llayras/313963966',
    soundcloud: 'https://soundcloud.com/los-llayras-official',
    tidal: 'https://listen.tidal.com/artist/4013373',
    wiki: 'https://www.saps.com.mx/biografias/los-llayras-biografia.html',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@los_llayras_oficial',
    napster: 'https://music.amazon.com.mx/artists/B002WPVZAU/los-llayras',
  },
  {
    title: 'Grupo Bryndis',
    description:
      'El Grupo Bryndis fue fundado en 1989 por músicos provenientes de Cerritos, San Luis Potosí, México. El grupo fue formado en Santa Paula, California, y desde entonces han vendido más de 2.5 millones discos y han sido múltiples ganadores de discos de Oro y Platino. Reconocidos con importantes premiaciones que incluyen un Grammy en el 2006 como Mejor Álbum Mexicano/México-Americano, varios premios Latin Grammy entre los que se destacan Mejor Álbum Mexicano/México-Americano y Canción del Año; asimismo, han sido reconocidos por premio Lo Nuestro, Premios Billboard, Tejano Music Award y premio Furia Musical , entre otros.Una historia y carrera musical de 2 décadas cantandole al amor, avalan la trayectoria de Bryndis como el grupo romántico por excelencia, aunque tambien cuentan con temas tropicales mas alegres y varias cumbias para bailar. Peru, Bolivia, Argentina, España, Brazil, y muchos otros paises, por supuesto la Unión Americana y México.',
    videourl:
      'https://youtube.com/playlist?list=PLnWMNWVAMrysx1xLfS9-i7PE3YgTwfNVC',
    price: null,
    coverImg: 'bryndis.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://starmedios.com/gruposmusicales/Bryndis_grupo-Contrataciones-informes.html',
    youtube: 'https://www.youtube.com/channel/UCzuKMgJ-sX4V2kImpc_xYjg',
    facebook: 'https://www.facebook.com/GRUPOBRYNDISOFICIAL/',
    instagram: 'https://www.instagram.com/grupobryndisoficial/',
    twitter: 'https://twitter.com/elgrupobryndis',
    spotify:
      'https://open.spotify.com/playlist/6ppuwKaBjiizTVjxnlwV8f?si=9b0cfd1acd8d4550&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/244019',
    apple: 'https://music.apple.com/us/artist/grupo-bryndis/3500488',
    soundcloud: 'https://soundcloud.com/grupo-bryndis',
    tidal: 'https://listen.tidal.com/artist/3626122',
    wiki: 'https://en.wikipedia.org/wiki/Grupo_Bryndis',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@grupobryndisoficial',
    napster: 'https://music.amazon.com.mx/artists/B00130GLPE/grupo-bryndis',
  },
  {
    title: 'Grupo Macao de Alex y Jhonny',
    description:
      'Grupo Macao de Alex y Jhonny nace en el año 2003. Alex Hernandez y Jhonny Hernandez, dos hermanos nacidos en Passaic Nueva Jersey, logran juntar varios de sus amigos de la prepa y es asi que inicia la Potencia mas grande en la Union Americana. En el 2007 Grupo Macao ya se empieza a escuchar por toda la Union Americana y Mexico, ese mismo año hacen sus primeras giras en , Chicago y California, donde obtienen tremenda fuerza y desde entonces se an convertido en unas de las grandes agrupaciones del ambiente sonidero en la Union Americana. Hoy Grupo Macao de Alex y Jhonny sigue vigente y sus temas, DOS EN UNO, REYNA DE MI VIDA, QUIERO QUE SEPAS, LO QUE NO PUDO SER, y su EXITO MAS RECIENTE (PADRE NUESTRO) lo ah puesto entre los grupos mas sonados en Mexico y en Estados Unidos..',
    videourl: 'https://youtube.com/playlist?list=UULFYf_MAdIsuronbW0Okcfagw',
    price: null,
    coverImg: 'macaoalexyjohny.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://trademarks.justia.com/861/88/grupo-macao-alex-y-jhonny-la-potencia-joven-de-la-86188786.html',
    youtube: 'https://www.youtube.com/channel/UCYf_MAdIsuronbW0Okcfagw',
    facebook: 'https://www.facebook.com/Lapotenciajovendelacumbia/',
    instagram: 'https://www.instagram.com/grupo_macao_official/',
    twitter: 'https://twitter.com/MacaoAyJ',
    spotify: 'https://open.spotify.com/artist/2GAmpY3SIeEIjNjP8AjP2Z',
    deezer: 'https://www.deezer.com/mx/artist/11675063',
    apple:
      'https://music.apple.com/mx/artist/grupo-macao-de-alex-y-jhonny/1193408225',
    soundcloud: 'https://soundcloud.com/user-602336868',
    tidal: 'https://tidal.com/browse/artist/8416670',
    wiki: 'https://sazum.com/grupo-macao-de-alex-y-jhonny',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@grupomacaodealexyjohnny',
    napster:
      'https://music.amazon.com.mx/artists/B01MS537WH/grupo-macao-de-alex-y-jhonny',
  },
  {
    title: 'Los Yaguaru',
    description:
      'LOS YAGUARÚ (palabra de origen paraguayo, dialecto guaraní, que significa “Nutria de Río”) es una organización que conjuntó el talento de excelentes músicos procedentes de diferentes estados de la República Mexicana, con el firme propósito de ganarse el corazón y apoyo de los amantes del género tropical..',
    videourl: 'https://youtube.com/playlist?list=UULF8VjH9En2gg5u8yviUj_FHA',
    price: null,
    coverImg: 'yaguaru.jpg',
    category: 'Sonidero',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://lainternacionaldelamusica.com/?p=53#:~:text=LOS%20YAGUAR%C3%9A%20(palabra%20de%20origen,los%20amantes%20del%20g%C3%A9nero%20tropical.',
    youtube: 'https://www.youtube.com/channel/UC8VjH9En2gg5u8yviUj_FHA',
    facebook: 'https://www.facebook.com/losyaguaru/',
    instagram: 'https://www.instagram.com/LosYaguaru/',
    twitter: 'https://twitter.com/LosYaguaru1',
    spotify:
      'https://open.spotify.com/artist/1CajkwEgJac9j9alNJJxTQ?si=DI6Z8eP2QUWHvBEsshmtNA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1295781',
    apple: 'https://music.apple.com/mx/artist/los-yaguaru/426741441',
    soundcloud: 'https://soundcloud.com/losyaguaru',
    tidal: 'https://listen.tidal.com/album/217199553',
    wiki: 'https://es.wikipedia.org/wiki/Las_30_Cumbias_M%C3%A1s_Pegadas',
    ticketmaster:
      'https://www.ticketmaster.com.mx/cumbiamania-los-askis-los-yaguaru-ciudad-de-mexico-15-01-2024/event/3D005E98D4114388',
    tiktok: 'https://www.tiktok.com/@losyaguaru?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B004SKXU78/los-yaguaru',
  },
  {
    title: 'Los Askis',
    description:
      'Los Askis (amigo, en el idioma otomí), es un grupo de Cumbia Andina Mexicana, su labor pionera es el movimiento andino Mexicano que lo ha colocado prácticamente como el primer grupo de la Cumbia Andina Mexicana.El grupo nace en el año de 1994 con condición de tocar música de Latinoamérica y folclor en los centros culturales de Ciudad de México; hacían fusiones con otros géneros como el carnavalito, huayno, saya, huapango, son, rumba, nahua..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mmtvxgRinogROj8YIrFmLhzH9E75k4IIc',
    price: null,
    coverImg: 'losaskis.jpg',
    category: 'Sonidero',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://representacionesartisticas-azteca.com/grupo-quintanna/',
    youtube: 'https://www.youtube.com/channel/UCl-4spywWj0wxzkKBWVa8KA',
    facebook: 'https://www.facebook.com/losaskisinternacional',
    instagram: 'https://www.instagram.com/los_askis_forever.fans/',
    twitter: 'https://twitter.com/losaskis',
    spotify: 'https://open.spotify.com/artist/7wiOKrtPg196Hh90CdoKPi',
    deezer: 'https://www.deezer.com/mx/artist/1706903',
    apple: 'https://music.apple.com/mx/artist/los-askis/337819635',
    soundcloud: 'https://soundcloud.com/los-askis',
    tidal: 'https://listen.tidal.com/artist/5643845',
    wiki: 'https://es.wikipedia.org/wiki/Los_Askis',
    ticketmaster:
      'https://www.ticketmaster.com.mx/los-askis-boletos/artist/787244',
    tiktok: 'https://www.tiktok.com/@losaskisoficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B002UTE0VO/los-askis',
  },
  {
    title: 'Aniceto Molina',
    description:
      'Aniceto de Jesús Molina Aguirre (El Campano, Córdoba, Colombia, 17 de abril de 1939 - San Antonio, Texas, 30 de marzo de 2015)1​ fue un acordeonista y cantautor colombiano en el género cumbia. Su mayor éxito fue la "Cumbia sampuesana" que, aunque no es de su autoría, su versión es la más conocida y la más sonada en Estados Unidos, México y Centroamérica. Su ritmo muy particular y la mezcla de instrumentos de vientos, metales y madera con el acordeón le dieron la distinción entre los exponentes de la cumbia. Tuvo éxito en México, pero después de grabar “Josefina” y llegar a El Salvador, su éxito en el país centroamericano fue inevitable: compuso temas al público salvadoreño como “El peluquero”, “El garrobero”, “La fiesta cumbiambera“, entre otros..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lW5jaGpmS1vYxmm_G4Cq7DlelmaCAw4fw',
    price: null,
    coverImg: 'aniceto.jpeg',
    category: 'Sonidero',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCWcS1AUnZXbxWuxbHgjxk2A',
    facebook: 'https://www.facebook.com/profile.php?id=100050256619217',
    instagram: 'https://www.instagram.com/anicetomolinaoficial/',
    twitter: 'https://twitter.com/anicetomolina_',
    spotify: 'https://open.spotify.com/artist/0vpwDjHfD1T65OcmvpcF0S',
    deezer: 'https://www.deezer.com/mx/artist/126572',
    apple: 'https://music.apple.com/mx/artist/aniceto-molina/26116457',
    soundcloud: 'https://soundcloud.com/aniceto-molina',
    tidal: 'https://listen.tidal.com/artist/3584225',
    wiki: 'https://es.wikipedia.org/wiki/Aniceto_Molina',
    ticketmaster:
      'https://www.ticketmaster.com.mx/ritmo-sabor-y-cumbia-alberto-pedraza-invitados-mexico-05-06-2023/event/14005D35AC298922',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Aniceto%20Molina&t=1683262383705',
    napster: 'https://music.amazon.com.mx/artists/B000QKADZG/aniceto-molina',
  },
  {
    title: 'Los Tepoz',
    description:
      'Grupo Los Tepoz nació en el año 2010. Fue un proyecto planeado por Miguel Tepoz después de un proyecto anterior dirigido por su padre. Miguel Tepoz con gran tenacidad e inspiración empezó a componer varios temas musicales en el género de cumbia sonidera..',
    videourl: 'https://youtube.com/playlist?list=UULPlLGOqlnpPg3DfnkM8HOZiA',
    price: null,
    coverImg: 'lostepoz.jpeg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://superola.com/listing/grupo-los-tepoz/',
    youtube: 'https://www.youtube.com/channel/UClLGOqlnpPg3DfnkM8HOZiA',
    facebook: 'https://www.facebook.com/grupolostepozoficial',
    instagram: 'https://www.instagram.com/explore/tags/grupolostepoz/',
    twitter: 'https://twitter.com/los_tepoz',
    spotify: 'https://open.spotify.com/artist/1yu6r3vgWWDpQLOVHtM8pW',
    deezer: 'https://www.deezer.com/mx/artist/112257572',
    apple: 'https://music.apple.com/mx/artist/los-tepoz/1104032032',
    soundcloud:
      'https://soundcloud.com/fchdez/grupo-los-tepoz-mix-2019-fc-hdez',
    tidal: 'https://listen.tidal.com/artist/7492374',
    wiki: 'https://superola.com/listing/grupo-los-tepoz/',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@miguel_tepoz?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B019S6KZNI/los-tepoz',
  },
  {
    title: 'La Tropa Vallenata',
    description:
      'La Tropa Vallenata, es una de las agrupaciones mas representativas de la musica tropical del norte de nuestro país. Originarios de Monterrey Nuevo León y actualmente radicados en la Unión Americana, La Tropa Vallenata ha sabido representar con creces el ritmo colombiano ballenato, un estilo que es muy popular y esta arraigado en toda la zona regiomontana. La Tropa Vallenata a sabido trascender generaciones desde su fundación, pues ya son dos decádas que bajo la dirección de Alfonso Rios, la agrupación se mantiene en el gusto del publico. Con mas de 20 producciones discograficas, La Tropa Vallenata a colocado grandes exitos en el gusto del publico: Los Caminos de la vida, Mi razon de ser, Quiero saber de ti, son entre otros muchos los temas que han quedado como repertorio inolvidable de la agrupacion.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nItn37boX4cyr6JumOmYWpkWCXMJpduLs',
    price: null,
    coverImg: 'latropavallenata.jpg',
    category: 'Sonidero',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://starmedios.com/gruposmusicales/Tropa_vallenata-Contrataciones-informes.html',
    youtube: 'https://www.youtube.com/channel/UCFoI_nsfPrFns9gXdRPN3ww',
    facebook: 'https://www.facebook.com/latropa.vallenta',
    instagram: 'https://www.instagram.com/tropavallnata/',
    twitter: 'https://twitter.com/tropavallenata',
    spotify: 'https://open.spotify.com/artist/6ESzKdcEDKvhUek4ZaHruk',
    deezer: 'https://www.deezer.com/mx/artist/364666',
    apple: 'https://music.apple.com/mx/artist/la-tropa-vallenata/308406066',
    soundcloud: 'https://soundcloud.com/aniceto-molina',
    tidal: 'https://listen.tidal.com/artist/3584225',
    wiki: 'https://es.wikipedia.org/wiki/Aniceto_Molina',
    ticketmaster:
      'https://www.ticketmaster.com.mx/ritmo-sabor-y-cumbia-alberto-pedraza-invitados-mexico-05-06-2023/event/14005D35AC298922',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Aniceto%20Molina&t=1683262383705',
    napster: 'https://music.amazon.com.mx/artists/B000QKADZG/aniceto-molina',
  },

  {
    title: 'Grupo Quintanna',
    description:
      'Grupo Quintanna es un grupo de música mexicano formado en 2010. El grupo está compuesto por José Luis Pineda, Luis Ángel Pérez y Adrián Ramos. Los tres miembros son de la Ciudad de México y han estado tocando juntos durante más de 10 años,es conocido principalmente por su sonido de pop latino y baladas románticas. Su música ha sido presentada en varias películas y programas de televisión, incluyendo la exitosa película "Y Tu Mamá También" (2002). Han logrado un gran éxito con sus sencillos "Más Allá", "Esperanza", "Loco Por Ti" y "No Me Dejes Ir". Ha colaborado con varios otros artistas, incluyendo a Cristian Castro, Alejandro Fernández y Paulina Rubio. También han sido incluidos en la banda sonora de películas exitosas como "Un Hombre Busca Una Mujer" (2012) y "La Leyenda Del Charro Negro" (2015).',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mi7ojJjN0ZFxp5zzusH6JL9IBtrqkmyyw',
    price: null,
    coverImg: 'quintanna.jpeg',
    category: 'Sonidero',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://representacionesartisticas-azteca.com/grupo-quintanna/',
    youtube: 'https://www.youtube.com/channel/UC1Y025qutHcWeBzgGflF0yw',
    facebook: 'https://www.facebook.com/GrupoQuintannaPage',
    instagram: 'https://www.instagram.com/grupoquintannaoficial/?hl=en',
    twitter: 'https://twitter.com/hashtag/GrupoQuintanna?src=hashtag_click',
    spotify: 'https://open.spotify.com/artist/17jlmfAaFHbfrauHk2HiNc',
    deezer: 'https://www.deezer.com/mx/artist/58591022',
    apple: 'https://music.apple.com/mx/artist/grupo-quintanna/1510679311',
    soundcloud:
      'https://soundcloud.com/djpadrinomixsonidero/grupo-quintanna-mix-2020-lo-mas-romantico-y-lo-mejor-padrino-mix-dj',
    tidal: 'https://listen.tidal.com/artist/10923040',
    wiki: 'https://es.wikipedia.org/wiki/Las_30_Cumbias_M%C3%A1s_Pegadas',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Cumbia',
    tiktok: 'https://www.tiktok.com/@grupoquintanna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07N8H1SKB/grupo-quintanna',
  },
  {
    title: 'Raymix',
    description:
      'Edmundo Gómez Moreno (San José El Vidrio, Estado de México; 17 de febrero de 1991),2 mejor conocido como Raymix, es un ingeniero aeronáutico, compositor y cantante mexicano de electrocumbia y trance. Apodado El rey de la electrocumbia, Raymix comenzó su carrera musical a principios de la década de 2010, cuando se unió a un proyecto de trance llamado Light & Wave con otros dos músicos. Su canción «Feeling the city» apareció en el programa de radio de Armin van Buuren, A State of Trance. En 2013, Raymix fue invitado a trabajar en una pasantía educativa de la NASA, donde ayudó a desarrollar un satélite.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWHTpQuUb9wod5uZf_aI6Mv',
    price: '$50',
    coverImg: 'Raymix.jpg',
    category: 'Electro-Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://youtube.com/playlist?list=PLDddB2n43-_aUy20_nhtI6RTFEjo2BFsi',
    youtube: 'https://www.youtube.com/channel/UCloweRF4YoKEC0zfHP1Bp7Q',
    facebook: 'https://www.facebook.com/raymixmusic',
    instagram: 'https://www.instagram.com/raymixmusic/',
    twitter: 'https://twitter.com/raymixmusic',
    spotify: 'https://open.spotify.com/artist/0hHT2BH7XTm3ZdZb6CX064',
    deezer: 'https://www.deezer.com/mx/artist/5357220',
    tidal: 'https://listen.tidal.com/artist/6272566',
    apple: 'https://music.apple.com/mx/artist/raymix/1063398071',
    soundcloud: 'https://soundcloud.com/raymix-358827986',
    wiki: 'https://es.wikipedia.org/wiki/Raymix',
    ticketmaster: 'https://www.facebook.com/raymixmusic/events',
    tiktok: 'https://www.tiktok.com/@raymixmusic?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00GPQAIBO/raymix',
  },
  {
    title: 'La Sonora Dinamita',
    description:
      'La Sonora Dinamita es una agrupación Mexicana y Colombiana exponente de música tropical, principalmente de la cumbia. Se fundó en 1960, en Medellín, Colombia, por iniciativa de Antonio Fuentes, con la voz líder del fallecido cantante Lucho Argaín colombiano naturalizado mexicano. En la actualidad han vendido entre 45 y 50 millones de copias en todo el mundo..',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUaEUi8KFNbN1T2oTNgZvDH',
    price: '$50',
    coverImg: 'SonoraDinamita.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.originalsonoradinamita.com/',
    youtube: 'https://www.youtube.com/channel/UCE_KPQxpcMDtV2zX1ru24Zg',
    facebook: 'https://www.facebook.com/originalsonoradinamita',
    instagram: 'https://www.instagram.com/sonora_dinamita/',
    twitter: 'https://twitter.com/dinamitaonline',
    spotify: 'https://open.spotify.com/artist/13or1Wf6ipcvSIiurZATvw',
    deezer: 'https://www.deezer.com/mx/artist/616908',
    apple: 'https://music.apple.com/mx/artist/la-sonora-dinamita/918315916',
    soundcloud: 'https://soundcloud.com/lasonoradinamita',
    wiki: 'https://en.wikipedia.org/wiki/La_Sonora_Dinamita',
    ticketmaster:
      'https://www.ticketmaster.com.mx/la-sonora-dinamita-boletos/artist/755214',
    tidal: 'https://listen.tidal.com/artist/3609831',
    tiktok: 'https://www.tiktok.com/@lasonoradinamitaoficial',
    napster: 'https://web.napster.com/artist/la-sonora-dinamita',
  },
  {
    title: 'Fito Olivares',
    description:
      'Rodolfo Olivares (Camargo, Tamaulipas, México, 19 de abril de 1947) es un músico mexicano. Es hijo de María Cristina Olivares y Mucio Olivares. Pasó su infancia en un rancho de Rechinadores, Tamaulipas. En la escuela de este rancho aprendió a tocar la armónica que su padre le compró. Su padre Mucio Olivares era un buen saxofonista y empezaron a practicar juntos con el saxofón cuando tenía 12 años. Se graduó de la academia comercial en 1961 y comenzó a trabajar manteniendo cuentas de algunos negocios. Comenzó a jugar profesionalmente en 1963, a la edad de 16 años, en Camargo, Tamaulipas.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mVIq7Wv4EHxU41I6OoNRS32vuTwlVx_GY',
    price: '$50',
    coverImg: 'FitoOlivares.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Fito_Olivares',
    youtube: 'https://www.youtube.com/channel/UC2umdJm-2PaE8mHajC0BF4w',
    facebook: 'https://www.facebook.com/profile.php?id=100044383811180',
    instagram: 'https://www.instagram.com/fito_olivares_oficial/',
    twitter: 'https://twitter.com/olivares_fito',
    spotify: 'https://open.spotify.com/artist/4VRHPqW54KVxJAhUWNKjNF',
    deezer: 'https://www.deezer.com/mx/artist/243752',
    apple:
      'https://music.apple.com/mx/artist/fito-olivares-y-su-grupo/575907753',
    soundcloud: 'https://soundcloud.com/fito-olivares-official',
    tidal: 'https://listen.tidal.com/artist/3509235',
    wiki: 'https://en.wikipedia.org/wiki/Fito_Olivares',
    ticketmaster:
      'https://www.ticketmaster.com/Fito-Olivares-tickets/artist/763146',

    tiktok: 'https://www.tiktok.com/@fito.olivares',
    napster: 'https://web.napster.com/artist/fito-olivares',
  },
  {
    title: 'La Sonora Santanera',
    description:
      'La Sonora Santanera, más conocida como La Única e Internacional Sonora Santanera, es una de las agrupaciones mexicanas más importantes y famosas de la música tropical mexicana, fundada en el año 1955, originaria de Tabasco, que se ha consolidado popularmente mediante un estilo singular influenciado por el danzón, el mambo, el bolero, la rumba, el chachachá, la guaracha y la cumbia, así como canciones que son parte de la memoria colectiva de México. El concepto fue creado por el músico y trompetista tabasqueño Carlos Colorado.',
    videourl: 'https://youtube.com/playlist?list=PL1207E50B46D6A148',
    price: 36,
    coverImg: 'LaSonoraSantanera.jpg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Sonora_Santanera',
    youtube: 'https://www.youtube.com/channel/UCyItDxH2x0pj_CJO7SKS-fQ',
    facebook: 'https://www.facebook.com/SantaneraMx/',
    instagram: 'https://www.instagram.com/santaneramx/?hl=es-la',
    twitter: 'https://mobile.twitter.com/s_santanera',
    spotify: 'https://open.spotify.com/artist/3CsPxFJGyNa9ep79CFWN77',
    deezer: 'https://www.deezer.com/mx/artist/551524?autoplay=true',
    apple: 'https://music.apple.com/mx/artist/la-sonora-santanera/126067406',
    soundcloud: 'https://soundcloud.com/lasonorasantanera',
    tidal: 'https://listen.tidal.com/artist/3656198',
    wiki: 'https://en.wikipedia.org/wiki/Sonora_Santanera',
    ticketmaster:
      'https://www.ticketmaster.com.mx/la-unica-internacional-sonora-santanera-mexico-27-01-2023/event/14005D7796C01AA4',
    tiktok: 'https://www.tiktok.com/@sonorasantanera',
    napster:
      'https://music.amazon.com.mx/artists/B00136DED0/la-sonora-santanera',
  },
  {
    title: 'Grupo Cañaveral de Humberto Pabon',
    description:
      ' GRUPO CAñAVERAL nace en enero de 1995, con la idea de crear un grupo que rescatara el auténtico sabor de la cumbia en México. Creado por el . Humberto Pabón Olivares, y por primera vez en México, se integra a una agrupación auténticamente mexicana, el famosísimo Tambor Alegre originario de Colombia..',
    videourl:
      'https://youtube.com/playlist?list=PLJRMiyX0t8zQpn0k3ygRs4RoJwEtmGQQA',
    price: 36,
    coverImg: 'GRUPOcAñAVERAL.jpg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.grupocanaveral.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCYz5tj23JpsjrUPL17qu0Aw',
    facebook: 'https://www.facebook.com/grupocanaveraloficial',
    instagram: 'https://www.instagram.com/grupocanaveral/',
    twitter: 'https://twitter.com/grupocanaveral?lang=es',
    spotify: 'https://open.spotify.com/artist/48zixAu4wMDZwpVbOenDU7',
    deezer: 'https://www.deezer.com/mx/artist/1705508?autoplay=true',
    apple:
      'https://music.apple.com/mx/artist/grupo-ca%C3%B1averal-de-humberto-pab%C3%B3n/475344077',
    soundcloud: 'https://soundcloud.com/grupocanaveralofficial',
    tidal: 'https://listen.tidal.com/artist/4115330',
    wiki:
      'https://commons.wikimedia.org/wiki/File:Grupo_Ca%C3%B1averal-_Celebraci%C3%B3n_25_a%C3%B1os.jpg',
    ticketmaster:
      'https://www.ticketmaster.com/grupo-canaveral-tickets/artist/791914',
    tiktok: 'https://www.tiktok.com/@grupocanaveraloficial',
    napster:
      'https://music.amazon.com.mx/artists/B0065CKKD4/grupo-ca%C3%B1averal-de-humberto-pab%C3%B3n',
  },
  {
    title: 'Alberto Pedraza',
    description:
      ' En los años 50 el padre de Pedraza participó con su banda de viento en la película La oveja negra de 1949.Pedraza nació en San Juan de Aragón, colonia de la Ciudad de México donde han surgido artistas de la cumbia mexicana sonidera como el Super Grupo Colombia, mismo que fundó junto a su hermano Aarón e integró durante 24 años y donde compuso algunos de los éxitos de la agrupación como «La cumbia gabacha».En 2001 Pedraza decidió separarse del grupo para iniciar una carrera solista, grabando un primer disco en ese mismo año. Al año siguiente grabaría su más grande éxito «La guaracha sabrosona» en el disco De reventón. Sus canciones se han convertido en éxitos por sonidos como Sonido La Changa, Sonido Marisol y Sonido Conga.​En 2021 grabó un disco por los 50 años de carrera, mismo en el que grabó canciones con Alfredo Gutiérrez, Dr. Shenka de Panteón Rococó, Santa Fe Klan y María Barracuda, entre otros artistas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8NqkjKCDLP7da7bubuuqPal-kCAruRxo',
    price: 36,
    coverImg: 'albertopedraza.jpg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.auditorio.com.mx/cartelera/2024/marzo/alberto-pedraza.html',
    youtube: 'https://www.youtube.com/channel/UCIL91Rm-3nPLqwK65d51yIw',
    facebook: 'https://www.facebook.com/AlbertoPedrazaOficial/',
    instagram: 'https://www.instagram.com/albertopedrazaoficial/',
    twitter: 'https://twitter.com/albertopedraza_',
    spotify:
      'https://open.spotify.com/artist/3TQh6LXI9ADgyZJTT19TeR?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/464881?autoplay=true',
    apple: 'https://music.apple.com/mx/artist/alberto-pedraza/128905456',
    soundcloud: 'https://soundcloud.com/albertopedraza',
    tidal: 'https://listen.tidal.com/artist/3888511',
    wiki: 'https://es.wikipedia.org/wiki/Alberto_Pedraza',
    ticketmaster:
      'https://www.ticketmaster.com/alberto-pedraza-tickets/artist/2337367',
    tiktok: 'https://www.tiktok.com/@albertopedrazaoficial',
    napster: 'https://music.amazon.com.mx/artists/B000RW4JNA/alberto-pedraza',
  },
  {
    title: 'Rayito Colombiano',
    description:
      ' Rayito Colombiano es una agrupación originaria de la Delegación de Iztapalapa, Ciudad de México, fundada en 1996 con un concepto musical que se perfiló por sí solo gracias a la magistral combinación de la sensibilidad mexicana con el folklore colombiano..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k-LNyuZ_0yIbgTE2-zy0rYRZs5g1BaZqM',
    price: 36,
    coverImg: 'RayitoColombiano.jpeg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://g.co/kgs/PxmVfQ',
    youtube: 'https://www.youtube.com/channel/UCDd6ewtFTxa2Vcc2DRqsyKg',
    facebook: 'https://www.facebook.com/RayitoMXOfic',
    instagram: '',
    twitter: '',
    spotify: 'https://open.spotify.com/artist/65yadZcTsPmv9djSu87FHP',
    deezer: 'https://www.deezer.com/mx/artist/519838',
    apple: 'https://music.apple.com/mx/artist/rayito-colombiano/323503458',
    soundcloud: 'https://soundcloud.com/rayito-colombiano',
    tidal: 'https://listen.tidal.com/artist/3793530',
    wiki: 'https://es.wikipedia.org/wiki/Leader_Music',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@rayitocolombianooficial',
    napster: 'https://music.amazon.com.mx/artists/B002UTE2Q2/rayito-colombiano',
  },
  {
    title: 'Celso Piña',
    description:
      'Celso Piña Arvizu (Monterrey; 6 de abril de 1953-ibídem. 21 de agosto de 2019) fue un cantante, compositor y acordeonista mexicano de música de distintos géneros. Fue pionero y uno de los principales exponentes en la composición e interpretación de música colombiana como cumbia colombiana y vallenato en su ciudad natal y su posterior expansión al norte de México y el sur de los Estados Unidos. También protagonista de la posterior fusión de dichos ritmos colombianos con sonidos tropicales y géneros populares de México como la música norteña y el sonidero y otros como el ska, el reggae, el rap y el hip-hop, entre otros..',

    videourl:
      'https://youtube.com/playlist?list=PLofghy0OIxVVf-67dy3nGQLHrAlJGM949',
    price: '$50',
    coverImg: 'CelsoPiña.jpg',
    category: 'Cumbia',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.celso.mx//',
    youtube: 'https://www.youtube.com/channel/UCkTVKrjdcOBVGuTJj38ShrA',
    facebook: 'https://www.facebook.com/celso.pina',
    instagram: 'https://www.instagram.com/celsopinaoficial/',
    twitter: 'https://twitter.com/celsooficial',
    spotify: 'https://open.spotify.com/artist/6PmfbeAL7jAauFPNyQ5UPi',
    deezer: 'https://www.deezer.com/mx/artist/242926',
    apple: 'https://music.apple.com/mx/artist/celso-pi%C3%B1a/2138501',
    soundcloud: 'https://soundcloud.com/lasonoradinamita',
    tidal: 'https://listen.tidal.com/artist/3510422',
    wiki: 'https://en.wikipedia.org/wiki/Celso_Pi%C3%B1a',
    ticketmaster:
      'https://www.ticketmaster.com/celso-pina-tickets/artist/789445',
    tiktok: 'https://www.tiktok.com/search?q=celso%20pina&t=1658086600586',
    napster: 'https://web.napster.com/artist/celso-pina',
  },
  {
    title: 'Grupo Dezkontrol',
    description:
      ' Grupo Dezkontrol de Roberto Camacho, Tepeaca, Puebla, Mexico, La Huerfanita con Violines- Grupo Dezkontrol ... Cumbia Hidalgo - Video Oficial @Grupo Dezkontrol de Roberto Camacho  Los creadores del Cumviolin.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kbLTAwi8gaqapjQiKG3sXkqhIwkyLQzjk',
    price: 36,
    coverImg: 'DezKontrol.jpg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://g.co/kgs/PxmVfQ',
    youtube: 'https://www.youtube.com/@GrupoDezkontrol',
    facebook: 'https://www.facebook.com/grupodezkontrol.music/',
    instagram: 'https://www.instagram.com/grupocanaveral/',
    twitter: 'https://twitter.com/DezkontrolGrupo',
    spotify: 'https://open.spotify.com/artist/65yadZcTsPmv9djSu87FHP',
    deezer: 'https://www.deezer.com/mx/artist/14057371',
    apple: 'https://music.apple.com/mx/artist/grupo-dezkontrol/1623026771',
    soundcloud: 'https://soundcloud.com/grupo-dezkontrol-k',
    tidal: 'https://listen.tidal.com/artist/9498544',
    wiki: 'https://g.co/kgs/PxmVfQ',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupodezkontrol',
    napster: 'https://music.amazon.com.mx/artists/B079SXZ9NF/grupo-dezkontrol',
  },
]

export default CardData26
